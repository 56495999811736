import React from "react";
import "./genericModal.css";

const GenericModal = ({ children, onClose, showClose = true }) => {

    const overlayClicked = (e) => {
        if (e.target.className === "overlay") {
             onClose();
         } else {
             return;
         }
     };
    
    return (
        <div className="gen-modal-comp">
            <div className="overlay" onClick={(e) => overlayClicked(e)}>
                <div className="content">{children}</div>
            </div>
        </div>
    );
};

export default GenericModal;
