import React, { useEffect, useState } from 'react';
import BackButton from '../../components/backButton/backButton';
import LeftPanel from '../../components/left-panel/left-panel';
import PaymentSelection from "../../components/paymentSelection/paymentSelection"
import "../../globalStyles/globalStyles.css"
import { useHistory } from "react-router-dom";
import storage from "../../components/utils/storage";

const Payment = (props) => {
    let history = useHistory();
     const [isMweb, setIsMweb] = useState(false);

     function getProductItemId() {
        var url = new URL(window.location.href);
        return url.searchParams.get("product");
      }

     useEffect(() => {
        if (!storage.isLoggedIn()) {
            // history.goBack();
            if(getProductItemId()){
                history.replace("/user/login?source=ola&product="+getProductItemId());
              }else{
                history.replace("/user/login?source=ola");
              }
          }
         handleResize();
         window.addEventListener("resize", handleResize);
     }, []);

     const handleResize = () => {
         if (window.innerWidth <= 439) {
             setIsMweb(true);
         } else {
             setIsMweb(false);
         }
     };
    return (
        <div className="main-container">
            {isMweb ? (
                <div className="common-rightside">
                    <BackButton />
                    <PaymentSelection data={props.location.state} prepaymentValue={JSON.parse(
                          localStorage.getItem("prepaymentValue")
                      )}/>
                </div>
            ) : (
                <>
                    <LeftPanel
                        selectedModalData={JSON.parse(
                            localStorage.getItem("selectedModalData")
                        )}
                        currentColor={JSON.parse(
                            localStorage.getItem("currentColorObject")
                        )}
                    />
                    <div className="common-rightside">
                        <BackButton />
                        <PaymentSelection data={props.location.state} prepaymentValue={JSON.parse(
                          localStorage.getItem("prepaymentValue")
                      )}/>
                    </div>
                </>
            )}
        </div>
    );
}

export default Payment;