import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import { useInput } from "../../../hooks/inputHook";
import Data from "../../../utils/data";
import api from "../../../utils/api";

const Review = (props) => {
  const [price, setPrice] = useState("");
  const [quality, setQuality] = useState("");
  const [value, setValue] = useState("");
  const { value: name, bind: bindName } = useInput("");
  const { value: summary, bind: bindSummary } = useInput("");
  const { value: review, bind: bindReview } = useInput("");

  function displayStars(length) {
    const items = [];
    var i;
    for (i = 0; i < 5; i++) {
      if (i < length) {
        items.push(<FontAwesomeIcon icon="star" />);
      } else {
        items.push(<FontAwesomeIcon icon={farStar} />);
      }
    }
    return items;
  }
  function onValueChange(event) {
    if (event.target.name == "price") {
      setPrice(event.target.value);
    } else if (event.target.name == "quality") {
      setQuality(event.target.value);
    } else if (event.target.name == "value") {
      setValue(event.target.value);
    }
    // this.setState({
    //   selectedOption: event.target.value
    // });
  }
  const formSubmit = (evt) => {
    evt.preventDefault();
    fetch(Data.API_BASE_URL + "post-reviews", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        name: name,
        quality: quality,
        price: price,
        value: value,
        product_id: props.product.id,
        summary: summary,
        review: review,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        console.log(response);
        if (response.statusCode === 200) {
          window.scrollTo(0, 0);
          console.log(response);
          props.setMessage(response.message);
          props.setShowMessage(true);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    <div className="review-wrapper">
      <div className="product-reviews clearfix">
        <div className="ratings-wrp">
          <div className="rating-circle">
            <div className="rating-content">
              <span className="total">
                {props.product.ratings ? props.product.ratings : 0}
              </span>
              <div className="rating">
                <div className="rating-details in-block">
                  <div className="rating-stars" ratings={props.product.ratings}>
                    <div className="grey-stars-m"></div>
                    <div
                      className="filled-stars-m"
                      style={{ width: "91%" }}
                    ></div>
                  </div>
                  <span className="votes">
                    <span>{props.product.reviews.length}</span> Votes
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="rating-progress">
            <div className="reviews-content-left">
              <h2>Customer Reviews</h2>

              {props.product.reviews.length > 0
                ? props.product.reviews.map(function (review, index) {
                    return (
                      <div className="review-ratting">
                        <p>
                          <a
                            href="#"
                            style={{
                              pointerEvents: "none",
                            }}
                          >
                            {review.review}{" "}
                          </a>{" "}
                          Review by Customer
                        </p>
                        <table>
                          <tbody>
                            <tr>
                              <th>Price</th>
                              <td>
                                <div className="rating">
                                  {displayStars(review.price)}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Value</th>
                              <td>
                                <div className="rating">
                                  {displayStars(review.value)}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Quality</th>
                              <td>
                                <div className="rating">
                                  {displayStars(review.quality)}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="author">
                          {review.name}
                          <small> ({review.posted_on})</small>
                        </p>
                      </div>
                    );
                  })
                : "No reviews yet on this product"}
            </div>
          </div>
        </div>
        <div className="rating--w-wrp">
          <div className="reviews-content-right" onSubmit={formSubmit}>
            <h2>Write Your Own Review</h2>
            <form acceptCharset="UTF-8">
              <h3>
                You're reviewing: <span>{props.product.name}</span>
              </h3>
              <h4>
                How do you rate this product?<em>*</em>
              </h4>
              <div
                className="table-responsive reviews-table"
                style={{ overflowX: "auto" }}
              >
                <table>
                  <tbody>
                    <tr>
                      <th></th>
                      <th>1 star</th>
                      <th>2 stars</th>
                      <th>3 stars</th>
                      <th>4 stars</th>
                      <th>5 stars</th>
                    </tr>
                    <tr>
                      <td>Quality</td>
                      <td>
                        <input
                          type="radio"
                          name="quality"
                          required
                          value="1"
                          onChange={onValueChange}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          name="quality"
                          required
                          value="2"
                          onChange={onValueChange}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          name="quality"
                          required
                          value="3"
                          onChange={onValueChange}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          name="quality"
                          required
                          value="4"
                          onChange={onValueChange}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          name="quality"
                          required
                          value="5"
                          onChange={onValueChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Price</td>
                      <td>
                        <input
                          type="radio"
                          name="price"
                          required
                          value="1"
                          onChange={onValueChange}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          name="price"
                          required
                          value="2"
                          onChange={onValueChange}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          name="price"
                          required
                          value="3"
                          onChange={onValueChange}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          name="price"
                          required
                          value="4"
                          onChange={onValueChange}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          name="price"
                          required
                          value="5"
                          onChange={onValueChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Value</td>
                      <td>
                        <input
                          type="radio"
                          name="value"
                          required
                          value="1"
                          onChange={onValueChange}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          name="value"
                          required
                          value="2"
                          onChange={onValueChange}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          name="value"
                          required
                          value="3"
                          onChange={onValueChange}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          name="value"
                          required
                          value="4"
                          onChange={onValueChange}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          name="value"
                          required
                          value="5"
                          onChange={onValueChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="form-area">
                <div className="form-element">
                  <label>
                    Nickname <em>*</em>
                  </label>
                  <input
                    type="text"
                    name="name"
                    required=""
                    {...bindName}
                    required
                  />
                </div>
                <div className="form-element">
                  <label>
                    Summary of Your Review <em>*</em>
                  </label>
                  <input type="text" name="summary" required {...bindSummary} />
                </div>
                <div className="form-element">
                  <label>
                    Review <em>*</em>
                  </label>
                  <textarea name="review" required {...bindReview}></textarea>
                </div>
                <div className="buttons-set">
                  <button
                    className="button submit"
                    title="Submit Review"
                    type="submit"
                  >
                    <span>
                      <i className="fa fa-thumbs-up"></i> &nbsp;Review
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
