import React from "react";

import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ListProduct = (props) => {
  return (
    <React.Fragment>
      <div className="sdg-ratio  ">
        <Link
          to={
            "/product-" +
            props.product.name.replace(/[#+. _/]/gi, "-").toLowerCase() +
            "-" +
            props.product.id
          }
          title={props.product.name}
          data-pjax=".pjax-pdp"
          className="ratio-box"
        >
          <LazyLoadImage
            src={props.product.featured_image}
            alt={props.product.name}
            className="loaded main-image"
            onMouseOver={(e) =>
              (e.currentTarget.src = props.product.alt_featured_image)
            }
            onMouseOut={(e) =>
              (e.currentTarget.src = props.product.featured_image)
            }
          />
        </Link>
      </div>
      {props.product.discount_percent > 0 ? (
        <div className="discount-holder first">
          <span className="product-disc">
            Save {props.product.discount_percent}%
          </span>
        </div>
      ) : (
        ""
      )}
      {/* {console.log(props.product)} */}
      {props.product.free != null ? (
        <div className="discount-holder badge-deals badge-item0">
          <span
            className="product-disc text-center"
            style={{
              backgroundColor: "#95ffb5",
              color: "#000000",
            }}
          >
            <LazyLoadImage
              src={props.product.free.image}
              height="50px"
              className="free-product"
              style={{ objectFit: "cover", margin: "0 auto" }}
            />
            Free
          </span>
        </div>
      ) : (
        ""
      )}
      <div className="hover-actions">
        <div className="carousel-details">
          <Link
            to={
              "/product-" +
              props.product.name.replace(/[#+. _/]/gi, "-").toLowerCase() +
              "-" +
              props.product.id
            }
            title={props.product.name}
            alt={props.product.name}
          >
            <div className="slider--prd-info">
              <h4 className="name">
                <em>{props.product.brand}</em> {props.product.name}
                <br />
                {props.product.model_number}
              </h4>
            </div>
          </Link>
        </div>
      </div>
      {props.product.new == 1 ? (
        <div className="dg-privilege__holder left">
          <span className="product-disc">New</span>
        </div>
      ) : (
        ""
      )}
      {props.product.product_type === "Combo" ? (
        <div className="discount-holder combo-ribbon">
          <div className="product-disc">
            <span className="onsale">Bundle</span>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="slider-extra--wrp">
        <div className="slider-extra">
          {props.product.ratings != null ? (
            <div className="rating-badge">
              <div
                className="rating title-review"
                itemProp="aggregateRating"
                itemScope=""
                itemType="http://schema.org/AggregateRating"
              >
                <div className="rating-details rating-details-block in-block prod-extra">
                  <div className="rating-stars">
                    <div className="grey-stars"></div>
                    <div
                      className="filled-stars"
                      style={{
                        width: (props.product.ratings / 5) * 100 + "%",
                      }}
                    ></div>
                  </div>
                  <span className="product-rating-count" itemProp="ratingValue">
                    ({props.product.ratings})
                  </span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <span className="algolia-instock" style={{ fontSize: "10px" }}>
            Collect CG|Coins:{" "}
            <NumberFormat
              displayType={"text"}
              thousandSeparator={true}
              thousandsGroupStyle="lakh"
              value={props.product.loyalty_point}
            />
          </span>

          <br />
          {props.product.emi_price != null ? (
            <span className="algolia-instock ">
              EMI: NPR{" "}
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                value={props.product.emi_price}
              />{" "}
            </span>
          ) : (
            ""
          )}
          <br />
          {props.product.availability == 1 ? (
            <span className="algolia-instock ">
              <span className="bullet">&#9679;</span> In stock
            </span>
          ) : (
            <span className="algolia-outofstock">
              <span className="bullet">&#9679;</span> Out of stock
            </span>
          )}
        </div>
        {props.product.discount_percent != null ? (
          <div className="product-price">
            <div className="cross-price">
              <span className="currency">NPR</span>
              <span className="strike">
                <NumberFormat
                  displayType={"text"}
                  thousandSeparator={true}
                  thousandsGroupStyle="lakh"
                  value={props.product.price}
                />
              </span>
            </div>
            <div className="price">
              <span className="currency">NPR</span>
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                value={props.product.discount_price}
              />
            </div>
          </div>
        ) : (
          <div className="product-price">
            <div className="price">
              <span className="currency">NPR</span>
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                value={props.product.price}
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ListProduct;
