import React, { useState, useEffect, useContext } from "react";
import { useInput } from "../hooks/inputHook";
import { useHistory, Link } from "react-router-dom";
import Data from "../utils/data";
import Api from "../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClipLoader from "react-spinners/ClipLoader";
import storage from "../utils/storage";
import cartFunction from "../utils/cartFunction";
import wishlistFunction from "../utils/wishlistFunction";
import { USER_REGISTER_BY_MOBILE, USER_FORGET } from "../route/routeURL";
import { LoyaltyContext } from "../Layouts/LoyaltyProvider/LoyaltyContextProvider";
import { CartContext } from "../Layouts/CartContextProvider";
const CustomerLogin = (props) => {
  let history = useHistory();
  const { refreshLoyaltyApi } = useContext(LoyaltyContext);
  const { refreshApi } = useContext(CartContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { value: username, bind: bindUsername } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");
  const handleSubmit = (evt) => {
    evt.preventDefault();
    fetch(Data.API_BASE_URL + "user-login", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        if (response.statusCode == 200) {
          storage.login(response.data);
          if (new URLSearchParams(props.location.search).get("cart")) {
            var productId = new URLSearchParams(props.location.search).get(
              "cart"
            );
            if (productId) {
              productId = productId.split("-");
              productId = productId[productId.length - 1];
              return addProductToCart(productId);
            }
          } else if (
            new URLSearchParams(props.location.search).get("wishlist")
          ) {
            var productId = new URLSearchParams(props.location.search).get(
              "cart"
            );
            if (productId) {
              productId = productId.split("-");
              productId = productId[productId.length - 1];
              return addProductToWishlist(productId);
            }
          }
          refreshApi();
          refreshLoyaltyApi();
          history.replace("/user/account/");
        } else {
          setErrorMessage(response.message);
          console.log(errorMessage);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  async function addProductToWishlist(productId) {
    let data = await wishlistFunction.addToWishlist(productId);
    if (data) {
      return history.push({
        pathname: "/user/wishlist",
      });
    }
  }
  async function addProductToCart(productId) {
    let data = await cartFunction.addToCart(productId);
    if (data) {
      return history.push({
        pathname: "/user/cart",
      });
    }
  }
  useEffect(() => {
    setIsLoading(false);
  }, []);
  return (
    <main id="content" className="site-content main-content" role="main">
      <div className="sharaf-wrp" id="spjax">
        {isLoading ? (
          <div
            className="container"
            style={{ textAlign: "center", padding: "100px 0px" }}
          >
            <ClipLoader
              css={{ margin: "0 auto" }}
              size={150}
              //size={"150px"} this also works
              color={"#123abc"}
              loading={isLoading}
            />
          </div>
        ) : (
          <React.Fragment>
            <div className="container">
              <div id="primary" className="content-area">
                <main id="main" className="site-main" role="main">
                  <div id="post-7">
                    <div className="entry-content">
                      <div className="woocommerce">
                        <div className="sh-reg">
                          <div className="container">
                            <div className="reg-container loginpage-wrp">
                              <form
                                className="box"
                                id="loginform"
                                onSubmit={handleSubmit}
                              >
                                <div className="login-text--wrp">
                                  <h1 className="headline">Login</h1>
                                </div>
                                <div className="login-button-container"></div>
                                <div className="register-input-item">
                                  <input
                                    type="text"
                                    className="input-text register-user-input-name register-user-input"
                                    name="username"
                                    id="username"
                                    {...bindUsername}
                                    placeholder="Mobile/Email address"
                                    required
                                  />
                                </div>
                                <div className="register-input-item">
                                  <input
                                    className="input-text register-user-input-name register-user-input"
                                    type="password"
                                    name="password"
                                    id="password"
                                    {...bindPassword}
                                    placeholder="Password"
                                    autocomplete="new-password"
                                    required
                                  />
                                </div>
                                <div
                                  className="register-input-item"
                                  style={{
                                    color: "red",
                                    fontSize: "13px",
                                  }}
                                >
                                  {errorMessage ? (
                                    <React.Fragment>
                                      <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                                      {errorMessage}{" "}
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="register-register-button-container">
                                  <input
                                    type="submit"
                                    className="button register-register-button btn btn-secondary"
                                    name="login"
                                    value="Login"
                                  />
                                </div>
                                <div className="forgot-link">
                                  <Link to={USER_FORGET}>Forgot password?</Link>
                                </div>

                                <input type="hidden" name="redirect" value="" />
                              </form>
                              <p className="login-info-text">
                                <span className="line">
                                  <span className="or-text">OR</span>
                                </span>
                              </p>

                              {/* <div className="wc-social-login form-row-wide">
                                <p></p>
                                <a
                                  href="//uae.sharafdg.com/?wc-api=auth&#038;start=facebook&#038;return=http%3A%2F%2Fuae.sharafdg.com%2Fmy-account%2F"
                                  className="button-social-login button-social-login-facebook"
                                >
                                  <span className="si si-facebook"></span>Log in
                                  with Facebook
                                </a>{" "}
                                <a
                                  href="//uae.sharafdg.com/?wc-api=auth&#038;start=google&#038;return=http%3A%2F%2Fuae.sharafdg.com%2Fmy-account%2F"
                                  className="button-social-login button-social-login-google"
                                >
                                  <span className="si si-google"></span>Log in
                                  with Google
                                </a>
                              </div> */}

                              <div className="text-center create-account">
                                <span className="register-info-text">
                                  New to CG Digital?
                                </span>
                                <Link
                                  className="btn btn-primary"
                                  to={USER_REGISTER_BY_MOBILE}
                                >
                                  Create Account
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>

            <div className="container search-loading hide">
              <div className="search-row ">
                <div className="row">
                  <div className="col-md-3">
                    <div className="col s6">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col s6">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col s6">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col s6">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </main>
  );
};

export default CustomerLogin;
