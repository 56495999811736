import React, { useEffect, useState } from "react";
import "./desktopSlider.css";

const DesktopSlider = ({ image_carousal_list }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(image_carousal_list[0]);

  useEffect(() => {
    setCurrentImage(image_carousal_list[currentIndex]);
  }, [image_carousal_list]);

  const preClick = () => {
    if (currentIndex - 1 < 0) {
      setCurrentImage(image_carousal_list[image_carousal_list.length - 1]);
      setCurrentIndex(image_carousal_list.length - 1);
    } else {
      setCurrentImage(image_carousal_list[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);
    }
  };
  const nextClick = () => {
    if (currentIndex + 1 > image_carousal_list.length - 1) {
      setCurrentImage(image_carousal_list[0]);
      setCurrentIndex(0);
    } else {
      setCurrentImage(image_carousal_list[currentIndex + 1]);
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div className="desktop-slider">
      <div className="slick-prev" onClick={preClick}></div>
      <div className="image-container">
        <div
          className={`carousel-image`}
          style={{
            background: `url(${currentImage})`,
          }}
        ></div>
      </div>
      <div className="shadow-container"></div>

      <div className="slick-next" onClick={nextClick}></div>
    </div>
  );
};

export default DesktopSlider;
