import React from "react";
import "./backButton.css";
import { useHistory } from "react-router-dom";

const BackButton = ({url}) => {
  const { goBack } = useHistory();
  return (
    <div
      className="backButton-cont"
      onClick={goBack}
    >
      {console.log(goBack)}
      <img src="https://d34kmefuuy0be0.cloudfront.net/ev_assets/long_back_arrow_38efed2689.svg" />
    </div>
  );
};

export default BackButton;
