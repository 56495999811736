import React from "react";
import "./noPage.css";

const NoPage = () => {
  return (
    <div className="nopage-cont">
      <div className="box">
        <h1 className="404">404</h1>
        <div className="text">This page could not be found.</div>
      </div>
    </div>
  );
};

export default NoPage;
