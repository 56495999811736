import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { isIOS } from "react-device-detect";
import popUpImage from "../../../image/app-download-buttons.jpg";
import storage from "../../utils/storage";
const PopUp = (props) => {
    const node = useRef();
    const [active, setActive] = useState(true);
    useEffect(() => {


        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                closeModal();
            }
        };
        window.addEventListener('keydown', handleEsc);
        document.addEventListener("mousedown", handleClick);

        return () => {
            window.removeEventListener('keydown', handleEsc);
            document.removeEventListener("mousedown", handleClick);
        };

    }, []);
    const handleClick = e => {
        if (node.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click 
        closeModal();
    };
    function closeModal() {
        storage.save('pop_up', new Date().toLocaleString());
        setActive(false);
    }
    return (
        <React.Fragment>
            {/* {props.active == "active" ? setActive(true) : ""} */}
            <div className={active ? "modal modal-bottom fade in show" : "modal modal-bottom fade"} id={props.id} tabIndex="-1" style={props.active ? { transform: 'translate(0px, 0px)', display: 'block' } : {}}>
                <div ref={node} className={props.dataSection === "large" ? "modal-dialog modal-dialog-large" : "modal-dialog"}>
                    <div className="modal-content">
                        <div className="row">
                            <div className="col-lg-12" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                {/* <h4 className="text-center">Download CG Digital App</h4> */}
                                <a href={isIOS ? "https://apps.apple.com/us/app/cg-digital/id1495822676?ls=1" : "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.cgtechnodreams.cgdigital.app"}>
                                    <img src={popUpImage} height="140px" style={{ width: "100%", objectFit: "cover" }} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PopUp;
