
import React, {useEffect} from "react";
import { Switch, Route, withRouter } from "react-router-dom";
// import Homepage from "../pages/homepage/homepage";
import Login from "../pages/login/login";
import NoPage from "../pages/noPage/noPage";
import SignUp from "../pages/signup/signup";
import ShippingAddress from "../pages/ShippingAddress/shippingAddress";
import Payment from "../pages/payment/payment";
import MyReservations from "../pages/myReservations/myReservations";
import PriceSummary from "../pages/priceSummary/priceSummary";
import Test from "../pages/priceSummary/Test";
import "../ola.css";

const Routers = () => {

  return (
 
      <Switch>
        {/* <Route path="/" element={<Homepage />} /> */}
        <Route path="/user/login" component={Login} />
        <Route path="/user/register" component={SignUp} />
        <Route path="/shippingAddress" component={ShippingAddress} />
        <Route path="/payment" component={Payment} />
        <Route path="/myReservations" component={MyReservations} />
        <Route path="/priceSummary" component={PriceSummary} />
        <Route path="/test" component={Test} />
        <Route path="*" component={NoPage} />
      </Switch>
  
  );
}


export default withRouter(Routers);