import React, { useEffect, useState } from "react";
import Data from "../../utils/data";
import api from "../../utils/api";
const LoyaltyHistory = (props) => {
  const [loyalty, setLoyalty] = useState([]);

  async function fetchOrders() {
    let url = new URL(Data.API_BASE_URL + "loyalty-history");
    await fetch(url, {
      method: "GET",
      headers: Data.API_HEADER,
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setLoyalty(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    fetchOrders();
  }, []);
  return (
    <React.Fragment>
      <h3 className="text-center">Loyalty Point History</h3>
      <form className="ng-pristine ng-valid">
        <div className="table-responsive">
          <table className="table order-table table-striped">
            <tbody className="panel-group accordion-faq" id="faq-accordion">
              <tr>
                <th>
                  <center>
                    <b>Date</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Order ID</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Loyalty Point</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Total Point</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Remarks</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Status</b>
                  </center>
                </th>
              </tr>
              {loyalty.map(function (loyal, index) {
                return (
                  <React.Fragment key={index}>
                    <tr className="panel">
                      <td>
                        <center>{loyal.created_at}</center>
                      </td>
                      <td>
                        <center>{loyal.order_id}</center>
                      </td>
                      <td>
                        <center>{loyal.loyalty_points}</center>
                      </td>
                      <td>
                        <center>{loyal.total_points}</center>
                      </td>
                      <td>
                        <center>{loyal.remarks}</center>
                      </td>
                      <td>
                        <center>{loyal.status}</center>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </form>
    </React.Fragment>
  );
};
export default LoyaltyHistory;
