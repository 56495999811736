import React, { useState, useMemo } from "react";
import Data from "../../utils/data";
import api from "../../utils/api";
import SearchBlock from "./SearchBlock";

import { useHistory } from "react-router-dom";
const HomeSearch = () => {
  const [searchData, setSearchData] = useState([]);
  const [search, setSearch] = useState("");
  const [showResults, setShowResults] = useState(false);

  let history = useHistory();
  const onChangeSearch = (evt) => {
    setShowResults(false);
    let value = evt.target.value;
    setSearch(value);
    if (value.length > 2) {
      searchProduct(value);
    }
  };
  function searchProduct(search) {
    let params = {};
    params.keywords = search;
    let url = new URL(Data.API_BASE_URL + "web-search");
    url.search = new URLSearchParams(params).toString();
    fetch(url, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setShowResults(true);
        setSearchData(response.data.products);
      })
      .catch((error) => {
        console.log(error);
        // history.push("/page-not-found");
      });
  }
  function _onBlur(e) {
    setTimeout(function () {
      setShowResults(false);
    }, 500);
  }
  const onClickSearch = (evt) => {
    evt.preventDefault();
    setShowResults(false);
    history.push({
      pathname: "/search/" + search,
    });
    return false;
  };

  return (
    <div className="navbar-search site-search">
      <div className="search-form">
        <div className="widget widget_product_search">
          <form
            className="product-search"
            onSubmit={(evt) => onClickSearch(evt)}
            autoComplete="off"
          >
            <div className="input-group search-wrap">
              <input
                type="search"
                className="search-field form-control js-site-search-input"
                placeholder="Search Products&hellip;"
                // name="search"
                onChange={onChangeSearch}
                onBlur={_onBlur}
              />
              {showResults ? (
                <SearchBlock
                  products={searchData.slice(0, 5)}
                  // onBlur={_onBlur}
                />
              ) : (
                ""
              )}
              <i className="sh sh-search"></i>
            </div>
            <button type="submit" className="btn btn-secondary search-btn">
              Search
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HomeSearch;
