import React, { useState, useEffect } from "react";
import FloatingInput from "../FloatingInput/floatingInput";
import GenericModal from "../genericModal/genericModal";
import "./loginPage.css";
import ForgotPassword from "../forgotPassWord/forgotpassword";
import { useHistory } from "react-router-dom";
import Data from "../utils/data";
import Api from "../utils/api";
import storage from "../utils/storage";

const LoginPage = () => {
  const history = useHistory();
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [product, setProduct] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);


  const handleValidation = (evnt) => {};


  const handleSubmit = (evnt) => {
    evnt.preventDefault();
    setErrorMessage('');
    if(email == "" || password == ""){
      setErrorMessage("Please enter email and password!");
    }else{
      fetch(Data.API_BASE_URL + "login", {
        method: "POST",
        headers: Data.API_HEADER,
        body: JSON.stringify({
          email: email,
          password: password,
          source: 'ola',
          product: product
        }),
      })
        .then(Api.handleResponseAndError)
        .then((response) => {
          if (response.statusCode == 200) {
            storage.login(response.data);
            // localStorage.setItem('shipping_address', null);
            localStorage.removeItem('shipping_address');
            if(response.data.product){
              history.replace("/shippingAddress?source="+response.data.source+"&product="+response.data.product);
            }else{
              history.replace("/myReservations?source="+response.data.source);
            }
          } else {
            setErrorMessage(response.message);
            // console.log(errorMessage);
            // 
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }

  };

  function getProductItemId() {
    var url = new URL(window.location.href);
    return url.searchParams.get("product");
  }

  useEffect(() => {
    setProduct(getProductItemId());
  }, []);

  return (
    <>
    <form
      onSubmit={handleSubmit}
    >
      <div className="login-wrapper">
        <div className="login-title">Fast forward to the future</div>
        <div className="login-desc">
          Login with your registered Email or create a new account
        </div>
        <div className="input-boxes">
          <FloatingInput
            type="email"
            label="Email"
            value={email}
            setValue={setEmail}
            handleValidation={handleValidation} 
          />
          <FloatingInput
            type="password"
            label="Password"
            value={password}
            setValue={setPassword}
            handleValidation={handleValidation} 
          />
        </div>
       
        
        <div className="forgot-wrapper">
          <div onClick={() => setShowForgotModal(!showForgotModal)}>
            Forgot password?
          </div>
        </div>
        {errorMessage ? (<><p style={{'color':'red'}}>{errorMessage}</p></>) : ""}
        <div className="buttons-container">
          <button
            className="login-button">
            Login to proceed
          </button>
          <div className="or-cotainer">
            <div>
              <hr />
            </div>
            <div className="or-title">OR</div>
            <div>
              <hr />
            </div>
          </div>
          {product 
          ? <button type="button"
          className="signup-button"
          onClick={() => (window.location.href = "/user/register?source=ola&product="+product)}> Sign up</button>
          : <button type="button"
            className="signup-button"
            onClick={() => (window.location.href = "/user/register?source=ola")}> Sign up</button>}
      
        </div>
      </div>
      {showForgotModal && (
        <GenericModal showClose={false}>
          <ForgotPassword setShowPasswordPopup={setShowForgotModal} email={email} />
        </GenericModal>
      )}
      </form>
    </>
  );
};

export default LoginPage;
