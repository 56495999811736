import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import NextSlider from "../../Layouts/Silder/NextSlider";
import PrevSlider from "../../Layouts/Silder/PrevSlider";
import SliderProduct from "./SliderProduct/SliderProduct";
class Featured extends Component {
  state = {};
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 6,
      autoplay: true,
      autoplaySpeed: 4000,
      nextArrow: <NextSlider />,
      prevArrow: <PrevSlider />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const { products } = this.props;
    return (
      <div className="container other-product product-list-grid">
        <div className="product-scroller">
          <div className="product-container">
            <ul className="nav tabs">
              <li className="tab">
                <a href="#attachment1" className="active">
                  Feature
                </a>
              </li>
            </ul>
            <div
              className="product-tab"
              id="attachment1"
              style={{ display: "block" }}
            >
              <Slider {...settings}>
                {products.map(function (product, index) {
                  return (
                    <div className="slide" key={index}>
                      <SliderProduct product={product} />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Featured;
