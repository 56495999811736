import React, { useEffect, useState } from "react";

import Ola from "./Ola";
import Digi from "./Digi";
import { useHistory } from "react-router-dom";
import Data from "./utils/data";
import Api from "./utils/api";

const Main = () => {
  let history = useHistory();

  const [source, setSource] = useState("");
  const [ola, setOla] = useState(false);

  function getSourceParam() {
    var url = new URL(window.location.href);
    var source = url.searchParams.get("source");
    var product = url.searchParams.get("product");
    setSource(source);
    if (source == "ola") {
      if (product) {
        fetchProduct();
      } else {
        localStorage.setItem(
          "selectedModalData",
          JSON.stringify({
            data: {
              catalog_pricing_details: [
                {
                  product_overview: [
                    {
                      title: "TOP SPEED",
                      desc: "95 [km/h]",
                    },
                    {
                      title: "0 to 40 km/h",
                      desc: "3.8 [sec]",
                    },
                    {
                      title: "ARAI CERTIFIED / OLA TRUE RANGE",
                      desc: "141 [km] / 128 [km]",
                    },
                  ],
                  araiData: [
                    {
                      title: "What is ARAI certified range?",
                      description:
                        "ARAI is the official body that independently tests all mass produced vehicles under defined test conditions. Please note that ARAI results usually vary 25-40% compared to real world results.",
                    },
                    {
                      title: "Ola True Range",
                      description:
                        " The ARAI certified range of Ola S1 Pro is 181 km, and the True Range for Ola S1 Pro is 170 km. True Range is the distance you can travel with your Ola scooter under certain conditions of one rider with an average weight of 70 kg in city driving conditions with max 3-5% incline riding in Normal mode under moderate temperature (24-35 °C) conditions.",
                    },
                  ],
                },
              ],
            },
          })
        );
        localStorage.setItem(
          "currentColorObject",
          JSON.stringify({
            metadata: {
              image_carousal_list: [
                "https://olaelectric-videos.azureedge.net/olaelectric-videos/discovery-new-final/buyingfloor/white0copy.jpg",
                "https://olaelectric-videos.azureedge.net/olaelectric-videos/discovery-new-final/buyingfloor/white1copy.jpg",
                "https://olaelectric-videos.azureedge.net/olaelectric-videos/discovery-new-final/buyingfloor/white2copy.jpg",
                "https://olaelectric-videos.azureedge.net/olaelectric-videos/discovery-new-final/buyingfloor/white3copy.jpg",
                "https://olaelectricvideos.blob.core.windows.net/olaelectric-videos/discovery-new/new-slider-images-s1/White4.jpg",
                "https://olaelectricvideos.blob.core.windows.net/olaelectric-videos/discovery-new/new-slider-images-s1/White5.jpg",
              ],
            },
          })
        );
        localStorage.setItem("prepaymentValue", JSON.stringify(null));
        setOla(true);
      }
    } else {
      // jivoChat();
    }
  }

  function jivoChat() {
    var widget_id = "YGfrzQAnAY";
    var d = document;
    var w = window;

    function l() {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "//code.jivosite.com/script/widget/" + widget_id;
      var ss = document.getElementsByTagName("script")[0];
      ss.parentNode.insertBefore(s, ss);
    }
    if (d.readyState == "complete") {
      l();
    } else {
      if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  }

  async function fetchProduct() {
    await fetch(Data.API_BASE_URL + "ola/product/" + getProductItemId(), {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        console.log(response);
        if (response.status == "fail") {
          // alert('inactive product');
          history.push("no-page");
        } else {
          localStorage.setItem(
            "selectedModalData",
            JSON.stringify(response.selectedModalData)
          );
          localStorage.setItem(
            "currentColorObject",
            JSON.stringify(response.currentColor)
          );
          localStorage.setItem(
            "prepaymentValue",
            JSON.stringify(response.prepaymentValue)
          );

          console.log(JSON.parse(localStorage.getItem("selectedModalData")));
          setOla(true);
        }
      })
      .catch((error) => {
        console.log(error);
        // return false;
      });
  }

  function getProductItemId() {
    var url = new URL(window.location.href);
    return url.searchParams.get("product");
  }

  useEffect(() => {
    getSourceParam();
  }, []);

  return (
    <React.Fragment>
      {source == "ola" ? <>{ola == true ? <Ola /> : <></>}</> : <Digi />}
    </React.Fragment>
  );
};

export default Main;
