import React from "react";
import MessageContext from "./MessageContext";

const WithContext = (Component) => {
  return (props) => (
    <MessageContext.Consumer>
      {(value) => <Component {...props} value={value} />}
    </MessageContext.Consumer>
  );
};
export default WithContext;
