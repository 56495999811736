import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cartFunction from "../../utils/cartFunction";

import { useHistory } from "react-router-dom";
const PlusMinus = (props) => {
  let history = useHistory();
  const [count, setCount] = useState(1);
  async function handleSubmit(evt, product) {
    evt.preventDefault();
    console.log(product);
    let data = await cartFunction.addToCart(product.product_id, count);
    if (data) {
      return history.push({
        pathname: "/user/cart",
      });
    }
  }
  function addProductCount() {
    setCount(count + 1);
  }
  function lessProductCount() {
    if (count > 1) setCount(count - 1);
  }
  return (
    <form
      className="ng-pristine ng-valid"
      onSubmit={(evt) => handleSubmit(evt, props.product)}
    >
      <div className="cart-plus-minus">
        <label htmlFor="qty">Quantity:</label>
        <div className="numbers-row ">
          <button
            type="button"
            style={{
              cursor: "pointer",
              padding: "12px 14px 9px 16px",
              display: "inline-block",
            }}
            className="dec qtybutton"
            onClick={() => lessProductCount()}
          >
            <FontAwesomeIcon icon="minus" />
          </button>
          <input
            style={{ display: "inline-block" }}
            className="qty"
            type="text"
            value={count}
            id="qty"
            readOnly
          />
          <button
            type="button"
            style={{
              cursor: "pointer",
              padding: "12px 14px 9px 16px",
              display: "inline-block",
            }}
            className=" inc qtybutton"
            onClick={() => addProductCount()}
          >
            <FontAwesomeIcon icon="plus" />
          </button>
        </div>
      </div>
      <button
        className="button pro-add-to-cart"
        title="Add to Cart"
        type="submit"
      >
        <span>
          <FontAwesomeIcon icon="shopping-cart" /> Add to Cart
        </span>
      </button>
    </form>
  );
};

export default PlusMinus;
