import React, { useState, useEffect } from "react";
import { useInput } from "../../../hooks/inputHook";
import Data from "../../../utils/data";
import api from "../../../utils/api";

const StoreConsultation = (props) => {
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const { value: name, bind: bindName } = useInput("");
  const { value: email, bind: bindEmail } = useInput("");
  const { value: number, bind: bindNumber } = useInput("");
  const { bind: bindProvinceId, setValue: setProvinceId } = useInput("");
  const { bind: bindCityId, setValue: setCityId } = useInput("");
  const { value: areaId, bind: bindAreaId } = useInput("");
  const { value: message, bind: bindMessage } = useInput("");
  function OnChangeProvice(e) {
    let provinceId = e.target.value;
    if (provinceId !== null && provinceId !== "") {
      setProvinceId(e.target.value);
      setCityId("");
      const cities = provinces.find((province) => province.id == provinceId).cities;
      setCities(cities);
    } else {
      setProvinceId("");
      setCities([]);
      setAreas([]);
    }
  }
  function OnChangeCity(e) {
    let cityId = e.target.value;
    if (cityId !== null && cityId !== "") {
      setCityId(e.target.value);
      const areas = cities.find((city) => city.id == cityId)
        .areas;
      setAreas(areas);
    } else {
      setCityId("");
      setAreas([]);
    }
  }
  function fetchProvinces() {
    fetch(Data.API_BASE_URL + "get-locations-with-areas", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setProvinces(response.data);
      })
      .catch((error) => {
        console.log(error);
        // history.push("/page-not-found");
      });
  }
  const handleSubmit = (evt) => {
    evt.preventDefault();
    fetch(Data.API_BASE_URL + "post-stock-consultation", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        name: name,
        email: email,
        number: number,
        area_id: areaId,
        product_id: props.product.id,
        queries: message,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        if (response.statusCode === 200) {
          var close = document.getElementById("close-modal");
          close.click();
          window.scrollTo(0, 0);
          props.setMessage(response.message);
          props.setShowMessage(true);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  useEffect(() => {
    fetchProvinces();
  }, []);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
              <div className="form-area">
                <input type="hidden" name="product_id" value="81" />

                <h4 style={{ textAlign: "center" }} id="product-name">
                  {props.product.name}
                </h4>
                <h5
                  style={{
                    marginBottom: "15px",
                    textAlign: "center",
                    padding: "0",
                  }}
                >
                  {props.product.model_number}
                </h5>
                <div className="row">
                  <div className="form-group col-md-4 col-xs-6">
                    <label htmlFor="name">
                      Name <span style={{ color: "#b30000" }}>&nbsp;*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter Name"
                      required
                      {...bindName}
                    />
                    <span className="error-message"></span>
                  </div>
                  <div className="form-group col-md-4 col-xs-6">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter Email"
                      {...bindEmail}
                    />
                    <span className="error-message"></span>
                  </div>
                  <div className="form-group col-md-4 col-xs-6">
                    <label htmlFor="email">
                      Phone <span style={{ color: "#b30000" }}>&nbsp;*</span>
                    </label>
                    <input
                      type="number"
                      name="number"
                      className="form-control"
                      placeholder="Enter Phone Number"
                      {...bindNumber}
                      required
                    />
                    <span className="error-message"></span>
                  </div>

                  <div className="form-group col-md-4 col-xs-6">
                    <label htmlFor="name">
                      Province <span style={{ color: "#b30000" }}>&nbsp;*</span>
                    </label>
                    <select
                      className="form-control select2"
                      id="province"
                      {...bindProvinceId}
                      onChange={OnChangeProvice}
                      required
                    >
                      <option defaultValue value="">Select Province</option>
                      {provinces.map(function (province, index) {
                        return (
                          <option key={index} value={province.id}>
                            {province.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="error-message"></span>
                  </div>
                  <div className="form-group col-md-4 col-xs-6">
                    <label htmlFor="email">
                      City <span style={{ color: "#b30000" }}>&nbsp;*</span>
                    </label>
                    <select
                      className="form-control select2"
                      id="city"
                      {...bindCityId}
                      onChange={OnChangeCity}
                      required
                    >
                      <option defaultValue value="">Select City</option>
                      {cities.map(function (city, index) {
                        return (
                          <option key={index} value={city.id}>
                            {city.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="error-message"></span>
                  </div>
                  <div className="form-group col-md-4 col-xs-6">
                    <label htmlFor="email">
                      Area/Place{" "}
                      <span style={{ color: "#b30000" }}>&nbsp;*</span>
                    </label>
                    <select
                      className="form-control select2"
                      id="area"
                      required
                      {...bindAreaId}
                    >
                      <option defaultValue value="">Select Area / Place</option>
                      {areas.map(function (area, index) {
                        return (
                          <option key={index} value={area.id}>
                            {area.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="error-message"></span>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="inputAddress">Message</label>
                  <textarea
                    name="message"
                    {...bindMessage}
                    className="form-control"
                    type="textarea"
                    id="message"
                    placeholder="Enquiry Message"
                    maxLength="140"
                    rows="7"
                  ></textarea>
                  <span className="error-message"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="submit"
            name="submit"
            className="btn btn-cgdigital pull-right"
          >
            Submit
          </button>
          <button
            type="button"
            id="close-modal"
            className="btn btn-danger pull-left"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default StoreConsultation;
