import React, { useEffect, useState } from "react";
import BackButton from "../../components/backButton/backButton";
import LeftPanel from "../../components/left-panel/left-panel";
import ShippingAddressInput from "../../components/shippingAddress/shippingAddress";
import "../../globalStyles/globalStyles.css";
import { useHistory } from "react-router-dom";
import storage from "../../components/utils/storage";
import Data from "../../components/utils/data";
import api from "../../components/utils/api";


const ShippingAddress = () => {
  let history = useHistory();
  let user = storage.getUser();

  const [isMweb, setIsMweb] = useState(false);

  const [isLoading, setIsloading] = useState(true);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [shippingAddress, setShippingAddress] = useState("");

  function getProvince() {
    setIsloading(true);
    fetch(Data.API_BASE_URL + "get-provinces?source=ola", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setProvinces(response.data);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchCities(province){
    // setIsloading(true);
    setCities([]);
    setAreas([]);
    fetch(Data.API_BASE_URL + "get-cities-via-province/" + province+"?source=ola", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setCities(response.data);
        // setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchAreas(city){
    setAreas([]);
    fetch(Data.API_BASE_URL + "get-areas-via-city/" + city+"?source=ola", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setAreas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getProductItemId() {
    var url = new URL(window.location.href);
    return url.searchParams.get("product");
  }


  useEffect(() => {
    if (!storage.isLoggedIn()) {
      // history.goBack();
      if(getProductItemId()){
        history.replace("/user/login?source=ola&product="+getProductItemId());
      }else{
        history.replace("/user/login?source=ola");
      }
    }else{
      getProvince();

      if (localStorage.getItem("shipping_address") != null) {
        
      
        fetchCities(JSON.parse(
          localStorage.getItem("shipping_address")
        ).province);
  
        fetchAreas(JSON.parse(
          localStorage.getItem("shipping_address")
        ).city);
  
        setShippingAddress(JSON.parse(
          localStorage.getItem("shipping_address")
        ));
        
  
      }else{
        setShippingAddress({
            'full_name' : user.name,
            'phone' : user.mobile,
            'email' : user.email,
            'province': "",
            'city' : "",
            'area' : "",
            'address' : user.address,
            'note' : ""
        });
      }
    }
    

    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth <= 439) {
      setIsMweb(true);
    } else {
      setIsMweb(false);
    }
  };
  return (
    <>
    {isLoading == false ? (
    <div className="main-container">
      {isMweb ? (
        <div className="common-rightside">
          <BackButton />
          <ShippingAddressInput shippingAddress={shippingAddress} provinces={provinces} cities={cities} areas={areas} fetchCities={fetchCities} fetchAreas={fetchAreas} />
        </div>
      ) : (
        <>
          <LeftPanel
            selectedModalData={JSON.parse(
              localStorage.getItem("selectedModalData")
            )}
            currentColor={JSON.parse(
              localStorage.getItem("currentColorObject")
            )}
          />
          <div className="common-rightside">
            <BackButton />
            <ShippingAddressInput shippingAddress={shippingAddress} provinces={provinces} cities={cities} areas={areas} fetchCities={fetchCities} fetchAreas={fetchAreas} />
          </div>
        </>
      )}
    </div>
    ) : ''}
    </>
  );
};

export default ShippingAddress;
