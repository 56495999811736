import React, { useEffect, useState } from "react";
import BackButton from "../../components/backButton/backButton";
import LeftPanel from "../../components/left-panel/left-panel";
import LoginPage from "../../components/loginPage/loginPage";
import storage from "../../components/utils/storage";
import { useHistory } from "react-router-dom";

import "../../globalStyles/globalStyles.css";


const Login = () => {
  let history = useHistory();
  const [isMweb, setIsMweb] = useState(false);
 
  const handleResize = () => {
    if (window.innerWidth <= 439) {
      setIsMweb(true);
    } else {
      setIsMweb(false);
    }
  };

  function getProductItemId() {
    var url = new URL(window.location.href);
    return url.searchParams.get("product");
  }

  useEffect(() => {
    if (storage.isLoggedIn()) {
      if(getProductItemId()){
        history.replace("/shippingAddress?source=ola&product="+getProductItemId());
      }else{
        history.replace("/myReservations?source=ola");
      }
      
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);


  return (
      <div className="main-container">
          {isMweb ? (
              <div className="common-rightside">
              
                  <BackButton/>
                  <LoginPage />
              </div>
          ) : (
              <>
                  <LeftPanel
                      selectedModalData={JSON.parse(
                          localStorage.getItem("selectedModalData")
                      )}
                      currentColor={JSON.parse(
                          localStorage.getItem("currentColorObject")
                      )}
                  />
                  <div className="common-rightside">
                    <BackButton/>
                      <LoginPage />
                  </div>
              </>
          )}
      </div>
  );
};

export default Login;
