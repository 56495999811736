import React, { useEffect, useState, useContext } from "react";
import { HOME, CART } from "../route/routeURL";
import { Link, useHistory } from "react-router-dom";
import storage from "../utils/storage";
import Data from "../utils/data";
import Api from "../utils/api";
import { BrowserView, MobileView } from "react-device-detect";
import UserLoginHeader from "./UserLoginHeader";
import NumberFormat from "react-number-format";
import HomeSearch from "./Search/HomeSearch";
import CompareHeader from "./CompareHeader";
import { MobileSidebar } from "./MobileSideBar/MobileSidebar";
import logo from "../../assets/img/logo.png";
import { MobileSearch } from "./MobileSearch/MobileSearch";
import { CartContext } from "./CartContextProvider";
import { isIOS } from "react-device-detect";
import popUpImage from "../../image/app-download-buttons.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { ScaleLoader } from "react-spinners";
const Header = (props) => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const { cartItem } = useContext(CartContext);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  let history = useHistory();
  function fetchCategories() {
    fetch(Data.API_BASE_URL + "get/categories", {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        setCategoriesData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function clickOpenSidebar(value = null) {
    if (value === true) {
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  }

  function setCategoriesData(data) {
    setCategories(data["category"]);
    setBrands(data["brands"]);
  }
  function getTotal() {
    let sum = 0;
    cartItem.forEach((product) => {
      sum += product.product_discount_price * product.qty;
    });
    return sum;
  }
  const goToCart = (evt) => {
    evt.preventDefault();

    if (storage.isLoggedIn()) {
      history.push("/user/cart");
    } else {
      history.push("/user/login");
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <React.Fragment>
      <BrowserView>
        <header className="site-header main-header" role="banner">
          <div className="new-header jank-fixed">
            <div className="top-header">
              <div className="container">
                <div className="navbar-header">
                  <a className="sr-only" href="#site-navigation">
                    Skip to navigation
                  </a>
                  <a className="sr-only" href="#content">
                    Skip to content
                  </a>
                  <Link to={HOME} rel="home">
                    <div className="navbar-brand site-title"></div>
                  </Link>
                  <HomeSearch />
                  <div className="navbar-right">
                    <div className="navbar-flag">
                      <div className="dropdown">
                        <div
                          className="flag-wrp dropdown-toggle"
                          id="flags"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <p className="country">
                            <i className="circle sh-country sh-flag-NEP"></i>
                            <span className="country-name">NEP</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <UserLoginHeader />
                    <div className="navbar-login">
                      <div className="login-wrp">
                        <div className="dropdown">
                          <div
                            className="dropdown-toggle"
                            // data-toggle="dropdown"
                            // aria-haspopup="true"
                            // aria-expanded="false"
                          >
                            <a
                              className="cartview decoration-none"
                              onClick={goToCart}
                              title="View your shopping cart"
                            >
                              <FontAwesomeIcon icon={faShoppingCart} style={{ transform:"scale(1.5)" }} />
                            </a>
                          </div>
                          <div
                            className="dropdown-menu"
                            style={{ minWidth: "310px" }}
                          >
                            <div
                              className="sign-dropdown"
                              style={{ maxHeight: "400px", overflow: "auto" }}
                            >
                              {cartItem != null && cartItem.length > 0 ? (
                                <React.Fragment>
                                  <h5>Recently added item(s)</h5>
                                  {cartItem.map(function (product, index) {
                                    return (
                                      <div className="row" key={index}>
                                        <div className="col-md-4">
                                          <img
                                            src={product.product_img}
                                            alt={product.product_name}
                                            width="65"
                                          />
                                        </div>
                                        <div className="col-md-8">
                                          {" "}
                                          <a
                                            href="#"
                                            title="Remove This Item"
                                            className="remove-cart"
                                          >
                                            <i className="icon-close"></i>
                                          </a>
                                          <p className="product-name">
                                            <Link
                                              to={
                                                "/product-" +
                                                product.product_name.replace(
                                                  /\s/g,
                                                  "-"
                                                ) +
                                                "-" +
                                                product.product_id
                                              }
                                            >
                                              {product.product_name}
                                            </Link>
                                          </p>
                                          <strong>{product.qty}</strong> x{" "}
                                          <span className="price">
                                            {" "}
                                            {product.product_discount > 0 ? (
                                              <NumberFormat
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                thousandsGroupStyle="lakh"
                                                prefix={"NPR "}
                                                value={
                                                  product.product_discount_price
                                                }
                                              />
                                            ) : (
                                              <NumberFormat
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                thousandsGroupStyle="lakh"
                                                prefix={"NPR "}
                                                value={product.product_price}
                                              />
                                            )}
                                          </span>{" "}
                                        </div>
                                      </div>
                                    );
                                  })}{" "}
                                  <div className="row">
                                    <div
                                      className="col-md-4"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <h5>SubTotal :</h5>
                                    </div>
                                    <div
                                      className="col-md-6"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <NumberFormat
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        thousandsGroupStyle="lakh"
                                        prefix={"NPR "}
                                        value={getTotal()}
                                      />
                                    </div>
                                  </div>
                                  <div className="login--dropdown-link">
                                    <div className="drop-sign">
                                      <Link
                                        className="btn-transparent"
                                        to={CART}
                                      >
                                        View Cart
                                      </Link>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <h5>No Items in cart</h5>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CompareHeader />

                    {/* <div className="navbar-bag">
                  <div className="tooltip-wrp">
                    <Link
                      className="cartview decoration-none"
                      // data-toggle="modal"
                      // data-target="cart-modal"
                      to={CART}
                      // data-href="//uae.sharafdg.com/cart/"
                      title="View your shopping cart"
                    >
                      <i className="sh sh-bag"></i>
                    </Link>
                  </div>
                </div>
               */}
                  </div>
                </div>
              </div>
            </div>
            <div className="storefront-primary-navigation sdgmega-menu">
              <div className="container">
                <div className="main-menu">
                  <nav
                    id="site-navigation"
                    role="navigation"
                    aria-label="Primary Navigation"
                  >
                    {categories.map(function (category, index) {
                      return (
                        <div
                          id="mega-menu-wrap-menu_1"
                          className="mega-menu-wrap"
                          key={index}
                        >
                          <div className="mega-menu-toggle" tabIndex="0">
                            <div
                              className="mega-toggle-block mega-menu-toggle-block mega-toggle-block-right mega-toggle-block-1"
                              id="mega-toggle-block-1"
                            ></div>
                          </div>
                          <ul
                            id="mega-menu-menu_1"
                            className="mega-menu mega-menu-horizontal mega-no-js"
                          >
                            <li className="mega-menu-item mega-menu-item-has-children mega-align-bottom-left mega-menu-megamenu">
                              <Link
                                className="mega-menu-link"
                                to={"/cat-" + category.category_url}
                                aria-haspopup="true"
                                tabIndex="0"
                              >
                                {category.name}
                              </Link>
                              <ul className="mega-sub-menu  hide-click-menu">
                                {category.subCategories.map(function (
                                  subCategory,
                                  index
                                ) {
                                  return (
                                    <li
                                      className={
                                        category.subCategories.length > 16
                                          ? "mega-menu-item mega-menu-item-has-children mega-menu-columns-1-of-8"
                                          : "mega-menu-item mega-menu-item-has-children mega-menu-columns-1-of-5"
                                      }
                                      key={index}
                                    >
                                      <Link
                                        className={
                                          subCategory.subCategoryTypes.length >
                                          0
                                            ? "mega-menu-link"
                                            : "mega-menu-link on-border-header"
                                        }
                                        to={
                                          "/sub-" + subCategory.sub_category_url
                                        }
                                        aria-haspopup="true"
                                      >
                                        {subCategory.name}
                                      </Link>
                                      {subCategory.subCategoryTypes.length >
                                      0 ? (
                                        <ul className="mega-sub-menu">
                                          {subCategory.subCategoryTypes.map(
                                            function (subCategoryType, index) {
                                              return (
                                                <li
                                                  className="mega-menu-item"
                                                  key={index}
                                                >
                                                  <Link
                                                    className="mega-menu-link"
                                                    to={
                                                      "/type-" +
                                                      subCategoryType.sub_category_type_url
                                                    }
                                                  >
                                                    {subCategoryType.name}
                                                  </Link>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                    <div id="mega-menu-wrap-menu_7" className="mega-menu-wrap">
                      <div className="mega-menu-toggle" tabIndex="0">
                        <div
                          className="mega-toggle-block mega-menu-toggle-block mega-toggle-block-right mega-toggle-block-1"
                          id="mega-toggle-block-1"
                        ></div>
                      </div>
                      <ul
                        id="mega-menu-menu_7"
                        className="mega-menu mega-menu-horizontal mega-no-js"
                      >
                        <li className="mega-menu-item mega-menu-item-has-children mega-align-bottom-left mega-menu-megamenu">
                          <a
                            className="mega-menu-link"
                            href="#"
                            aria-haspopup="true"
                            tabIndex="0"
                          >
                            BRANDS
                          </a>
                          <ul className="mega-sub-menu hide-click-menu">
                            {brands.map(function (brand, index) {
                              return (
                                <li
                                  className="mega-menu-item mega-menu-item-type-widget widget_sp_image mega-menu-columns-1-of-5"
                                  key={index}
                                >
                                  <Link
                                    to={"/" + brand.brand_url}
                                    className="widget_sp_image-image-link"
                                  >
                                    <img
                                      width="100"
                                      height="100"
                                      className="attachment-full"
                                      style={{ maxWidth: "100%" }}
                                      src={brand.image}
                                    />
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </BrowserView>
      <MobileView>
        {/* download app starts                     */}

        {2 == 3 ? (
          <>
            <a
              href={
                isIOS
                  ? "https://apps.apple.com/us/app/cg-digital/id1495822676?ls=1"
                  : "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.cgtechnodreams.cgdigital.app"
              }
              id="thisisdownloadbtn"
            >
              <img
                src={popUpImage}
                height="140px"
                style={{ width: "100%", objectFit: "cover", height: "110px" }}
              />
            </a>
            <span
              className="crossHeadDownload"
              style={{
                position: "absolute",
                top: "5px",
                left: "10px",
                color: "#fff",
                background: "red",
                padding: "3px",
                width: "20px",
                height: "20px",
                textAlign: "center",
                borderRadius: "50%",
                // fontWeight: "bold",
                fontSize: "10px",
              }}
              onClick={(e) => {
                document.getElementById("thisisdownloadbtn").style.display =
                  "none";
                e.target.style.display = "none";
              }}
            >
              X
            </span>
          </>
        ) : (
          ""
        )}

        {/* download app ends */}

        <header className="sharaf-header">
          <div className="navbar-header container">
            <div className="row">
              <div className="col-xs-2">
                <div className="navbar-left">
                  {/* <button onClick={() => clickOpenSidebar()}> .. </button> */}
                  <MobileSidebar
                    width={350}
                    categories={categories}
                  ></MobileSidebar>
                </div>
              </div>

              <div className="col-xs-5">
                <div className="navbar-middle">
                  <Link to={HOME} rel="home">
                    {/* <div className="navbar-brand-mobile site-title "></div> */}
                    <img src={logo} className="navbar-brand-mobile" />
                  </Link>
                </div>
              </div>
              <div className="col-xs-5">
                <div className="navbar-right">
                  <UserLoginHeader />

                  <div className="navbar-login">
                    <div className="login-wrp">
                      <div className="dropdown">
                        <div
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <Link
                            className="cartview decoration-none"
                            to={CART}
                            title="View your shopping cart"
                          >
                            <FontAwesomeIcon icon={faShoppingCart}  style={{ transform:"scale(1.5)" }} />
                          </Link>
                        </div>
                        <div
                          className="dropdown-menu"
                          style={{ minWidth: "310px" }}
                        >
                          <div
                            className="sign-dropdown"
                            style={{ maxHeight: "400px", overflow: "auto" }}
                          >
                            <h5>No Items in cart</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CompareHeader />
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{ marginBottom: "5px", padding: "10px" }}
            >
              <MobileSearch />
            </div>
          </div>
        </header>
      </MobileView>
    </React.Fragment>
  );
};

export default Header;
