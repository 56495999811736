
import React, { useState } from "react";
import FloatingInput from "../FloatingInput/floatingInput";
import "./forgotPassWord.css";
import Data from "../utils/data";
import Api from "../utils/api";

const ForgotPassword = (props) => {

  const [email, setEmail] = useState(props.email);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [useResetCode, setUserResetCode] = useState("");
  const [formResetCode, setFormResetCode] = useState("");

  const [codeSend, setCodeSend] = useState(false);
  const [codeMatched, setCodeMatched] = useState(false);
  
  const [userToken, setUserToken] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);


  const handleValidation = () => {};

  const handleSubmit = () => {

    setSuccessMessage('');
    setErrorMessage('');


    const emailRegExp   = /\S+@\S+\.\S+/;
    if(email == ''){
      return setErrorMessage('Please enter your email.');
    }else if(!emailRegExp.test(email)){
      return setErrorMessage('Please enter valid email address.');
    }

    setCodeSend(true);
   
    fetch(Data.API_BASE_URL + "forgot-password", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        email: email,
        source: 'ola'
      }),
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        console.log(response);
        if (response.statusCode == 200) {
          // setSuccessMessage(response.message);
          setUserResetCode(response.resetCode);
          setUserToken(response.remember_token);
          setCodeSend(true);
        } else {
          setCodeSend(false);
          setErrorMessage(response.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };


  function handleResetCodeSubmit(){
    setSuccessMessage('');
    setErrorMessage('');
    if(formResetCode == ''){
      return setErrorMessage('Enter Reset Code');
    }
    else if(formResetCode != useResetCode){
      return setErrorMessage('Invalid Reset Code');
    }
    if(formResetCode == useResetCode){
      setCodeMatched(true);
    }
    
  }

  function handleSetPasswordSubmit(){
    setSuccessMessage('');
    setErrorMessage('');
    if(newPassword != passwordConfirmation){
      setErrorMessage('Password does not match');
    }else{
      let token = userToken;
      let url = new URL(Data.API_BASE_URL + "forgot-password-reset");
      url.search = new URLSearchParams({
        token: token,
      }).toString();
      fetch(url, {
        method: "POST",
        headers: Data.API_HEADER,
        body: JSON.stringify({
          password: newPassword,
        }),
      })
        .then(Api.handleResponseAndError)
        .then((response) => {
          console.log(response);
          if (response.statusCode == 200) {
            setNewPassword('');
            setPasswordConfirmation('');
            setSuccessMessage(response.message);
            setPasswordChanged(true);
          } else {
            setErrorMessage(response.message);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }


  return (
    <div className="popup_overlay">
      <div className="pop_container">
        <div className="cross-icon-wrapper">
          <img
            className={"cross_icon"}
            src="data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M16 1.61143L14.3886 0L8 6.38857L1.61143 0L0 1.61143L6.38857 8L0 14.3886L1.61143 16L8 9.61143L14.3886 16L16 14.3886L9.61143 8L16 1.61143Z' fill='black'/%3E %3C/svg%3E"
            alt="cross"
            onClick={() => {
              props.setShowPasswordPopup((prev) => !prev);
            }}
          />
        </div>
        

        {codeSend == false 
          ? 
          <>
          <div className="page-title">Forgot password</div>
            <div className="page-desc">
            Please enter your email address. You will receive a link to create a
            new password via email
            </div>
            <FloatingInput
                type="email"
                label="email"
                value={email}
                setValue={setEmail}
                handleValidation={handleValidation} 
              />
              {successMessage !== "" 
              ? <><p style={{color:'green'}}>{successMessage}</p></> 
              : ''}
              {errorMessage !== "" 
              ? <><p style={{color:'red'}}>{errorMessage}</p></> 
              : ''}
            <div>
              <button type="button" className="reset-button" onClick={handleSubmit}>Reset password</button>
            </div>
          </>
          : ''}


        {codeSend && codeMatched == false
          ? 
          <>
          <div className="page-title">Forgot password</div>
            <div className="page-desc">
              Please enter password reset code send to your mail.
            </div>
            <FloatingInput
                type="text"
                label="Reset Code"
                value={formResetCode}
                setValue={setFormResetCode}
                handleValidation={handleValidation} 
              />
              {successMessage !== "" 
              ? <><p style={{color:'green'}}>{successMessage}</p></> 
              : ''}
              {errorMessage !== "" 
              ? <><p style={{color:'red'}}>{errorMessage}</p></> 
              : ''}
            <div>
              <button type="button" className="reset-button" onClick={handleResetCodeSubmit}>Reset Code</button>
            </div>
          </>
          : 
          ""
        }

        {codeSend && codeMatched
          ? 
          <>
          {passwordChanged == false
            ? 
            <>
            <div className="page-title">Forgot password</div>
            <div className="page-desc">
              Please reset your new password.
            </div>
            <FloatingInput
                type="password"
                label="New Password"
                value={newPassword}
                setValue={setNewPassword}
                handleValidation={handleValidation} 
              />
               <FloatingInput
                type="password"
                label="Password Confirmation"
                value={passwordConfirmation}
                setValue={setPasswordConfirmation}
                handleValidation={handleValidation} 
              />
              {successMessage !== "" 
              ? <><p style={{color:'green'}}>{successMessage}</p></> 
              : ''}
              {errorMessage !== "" 
              ? <><p style={{color:'red'}}>{errorMessage}</p></> 
              : ''}
            <div>
              <button type="button" className="reset-button" onClick={handleSetPasswordSubmit}>Set Password</button>
            </div>
            </>
            : 
            <>
            <div className="page-title">Password changed</div>
              <div className="page-desc"></div>
              {successMessage !== "" 
              ? <><p style={{color:'green'}}>{successMessage}</p></> 
              : ''}
              {errorMessage !== "" 
              ? <><p style={{color:'red'}}>{errorMessage}</p></> 
              : ''}
            </>
          }
            
          </>
          : 
          ""
        }

        
      </div>
    </div>
  );
};

export default ForgotPassword;
