import React, { useState, useEffect } from "react";
import Breadcrumb from "../../Layouts/Breadcrumb";
import UserDetail from "./UserDetail";
import UserChangePassword from "./UserChangePassword";
import storage from "../../utils/storage";
import OrderHistory from "./OrderHistory";
import BookingHistory from "./BookingHistory";
import WithContext from "../../Layouts/MainMessage/WithContext";
import { useHistory } from "react-router-dom";
import LoyaltyHistory from "./LoyaltyHistory";
import { MetaTags } from "react-meta-tags";
import Data from "../../utils/data";
import api from "../../utils/api";
import { useInput } from "../../hooks/inputHook";

const UserAccount = (props) => {
  let history = useHistory();
  const user = storage.getUser();
  const [goto, setGoto] = useState("");
  const [productModels, setProductModels] = useState([]);

  const { value: model_number, bind: bindModelNumber, setValue: setModelNumber } = useInput("");
  const { value: serial_number, bind: bindSerialNumber, setValue: setSerialNumber } = useInput("");
  const { value: place_of_purchase, bind: bindPlaceofPurchase, setValue: setPlaceofPurchase } = useInput("");
  const { value: purchase_date, bind: bindPurchasedDate, setValue: setPurchasedDate } = useInput(
    ""
  );
  const { value: file, setValue: setFile } = useInput(
    ""
  );

  function checkUrl() {
    if (new URLSearchParams(props.location.search).get("goto")) {
      var go = new URLSearchParams(props.location.search).get("goto");
      setGoto(go);
    }
  }
  const onFileChange = event => {

    setFile(event.target.files[0]);

  };
  const handleSubmit = (evt) => {
    evt.preventDefault();
    const formData = new FormData();

    // Update the formData object
    formData.append('model_no', model_number);
    formData.append('serial_number', serial_number);
    formData.append('place_of_purchase', place_of_purchase);
    formData.append('purchase_date', purchase_date);
    formData.append('proof_of_purchase', file);
    fetch(Data.API_BASE_URL + "post-warranty-web", {
      method: "POST",
      headers: {
        'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
        'Authorization': "Bearer " + storage.getAccessToken(),
      },
      body: formData,
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        if (response.statusCode == 200) {
          window.scrollTo(0, 0);
          props.value.setMessage(response.message);
          setModelNumber("");
          setSerialNumber("");
          setPlaceofPurchase("");
          setPurchasedDate("");
          setFile("");
        } else {
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  async function getProductModels() {
    let url = new URL(Data.API_BASE_URL + "get-product-models");
    await fetch(url, {
      method: "GET",
      headers: Data.API_HEADER,
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setProductModels(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    if (!storage.isLoggedIn()) {
      history.replace("/");
    }
    getProductModels();
    checkUrl();
  }, []);
  return (
    <React.Fragment>
      <Breadcrumb
        levelOne={{
          name: "Account",
          link: "/user/account/",
        }}
      />
      <MetaTags>
        <title>User Account</title>
      </MetaTags>
      <section className="pdp-section shown">
        <div className="product-tab-inner " id="productTabContent">
          <ul className="nav tabs">
            <li className="tab prd-desc">
              <a href="#description" className={goto == "" ? "active" : ""}>
                My Profile
              </a>
            </li>
            <li className="tab prd-Specs">
              <a
                href="#orderhistory"
                className={goto == "order-history" ? "active" : ""}
              >
                Order History
              </a>
            </li>
            <li className="tab prd-Specs">
              <a
                href="#loyaltyHistory"
                className={goto == "loyalty-history" ? "active" : ""}
              >
                Loyalty Point History
              </a>
            </li>
            <li className="tab prd-Specs">
              <a
                href="#bookinghistory"
                className={goto == "booking-history" ? "active" : ""}
              >
                Booking History
              </a>
            </li>
            {/* <li className="tab review-tab">
              <a href="#warranty">Warranty Registration</a>
            </li> */}
          </ul>

          <div
            className="tab-pane active in product-tab"
            id="description"
            style={goto == "" ? { display: "block" } : { display: "none" }}
          >
            <UserDetail user={user} setMessage={props.value.setMessage} />
            <UserChangePassword
              user={user}
              setMessage={props.value.setMessage}
            />
          </div>

          <div
            className="tab-pane product-tab"
            id="orderhistory"
            style={
              goto == "order-history"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <OrderHistory user={user} setMessage={props.value.setMessage} />
          </div>
          <div
            className="tab-pane product-tab"
            id="loyaltyHistory"
            style={
              goto == "loyalty-history"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <LoyaltyHistory user={user} />
          </div>
          <div
            className="tab-pane product-tab"
            id="bookinghistory"
            style={
              goto == "booking-history"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <BookingHistory user={user} setMessage={props.value.setMessage} />
          </div>
          <div
            id="warranty"
            className="tab-pane product-tab"
            style={{ display: "none" }}
          >
            <h3 className="text-center">Register a Product</h3>
            <form
              acceptCharset="UTF-8"
              encType="multipart/form-data"
              className="ng-pristine ng-valid"
              onSubmit={handleSubmit}
            >
              <div className="col-md-6 row">
                <fieldset>
                  <label htmlFor="email">
                    <b>Enter Model Number</b>
                  </label>

                  <div
                    style={{
                      position: "relative",
                      marginBottom: "10px",
                    }}
                  >
                    <span>
                      <select
                        className="form-control select2 select2-hidden-accessible"
                        required=""
                        name="model_no"
                        tabIndex="-1"
                        aria-hidden="true"
                        {...bindModelNumber}
                      >
                        <option defaultValue>Enter your model number</option>
                        {productModels.map(function (model, index) {
                          return <option value={model.model_number} key={index}>{model.model_number}</option>
                        })}
                      </select>
                    </span>
                  </div>
                </fieldset>
              </div>

              <div className="col-md-6 ">
                <label htmlFor="email">
                  <b>Serial Number ( or IMEI/MEID )</b>
                </label>
                <input
                  type="text"
                  placeholder="e.g. 409HEN130007"
                  name="serial_number"
                  {...bindSerialNumber}
                  required
                />
              </div>

              <div className="col-md-6 row">
                <label htmlFor="psw">
                  <b>Place of Purchase</b>
                </label>
                <input
                  type="text"
                  placeholder="Place of Purchase"
                  name="place_of_purchase"
                  {...bindPlaceofPurchase}
                  required
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="psw">
                  <b>Purchased Date</b>
                </label>
                <input
                  type="text"
                  name="purchase_date"
                  id="datetimepicker1"
                  placeholder="Purchased Date"
                  {...bindPurchasedDate}
                  required
                />
              </div>

              <label htmlFor="uploadFile">
                <b>Proof of Purchase</b>
              </label>
              <input
                type="file"
                id="uploadFile"
                name="proof_of_purchase"
                onChange={onFileChange}
                accept="image/*"
                required
              />

              <div className="clearfix" style={{ textAlign: "center" }}>
                <input
                  className="btn btn-success"
                  value="Register a Product"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default WithContext(UserAccount);
