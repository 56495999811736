import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Layouts/Breadcrumb";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Data from "../../utils/data";
import api from "../../utils/api";
import Faq from "react-faq-component";
const Faqs = (props) => {
  const [isLoading, setIsloading] = useState(true);
  const [faq, setFaq] = useState([]);
  let history = useHistory();
  function getCustomPage() {
    fetch(Data.API_BASE_URL + "get-faqs", {
      method: "GET",
      headers: Data.API_HEADER,
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setIsloading(false);
        console.log(response.data);
        setFaq(response.data);
      })
      .catch((error) => {
        history.push("/not-found");
        console.log(error);
      });
  }
  const data = {
    title: "",
    rows: faq,
  };
  const styles = {
    // bgColor: 'white',
    titleTextColor: "#0171b6",
    rowTitleColor: "#0171b6",
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };
  const config = {
    animate: true,
    // arrowIcon: "V",
  };
  function getPageData() {
    let id = props.match.params["data"];
    return id;
  }
  useEffect(() => {
    getCustomPage();
  }, []);
  return (
    <React.Fragment>
      <div className="search-page" id="sr1">
        <div className="search-content">
          {isLoading ? (
            <div
              className="container"
              style={{ textAlign: "center", padding: "100px 0px" }}
            >
              <ClipLoader
                css={{ margin: "0 auto" }}
                size={150}
                //size={"150px"} this also works
                color={"#123abc"}
                loading={isLoading}
              />
            </div>
          ) : (
            <div className="container">
              <Breadcrumb
                levelOne={{
                  name: "FAQ",
                  link: "/page/faqs",
                }}
              />
              <main id="primary" class="content-area">
                <main id="main" class="site-main" role="main">
                  <div class="container">
                    <div class="static-page white-well">
                      <div id="post-92427">
                        <div class="entry-content">
                          <h1>FREQUENTLY ASKED QUESTIONS</h1>
                          <hr />
                          {faq.length > 0 ? (
                            <Faq data={data} styles={styles} config={config} />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </main>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Faqs;
