import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Data from "../../utils/data";
import Api from "../../utils/api";
import util from "../../utils/util";
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory, withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import LoadingImage from "../../../assets/img/loading.gif";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import ListProduct from "./ListProduct/ListProduct";
import MetaTags from "react-meta-tags";
import ClickBanner from "../../Home/Banner/ClickBanner";
import CategorySkeleton from "../Skeleton/CategorySkeleton";
import AddSlider from "./CategorySlider/AddSlider";
const SubCategoryType = (props) => {
  let history = useHistory();
  const [category, setCategory] = useState({});
  const [meta, setMeta] = useState({});
  const [subCategoryType, setSubCategoryType] = useState({});
  const [appliedFilter, setAppliedFilter] = useState([]);
  const [filters, setFilters] = useState([]);
  const [subCategory, setSubCategory] = useState({});
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [priceRangeChange, setPriceRangeChange] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [productPaginate, setProductPaginate] = useState({});
  const [nextHref, setNextHref] = useState("");
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  function fetchSubCategory() {
    let params = fetchParams();
    let url = new URL(Data.API_BASE_URL + "get/type-" + getSubCategoryTypeId());
    url.search = new URLSearchParams(params).toString();
    fetch(url, {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        setHasMoreItems(true);
        setNextHref("");
        setSubCategoryData(response.data);

        setProducts(response.data.products);

        console.log("here");
        const gtmProductArr = [];

        response.data.products.map(function (product) {
          gtmProductArr.push({
            id: product.product_code,
            name: product.name,
            google_business_vertical: "retail",
          });
        });

        // GTM(gtmProductArr);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getSubCategoryTypeId() {
    return props.match.params["sub_category_type_name"];
  }
  function fetchParams(page = null) {
    // alert(page);
    let params = {};
    let brand = new URLSearchParams(props.location.search).get("brand");
    let range = new URLSearchParams(props.location.search).get("range");
    let sort = new URLSearchParams(props.location.search).get("sort");
    if (page == 1)
      if (nextHref) {
        params.page = new URLSearchParams(new URL(nextHref).search).get("page");
      }
    if (brand) {
      params.brand = brand;
    }
    if (range) {
      params.range = range;
    }
    if (sort) {
      params.sort = sort;
    }
    let appliedF = new URLSearchParams(props.location.search)
      .toString()
      .split("&");
    appliedF.forEach(function (filter) {
      filter = filter.split("=");
      params[filter[0]] = decodeURIComponent(filter[1]);
    });
    return params;
  }
  function checkFilter(filter, value) {
    let urlFilter = new URLSearchParams(props.location.search).get(
      util.stringToSlug(filter.name)
    );
    if (urlFilter) {
      let filters = urlFilter.split("---");
      var findFilterName = filters.find(function (filter) {
        return filter == value.feature_value;
      });
      if (findFilterName) {
        return true;
      } else {
        return false;
      }
    }
  }
  function checkBrand(brand) {
    let urlBrand = new URLSearchParams(props.location.search).get("brand");
    if (urlBrand) {
      let brands = urlBrand.split("---");
      var findBrand = brands.find(function (brandfind) {
        return brandfind == brand.name;
      });
      if (findBrand) {
        return true;
      } else {
        return false;
      }
    }
  }
  function checkFilterApply() {
    let filter = new URLSearchParams(props.location.search).toString();
    filter = filter.split("&");
    let div;
    if (filter.length > 1 || filter[0] != "") {
      filter = filter
        .filter(function (data) {
          if (
            data.split("=")[0] != "sort" &&
            data.split("=")[0] != "range" &&
            data.split("=")[0] != "botIframe" &&
            data.split("=")[0] != "removeByTag" &&
            data.split("=")[0] != "removeById" &&
            data.split("=")[0] != "removeByClassName" &&
            data.split("=")[0] != "palmbotquery" &&
            data.split("=")[0] != "fullscreen"
          ) {
            return true;
          } else {
            return false;
          }
        })
        .map(function (data) {
          let objectData = {};
          objectData.filter_name = data.split("=")[0];
          objectData.filter_data = data.split("=")[1];
          objectData.filter_data = objectData.filter_data.split("---");
          return objectData;
        });

      div = filter.map(function (data) {
        let divInside = data.filter_data.map(function (filter_data, index) {
          return (
            <li key={index}>
              <span className="multiselect-child">
                <a onClick={() => deleteFilterData(filter_data, data)}>
                  <span className="filter-type">
                    {" "}
                    {decodeURIComponent(filter_data)}{" "}
                    <span className="remove-filter">X</span>
                  </span>{" "}
                </a>
              </span>
            </li>
          );
        });
        return divInside;
      });
    }
    return div;
  }
  function deleteFilterData(filter_data, filter_name) {
    let filter = new URLSearchParams(props.location.search).toString();
    filter = filter.split("&");
    let search = {};
    if (filter.length > 1 || filter[0] != "") {
      filter = filter
        .filter(function (data) {
          if (data.split("=")[0] != "sort" && data.split("=")[0] != "range") {
            return true;
          } else {
            return false;
          }
        })
        .map(function (data) {
          let objectData = {};
          objectData.filter_name = data.split("=")[0];
          objectData.filter_data = data.split("=")[1];
          objectData.filter_data = objectData.filter_data.split("---");
          var findData = objectData.filter_data.indexOf(filter_data);
          if (findData !== -1) {
            objectData.filter_data.splice(findData, 1);
          }
          return objectData;
        });
    }
    var url = "?";
    filter.forEach(function (data, index) {
      if (data.filter_data.length > 0) {
        url += data.filter_name + "=" + data.filter_data.join("---") + "&";
      }
    });
    url = url.trim("&");
    history.push({
      pathname: "/type-" + subCategoryType.sub_category_type_url,
      search: url,
    });
  }
  function setSubCategoryData(data) {
    setCategory(data["category"]);
    setProducts([]);
    setMeta(data["meta"]);
    setSubCategory(data["subCategory"]);
    setSubCategoryType(data["subCategoryType"]);
    setBrands(data["brands"]);
    setProductPaginate(data["productPaginate"]);
    setPriceRange(data["price_range"]);
    setFilters(data["filters"]);
    let range = new URLSearchParams(props.location.search).get("range");
    let appliedF = new URLSearchParams(props.location.search)
      .toString()
      .split("&");
    setAppliedFilter(appliedF);
    if (range) {
      range = range.split("-");
      let newRange = {};
      newRange.min = range[0];
      newRange.max = range[1];
      setPriceRangeChange(newRange);
    } else {
      setPriceRangeChange(data["price_range"]);
    }
    setSliders(data["sliders"]);
    setIsloading(false);
  }

  function handleChangeFilter(evt, filter, value) {
    const item = util.stringToSlug(filter.name);
    const val = value.feature_value;
    const isChecked = evt.target.checked;
    let filterName = new URLSearchParams(props.location.search).get(item);

    // console.log(filterName);
    if (filterName) {
      let filterNames = filterName.split("---");
      var findFilterName = filterNames.find(function (filterName) {
        return filterName == val;
      });
      // console.log(findFilterName);
      if (findFilterName) {
        // console.log(isChecked);
        filterNames.splice(filterNames.indexOf(findFilterName), 1);
        if (filterNames && filterNames.length > 0) {
          history.push({
            pathname: props.match.url,
            search: "?" + item + "=" + filterNames.join("---"),
          });
        } else {
          history.push({
            pathname: props.match.url,
          });
        }
      } else {
        history.push({
          pathname: props.match.url,
          search:
            "?" +
            item +
            "=" +
            encodeURIComponent(filterName) +
            "---" +
            encodeURIComponent(val),
        });
      }
    } else {
      // console.log("yeta");
      history.push({
        pathname: props.match.url,
        search:
          (props.location.search ? props.location.search + "&" : "?") +
          item +
          "=" +
          encodeURIComponent(val).replace(" ", "+"),
      });
    }
  }
  function handleChange(e) {
    const item = e.target.name;
    const val = encodeURIComponent(e.target.value);
    const isChecked = e.target.checked;
    let brand = new URLSearchParams(props.location.search).get("brand");

    if (brand) {
      let brands = brand.split("---");
      var findBrand = brands.find(function (brand) {
        return brand == val;
      });

      if (findBrand) {
        brands.splice(brands.indexOf(findBrand), 1);
        history.push({
          pathname: props.match.url,
          search: "?brand=" + encodeURIComponent(brands.join("---")),
        });
      } else {
        history.push({
          pathname: props.match.url,
          search: "?brand=" + encodeURIComponent(brand + "---" + val),
        });
      }
    } else {
      history.push({
        pathname: props.match.url,
        search: "?brand=" + encodeURIComponent(val),
      });
    }
  }
  function handlePriceChange(value) {
    let params = fetchParams();
    params.range = value.min + "-" + value.max;
    history.push({
      pathname: props.match.url,
      search: new URLSearchParams(params).toString(),
    });
  }
  function handleSortBy(e) {
    let params = fetchParams();
    params.sort = e.target.value;
    history.push({
      pathname: props.match.url,
      search: new URLSearchParams(params).toString(),
    });
  }

  function GTM(gtmProductArr) {
    var dataLayer = window.dataLayer || [];

    dataLayer.push({
      event: "view_item_list",
      value: 1003,
      items: gtmProductArr,
    });
  }

  useEffect(() => {
    setIsloading(true);
    fetchSubCategory();
  }, [props.match.params]);

  function loadMore(page) {
    let url;
    let params = fetchParams(1);

    console.log(nextHref);
    if (nextHref) {
      url = new URL(nextHref);
    } else {
      url = new URL(Data.API_BASE_URL + "get/type-" + getSubCategoryTypeId());
    }
    url.search = new URLSearchParams(params).toString();

    fetch(url, {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        console.log(response.data.products);
        // setProducts(response.data.products);
        // response.data.products.map(function (product) {
        //   products.push(product);
        //   console.log('-----------');
        // });
        if (response.data.productPaginate.next_page_url) {
          setNextHref(response.data.productPaginate.next_page_url);
        } else {
          setHasMoreItems(false);
        }
        const gtmProductArr = [];
        response.data.products.map(function (product) {
          gtmProductArr.push({
            id: product.product_code,
            // name: product.name,
            google_business_vertical: "retail",
          });
        });

        GTM(gtmProductArr);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <React.Fragment>
      <div className="search-page" id="sr1">
        <div className="search-content">
          {isLoading ? (
            <div
              className="container"
              style={{ textAlign: "center", padding: "20px 0px" }}
            >
              <CategorySkeleton />
            </div>
          ) : (
            <div className="container">
              <Breadcrumb
                levelThree={{
                  name: subCategoryType.name,
                  link: "/type-" + subCategoryType.sub_category_type_url,
                }}
                levelTwo={{
                  name: subCategoryType.sub_category_name,
                  link: "/sub-" + subCategoryType.sub_category_url,
                }}
                levelOne={{
                  name: subCategoryType.category_name,
                  link: "/cat-" + subCategoryType.category_url,
                }}
              />
              <MetaTags>
                <title>{meta.title ? meta.title : subCategoryType.name}</title>
                <meta name="description" content={meta.description} />
                <meta property="og:title" content={meta.title} />
                <meta name="keywords" content={meta.keywords} />
              </MetaTags>
              <div className="row search-row">
                <div className="facet-results ">
                  <div className="filter-wrp">
                    <div className="left-wrp">
                      <div className="filter-container" id="facets">
                        {appliedFilter > 1 || appliedFilter[0] != "" ? (
                          <div className="filter-type">
                            <div className="filter-section">
                              <div className="filter-name filter-accordion">
                                <div className="filter-header">
                                  <span className="cat-name">
                                    {" "}
                                    Filter{" "}
                                    <Link
                                      to={
                                        "/type-" +
                                        subCategoryType.sub_category_type_url
                                      }
                                    >
                                      Clear All
                                    </Link>
                                  </span>
                                  <FontAwesomeIcon
                                    className="sh-icon arrow_down"
                                    icon="angle-down"
                                  />
                                </div>
                              </div>
                              <div className="filter-collapse">
                                <div className="filter-accordion cat-layer active">
                                  <div className="saide-bar-menu">
                                    <div className="filter-section">
                                      <div className="filter-header">
                                        <div
                                          // href="#"
                                          data-attribute="taxonomies.taxonomies_hierarchical"
                                          data-value="Home Appliances"
                                          className="top-cat decoration-none active space-15 filter-data"
                                        >
                                          <ol>{checkFilterApply()}</ol>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="filter-type">
                          <div className="filter-section">
                            <div className="filter-name filter-accordion">
                              <div className="filter-header">
                                <span className="cat-name"> Shop By </span>
                                <FontAwesomeIcon
                                  className="sh-icon arrow_down"
                                  icon="angle-down"
                                />
                              </div>
                            </div>
                            <div className="filter-collapse">
                              <div className="filter-accordion cat-layer active">
                                <div className="filter-section">
                                  <div className="filter-header">
                                    <a
                                      href="#"
                                      data-attribute="taxonomies.taxonomies_hierarchical"
                                      data-value="Home Appliances"
                                      className="top-cat decoration-none active space-15"
                                    >
                                      <span className="cat-name">
                                        <b>{subCategoryType.name}</b>
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="filter-type checkbox-factet">
                          <div className="filter-section">
                            <div className="filter-name filter-accordion">
                              <div className="filter-header">
                                <span className="cat-name"> Brand </span>
                                <FontAwesomeIcon
                                  className="sh-icon arrow_down"
                                  icon="angle-down"
                                />
                              </div>
                            </div>
                            <div className="filter-collapse">
                              <div className="collapse-body collapse-list">
                                <div className="filter-list">
                                  <ul className="nav facets">
                                    {brands.map(function (brand, index) {
                                      return (
                                        <li
                                          className="facet active"
                                          key={index}
                                        >
                                          <div className="filter-item">
                                            <div className="custom-checkbox">
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  name="brand"
                                                  className="checkbox"
                                                  data-facet="taxonomies.attr.Brand"
                                                  value={brand.name}
                                                  checked={checkBrand(brand)}
                                                  onChange={handleChange}
                                                />
                                                <i className="sh sh-checkbox"></i>
                                                <span className="item-name">
                                                  {brand.name}
                                                </span>
                                                <span className="item-count">
                                                  {brand.product_count}
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="filter-type">
                          <div className="filter-section">
                            <div className="filter-name filter-accordion">
                              <div className="filter-header">
                                <span className="cat-name"> Price </span>
                                <FontAwesomeIcon
                                  className="sh-icon arrow_down"
                                  icon="angle-down"
                                />
                              </div>
                            </div>
                            <div className="filter-collapse">
                              <div className="collapse-body">
                                <div
                                  className="range-slider"
                                  style={{ margin: "20px" }}
                                >
                                  <InputRange
                                    maxValue={priceRange.max}
                                    minValue={priceRange.min}
                                    formatLabel={(value) => `NPR ${value}`}
                                    onChange={(value) =>
                                      setPriceRangeChange(value)
                                    }
                                    onChangeComplete={(value) =>
                                      handlePriceChange(value)
                                    }
                                    value={priceRangeChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="filter-type checkbox-factet">
                          <div className="filter-section">
                            <div className="filter-name filter-accordion">
                              <div className="filter-header">
                                <span className="cat-name"> Filter </span>
                                <FontAwesomeIcon
                                  className="sh-icon arrow_down"
                                  icon="angle-down"
                                />
                              </div>
                            </div>
                            <div className="filter-collapse">
                              <div className="collapse-body collapse-list">
                                {filters.map(function (filter, index) {
                                  return (
                                    <React.Fragment key={index}>
                                      <div className="filter-header">
                                        <span className="cat-name filter-title">
                                          {filter.name}
                                        </span>
                                        <br />
                                      </div>
                                      <div className="filter-list filter-lists">
                                        <ul className="nav facets">
                                          {filter.values.map(function (
                                            value,
                                            indexTwo
                                          ) {
                                            return (
                                              <li
                                                className="facet active"
                                                key={indexTwo}
                                              >
                                                <div className="filter-item">
                                                  <div className="custom-checkbox">
                                                    <label>
                                                      <input
                                                        type="checkbox"
                                                        name="brand"
                                                        className="checkbox"
                                                        data-facet="taxonomies.attr.Brand"
                                                        value={
                                                          value.feature_value
                                                        }
                                                        checked={checkFilter(
                                                          filter,
                                                          value
                                                        )}
                                                        onChange={(evt) =>
                                                          handleChangeFilter(
                                                            evt,
                                                            filter,
                                                            value
                                                          )
                                                        }
                                                      />
                                                      <i className="sh sh-checkbox"></i>
                                                      <span className="item-name">
                                                        {value.feature_value}
                                                      </span>
                                                      <span className="item-count">
                                                        {value.product_count}
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>

                        <AddSlider sliders={sliders} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-results">
                  {subCategoryType.image ? (
                    <div style={{ width: "100%" }}>
                      <img
                        src={subCategoryType.image}
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "100%",
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="grid-view">
                    <div className="clearfix search-header">
                      <h3 style={{ marginLeft: "20px" }}>
                        {subCategoryType.name}
                      </h3>
                      <div className="search-text" id="stats">
                        Showing {productPaginate.total} Results
                        {/* for "<span className="bold">lg</span>" */}
                      </div>
                      <div className="sort-wrp">
                        <span className="sort-by">Sort by</span>
                        <section className="sort-container">
                          <div className="selectric-wrapper selectric-sort">
                            <select
                              name="sort"
                              title="sort"
                              className="sort-by-select"
                              value={fetchParams().sort}
                              onChange={handleSortBy}
                            >
                              <option value=""> - </option>
                              <option value="new">New In</option>
                              <option value="low-to-high">Lowest Price</option>
                              <option value="high-to-low">Highest Price</option>
                            </select>

                            <div className="selectric-items" tabIndex="-1">
                              <div className="selectric-scroll">
                                <ul>
                                  <li data-index="0" className="selected">
                                    Most Relevant
                                  </li>
                                  <li data-index="1" className="">
                                    Lowest Price
                                  </li>
                                  <li data-index="2" className="last">
                                    Highest Price
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <input className="selectric-input" tabIndex="0" />
                          </div>
                        </section>
                      </div>
                      <div id="refinement-tags" className="clearfix"></div>
                    </div>
                    <div className="product-scroller js-product-list">
                      <div className="product-container">
                        <div
                          className="product-items row reset-margin"
                          id="hits"
                        >
                          <InfiniteScroll
                            pageStart={0}
                            loadMore={() => loadMore()}
                            hasMore={hasMoreItems}
                            loader={
                              <div style={{ alignContent: "center" }} key={0}>
                                <img
                                  src={LoadingImage}
                                  style={{
                                    height: "150px",
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                />
                              </div>
                            }
                          >
                            {products.map(function (product, index) {
                              return (
                                <div
                                  className="slide col-md-3 reset-padding fadeInUp"
                                  key={index}
                                >
                                  <ListProduct product={product} />
                                </div>
                              );
                            })}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubCategoryType;
