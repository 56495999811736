import React from "react";
import GenericModal from "../genericModal/genericModal";
import "./popModal.css";

const PopUpCard = ({ setShowPopUp, cardContent }) => {
    return (
        <GenericModal showClose={false} onClose={() => setShowPopUp(false)}>
            <div className="pop_container">
                <Card setShowPopUp={setShowPopUp} cardContent={cardContent} />
            </div>
        </GenericModal>
    );
};

const Card = ({ setShowPopUp, cardContent }) => {
    return (
        <>
            {cardContent.map((card, index) => (
                <div key={card.title}>
                    {index === 0 && (
                        <img
                            onClick={() => setShowPopUp(false)}
                            className={"cross_icon"}
                            src="data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M16 1.61143L14.3886 0L8 6.38857L1.61143 0L0 1.61143L6.38857 8L0 14.3886L1.61143 16L8 9.61143L14.3886 16L16 14.3886L9.61143 8L16 1.61143Z' fill='black'/%3E %3C/svg%3E"
                            alt="cross"
                        />
                    )}
                    <div className={"pop_header"}>{card.title}</div>
                    <div className={"pop_content"}>{card.description}</div>
                    {index + 1 !== cardContent.length && <br />}
                </div>
            ))}
        </>
    );
};

export default PopUpCard;
