import React, { useState, useEffect } from "react";
import araiInfo from "../olaAssets/araiInfo.svg";
import DesktopSlider from "./desktopSlider";
import "./left-panel.css";
import PopUpCard from "../araiPopup";

const LeftPanel = ({ selectedModalData, currentColor }) => {
 
  const [showAriPopup, setShowAraiPopup] = useState(false);
  const product_overview =
    selectedModalData.data.catalog_pricing_details[0].product_overview;


  const boldText = (data) => {
    let boldData = data
      .replaceAll("[", '<span class="small-val-att-np">')
      .replaceAll("]", "</span>");

    return boldData;
  };



  return (
    <>
      <div className="left-panel">
        <div className="logo-container-np">
          <div>
            <img
              src="https://d34kmefuuy0be0.cloudfront.net/ev_assets/logo_dark_70760c0b6a.svg"
              alt="OLA"
              className="logo-np"
            />
          </div>
        </div>

        <DesktopSlider
          image_carousal_list={currentColor.metadata.image_carousal_list}
        />
        <div className="att-main-container-new">
          <div className="three-attributes-container">
                {product_overview.length > 0 ? (<>
                    {product_overview.map(function (el, index){
                        return (
                          <div key={index}>
                            <p>
                              <span
                                className="val-att-np"
                                dangerouslySetInnerHTML={{
                                  __html: boldText(el.desc),
                                }}
                              ></span>
                              {index == 2 && (
                                <img
                                  className="arai-info"
                                  onClick={() => {
                                    setShowAraiPopup(true);
                                  }}
                                  src={araiInfo}
                                />
                              )}
                            </p>
                            <p className="name-att-np"> {el.title}</p>
                          </div>
                        );
                    })}
                  </>) 
                  : (<></>)

                  }
          </div>
        </div>
      </div>
      {showAriPopup && (
        <PopUpCard
          setShowPopUp={setShowAraiPopup}
          cardContent={selectedModalData.data.catalog_pricing_details[0].araiData}
        />
      )}
    </>
  );
};

export default LeftPanel;
