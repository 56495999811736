import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import connectIPS from "../../image/connectIPS-logo.jpg";
import khaltiLogo from "../../image/khalti-logo.jfif";
import qrCode from "../../image/qr_code.png";
import cgPay from "../../image/cg-pay.jpg";
import Newsletter from "./Newsletter/Newsletter";
import api from "../utils/api";
import Data from "../utils/data";
const Footer = (props) => {
  const [pages, setPages] = useState([]);
  function fetchPages() {
    fetch(Data.API_BASE_URL + "all-page", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setPages(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    fetchPages();
  }, []);
  return (
    <React.Fragment>
      <Newsletter />
      <footer className="footer others">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-xs-12">
                <div className="row">
                  <div className="col-md-4 col-xs-6 foot-nav">
                    <div id="nav_menu-6" className="widget widget_nav_menu">
                      <span className="gamma widget-title">Company</span>
                      <div className="menu-about-us-container">
                        <ul id="menu-about-us" className="menu">
                          {pages.map(function (page, index) {
                            return (
                              <li
                                id="menu-item-324157"
                                key={index}
                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-324157"
                              >
                                <Link to={"/page-" + page.page_url}>
                                  {page.title}
                                </Link>
                              </li>
                            );
                          })}
                          <li
                            id="menu-item-326993"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-326993"
                          >
                            <Link to={"/faqs"}>F.A.Qs</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-6 foot-nav">
                    <div id="nav_menu-7" className="widget widget_nav_menu">
                      <span className="gamma widget-title">Assistance</span>
                      <div className="menu-assistance-container">
                        <ul id="menu-assistance" className="menu">
                          <li
                            id="menu-item-542002"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-542002"
                          >
                            <a href="">Store Locator</a>
                          </li>
                          {/* <li
                            id="menu-item-667871"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-667871"
                          >
                            <a href="">
                              DG Shield
                            </a>
                          </li>
                           */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-6 foot-nav">
                    <div id="nav_menu-7" className="widget widget_nav_menu">
                      <span className="gamma widget-title">Stores</span>
                      <div className="menu-assistance-container">
                        <ul id="menu-assistance" className="menu">
                          <li
                            id="menu-item-542002"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-542002"
                          >
                            <Link to={"/stores/cg-digital"}>CG Digital</Link>
                          </li>
                          <li
                            id="menu-item-667871"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-667871"
                          >
                            <Link to={"/stores/lg-shoppe"}>LG Shoppe</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-4 col-xs-12 foot-nav">
                    <div id="nav_menu-8" className="widget widget_nav_menu">
                      <span className="gamma widget-title">Publications</span>
                      <div className="menu-interactive-container">
                        <ul id="menu-interactive" className="menu">
                          <li
                            id="menu-item-7823"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-7823"
                          >
                            <a href="/catalogue-uae/">Latest Catalogue</a>
                          </li>
                          <li
                            id="menu-item-368562"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-368562"
                          >
                            <a href="/catalogue-bahrain/">Catalogue Bahrain</a>
                          </li>
                          <li
                            id="menu-item-368291"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-368291"
                          >
                            <a href="/catalogue-oman/">Catalogue Oman</a>
                          </li>
                          <li
                            id="menu-item-884178"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-884178"
                          >
                            <a href="">Newsletter</a>
                          </li>
                          <li
                            id="menu-item-2344966"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2344966"
                          >
                            <a href="/news/">Media</a>
                          </li>
                          <li
                            id="menu-item-1613190"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1613190"
                          >
                            <a target="_blank" href="">
                              Blog
                            </a>
                          </li>
                          <li
                            id="menu-item-2738293"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2738293"
                          >
                            <a target="_blank" href="">
                              Become an Affiliate
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                */}
                </div>
              </div>
              <br />
              <div className="col-md-5 col-xs-12">
                <div className="footer-helper">
                  {/* <div className="feedback-wrp">
                    <a href="/feedback/">
                      <i className="sh sh-chat"></i>
                      <div className="footer--icon-text">
                        <span className="bd">Submit</span>
                        <span className="lt">Your feedback</span>
                      </div>
                    </a>
                  </div> */}
                  <div className="call-wrp">
                    <a href="tel:9851211057">
                      <i className="sh sh-call"></i>
                      <div className="footer--icon-text">
                        <span>Give a Call</span>
                        <span>+977 01 5108122 Ext: 2747 / 2831</span>
                        <span>+977 9851211057</span>
                        <span>+977 9851273416</span>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="social-media">
                  <div className="app-wrp">
                    <span>Download our App</span>
                    <a
                      href="https://apps.apple.com/us/app/cg-digital/id1495822676?ls=1"
                      target="_blank"
                    >
                      <i className="sh-apple"></i>
                    </a>
                    <a
                      href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.cgtechnodreams.cgdigital.app"
                      target="_blank"
                    >
                      <i className="sh-android"></i>
                    </a>
                  </div>
                  <div className="social-wrp">
                    <span>Get in touch</span>
                    <div className="text-right in-block">
                      <a
                        href="https://www.facebook.com/cgdigital.com.np"
                        target="_blank"
                      >
                        <i className="sh-facebook"></i>
                      </a>
                      <a
                        href="https://twitter.com/cgdigitalcomnp"
                        target="_blank"
                      >
                        <i className="sh-twitter"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/cgdigital.com.np/"
                        target="_blank"
                      >
                        <i className="sh-instagram"></i>
                      </a>
                      <a href="https://www.youtube.com/user/MrCgdigital">
                        <i className="fa fa-youtube-play"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="external-logo">
                  <div className="card">
                    <span className="fpay">The Easiest way to pay</span>
                    <a href="https://cgpay.com.np/" target="_blank">
                      {" "}
                      <img src={cgPay} height="34px" />
                    </a>
                    <a href="https://www.nabilbank.com/" target="_blank">
                      {" "}
                      <span className="pm visa"></span>
                    </a>
                    <span className="pm">
                      <a href="https://www.connectips.com/" target="_blank">
                        <img src={connectIPS} height="34px" />
                      </a>
                    </span>
                    <a href="">
                      <span className="pm cash"></span>
                    </a>
                    <a href="https://esewa.com.np" target="_blank">
                      <span className="pm payit"></span>
                    </a>
                    <a href="https://khalti.com/" target="_blank">
                      <img src={khaltiLogo} height="34px" />
                    </a>
                  </div>
                </div>
                <div className="mt-10 clearfix">
                  <div className="euronics">
                    <a href="">
                      <img src="/img/logo.png" alt="CG Digital" />
                    </a>
                    <Link to={"/"}>
                      <img
                        src={qrCode}
                        alt="CG Digital"
                        style={{
                          height: "70px",
                          width: "70px",
                          marginLeft: "10px",
                        }}
                      />
                    </Link>
                    <div className="mt-5">
                      <a href="">
                        {/* <img
                          src="/images/MemberofEuronics.svg?v=1"
                          alt="Euronics Sharaf DG"
                      />  */}
                      </a>
                    </div>
                  </div>
                  <div className="pull-right ">
                    {/* <img src="/img/ConsumerRightsLogo.png" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="sh-footer-left">
              <span>COPYRIGHT © 2021 CG Digital. ALL RIGHTS RESERVED</span>
              <span>
                <Link to={"/page-privacy-policy-9"}>Privacy policy</Link>
              </span>
              <span className="last">
                <Link to={"/page-terms-and-conditions-3"}>
                  Terms and conditions
                </Link>
              </span>
            </div>
            <div className="sh-footer-right bottom-link"></div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
