import React, { useState } from "react";
import Data from "../../utils/data";
import { useInput } from "../../hooks/inputHook";
import api from "../../utils/api";
const Newsletter = () => {
  const { value: email, bind: bindEmail } = useInput("");
  const [message, setMessage] = useState("");
  const [disable, setDisable] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisable("disabled");
    console.log(email);
    fetch(Data.API_BASE_URL + "post-subscribers", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        email: email,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        console.log(response);
        if (response.statusCode === 200) {
          console.log(response);
          setMessage(response.message);
          setDisable("");
          setTimeout(function () {
            setMessage("");
          }, 5000);
        } else {
          setErrorMessage(response.message);
          setDisable("");
          setTimeout(function () {
            setErrorMessage("");
          }, 5000);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    <div className="footer-newsletter">
      <div className="container">
        <div className="row">
          <form
            id="newsletter-validate-detail"
            method="post"
            onSubmit={handleSubmit}
            className="ng-pristine ng-valid"
          >
            <div className="col-md-4 col-xs-12">
              <h3 className="hidden-sm">Sign up for newsletter</h3>
            </div>
            <div className="col-md-8 col-xs-12">
              <div className="newsletter-inner">
                <input
                  className="newsletter-email"
                  type="email"
                  required=""
                  name="email"
                  placeholder="Enter Your Email"
                  {...bindEmail}
                />
                <button
                  className="button subscribe"
                  type="submit"
                  title="Subscribe"
                  disabled={disable}
                >
                  Subscribe
                </button>
                <br />
                {errorMessage !== "" ? (
                  <div
                    style={{
                      padding: "10px 0px 0px 10px",
                      color: "#840a0a",
                      textTransform: "capitalize",
                      display: "inherit",
                    }}
                  >
                    {errorMessage}
                  </div>
                ) : (
                  ""
                )}
                {message !== "" ? (
                  <div
                    className="message"
                    style={{
                      padding: "10px 0px 0px 10px",
                      color: "darkgreen",
                      textTransform: "capitalize",
                      display: "inherit",
                    }}
                  >
                    {" "}
                    {message}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
