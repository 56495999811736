import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Layouts/Breadcrumb";
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInput } from "../../hooks/inputHook";
import Data from "../../utils/data";
import api from "../../utils/api";
const ContactUs = (props) => {
  const [isLoading, setIsloading] = useState(true);
  const { value: name, bind: bindName, setValue: setName } = useInput("");
  const { value: email, bind: bindEmail, setValue: setEmail } = useInput("");
  const { value: phone, bind: bindPhone, setValue: setPhone } = useInput("");
  const { value: enquiry, bind: bindEnquiry, setValue: setEnquiry } = useInput(
    ""
  );
  const handleSubmit = (evt) => {
    evt.preventDefault();
    fetch(Data.API_BASE_URL + "post-enquiry", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        enquiry: enquiry,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        if (response.statusCode == 200) {
          setName("");
          setEmail("");
          setPhone("");
          setEnquiry("");
          // swal(response.message);
        } else {
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  useEffect(() => {
    setIsloading(false);
  }, []);
  return (
    <React.Fragment>
      <div className="search-page" id="sr1">
        <div className="search-content">
          {isLoading ? (
            <div
              className="container"
              style={{ textAlign: "center", padding: "100px 0px" }}
            >
              <ClipLoader
                css={{ margin: "0 auto" }}
                size={150}
                //size={"150px"} this also works
                color={"#123abc"}
                loading={isLoading}
              />
            </div>
          ) : (
            <div className="container">
              <Breadcrumb
                levelOne={{
                  name: "Contact Us",
                  link: "page/contact-us",
                }}
              />

              <div class="container">
                <div class="static-page white-well">
                  <div class="contact-page">
                    <br />
                    <h1 class="text-center">Contact Us</h1>
                    <br />
                    <div class="row">
                      <div class="col-md-3">
                        <div class="address-box text-center">
                          <h3>ADDRESS</h3>
                          <FontAwesomeIcon icon="home" />
                          CG Digital Park,
                          <br /> Satungal,
                          <br /> Kathmandu,
                          <br /> Nepal{" "}
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="address-box text-center">
                          <h3>POST BOX</h3>
                          <FontAwesomeIcon icon="inbox" />
                          Post box: 1073
                          <br />
                          Post code: 44619
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="address-box text-center">
                          <h3>PHONE</h3>
                          <FontAwesomeIcon icon="phone" />
                          +977 01 5108122 Ext: 2747
                          <br /> 9851273416
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="address-box address-box-noborder text-center">
                          <h3>EMAIL</h3>
                          <a href="mailto:info@cgdigital.com.np">
                            <FontAwesomeIcon icon="envelope" />
                            info@cgdigital.com.np
                          </a>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="clearfix"></div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="enquiry-form">
                          <h3 class="text-center">Enquiry Form</h3>

                          <form
                            onSubmit={handleSubmit}
                            accept-charset="UTF-8"
                            class="form-horizontal"
                          >
                            <fieldset>
                              <br />
                              <div class="form-group required">
                                <label
                                  class="col-sm-3 control-label"
                                  for="input-name"
                                >
                                  Your Name
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    name="name"
                                    id="input-name"
                                    class="form-control"
                                    {...bindName}
                                    required
                                  />
                                </div>
                              </div>
                              <div class="form-group required">
                                <label
                                  class="col-sm-3 control-label"
                                  for="input-email"
                                >
                                  E-Mail Address
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="email"
                                    name="email"
                                    id="input-email"
                                    class="form-control"
                                    {...bindEmail}
                                    required
                                  />
                                </div>
                              </div>
                              <div class="form-group required">
                                <label
                                  class="col-sm-3 control-label"
                                  for="input-phone"
                                >
                                  Phone
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    name="phone"
                                    id="input-phone"
                                    class="form-control"
                                    {...bindPhone}
                                    required
                                  />
                                </div>
                              </div>
                              <div class="form-group required">
                                <label
                                  class="col-sm-3 control-label"
                                  for="input-enquiry"
                                >
                                  Enquiry
                                </label>
                                <div class="col-sm-9">
                                  <textarea
                                    name="enquiry"
                                    rows="10"
                                    id="input-enquiry"
                                    class="form-control"
                                    required
                                    {...bindEnquiry}
                                  ></textarea>
                                </div>
                              </div>
                            </fieldset>
                            <div class="buttons">
                              <div class="text-center">
                                <input
                                  class="btn-transparent"
                                  type="submit"
                                  value="Submit"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="map-holder embed-responsive-21by9">
                          <h3 class="text-center">Location</h3>
                          <br />
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14132.051027979898!2d85.24206631685792!3d27.686000593147767!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb22ec71a72edb%3A0xe2e09453487fa02c!2sC.G.+Electronics+Pvt.+Ltd.!5e0!3m2!1sen!2snp!4v1537089654213"
                            width="100%"
                            height="423"
                            frameborder="0"
                            style={{ border: "0" }}
                            allowfullscreen=""
                          ></iframe>
                          <div id="map_canvas"></div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
