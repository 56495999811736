import React from "react";
import { Link } from "react-router-dom";
const SupplierLogo = (props) => {
  return (
    <>
      <Link to={props.clickEvent}>
        <img
          src={props.logo}
          style={{
            height: "20px",
            objectFit: "contain",
            verticalAlign: "none",
            marginLeft: "5px",
          }}
        />
      </Link>
    </>
  );
};

export default SupplierLogo;
