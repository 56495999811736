import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useInput } from "../../hooks/inputHook";
export const MobileSearch = () => {
  const { value: search, bind: bindSearch } = useInput("");
  let history = useHistory();
  const onClickSearch = (evt) => {
    evt.preventDefault();
    console.log(search);
    history.push({
      pathname: "/search/" + search,
    });
    return false;
  };
  return (
    <React.Fragment>
      <form onSubmit={(evt) => onClickSearch(evt)} autocomplete="off">
        <input
          type="search"
          className="search-field col-xs-8"
          placeholder="Search Products&hellip;"
          name="q"
          {...bindSearch}
          //   title="Search for:"
          style={{ padding: "15px 5px", border: "solid 3px #0171b6" }}
        />
        <button className="btn btn-secondary col-xs-4 search-mobile-btn" style={{ padding: "15px 5px" }}>
          <FontAwesomeIcon icon="search" />
        </button>
      </form>
    </React.Fragment>
  );
};
