import React, { useState, useEffect } from "react";
import FloatingInput from "../FloatingInput/floatingInput";
import "./shippingAddress.css";
import { useHistory } from "react-router-dom";
import "../../ola.css";
import storage from "../utils/storage";
import LoadingOverlay from "react-loading-overlay";



const ShippingAddressInput = (props) => {

  let history = useHistory();
  let user = storage.getUser();

  const [fullName, setFullName] = useState(props.shippingAddress.full_name);
  const [email, setEmail] = useState(props.shippingAddress.email);
  const [phone, setPhone] = useState(props.shippingAddress.phone);
  const [province, setProvince] = useState(props.shippingAddress.province);
  const [city, setCity] = useState(props.shippingAddress.city);
  const [area, setArea] = useState(props.shippingAddress.area);
  const [address, setAddress] = useState(props.shippingAddress.address);
  const [note, setNote] = useState(props.shippingAddress.note);

  const [errorMessageFullname, setErrorMessageFullname] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePhone, setErrorMessagePhone] = useState("");
  const [errorMessageProvince, setErrorMessageProvince] = useState("");
  const [errorMessageAddress, setErrorMessageAddress] = useState("");
  const [errorMessageNote, setErrorMessageNote] = useState("");
  const [errorMessageCity, setErrorMessageCity] = useState("");
  const [errorMessageArea, setErrorMessageArea] = useState("");

  const [isActiveOverlay, setIsActiveOverlay] = useState(false);


  const handleValidation = (evnt) => {
    const inputValue = evnt.target.value.trim();
    const inputField = evnt.target.getAttribute('label');

    if(inputField === 'Full Name'){
      const pattern = /[^a-zA-Z\s.]/g;
      if(inputValue.length === 0){
        setErrorMessageFullname("Please enter full name.");
      }
      else if(pattern.test(inputValue)){
        setErrorMessageFullname("Name should not contain special characters.");
      }
      else{
        setErrorMessageFullname("");
      }
    }

    if(inputField==='Email'){
      const emailRegExp   = /\S+@\S+\.\S+/;
      if(inputValue.length === 0){
        setErrorMessageEmail("Please enter email.");
      }else if(!emailRegExp.test(inputValue)){
        setErrorMessageEmail("Please enter valid email address.");
      }else{
        setErrorMessageEmail("");
      }
    }

    if(inputField==='Mobile Number'){
      if(inputValue.length === 0){
        setErrorMessagePhone("Please enter mobile number.");
      }else if(inputValue.length != 10){
        setErrorMessagePhone("Please enter valid mobile number.");
      }else{
        setErrorMessagePhone("");
      }
    }

    if(evnt.target.getAttribute('title')==='Address'){
      if(inputValue.length === 0){
        setErrorMessageAddress("Please enter address.");
      }else{
        setErrorMessageAddress("");
      }
    }

  };

  function getProductItemId() {
    var url = new URL(window.location.href);
    return url.searchParams.get("product");
  }

  function handleRequest(evnt){
    evnt.preventDefault();
    setIsActiveOverlay(true);
    
    setErrorMessageFullname('');
    setErrorMessageEmail('');
    setErrorMessagePhone('');
    setErrorMessageProvince('');
    setErrorMessageAddress('');

    let formError = false;

    const pattern = /[^a-zA-Z\s.]/g;
    if(fullName == ''){
      setErrorMessageFullname('Please enter fullname');
      formError = true;
    }else if(pattern.test(fullName)){
      setErrorMessageFullname("Name should not contain special characters.");
      formError = true;
    }
    
    const emailRegExp   = /\S+@\S+\.\S+/;
    if(email == ''){
      setErrorMessageEmail('Please enter email');
      formError = true;
    }else if(!emailRegExp.test(email)){
      setErrorMessageEmail("Please enter valid email address.");
      formError = true;
    }
    
    if(phone == ''){
      setErrorMessagePhone('Please enter mobile number.');
      formError = true;
    }else if(phone.length != 10){
      setErrorMessagePhone("Please enter valid mobile number.");
      formError = true;
    }
    
    if(province == '' || !province){
      setErrorMessageProvince('Please select province.');
      formError = true;
    }if(area == '' || !area){
      setErrorMessageArea('Please select area.');
      formError = true;
    }if(city == '' || !city){
      setErrorMessageCity('Please select city.');
      formError = true;
    }if(address == '' || !address){
      setErrorMessageAddress('Please enter address.');
      formError = true;
    }
    setIsActiveOverlay(false);
    
    if(formError == false){

      localStorage.setItem('shipping_address', JSON.stringify({
          'full_name' : fullName,
          'phone' : phone,
          'email' : email,
          'province': province,
          'city' : city,
          'area' : area,
          'address' : address,
          'note' : note
    }));

      history.push({
        pathname: "/payment",
        search:
        "?source=ola&product="+getProductItemId(),
        state: {'fullName' : fullName, 'email' : email, 'phone' : phone, 'province' : province, 'city' : city, 'area' : area, 'address': address, 'note' : note}
      })
    }
   
  }

  function selectInput(title, value){
    if(title == 'Province'){    
      setErrorMessageProvince("");
      setCity('');
      setArea('');
      props.fetchCities(value);
    }
    if(title == 'City'){  
      setErrorMessageCity("");  
      setArea('');
      props.fetchAreas(value);
    }
    if(title == 'Area'){  
      setErrorMessageArea("");  
    }
  }


useEffect(() => {  
    if (!storage.isLoggedIn()) {
      history.goBack();
    }
    
   
});


  return (
    <LoadingOverlay active={isActiveOverlay} spinner text="Loading">
       <form
      onSubmit={handleRequest}
    >
      <div className="newaccount-wrapper">
          <div className="newaccount-title">Shipping address</div>
          <div className="inputBox-container">
         
              <FloatingInput
                  type="text"
                  label="Full Name"
                  value={fullName}
                  setValue={setFullName}
                  handleValidation={handleValidation} 
              />
              {errorMessageFullname ? (<><p style={{'color':'red'}}>{errorMessageFullname}</p></>) : ""}  
              <FloatingInput
                  type="number"
                  label="Mobile Number"
                  value={phone}
                  setValue={setPhone}
                  maxlength="10"
                  handleValidation={handleValidation} 
              />
              {errorMessagePhone ? (<><p style={{'color':'red'}}>{errorMessagePhone}</p></>) : ""} 
              <FloatingInput
                  type="text"
                  label="Email"
                  value={email}
                  setValue={setEmail}
                  handleValidation={handleValidation} 
              />
              {errorMessageEmail ? (<><p style={{'color':'red'}}>{errorMessageEmail}</p></>) : ""} 
              <FloatingInput
                  type="dropDown"
                  dropDownData={props.provinces}
                  title="Province"
                  value={province}
                  setValue={setProvince}
                  selectInput={selectInput}
                  handleValidation={handleValidation} 
              />
              {errorMessageProvince ? (<><p style={{'color':'red'}}>{errorMessageProvince}</p></>) : ""} 
              <FloatingInput
                  type="dropDown"
                  dropDownData={props.cities}
                  title="City"
                  value={city}
                  setValue={setCity}
                  selectInput={selectInput}
                  handleValidation={handleValidation} 
              />
               {errorMessageCity ? (<><p style={{'color':'red'}}>{errorMessageCity}</p></>) : ""} 
              <FloatingInput
                  type="dropDown"
                  dropDownData={props.areas}
                  title="Area"
                  value={area}
                  setValue={setArea}
                  selectInput={selectInput}
                  handleValidation={handleValidation} 
              />
               {errorMessageArea ? (<><p style={{'color':'red'}}>{errorMessageArea}</p></>) : ""} 
              <FloatingInput
                  type="textarea"
                  value={address}
                  setValue={setAddress}
                  label="Address"
                  title="Address"
                  handleValidation={handleValidation} 
              />
              {errorMessageAddress ? (<><p style={{'color':'red'}}>{errorMessageAddress}</p></>) : ""} 
              <FloatingInput
                  type="textarea"
                  value={note}
                  setValue={setNote}
                  label={"Note"}
                  handleValidation={handleValidation} 
              />
              {errorMessageNote ? (<><p style={{'color':'red'}}>{errorMessageNote}</p></>) : ""} 
          </div>
          
    
          <div className="signup-button">
              <button type="submit">Proceed</button>
          </div>
      </div>
      </form>
      </LoadingOverlay>
  );
};
export default ShippingAddressInput;
