import React, { useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { USER_ACCOUNT } from "../route/routeURL";
import storage from "../utils/storage";
import { CartContext } from "../Layouts/CartContextProvider";
import { LoyaltyContext } from "../Layouts/LoyaltyProvider/LoyaltyContextProvider";
const OrderComplete = (props) => {
  let history = useHistory();
  const { refreshLoyaltyApi } = useContext(LoyaltyContext);

  const { refreshApi } = useContext(CartContext);
  useEffect(() => {
    if (!storage.isLoggedIn()) {
      history.replace("/");
    }
    refreshApi();
    refreshLoyaltyApi();
  }, []);
  return (
    <React.Fragment>
      <div className="container" style={{ height: "400px" }}>
        <h3>
          Dear {storage.getUserName()},
          <br />
          <br />
          {
            (new URLSearchParams(props.location.search).get("emi-purchase")) ?
              "Thank you very much for shopping with us. Your application to convert your transaction into Flexi Pay Easy EMI have been forwarded to your card issuing bank. It will take around 7 days to convert your transaction to EMI. Our sales representative will soon contact you for the confirmation of your order."
              : "Thank you very much for shopping with us. Our sales representative will soon contact you for the confirmation of your order."
          }
        </h3>
        <h4>Should you have any query, please call us on 9851211057/9851273416.</h4>
        <br />
        <br />
        <div className="text-center">
          <Link to={USER_ACCOUNT + "?goto=order-history"} className="btn btn-info">
            Go to Order History
          </Link>
        </div>
        <br />
        <br />
      </div>
    </React.Fragment>
  );
};

export default OrderComplete;
