import React, { useEffect, useState } from "react";
import Data from "../../utils/data";
import api from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";


import swal from "sweetalert";

const BookingHistory = (props) => {
  const [bookings, setBookings] = useState([]);

  async function fetchBookings() {
    let url = new URL(Data.API_BASE_URL + "get-bookings");
    await fetch(url, {
      method: "GET",
      headers: Data.API_HEADER,
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setBookings(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function cancelBookings(booking, reason) {
    let url = new URL(Data.API_BASE_URL + "cancel-booking/" + booking.id);
    await fetch(url, {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        cancelled_reason: reason,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const CancelBooking = (evt, booking) => {
    var cancelBooking = booking;
    swal({
      title: "Are you sure?",
      text:
        "You Want to Cancel Booking ID : " +
        booking.id +
        ", Reason for cancellation",
      content: "input",
      //   icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((name) => {
      if (!name) throw null;
      var cancelled = cancelBookings(booking, name);
      if (cancelled) {
        window.scrollTo(0, 0);
        props.setMessage(
          "Your Booking with Booking ID " +
          booking.id +
            " request for cancellation is forward to us."
        );
        fetchBookings();
      }
    });
  };
 
  useEffect(() => {
    fetchBookings();
  }, []);
  return (
    <React.Fragment>
      <h3 className="text-center">Booking History</h3>
      <form className="ng-pristine ng-valid">

        <div className="table-responsive">
          <table className="table order-table table-striped">
            <tbody className="panel-group accordion-faq" id="faq-accordion">
              <tr>
              <th>
                  <center>
                    <b>Product</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Date</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Booking ID</b>
                  </center>
                </th>
              
                <th>
                  <center>
                    <b>Prepayment</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Payment Method</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Order Status</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Payment Status</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Delivery Status</b>
                  </center>
                </th>
               
              </tr>
              {bookings.map(function (booking, index) {
                return (
                  <React.Fragment key={index}>
                    <tr className="panel" rowSpan="2">
                    <td>
                        <img src={booking.featured_image} alt=""  width="160px"/>
                        {/* <br></br>
                        <strong>{booking.product_name}</strong> */}
                      </td>
                      <td>
                        <center>{booking.created_at}</center>
                      </td>
                      <td>
                        <center>{booking.id}</center>
                      </td>
                     
                      <td>
                        <center>{booking.currency+' '+booking.total_amount}</center>
                      </td>
                      <td>
                        <center>{booking.payment_method}</center>
                      </td>
                      <td>
                        <center>{booking.status}</center>
                      </td>
                      <td>
                        <center>{booking.payment_status}</center>
                      </td>
                      <td>
                        <center>{booking.delivery_status}</center>
                      </td>
                    
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <div
                          className="table-responsive"
                          style={{ marginBottom: "30px" }}
                        >
                          {booking.status == "Pending" && booking.cancelled_booking != 'C' ? (
                            <p
                              className="cancel-order text-center"
                              style={{ color: "red" }}
                            >
                              If you want to cancel your booking click
                              <a
                                onClick={(evt) => CancelBooking(evt, booking)}
                                className="cancel-order"
                              >
                                here.
                              </a>
                            </p>
                          ) : (
                            ""
                          )}
                          {booking.status == "Pending" &&
                          booking.cancelled_booking == "C" ? (
                            <p
                              className="cancel-order text-center"
                              style={{ color: "red" }}
                            >
                              You have made booking cancellation request for this
                              booking.
                            </p>
                          ) : (
                            ""
                          )}

                          {booking.status == "Cancelled" &&
                          booking.cancelled_booking == "C" ? (
                            <p
                              className="cancel-order text-center"
                              style={{ color: "red" }}
                            >
                              Booking rejected on your booking cancellation request.
                            </p>
                          ) : (
                            ""
                          )}

                         
                        </div>
                      </td>

                      
                      
                    </tr>

                    {booking.buy_now_open_status == 1
                        ? <>
                       <tr>
                       <td>
                          <Link
                              to={
                                "/buynow-" +
                                booking.product_name
                                  .replace(/[#+. _/]/gi, "-")
                                  .toLowerCase() +
                                "-" +
                                booking.id
                              }
                            >
                              Buy Now
                            </Link> 
                      </td>
                       </tr>
                        </>
                        : ''
                      }

                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </form>
    </React.Fragment>
  );
};
export default BookingHistory;
