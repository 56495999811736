import React from "react";
const ScrollTextLayout = (props) => {

  return (
    <>
      {props.marque !== null ? (
        <div className="col-md-12">
          <marquee behavior="scroll" direction="left">
            <font face="times new roman,times,serif">
              <h5 className="marque">
                <font size="4" color="#000000">
                  <b>
                    <a href={props.view.go_to} target="_blank">
                      {props.view.title}
                    </a>
                  </b>
                </font>
              </h5>
            </font>
          </marquee>
        </div>
      ) : (
        " "
      )}
      <br /><br /><br />
    </>
  );
};

export default ScrollTextLayout;
