import ReactPixel from "react-facebook-pixel";

export const sendPageView = async () => {
  const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init("190990928760577", {}, options);
  // console.log('page-trigger');
  ReactPixel.pageView();
};
export const sendProductVisited = async (product) => {
  const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init("190990928760577", {}, options);

  ReactPixel.pageView();

  ReactPixel.track("ViewContent", {
    content_ids: product.code,
    content_type: "product",
    product_catalog_id: 473113137031415,
  });
};

export const sendAddToCart = async (product) => {
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init("190990928760577", {}, options);
  ReactPixel.track("AddToCart", {
    content_ids: product.code,
    brand: product.brand,
    category: product.product_category_name,
    currency: "NPR",
    price: product.discount_price,
    actual_price: product.price,
    content_type: "product",
    product_catalog_id: 473113137031415,
  });
};

export const sendAddToCartPushGTM = async (product) => {

  var dataLayer = window.dataLayer || [];
  // dataLayer.push({
  //   event: "add_to_cart",
  //   value: product.price,
  //   items: [
  //     {
  //       id: product.product_code,
  //       google_business_vertical: "retail",
  //     },
  //   ],
  // });

  dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      currency: "NPR",
      value: product.price,
      items: [
      {
        item_id: product.product_code,
        item_name: product.name,
        item_brand: product.brand,
        item_category: product.product_category_name,
        price: product.price,
        quantity: 1
      }
      ]
    }
  });
};
