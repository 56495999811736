import React, { createContext, useState, useReducer, useEffect } from "react";
import api from "../utils/api";
import Data from "../utils/data";
import storage from "../utils/storage";

export const CartContext = createContext();

const CartContextProvider = ({ children }) => {
  const [cartItem, setCartItem] = useState(null);
  const [cartData, setCartData] = useState({});
  const cartOnline = async (couponCode = null, redeemPoint = null) => {
    if (storage.isLoggedIn()) {
      if (couponCode != null) {
        let url = new URL(Data.API_BASE_URL + "get-cart");
        let data = { coupon: couponCode, platform: "W" };
        if (redeemPoint != null) {
          data.redeem = redeemPoint;
        }
        url.search = new URLSearchParams(data).toString();
        return await fetch(url, {
          method: "GET",
          headers: Data.API_HEADER,
        })
          .then(api.handleResponseAndError)
          .then((response) => {
            if (response.data !== null) {
              setCartData(response.data);
              if (response.data.cart_items.length > 0) {
                setCartItem(response.data.cart_items);

                // const gtmCartProductArr = [];
                // response.data.cart_items.map(function (product) {
                //   gtmCartProductArr.push({
                //     id: product.product_code,
                //     google_business_vertical: "retail",
                //   });
                // });

                // GTM(response.data.cart_total, gtmCartProductArr);
              } else {
                setCartItem([]);
              }
            } else {
              setCartItem([]);
            }
          })
          .catch((error) => {
            console.log(error);
            // storage.logout();
            setCartItem(null);
          });
      } else {
        let url = new URL(Data.API_BASE_URL + "get-cart");
        let data = { platform: "W" };
        if (redeemPoint != null) {
          data.redeem = redeemPoint;
        }
        url.search = new URLSearchParams(data).toString();
        return await fetch(url, {
          method: "GET",
          headers: Data.API_HEADER,
        })
          .then(api.handleResponseAndError)
          .then((response) => {
            if (response.data !== null) {
              setCartData(response.data);
              if (response.data.cart_items.length > 0) {
                setCartItem(response.data.cart_items);

                // const gtmCartProductArr = [];
                // response.data.cart_items.map(function (product) {
                //   gtmCartProductArr.push({
                //     id: product.product_code,
                //     google_business_vertical: "retail",
                //   });
                // });

                // GTM(response.data.cart_total, gtmCartProductArr);
              } else {
                setCartItem([]);
              }
            } else {
              setCartItem([]);
            }
          })
          .catch((error) => {
            console.log(error);
            setCartItem(null);
          });
      }
    } else {
      setCartItem(null);
    }
  };
  const addToCartApi = (productId, qty = 1) => {
    return fetch(Data.API_BASE_URL + "add-to-cart", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        qty: qty,
        product_id: productId,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        cartOnline();
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };
  const productDeleteApi = (product, coupon, redeem) => {
    return fetch(Data.API_BASE_URL + "delete-cart", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        product_id: product.product_id,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        cartOnline(coupon, redeem);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };
  function lessProductCountApi(product, coupon, redeem) {
    return fetch(Data.API_BASE_URL + "update-cart", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        qty: product.qty - 1,
        product_id: product.product_id,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        cartOnline(coupon, redeem);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }
  function addProductCountApi(product, coupon, redeem) {
    return fetch(Data.API_BASE_URL + "update-cart", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        qty: product.qty + 1,
        product_id: product.product_id,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        cartOnline(coupon, redeem);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }
  function refreshApi(couponCode = null, redeemPoint = null) {
    cartOnline(couponCode, redeemPoint);
  }

  function GTM(cartTotal, gtmCartProductArr) {
    var dataLayer = window.dataLayer || [];

    dataLayer.push({
      event: "add_to_cart",
      value: cartTotal,
      items: gtmCartProductArr,
    });
  }

  useEffect(() => {
    cartOnline();
  }, []);
  return (
    <CartContext.Provider
      value={{
        cartItem: cartItem,
        cartData: cartData,
        setCartData: setCartData,
        addToCartApi: addToCartApi,
        productDeleteApi: productDeleteApi,
        lessProductCountApi: lessProductCountApi,
        addProductCountApi: addProductCountApi,
        refreshApi: refreshApi,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
