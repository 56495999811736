import React, { useEffect, useState } from "react";
import Breadcrumb from "../Layouts/Breadcrumb";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from "react-number-format";
import Data from "../utils/data";
import api from "../utils/api";
import { Link } from "react-router-dom";
import { useInput } from "../hooks/inputHook";
import storage from "../utils/storage";
import cartFunction from "../utils/cartFunction";
import { USER_ACCOUNT, ORDER_COMPLETE, BOOKING_COMPLETE } from "../route/routeURL";
import WithContext from "../Layouts/MainMessage/WithContext";
import { MetaTags } from "react-meta-tags";
import CheckoutSkeleton from "../page/Skeleton/CheckoutSkeleton";
import LoadingOverlay from "react-loading-overlay";
import SupplierLogo from "../page/Product/Logo/SupplierLogo";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
const Prebook = (props) => {
  let history = useHistory();
  const [data, setData] = useState([]);
  
  const isChanging = React.useRef(false);

  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);

  // const { bind: bindProvinceId, setValue: setProvinceId } = useInput("");
  // const { bind: bindCityId, setValue: setCityId } = useInput("");

  const [esewaData, setEsewaData] = useState(null);
  const [selectBankTransfer, setSelectBankTransfer] = useState(false);
  const [banks, setBanks] = useState([]);
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [couponCode, setCouponCode] = useState(null);
  const [redeemPoint, setRedeemPoint] = useState(null);
  const [tds, setTds] = useState(null);
  const { value: name, bind: bindName, setValue: setName } = useInput("");
  const { value: email, bind: bindEmail, setValue: setEmail } = useInput("");
  const { value: mobile, bind: bindMobile, setValue: setMobile } = useInput("");

  const [isActiveOverlay, setIsActiveOverlay] = useState(false);
  const {
    value: provinceId,
    bind: bindProvinceId,
    setValue: setProvinceId,
  } = useInput("");
  const { value: cityId, bind: bindCityId, setValue: setCityId } = useInput("");
  const { value: areaId, bind: bindAreaId, setValue: setAreaId } = useInput("");

  const { value: bankId, bind: bindBankId, setValue: setBankId } = useInput("");
  const {
    value: address,
    bind: bindAddress,
    setValue: setAddress,
  } = useInput("");
  const {
    value: customer_note,
    bind: bindCustomerNote,
    setValue: setCustomerNote,
  } = useInput("");
  const {
    value: paymentMethod,
    bind: bindPaymentMethod,
    setValue: setPaymentMethod,
  } = useInput("");

     async function fetchData() {

        if (storage.isLoggedIn()) {
            let user = storage.getUser();
            setName(user.name);
            setEmail(user.email);
            setMobile(user.mobile);
            setAddress(user.address);
          }

    //
    let url = new URL(Data.API_BASE_URL +  "get-prebooking/" + getProductItemId());
     url.search = new URLSearchParams(data).toString();
    var fetchData = await fetch(url,
      {
        method: "GET",
         headers: Data.API_HEADER,
      }
    )
      .then(api.handleResponseAndError)
      .then((response) => {
      
          console.log(response.data);

          if(response.statusMessage == 'Fail'){
          
            history.replace("/");
          }
        
        return setData(response.data);
        
      })
      .catch((error) => {
        console.log("test" + error);
       
      });
  }
  


   function getProductItemId() {
    let id = props.match.params["product_name"].split("-");
    id = id[id.length - 1];
    return id;
  }



  async function getProvince() {
    setIsActiveOverlay(true);
    fetch(Data.API_BASE_URL + "get-provinces", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setIsActiveOverlay(false);
        setProvinces(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function getCities(e) {
    setIsActiveOverlay(true);
    setAreas([]);
    let provinceId = e.target.value;
    if (provinceId !== null && provinceId !== "") {
      setProvinceId(e.target.value);
      fetch(Data.API_BASE_URL + "get-cities-via-province/" + provinceId, {
        method: "GET",
      })
        .then(api.handleResponseAndError)
        .then((response) => {
          setIsActiveOverlay(false);
          setCities(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setIsActiveOverlay(false);
      setProvinceId("");
      setCityId("");
      setCities([]);
    }
  }

  async function getAreas(e) {
    setIsActiveOverlay(true);
    let city = e.target.options[e.target.selectedIndex].text;

    let cityId = e.target.value;

    // if (
    //   city.toLowerCase().includes("bhaktapur") ||
    //   city.toLowerCase().includes("kathmandu") ||
    //   city.toLowerCase().includes("lalitpur")
    // ) {
    //   console.log("Show cash on deliver");
    //   var tag = document.getElementById("payment_with_Cash On Delivery");
    //   tag.closest("label").closest("div").style.display = "block";
    // } else {
    //   console.log("hide");
    //   var tag = document.getElementById("payment_with_Cash On Delivery");
    //   tag.closest("label").closest("div").style.display = "none";
    //   tag.checked = false;
    // }

    if (cityId !== null && cityId !== "") {
      setCityId(e.target.value);
      fetch(Data.API_BASE_URL + "get-areas-via-city/" + cityId, {
        method: "GET",
      })
        .then(api.handleResponseAndError)
        .then((response) => {
          setIsActiveOverlay(false);
          setAreas(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setIsActiveOverlay(false);
      setCityId("");
      setAreas([]);
    }
  }

  function viewErrorMessage() {
    setTimeout(() => setFormError(false), 5000);
  }

  async function getBanks() {
    fetch(Data.API_BASE_URL + "bank-transfer/get-banks", {
      method: "GET",
      headers: Data.API_HEADER,
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setBanks(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function khaltiPayment(orderId, totalAmt) {
    var KhaltiCheckout = (function () {
      "use strict";

      function unwrapExports(x) {
        return x &&
          x.__esModule &&
          Object.prototype.hasOwnProperty.call(x, "default")
          ? x["default"]
          : x;
      }

      function createCommonjsModule(fn, module) {
        return (
          (module = { exports: {} }), fn(module, module.exports), module.exports
        );
      }

      var _typeof2 =
        typeof Symbol === "function" && typeof Symbol.iterator === "symbol"
          ? function (obj) {
              return typeof obj;
            }
          : function (obj) {
              return obj &&
                typeof Symbol === "function" &&
                obj.constructor === Symbol &&
                obj !== Symbol.prototype
                ? "symbol"
                : typeof obj;
            };

      var lib = createCommonjsModule(function (module, exports) {
        Object.defineProperty(exports, "__esModule", {
          value: true,
        });

        var _typeof =
          typeof Symbol === "function" && _typeof2(Symbol.iterator) === "symbol"
            ? function (obj) {
                return typeof obj === "undefined" ? "undefined" : _typeof2(obj);
              }
            : function (obj) {
                return obj &&
                  typeof Symbol === "function" &&
                  obj.constructor === Symbol
                  ? "symbol"
                  : typeof obj === "undefined"
                  ? "undefined"
                  : _typeof2(obj);
              };

        var SkipValidation = (exports.SkipValidation = function SkipValidation(
          message
        ) {
          this.name = "SkipValidation";
          this.message = message;
        });

        var validateSingle = (exports.validateSingle = function validateSingle(
          data,
          validators,
          multipleErrors,
          all,
          key
        ) {
          var errors = [];

          if (typeof validators === "function") {
            validators = [validators];
          }

          for (var i = 0; i < validators.length; i++) {
            try {
              var error = validators[i](data, all);
              if (typeof error === "string") {
                errors.push(
                  error.replace("{value}", data).replace("{key}", key)
                );
              }
            } catch (err) {
              if (err instanceof SkipValidation) {
                break;
              }
            }
          }

          if (multipleErrors === true) return errors;

          if (errors.length > 0) return errors[0];
        });

        var validate = (exports.validate = function validate(
          data,
          validators,
          multipleErrors
        ) {
          if (!validators) return;

          var errors = {};
          var noError = true;

          if (
            (typeof validators === "undefined"
              ? "undefined"
              : _typeof(validators)) === "object" &&
            !validators.length
          ) {
            for (var prop in validators) {
              if (validators.hasOwnProperty(prop)) {
                var error = validateSingle(
                  data[prop],
                  validators[prop],
                  multipleErrors,
                  data,
                  prop
                );

                if (error !== undefined) {
                  noError = false;
                }

                errors[prop] = error;
              }
            }

            return noError ? undefined : errors;
          }

          errors = validateSingle(data, validators, multipleErrors);
          return errors;
        });

        var required = (exports.required = function required(flag, error) {
          function isNullLike(value) {
            return value === undefined || value === "" || value === null;
          }

          return function (value) {
            if (flag && isNullLike(value)) {
              return error || "This field is required.";
            } else if (!flag && isNullLike(value)) {
              // skip rest of the validators
              throw new SkipValidation();
            }
          };
        });

        var isNumber = (exports.isNumber = function isNumber(error) {
          return function (value) {
            if (typeof value !== "number" || isNaN(value)) {
              return error || "'{value}' is not a valid number.";
            }
          };
        });

        var isString = (exports.isString = function isString(error) {
          return function (value) {
            if (typeof value !== "string") {
              return error || "'{value}' is not a valid string.";
            }
          };
        });

        var isFunction = (exports.isFunction = function isFunction(error) {
          return function (value) {
            if (typeof value !== "function") {
              return error || "Expected a function.";
            }
          };
        });

        var isObject = (exports.isObject = function isObject(error) {
          return function (value) {
            if (value !== Object(value)) {
              return error || "Expected an object.";
            }
          };
        });

        var isArray = (exports.isArray = function isArray(error) {
          return function (value) {
            if (Object.prototype.toString.call(value) !== "[object Array]") {
              return error || "Expected an array.";
            }
          };
        });

        var length = (exports.length = function length(_length, error) {
          return function (value) {
            var str = value + "";
            if (str.length !== _length) {
              return error || "It must be " + _length + " characters long.";
            }
          };
        });

        var isEmail = (exports.isEmail = function isEmail(error) {
          return function (value) {
            var pattern =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!pattern.test(value)) {
              return error || "Invalid email id.";
            }
          };
        });

        var equalsTo = (exports.equalsTo = function equalsTo(key, error) {
          return function (value, all) {
            if (value !== all[key]) {
              return error || "'{key}' and '" + key + "' do not match.";
            }
          };
        });

        var minLength = (exports.minLength = function minLength(length, error) {
          return function (value) {
            var str = value + "";
            if (str.length < length) {
              return (
                error || "It must be at least " + length + " characters long."
              );
            }
          };
        });

        var maxLength = (exports.maxLength = function maxLength(length, error) {
          return function (value) {
            var str = value + "";
            if (str.length > length) {
              return (
                error || "It must be at most " + length + " characters long."
              );
            }
          };
        });

        var isBoolean = (exports.isBoolean = function isBoolean(error) {
          return function (value) {
            if (value !== true && value !== false) {
              return error || "Invalid boolean value.";
            }
          };
        });

        var within = (exports.within = function within(list, error) {
          return function (value) {
            if (!(value instanceof Array)) {
              value = [value];
            }

            var odds = [];

            for (var index = 0; index < value.length; index++) {
              if (list.indexOf(value[index]) === -1) {
                odds.push(value[index]);
              }
            }

            if (odds.length > 0) {
              return (
                error || "[" + odds + "] do not fall under the allowed list."
              );
            }
          };
        });

        var excludes = (exports.excludes = function excludes(list, error) {
          return function (value) {
            if (!(value instanceof Array)) {
              value = [value];
            }

            var odds = [];

            for (var index = 0; index < value.length; index++) {
              if (list.indexOf(value[index]) !== -1) {
                odds.push(value[index]);
              }
            }

            if (odds.length > 0) {
              return error || "[" + odds + "] fall under restricted values.";
            }
          };
        });

        var pattern = (exports.pattern = function pattern(regex, error) {
          return function (value) {
            if (!regex.test(value)) {
              return error || "'{value}' does not match with the pattern.";
            }
          };
        });
      });

      unwrapExports(lib);
      var lib_1 = lib.SkipValidation;
      var lib_2 = lib.validateSingle;
      var lib_3 = lib.validate;
      var lib_4 = lib.required;
      var lib_5 = lib.isNumber;
      var lib_6 = lib.isString;
      var lib_7 = lib.isFunction;
      var lib_8 = lib.isObject;
      var lib_9 = lib.isArray;
      var lib_10 = lib.length;
      var lib_11 = lib.isEmail;
      var lib_12 = lib.equalsTo;
      var lib_13 = lib.minLength;
      var lib_14 = lib.maxLength;
      var lib_15 = lib.isBoolean;
      var lib_16 = lib.within;
      var lib_17 = lib.excludes;
      var lib_18 = lib.pattern;

      var _createClass = (function () {
        function defineProperties(target, props) {
          for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ("value" in descriptor) descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
          }
        }
        return function (Constructor, protoProps, staticProps) {
          if (protoProps) defineProperties(Constructor.prototype, protoProps);
          if (staticProps) defineProperties(Constructor, staticProps);
          return Constructor;
        };
      })();

      function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
          throw new TypeError("Cannot call a class as a function");
        }
      }

      var paymentType = {
        EBANKING: "EBANKING",
        MOBILE_BANKING: "MOBILE_BANKING",
        // CONNECT_IPS: "CONNECT_IPS",
        // SCT: "SCT",
        KHALTI: "KHALTI",
      };

      var CDN_HOST =
        "https://khalti.s3.ap-south-1.amazonaws.com/KPG/dist/2020.12.17.0.0.0";

      var ZHTML_src = CDN_HOST + "/payment_gateway_widget.html";
      var INFINITY_LOADER = CDN_HOST + "/icons/infinity-loader.svg";

      var clone = function clone(data) {
        if (!data) return data;
        return JSON.parse(JSON.stringify(data));
      };

      var eventHandlerSchema = {
        onSuccess: [lib_4(true), lib_7()],
        onError: [lib_4(false), lib_7()],
        onClose: [lib_4(false), lib_7()],
      };

      var configSchema = {
        publicKey: lib_4(true),
        productUrl: lib_4(true),
        productIdentity: lib_4(true),
        productName: lib_4(true),
        eventHandler: lib_4(true),
        amount: lib_4(true),
        merchantData: [lib_4(false), lib_8()],
        paymentPreference: [lib_4(false), lib_9()],
      };

      var KhaltiCheckout = (function () {
        function KhaltiCheckout(config) {
          _classCallCheck(this, KhaltiCheckout);

          this._widgetId = "khalti-widget-" + Date.now();
          this._config = config;
          this._widget = this.attachWidget();
          this.listenToWidget();
          this.paymentType = paymentType;
          this.widgetLoaded = false;
        }

        _createClass(KhaltiCheckout, [
          {
            key: "listenToWidget",
            value: function listenToWidget() {
              var _this = this;

              window.addEventListener(
                "message",
                function (e) {
                  if (!e.data.realm) return;
                  if (e.data.realm === "widgetInit") {
                    _this.widgetInit(e.data.payload);
                  } else if (
                    e.data.realm === "widgetLoad" &&
                    !_this.widgetLoaded
                  ) {
                    if (e.data.payload.loaded) {
                      _this.hideLoader();
                      _this.widgetInit();
                      _this.widgetLoaded = e.data.payload.loaded;
                    }
                  } else if (
                    !e.data.payload ||
                    e.data.payload.widget_id !== _this._widgetId
                  ) {
                    return;
                  } else {
                    var handler = "handle_msg_" + e.data.realm;
                    _this[handler](e.data.payload);
                  }
                },
                false
              );
            },
          },
          {
            key: "msgWidget",
            value: function msgWidget(realm, payload) {
              payload = clone(payload);
              payload.widgetId = this._widgetId;
              payload.source = "checkout_v2.1";
              this._widget.contentWindow.postMessage(
                { realm: realm, payload: payload },
                "*"
              );
            },
          },
          {
            key: "handle_msg_widgetInit",
            value: function handle_msg_widgetInit() {
              this.widgetInit();
            },
          },
          {
            key: "widgetInit",
            value: function widgetInit(data) {
              var paymentInfo = clone(this._config);
              delete paymentInfo.eventHandler;
              this.msgWidget("paymentInfo", paymentInfo);
            },
          },
          {
            key: "diplayLoader",
            value: function diplayLoader() {
              var loader = window.document.getElementById(
                "loader" + this._widgetId
              );
              loader.style.display = "block";
            },
          },
          {
            key: "hideLoader",
            value: function hideLoader() {
              var loader = window.document.getElementById(
                "loader" + this._widgetId
              );
              loader.style.display = "none";
            },
          },
          {
            key: "validateConfig",
            value: function validateConfig() {
              var errors = lib_3(this._config, configSchema);
              if (errors) {
                throw new Error(JSON.stringify(errors));
              } else {
                var _errors = lib_3(
                  this._config.eventHandler,
                  eventHandlerSchema
                );
                if (_errors) {
                  throw new Error(JSON.stringify({ eventHandler: _errors }));
                }
              }
            },
          },
          {
            key: "handle_msg_walletPaymentVerification",
            value: function handle_msg_walletPaymentVerification(paymentInfo) {
              this._config.eventHandler.onSuccess(paymentInfo);
              this.hide();
            },
          },
          {
            key: "handle_msg_widgetError",
            value: function handle_msg_widgetError(error) {
              var errorHandler = this._config.eventHandler.onError;
              errorHandler && errorHandler(error);
            },
          },
          {
            key: "disableParentScrollbar",
            value: function disableParentScrollbar() {
              this.parentOverflowValue = window.document.body.style.overflowY;
              window.document.body.style.overflowY = "hidden";
            },
          },
          {
            key: "enableParentScrollbar",
            value: function enableParentScrollbar() {
              window.document.body.style.overflowY = this.parentOverflowValue;
              this.parentOverflowValue = null;
            },
          },
          {
            key: "show",
            value: function show(updates) {
              Object.assign(this._config, updates);
              this.validateConfig();
              this.disableParentScrollbar();
              this._widget.style.display = "block";
              if (this.widgetLoaded) {
                this.widgetInit();
              } else {
                this.diplayLoader();
              }
            },
          },
          {
            key: "handle_msg_hide",
            value: function handle_msg_hide() {
              this.hide();
              var closeHandler = this._config.eventHandler.onClose;
              closeHandler && closeHandler();
            },
          },
          {
            key: "hide",
            value: function hide() {
              this.enableParentScrollbar();
              this._widget.style.display = "none";
            },
          },
          {
            key: "attachWidget",
            value: function attachWidget() {
              var widget = window.document.createElement("iframe");
              widget.setAttribute("id", this._widgetId);
              widget.style.position = "fixed";
              widget.style.display = "none";
              widget.style.top = "0";
              widget.style.left = "0";
              widget.width = "100%";
              widget.height = window.innerHeight + "px";
              widget.setAttribute("src", ZHTML_src);
              widget.style.zIndex = 99999;
              widget.setAttribute("frameborder", 0);
              widget.setAttribute("allowtransparency", true);

              var loader = window.document.createElement("div");
              loader.setAttribute("id", "loader" + this._widgetId);

              loader.style.width = "100%";
              loader.style.height = "100%";
              loader.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
              loader.style.top = "0px";
              loader.style.left = "0px";
              loader.style.position = "absolute";
              loader.style.display = "none";
              loader.innerHTML =
                '<img style="position:relative;left:50%;top:50%;transform:translate(-50%, -50%);z-index: 99999;" src=' +
                INFINITY_LOADER +
                "></img>";

              if (!window.document.body.contains(loader)) {
                window.document.body.appendChild(loader);
              }

              window.document.body.appendChild(widget);

              return widget;
            },
          },
        ]);

        return KhaltiCheckout;
      })();

      return KhaltiCheckout;
    })();

    var config = {
      // replace the publicKey with yours
      publicKey: "live_public_key_f0c18a0135c14c0ea704b19cd72c06cf",
      productIdentity: orderId,
      productName: "CG Digital",
      productUrl: "http://localhost:3000/user/checkout",
      paymentPreference: [
        "KHALTI",
        "EBANKING",
        "MOBILE_BANKING",
        "CONNECT_IPS",
        "SCT",
      ],
      eventHandler: {
        onSuccess(payload) {
          // hit merchant api for initiating verfication

          console.log(JSON.stringify(payload));

          let url = new URL(Data.API_BASE_URL + "payment/khalti/callback");

          fetch(url, {
            method: "POST",
            headers: Data.API_HEADER,
            body: JSON.stringify(payload),
          })
            .then(api.handleResponseAndError)
            .then((response) => {
              console.log(response);
              // history.push(ORDER_COMPLETE + "?order=completed");
              // history.push(response.url);
              window.location.href = response.url;
            })
            .catch((error) => {
              alert(error);
              setIsActiveOverlay(false);
              console.log(error);
            });
        },
        onError(error) {
          console.log(error);
        },
        onClose() {
          setIsActiveOverlay(false);
          console.log("widget is closing");
        },
      },
    };

    var checkout = new KhaltiCheckout(config);

    // checkout.show({ amount: 1000 });
    checkout.show({ amount: totalAmt * 100 });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setIsActiveOverlay(true);
    setFormError(false);
    let city = document.getElementById("shippingAddrCityInput").options[
      document.getElementById("shippingAddrCityInput").selectedIndex
    ].text;
    console.log("Cityname: " + city);

    // if (paymentMethod === "Cash On Delivery") {
    //   if (
    //     city.toLowerCase().includes("bhaktapur") ||
    //     city.toLowerCase().includes("kathmandu") ||
    //     city.toLowerCase().includes("lalitpur")
    //   ) {
    //     //do nothing
    //   } else {
    //     setFormError("Cash on Delivery is not available in " + city);
    //     var tag = document.getElementById("payment_with_Cash On Delivery");
    //     tag.closest("label").closest("div").style.display = "none";
    //     tag.checked = false;
    //     setIsActiveOverlay(false);
    //     return;
    //   }
    // }

    let url = new URL(Data.API_BASE_URL + "post-prebooking/" + data.product_id);

    if (
      new URLSearchParams(props.location.search).get("coupon") ||
      new URLSearchParams(props.location.search).get("redeem")
    ) {
      let couponCode = new URLSearchParams(props.location.search).get("coupon");
      let redeem = new URLSearchParams(props.location.search).get("redeem");
      url.search = new URLSearchParams({
        coupon: couponCode ? couponCode : null,
        redeem: redeem ? redeem : null,
        platform: "W",
      }).toString();
    } else {
      url.search = new URLSearchParams({
        platform: "W",
      }).toString();
    }

    fetch(url, {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        shipping_data: {
          name: name,
          email: email,
          address: address,
          mobile: mobile,
        },
        bank_id: bankId,
        payment_method: paymentMethod,
        area_id: areaId,
        city_id: cityId,
        province_id: provinceId,
        customer_note: customer_note,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        if (response.statusCode == 200) {
          if (
            paymentMethod == "Bank Transfer" ||
            paymentMethod == "Cash On Delivery" ||
            paymentMethod == "Loyalty Full Redeem"
          ) {
            setIsActiveOverlay(false);
            history.push(BOOKING_COMPLETE + "?booking=completed");
            props.value.setMessage(response.message);
          } else if (paymentMethod == "eSewa") {
            setEsewaData(response.payment_data);
            setIsActiveOverlay(false);
            document.getElementById("eSewaForm").submit();
          } else if (paymentMethod == "Khalti") {
            khaltiPayment(response.order_id, response.total_amount);
          } else {
            setIsActiveOverlay(false);
         
            window.location = response.payment_gateway_url;
          }
        }
      })
      .catch((error) => {
        setIsActiveOverlay(false);
        console.log(error);
      });
    evt.preventDefault();
  };
  const selectPaymentMethod = (evt) => {
    setPaymentMethod(evt.target.value);
    if (evt.target.value == "Bank Transfer") {
      setSelectBankTransfer(true);
    } else {
      setSelectBankTransfer(false);
    }
  };
  useEffect(() => {



    if (!storage.isLoggedIn()) {
      // history.replace("/user/login/");
      history.push({
        pathname: "/user/login",
        search:
          "?prebook=" + getProductItemId(),
      });
    } else {
        fetchData();
      getProvince();
      getBanks();
    }

    return () => {
      isChanging.current = true;
    };
  }, []);
  return (
    <React.Fragment>
      <Breadcrumb
        levelOne={{
          name: "Checkout",
          link: "/user/checkout/",
        }}
      />
      <MetaTags>
        <title>Checkout</title>
      </MetaTags>
    
      
        <LoadingOverlay active={isActiveOverlay} spinner text="Loading">
          <section className="pdp-section shown">
            <p id="form-error"></p>
            <div className="row">
              <div className="col-md-7 col-xs-12 background-theme-white">
                <form
                  onSubmit={handleSubmit}
                  acceptCharset="UTF-8"
                  id="checkout-form"
                  className="ng-pristine ng-valid"
                >
                  <input
                    name="_token"
                    type="hidden"
                    value="grVSBtn6dMd709n4Ac6bpLBIt2isB87cBatHmEa2"
                    autoComplete="off"
                  />
                  {/* <LoadingOverlay active={isActiveOverlay} spinner text="Loading"> */}
                  <div
                    className="page-content checkout-page"
                    style={{ padding: "0px" }}
                  >
                    <div className="box-border">
                      <div className="row">
                        <div className="col-lg-12" id="shipping_addr_block">
                          <div className="sidebar-bar-title">
                            {/* <p id="form-error"></p> */}
                            <h3>Booking Address</h3>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <label>
                                Name <span className="required">*</span>
                              </label>
                              <input
                                className="form-control input"
                                name="name"
                                type="text"
                                {...bindName}
                                required
                              />
                              <span className="error-message"></span>
                            </div>
                            <div className="col-lg-6">
                              <label>
                                Mobile <span className="required">*</span>
                              </label>
                              <input
                                className="form-control input"
                                name="mobile"
                                type="text"
                                {...bindMobile}
                                required
                              />
                              <span className="error-message"></span>
                            </div>
                          </div>

                    

                          <div className="row">
                            <div className="col-lg-4">
                              <label>
                                Province <span className="required">*</span>
                              </label>
                              <select
                                id="shippingAddrProvinceInput"
                                className="select2 form-control input select2-hidden-accessible"
                                name="province"
                                tabIndex="-1"
                                aria-hidden="true"
                                required
                                {...bindProvinceId}
                                onChange={(e) => {
                                  getCities(e);
                                }}
                              >
                                <option value="" defaultValue>
                                  Choose Province
                                </option>
                                {provinces.map(function (province, index) {
                                  return (
                                    <option value={province.id} key={index}>
                                      {province.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            <div className="col-lg-4">
                              <label>
                                City <span className="required">*</span>
                              </label>
                              <select
                                id="shippingAddrCityInput"
                                className="select2 form-control input select2-hidden-accessible"
                                name="city"
                                tabIndex="-1"
                                aria-hidden="true"
                                required
                                {...bindCityId}
                                onChange={(e) => {
                                  getAreas(e);
                                }}
                              >
                                <option value="" defaultValue>
                                  Choose City
                                </option>
                                {cities.map(function (city, index) {
                                  return (
                                    <option value={city.id} key={index}>
                                      {city.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            <div className="col-lg-4">
                              <label>
                                Area <span className="required">*</span>
                              </label>
                              <select
                                id="shippingAddrAreaInput"
                                className="select2 form-control input select2-hidden-accessible"
                                name="area"
                                tabIndex="-1"
                                aria-hidden="true"
                                required
                                {...bindAreaId}
                              >
                                <option value="" defaultValue>
                                  Choose Area
                                </option>
                                {areas.map(function (area, index) {
                                  return (
                                    <option value={area.id} key={index}>
                                      {area.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <label>
                                Email <span className="required">*</span>
                              </label>
                              <input
                                className="form-control input"
                                name="name"
                                type="text"
                                {...bindEmail}
                                required
                              />
                              <span className="error-message"></span>
                            </div>
                            <div className="col-lg-6">
                              <label>
                                Address <span className="required">*</span>
                              </label>
                              <input
                                className="form-control input"
                                name="mobile"
                                type="text"
                                {...bindAddress}
                                required
                              />
                              <span className="error-message"></span>
                            </div>
                          </div>

                         
                          <div>
                            <label>Customer Note</label>
                            <input
                              style={{ height: "75px", width: "100%" }}
                              name="customer_note"
                              type="text"
                              {...bindCustomerNote}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="page-content checkout-page">
                        <div className="box-border">
                          <h4>
                            Choose Payment Method{" "}
                            <span className="required">*</span>
                          </h4>
                          <div className="row">
                            <div className="row">
                              <div className="col-sm-12">
                                {data.payment_options
                                  ? data.payment_options.map(function (
                                      payment,
                                      index
                                    ) {
                                      return (
                                        <React.Fragment key={index}>
                                          <div className="col-sm-6">
                                            <label>
                                              <input
                                                className="payment_checkbox"
                                                type="radio"
                                                value={payment}
                                                id={"payment_with_" + payment}
                                                name="payment_method"
                                                required
                                                onClick={selectPaymentMethod}
                                              />{" "}
                                              {payment == "Nabil Card"
                                                ? "Master/Visa Debit/Credit Card/UnionPay"
                                                : payment}
                                            </label>
                                          </div>
                                          {/* {(index + 1) / 2 == 0 ? <br /> : ""} */}
                                        </React.Fragment>
                                      );
                                    })
                                  : ""}
                              </div>
                              <span className="error-message"></span>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="box-border" id="load-bank-choice">
                          {selectBankTransfer ? (
                            <div>
                              <label>
                                Choose Bank
                                <span className="required">*</span>
                              </label>
                              <select
                                id="shippingAddrDistrictInput"
                                className="select2 form-control input select2-hidden-accessible"
                                name="district"
                                tabIndex="-1"
                                aria-hidden="true"
                                required
                                {...bindBankId}
                              >
                                <option value="" defaultValue>
                                  Choose Bank
                                </option>
                                {banks.map(function (bank, index) {
                                  return (
                                    <option value={bank.id} key={index}>
                                      {bank.bank_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {formError ? (
                        <>
                          <p style={{ color: "red", paddingLeft: "10px" }}>
                            {formError}
                            {viewErrorMessage()}
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      <button type="submit" className="button">
                        <i className="fa fa-angle-double-right"></i>&nbsp;{" "}
                        <span>Complete Checkout</span>
                      </button>
                    </div>
                  </div>
                  {/* </LoadingOverlay> */}
                </form>
              </div>

              <div className="col-md-5 col-xs-12 background-theme-white">
                <div
                  className="theiaStickySidebar"
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "1px",
                    position: "static",
                    transform: "none",
                    top: "0px",
                    left: "689.5px",
                  }}
                >
                  <div className="sidebar-checkout block">
                    <div className="sidebar-bar-title">
                      <h3>Order Summary</h3>
                    </div>
                    <div className="block-content">
                   
                        
                        <div>
                        
                        <img
                                  src={data.product_img}
                                  alt={data.product_name}
                                  width="100%"
                                />

                        </div> 

                         <Link
                                      to={
                                        "/product-" +
                                        data.product_name +
                                        "-" +
                                        data.product_id
                                      }
                                      title={data.product_name}
                                       className="add_to_cart_button ajax_add_to_cart button btn btn-secondary alt"
                                    >
                                        <h2 style={{'margin' : '0px'}} itemprop="name" class="product_title entry-title">{data.product_name}</h2>
                                    </Link>
                       

                    
                         
<br></br><br></br>
                                    

                      <div className="top-subtotal">
                        Price:{" "}
                        <span>
                          {" "}
                          <NumberFormat
                            displayType={"text"}
                            thousandSeparator={true}
                            thousandsGroupStyle="lakh"
                            prefix={"NPR "}
                            value={data.grand_total}
                          />
                        </span>
                        <br />
                      </div>
                    
                   
                      {data.cash_back != null && data.cash_back != 0 ? (
                        <div className="top-subtotal">
                          Cash Back:{" "}
                          <span>
                            {"- "}
                            <NumberFormat
                              displayType={"text"}
                              thousandSeparator={true}
                              thousandsGroupStyle="lakh"
                              prefix={"NPR "}
                              value={data.cash_back}
                            />
                          </span>
                          <br />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="top-subtotal">
                        Shipping Charge:
                        <span>
                          {" "}
                          {data.delivery_charge != null ? (
                            <NumberFormat
                              displayType={"text"}
                              thousandSeparator={true}
                              thousandsGroupStyle="lakh"
                              prefix={"NPR "}
                              value={data.delivery_charge}
                            />
                          ) : (
                            "Free"
                          )}
                        </span>
                        <br />
                      </div>
                      <div className="top-subtotal" style={{'background' : '#0171b6', 'color': 'white'}}>
                        Booking Price:{" "}
                        <span className="price" style={{'color': 'white'}}>
                          {" "}
                          <NumberFormat
                            displayType={"text"}
                            thousandSeparator={true}
                            thousandsGroupStyle="lakh"
                            prefix={"NPR "}
                            value={data.prepayment_value}
                          />
                        </span>
                        <br />
                      </div>
                    </div>
                  </div>
                  <div
                    className="resize-sensor"
                    style={{
                      position: "absolute",
                      left: "0px",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      overflow: "hidden",
                      zIndex: "-1",
                      visibility: "hidden",
                    }}
                  >
                    <div
                      className="resize-sensor-expand"
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        right: "0px",
                        bottom: "0px",
                        overflow: "hidden",
                        zIndex: "-1",
                        visibility: "hidden",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          transition: "all 0s ease 0s",
                          width: "595px",
                          height: "376px",
                        }}
                      ></div>
                    </div>
                    <div
                      className="resize-sensor-shrink"
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        right: "0px",
                        bottom: "0px",
                        overflow: "hidden",
                        zIndex: "-1",
                        visibility: "hidden",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          transition: "0s",
                          width: "200%",
                          height: "200%",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="load-payment-form">
                {esewaData ? (
                  <form
                    className="payment-form"
                    action={esewaData.url}
                    method="post"
                    id="eSewaForm"
                  >
                    <input
                      type="hidden"
                      name="tAmt"
                      value={esewaData.data.tAmt}
                    />
                    <input
                      type="hidden"
                      name="amt"
                      value={esewaData.data.amt}
                    />
                    <input
                      type="hidden"
                      name="txAmt"
                      value={esewaData.data.txAmt}
                    />
                    <input
                      type="hidden"
                      name="psc"
                      value={esewaData.data.psc}
                    />
                    <input
                      type="hidden"
                      name="pdc"
                      value={esewaData.data.pdc}
                    />
                    <input
                      type="hidden"
                      name="scd"
                      value={esewaData.data.scd}
                    />
                    <input
                      type="hidden"
                      name="pid"
                      value={esewaData.data.pid}
                    />
                    <input type="hidden" name="su" value={esewaData.data.su} />
                    <input type="hidden" name="fu" value={esewaData.data.fu} />
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </LoadingOverlay>
     

    </React.Fragment>
  );
};

export default WithContext(Prebook);
