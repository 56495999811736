import React from "react";
import Skeleton from "react-loading-skeleton";
const CategorySkeleton = () => {
    return (
        <React.Fragment>
            <Skeleton height={30} />
            <div className="row" style={{ padding: "20px 0px" }}>
                <div className="col-lg-3">
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                    <Skeleton height={50} />
                    <Skeleton height={30} />
                    <Skeleton height={30} />
                    <Skeleton height={30} />
                    <Skeleton height={30} />
                    <Skeleton height={30} />
                    <Skeleton height={30} />
                    <Skeleton height={30} />
                </div>
                <div className="col-lg-9">
                    <Skeleton height={350} />
                    <Skeleton height={20} />
                    <Skeleton height={30} />
                    <Skeleton height={60} />
                </div>
            </div>
            <div className="row" style={{ padding: "20px 0px" }}>
                <div className="col-lg-3">
                    <Skeleton height={300} />
                </div>
                <div className="col-lg-3">
                    <Skeleton height={250} />
                </div>
                <div className="col-lg-3">
                    <Skeleton height={250} />
                </div>
                <div className="col-lg-3">
                    <Skeleton height={250} />
                </div>
            </div>
        </React.Fragment>
    )
};

export default CategorySkeleton;