import firebase from "firebase";
export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyCyTj2mnX761kSe6LTJlS9bR73VnBYySsk",
    authDomain: "cg-digital-195201.firebaseapp.com",
    databaseURL: "https://cg-digital-195201.firebaseio.com",
    projectId: "cg-digital-195201",
    storageBucket: "cg-digital-195201.appspot.com",
    messagingSenderId: "793226974140",
    appId: "1:793226974140:web:420bb3e3c69012d66611b9"
  });
  // askForPermissioToReceiveNotifications();
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then(registration => {
        // console.log(registration);
        firebase.messaging().useServiceWorker(registration);
      });
  } else {
    askForPermissioToReceiveNotifications();
  }
};
export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    console.log("token is ", token);

    return token;
  } catch (error) {
    console.error(error);
  }
};
