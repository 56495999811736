import React from "react";

import Slider from "react-slick";
import ClickBanner from "../../../Home/Banner/ClickBanner";
import { LazyLoadImage } from "react-lazy-load-image-component";
const AddSlider = (props) => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    lazyLoad: true,
    swipeToSlide: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 5000,
  };
  return (
    <div className="filter-type checkbox-factet ">
      <div className="filter-section">
        <div className="filter-collapse">
          <div className="collapse-body collapse-list slider-category">
            <Slider {...settings}>
              {props.sliders.map(function (slider, index) {
                return (
                  <div className="item" key={index}>
                    <ClickBanner banner={slider}>
                      <LazyLoadImage src={slider.image} />
                    </ClickBanner>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddSlider;
