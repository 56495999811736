import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import Data from "../utils/data";
import Api from "../utils/api";
import storage from "../utils/storage";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlusMinus from "./Wishlist/PlusMinus";
import { HOME, CART } from "../route/routeURL";
import LoadingOverlay from "react-loading-overlay";
import { MetaTags } from "react-meta-tags";
import { useHistory } from "react-router-dom";

const WishList = (props) => {
  let history = useHistory();
  const [lists, setLists] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [isActiveOverlay, setIsActiveOverlay] = useState(false);
  function fetchWishList() {
    let url = new URL(Data.API_BASE_URL + "get-wishlist");
    url.search = new URLSearchParams({
      token: storage.getAccessToken(),
    }).toString();
    fetch(url, {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        if (response.data != null) {
          setLists(response.data.wishlists);
        } else {
          setLists([]);
        }
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function handleDeleteFormList(product) {
    setIsActiveOverlay(true);
    let url = new URL(Data.API_BASE_URL + "delete-wishlist");
    url.search = new URLSearchParams({
      token: storage.getAccessToken(),
    }).toString();
    fetch(url, {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        product_id: product.product_id,
      }),
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        fetchWishList();
        setIsActiveOverlay(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    if (!storage.isLoggedIn()) {
      history.replace("/");
    }
    setIsloading(true);
    fetchWishList();
  }, []);
  return (
    <React.Fragment>
      <MetaTags>
        <title>Wishlist</title>
      </MetaTags>
      {isLoading ? (
        <div
          className="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      ) : (
        <div className="col-main">
          <div className="cart">
            {lists.length == 0 ? (
              <h3
                className="text-center"
                style={{ height: "200px", marginTop: "10%" }}
              >
                Your Wishlist is empty
                <br />
                <small>
                  <Link to={HOME}>Shop Now</Link>
                </small>
              </h3>
            ) : (
              <div className="page-content page-order">
                <div className="page-title">
                  <h2>Your Wishlists</h2>
                </div>

                <div className="order-detail-content">
                  <LoadingOverlay
                    active={isActiveOverlay}
                    spinner
                    text="Loading"
                  >
                    <div className="table-responsive">
                      <table className="table table-bordered cart_summary">
                        <thead>
                          <tr>
                            <th className="cart_product">Product</th>
                            <th>Description</th>
                            <th>Unit price</th>
                            <th className="action text-center">
                              <FontAwesomeIcon icon="trash" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map(function (list, index) {
                            return (
                              <tr key={index}>
                                <td className="cart_product">
                                  <Link
                                    to={
                                      "/product-" +
                                      list.product_name
                                        .replace(/[#+. _]/gi, "-")
                                        .toLowerCase() +
                                      "-" +
                                      list.product_id
                                    }
                                  >
                                    <img
                                      src={list.product_img}
                                      alt={list.product_name}
                                      style={{
                                        height: "100px",
                                        verticalAlign: "middle",
                                      }}
                                      className="center-img "
                                    />
                                  </Link>
                                </td>
                                <td className="cart_description">
                                  <p className="product-name">
                                    <Link
                                      to={
                                        "/product-" +
                                        list.product_name
                                          .replace(/[#+. _]/gi, "-")
                                          .toLowerCase() +
                                        "-" +
                                        list.product.id
                                      }
                                    >
                                      {list.product_name}
                                    </Link>
                                    <br />
                                  </p>
                                  {list.is_service == 0 ? (
                                    <PlusMinus product={list} />
                                  ) : (
                                    ""
                                  )}

                                  {list.available_quantity > 0 ? (
                                    <p className="availability in-stock pull-right">
                                      Availability: <span>In Stock</span>
                                    </p>
                                  ) : (
                                    <p className="out-of-stock in-stock pull-right">
                                      Availability: <span>Out of Stock</span>
                                    </p>
                                  )}
                                </td>
                                <td className="price">
                                  <p className="special-price">
                                    {" "}
                                    <span className="price-label">
                                      Special Price
                                    </span>{" "}
                                    <span className="price">
                                      {" "}
                                      <NumberFormat
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        thousandsGroupStyle="lakh"
                                        prefix={"NPR "}
                                        value={
                                          list.product_discount_price > 0
                                            ? list.product_discount_price
                                            : list.product_price
                                        }
                                      />
                                    </span>{" "}
                                  </p>
                                  {list.product_discount_price > 0 ? (
                                    <p className="old-price">
                                      {" "}
                                      <span className="price-label">
                                        Regular Price:
                                      </span>{" "}
                                      <span className="price">
                                        {" "}
                                        <NumberFormat
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          thousandsGroupStyle="lakh"
                                          prefix={"NPR "}
                                          value={list.product_price}
                                        />{" "}
                                      </span>{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td className="action text-center">
                                  <a onClick={() => handleDeleteFormList(list)}>
                                    <FontAwesomeIcon icon="times" />
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="cart_navigation">
                      <Link className="continue-btn" to={HOME}>
                        <FontAwesomeIcon icon="angle-left" />
                        &nbsp; Continue shopping
                      </Link>{" "}
                      <Link className="checkout-btn" to={CART}>
                        <FontAwesomeIcon icon="check" /> Proceed to cart
                      </Link>{" "}
                    </div>
                  </LoadingOverlay>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default WishList;
