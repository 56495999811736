import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import ClickBanner from "../Banner/ClickBanner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import NextSlider from "../../Layouts/Silder/NextSlider";
import PrevSlider from "../../Layouts/Silder/PrevSlider";
const OneBannerLayout = (props) => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3000,
    // arrows: false,
    nextArrow: <NextSlider />,
    prevArrow: <PrevSlider />,
  };
  return (
    <React.Fragment>
      <div className="home-div-layout">
        <div
          className="slider-container slick-initialized slick-slider"
          style={{ height: "100%" }}
        >
          {props.banners[0].length == 1 ? (
            <ClickBanner banner={props.banners[0][0]}>
              <LazyLoadImage
                style={{
                  width: "100%",
                  objectFit: "cover",
                }}
                src={props.banners[0][0].image}
              />
              {console.log(props.banners)}
            </ClickBanner>
          ) : (
            <Slider {...settings}>
              {props.banners[0].map(function (banner, index) {
                return (
                  <div key={index}>
                    {/* {console.log(banner)} */}

                    <ClickBanner banner={banner}>
                      <LazyLoadImage
                        style={{
                          width: "100%",
                          objectFit: "cover",
                        }}
                        alt={banner.name}
                        src={banner.image}
                      />
                    </ClickBanner>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OneBannerLayout;
