import React, { useEffect, useState } from "react";
import "./priceSummary.css";
import BackButton from "../../components/backButton/backButton";
import LeftPanel from "../../components/left-panel/left-panel";
import PriceDetails from "../../components/priceDetails/priceDetails";
import storage from "../../components/utils/storage";
import Data from "../../components/utils/data";
import api from "../../components/utils/api";
import { useHistory } from "react-router-dom";


const PriceSummary = (props) => {  
  let history = useHistory();

  const [isMweb, setIsMweb] = useState(false);

  const [isLoading, setIsloading] = useState(true);

  function checkout(){
    let url = new URL(Data.API_BASE_URL + "post-prebooking/" + props.location.state.product);
   
    url.search = new URLSearchParams({
      platform: "W",
    }).toString();

    fetch(url, {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        shipping_data: {
          name: props.location.state.fullName,
          email: props.location.state.email,
          address: props.location.state.address,
          mobile: props.location.state.phone,
        },
        payment_method: props.location.state.payment_method  === 'Card Payment' ? 'Nabil Card' : props.location.state.payment_method,
        source: 'ola',
        area: props.location.state.area,
        city: props.location.state.city,
        customer_note: props.location.state.note
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        console.log(response);
        if (response.statusCode == 200) {
          if(props.location.state.payment_method == 'Card Payment'){
            localStorage.setItem('nabilCartPaymentUrl', JSON.stringify(response.payment_gateway_url));
            setIsloading(false);
          }
          if(props.location.state.payment_method == 'Connect IPS'){
            localStorage.setItem('connectIpsPaymentData', JSON.stringify(response.payment_data));
            setIsloading(false);
          }
          if(props.location.state.payment_method == 'eSewa'){
            localStorage.setItem('esewaPaymentData', JSON.stringify(response.payment_data));
            setIsloading(false);
          }
      }
      })
      .catch((error) => {
        console.log(error);
        // alert(error);
      });
  }


  useEffect(() => {
    if (!storage.isLoggedIn()) {
      history.goBack();
    }
    localStorage.setItem('nabilCartPaymentUrl', null);
    localStorage.setItem('connectIpsPaymentData', null);
    localStorage.setItem('esewaPaymentData', null);
    checkout();
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth <= 439) {
      setIsMweb(true);
    } else {
      setIsMweb(false);
    }
  };
  return (
    <>
    {isLoading == false ? (
    <div className="main-container">
      {isMweb ? (
        <div className="common-rightside">
          <BackButton />
          <PriceDetails data={props.location.state}  
              nabilCartPaymentUrl={JSON.parse(localStorage.getItem("nabilCartPaymentUrl"))} 
              connectIpsPaymentData={JSON.parse(localStorage.getItem("connectIpsPaymentData"))}  
              esewaPaymentData={JSON.parse(localStorage.getItem("esewaPaymentData"))}  
              />
        </div>
      ) : (
        <>
          <LeftPanel
            selectedModalData={JSON.parse(
              localStorage.getItem("selectedModalData")
            )}
            currentColor={JSON.parse(
              localStorage.getItem("currentColorObject")
            )}
          />
          <div className="common-rightside">
            <BackButton />
            <PriceDetails data={props.location.state}  
              nabilCartPaymentUrl={JSON.parse(localStorage.getItem("nabilCartPaymentUrl"))} 
              connectIpsPaymentData={JSON.parse(localStorage.getItem("connectIpsPaymentData"))}  
              esewaPaymentData={JSON.parse(localStorage.getItem("esewaPaymentData"))}  
              />
          </div>
        </>
      )}
    </div>
        ) : ''}
        </>
  );
}


export default PriceSummary;