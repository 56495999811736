import React, { useEffect, useState } from "react";

import { useInput } from "../../../hooks/inputHook";

import Data from "../../../utils/data";
import api from "../../../utils/api";
import LoadingOverlay from "react-loading-overlay";
const Enquiry = (props) => {
  const { value: name, bind: bindName } = useInput("");
  const { value: email, bind: bindEmail } = useInput("");
  const { value: phone, bind: bindPhone } = useInput("");
  const { value: organization, bind: bindOrganization } = useInput("");
  const { value: message, bind: bindMessage } = useInput("");
  const [isActiveOverlay, setIsActiveOverlay] = useState(false);

  const handleSubmit = (evt) => {
    // console.log("test");
    evt.preventDefault();
    setIsActiveOverlay(true);
    fetch(Data.API_BASE_URL + "business-enquiry", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        organization: organization,
        product_id: props.product.id,
        enquiry: message,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        console.log(response);
        if (response.statusCode == 200) {
          setIsActiveOverlay(false);
          var close = document.getElementById("close-modal-enquiry");
          close.click();
          window.scrollTo(0, 0);
          props.setMessage(response.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <LoadingOverlay active={isActiveOverlay} spinner text="Loading">
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                <div className="form-area">
                  <div className="col-lg-2">
                    <img
                      src={props.product.featured_image}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        maxHeight: "100px",
                      }}
                      alt={props.product.name}
                    />
                  </div>
                  <div className="col-lg-10">
                    <h4 id="product-name">
                      {props.product.name}, {props.product.brand}
                    </h4>
                    <h5 style={{ marginBottom: "15px", padding: "0" }}>
                      {props.product.model_number}
                    </h5>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        {...bindName}
                        placeholder="Name&nbsp;*"
                        required
                      />
                      <span className="error-message"></span>
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        {...bindEmail}
                        placeholder="Email&nbsp;*"
                        required
                      />
                      <span className="error-message"></span>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        {...bindPhone}
                        placeholder="Phone&nbsp;*"
                        required
                      />
                      <span className="error-message"></span>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="organization"
                        name="organization"
                        {...bindOrganization}
                        placeholder="Organization"
                      />
                      <span className="error-message"></span>
                    </div>
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="form-control"
                        type="textarea"
                        id="message"
                        {...bindMessage}
                        placeholder="Enquiry Message&nbsp;*"
                        maxLength="140"
                        rows="7"
                        required
                      ></textarea>
                      <span className="error-message"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              name="submit"
              className="btn btn-cgdigital pull-right"
            >
              Submit
            </button>
            <button
              type="button"
              id="close-modal-enquiry"
              className="btn btn-danger pull-left"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </form>
      </LoadingOverlay>
    </React.Fragment>
  );
};

export default Enquiry;
