import React, { useState, useEffect } from "react";
import FloatingInput from "../FloatingInput/floatingInput";
import "./newAccount.css";
import { Country_Code } from "../Account/country";
import { useHistory } from "react-router-dom";
import Data from "../utils/data";
import Api from "../utils/api";
import storage from "../utils/storage";


const NewAccount = () => {
  const history = useHistory();
  const [product, setProduct] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [country, setCountry] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  
  const [errorMessageFullname, setErrorMessageFullname] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePhone, setErrorMessagePhone] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageConfirmPassword, setErrorMessageConfirmPassword] = useState("");


  const handleValidation = (evnt) => {
    const inputValue = evnt.target.value.trim();
    const inputField = evnt.target.getAttribute('label');

    if(inputField === 'Full Name'){
      const pattern = /[^a-zA-Z\s.]/g;
      if(inputValue.length === 0){
        setErrorMessageFullname("Please enter full name.");
      }
      else if(pattern.test(inputValue)){
        setErrorMessageFullname("Name should not contain special characters.");
      }
      else{
        setErrorMessageFullname("");
      }
    }

    if(inputField==='Email'){
      const emailRegExp   = /\S+@\S+\.\S+/;
      if(inputValue.length === 0){
        setErrorMessageEmail("Please enter email.");
      }else if(!emailRegExp.test(inputValue)){
        setErrorMessageEmail("Please enter valid email address.");
      }else{
        setErrorMessageEmail("");
      }
    }

    if(inputField==='Mobile Number'){
      if(inputValue.length === 0){
        setErrorMessagePhone("Please enter mobile number.");
      }else if(inputValue.length != 10){
        setErrorMessagePhone("Please enter valid mobile number.");
      }else{
        setErrorMessagePhone("");
      }
    }


    if(inputField==='Password'){
      const minLengthRegExp   = /.{6,}/;
      if(inputValue.length === 0){
        setErrorMessagePassword("Please enter password.");
      }else if(!minLengthRegExp.test(inputValue)){
        setErrorMessagePassword("Password should be minumum of 6 characters.");
      }else{
        setErrorMessagePassword("");
      }
    }

    if(inputField==='Confirm Password'){
      if(inputValue.length != 0){
        setErrorMessageConfirmPassword("");
      }
    }

  }


  const handleSubmit = (evnt) => {
    evnt.preventDefault();

    setErrorMessageFullname('');
    setErrorMessageEmail('');
    setErrorMessagePhone('');
    setErrorMessagePassword('');
    setErrorMessageConfirmPassword('');
    setSuccessMessage('');

    let formError = false;

    const pattern = /[^a-zA-Z\s.]/g;
    if(fullName == ''){
      setErrorMessageFullname('Please enter fullname');
      formError = true;
    }else if(pattern.test(fullName)){
      setErrorMessageFullname("Name should not contain special characters.");
      formError = true;
    }
    
    const emailRegExp   = /\S+@\S+\.\S+/;
    if(email == ''){
      setErrorMessageEmail('Please enter email');
      formError = true;
    }else if(!emailRegExp.test(email)){
      setErrorMessageEmail("Please enter valid email address.");
      formError = true;
    }
    
    if(phone == ''){
      setErrorMessagePhone('Please enter mobile number');
      formError = true;
    }else if(phone.length != 10){
      setErrorMessagePhone("Please enter valid mobile number.");
      formError = true;
    }
    
    if(password == ''){
      setErrorMessagePassword('Please enter password');
      formError = true;
    }else if(password.length < 6){
      setErrorMessagePassword('Password should be minumum of 6 characters.');
      formError = true;
    }
    
    if(confirmPassword == ''){
      setErrorMessageConfirmPassword('Please re enter password');
      formError = true;
    } 
    if(password != confirmPassword){
      setErrorMessageConfirmPassword("Your Password doesnot match!");
      formError = true;
    }


    if(formError == false){
      // alert(Data.API_BASE_URL + "register");
      fetch(Data.API_BASE_URL + "register", {
        method: "POST",
        headers: Data.API_HEADER,
        body: JSON.stringify({
          fullName: fullName,
          emailAddress: email,
          mobileNumber: phone,
          password: password,
          source: 'ola',
          product: product,
          platform: 'ola'
        }),
      })
        .then(Api.handleResponseAndError)
        .then((response) => {
          if (response.statusCode == 200) {
            setSuccessMessage(response.message);
            // history.replace("/use/login?source="+response.data.source+"&product="+response.data.product);
          } else {
         
            setErrorMessage(response.message);
            console.log(errorMessage);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }

  };

  function getProductItemId() {
    var url = new URL(window.location.href);
    return url.searchParams.get("product");
  }

  useEffect(() => {
    setProduct(getProductItemId());
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
    >
    <div className="newaccount-wrapper">
      <div className="newaccount-title">Create new account</div>
      <div className="inputBox-container">
        
        <FloatingInput
          type="text"
          label="Full Name"
          value={fullName}
          setValue={setFullName}
          handleValidation={handleValidation} 
        />
         {errorMessageFullname ? (<><p style={{'color':'red'}}>{errorMessageFullname}</p></>) : ""}  
        <FloatingInput
          type="text"
          label="Email"
          value={email}
          setValue={setEmail}
          handleValidation={handleValidation} 
        />
         {errorMessageEmail ? (<><p style={{'color':'red'}}>{errorMessageEmail}</p></>) : ""}  

        <FloatingInput
          type="number"
          label="Mobile Number"
          value={phone}
          setValue={setPhone}
          maxlength="10"
          handleValidation={handleValidation} 
        />
         {errorMessagePhone ? (<><p style={{'color':'red'}}>{errorMessagePhone}</p></>) : ""}  
        <FloatingInput
          type="password"
          label="Password"
          value={password}
          setValue={setPassword}
          handleValidation={handleValidation} 
        />
         {errorMessagePassword ? (<><p style={{'color':'red'}}>{errorMessagePassword}</p></>) : ""}  
        <FloatingInput
          type="password"
          label="Confirm Password"
          value={confirmPassword}
          setValue={setConfirmPassword}
          handleValidation={handleValidation} 
        />
         {errorMessageConfirmPassword ? (<><p style={{'color':'red'}}>{errorMessageConfirmPassword}</p></>) : ""}  
         {successMessage ? (<><p style={{'color':'green'}}>{successMessage}</p></>) : ""}
      </div>
     
      <div
        className="signup-button"
        // onClick={() => {
        //   // document.cookie = "oem_auth_token=1";
        //   handleSubmit()
        //   // navigate("/shippingAddress");
        // }}
      >
        <button type="submit">Sign up</button>
      </div>
    </div>
    </form>
  );
};
export default NewAccount;
