import React, { useEffect, useState } from "react";
import GenericModal from "../genericModal/genericModal";
import "./floatingInput.css";

const FloatingInput = ({
  label = "First Name",
  value,
  setValue,
  type,
  maxlength = "",
  dropDownData = [],
  title = "",
  selectInput,
  handleValidation
}) => {
  const [showDropdown, setShowDropDown] = useState(false);
  // const [showDropdownProvince, setShowDropDownProvince] = useState(false);
  // const [showDropdownCity, setShowDropDownCity] = useState(false);
  // const [showDropdownArea, setShowDropDownArea] = useState(false);

  function handleChange(e) {
    // if (type === "number" && value.length === maxlength - 0) {
    //   if (e.target.value !== "") return;
    // }
    // console.log(value.length);
    setValue(e.target.value);
    handleValidation(e);
  }

  const setDropDown = (title) => {

    setShowDropDown(!showDropdown);

    // if(title === 'Province'){
    //   setShowDropDownCity(false);
    //   setShowDropDownArea(false);
    //   setShowDropDownProvince(!showDropdownProvince)
    // }
    // if(title === 'City'){
    //   setShowDropDownProvince(false);
    //   setShowDropDownArea(false);
    //   setShowDropDownCity(!showDropdownCity);
    // }
    // if(title === 'Area'){
    //   setShowDropDownCity(false);
    //   setShowDropDownProvince(false);
    //   setShowDropDownArea(!showDropdownArea);
    // }

  };

  useEffect(() => {
   
    // if (type === "dropDown" && value === "") {
    //   setValue(dropDownData[0]);
    // }
  });

  return (
    <>
      {type !== "dropDown" ? (
        type !== "textarea" ? (
          <div className="input-container">
            <input
              label={label}
              type={type}
              value={value}
              onChange={handleChange}
              maxLength={maxlength}
              placeholder=" "
            />
            <label className={value && "filled"}>{label}</label>
          </div>
        ) : (
          <div className="textarea-container">
            <label className="textarea-label">{label}</label>
            <textarea title={title} value={value} onChange={handleChange}></textarea>
          </div>
        )
      ) : (
        <div className="dropdown-main">
          <div
            className="drop-downWraper"
            onClick={() => {
              setDropDown(title)
              
            }}
          >
            <div className="country-right">
              <div className="country-name">{title}</div>
              <div className="country-title">{value}</div>
            </div>
            <div className="down-arrow">
              <img
                src="https://d34kmefuuy0be0.cloudfront.net/ev_assets/down_arrow_35ff95ad1b.svg"
                alt="arrow"
              />
            </div>
          </div>

          {showDropdown && (
            <>
              <div className="dropdown-container-desk">
                {dropDownData.map((e, i) => (
                  <div
                    key={i}
                    className="each-country"
                    onClick={() => {
                      selectInput(title, e);
                      console.log(e);
                      setValue(e);
                      setShowDropDown(!showDropdown);
                    }}
                  >
                    {e}
                  </div>
                ))}
              </div>
            </>
          )}

          {/* {showDropdownProvince && (
            <>
              <div className="dropdown-container-desk">
                {dropDownData.map((e, i) => (
                  <div
                    key={i}
                    className="each-country"
                    onClick={() => {
                      selectInput(title, e);
                      console.log(e);
                      setValue(e);
                      setShowDropDownProvince(!showDropdownProvince);
                    }}
                  >
                    {e}
                  </div>
                ))}
              </div>
            </>
          )}
          {showDropdownCity && (
            <>
              <div className="dropdown-container-desk">
                {dropDownData.map((e, i) => (
                  <div
                    key={i}
                    className="each-country"
                    onClick={() => {
                      selectInput(title, e);
                      console.log(e);
                      setValue(e);
                      setShowDropDownCity(!showDropdownCity);
                    }}
                  >
                    {e}
                  </div>
                ))}
              </div>
            </>
          )}
          {showDropdownArea && (
            <>
              <div className="dropdown-container-desk">
                {dropDownData.map((e, i) => (
                  <div
                    key={i}
                    className="each-country"
                    onClick={() => {
                      selectInput(title, e);
                      console.log(e);
                      setValue(e);
                      setShowDropDownArea(!showDropdownArea);
                    }}
                  >
                    {e}
                  </div>
                ))}
              </div>
            </>
          )} */}
        </div>
      )}
    </>
  );
};

export default FloatingInput;
