import React from "react";

const CouponApplicationProducts = (props) => {
  return (
    <>
      <div id="" className="cgmodalcontainer" style={{ display: "none" }}>
        <div class="cgmodal">
          <div class="modal-content">
            <span id="closeCouponModal" class="cgclose">
              &times;
            </span>
            <p id="modal-content">
              <h3>Applicable Products</h3>
              <div className="row" style={{ margin: "0" }}>
                <div className="col-6 col-md-2 col-lg-2 product-grid-list">
                  <div className="product-box-list-page">
                    <div className="lg-img-container">
                      <div className="p-img">
                        <a href="/">
                          <img
                            src="https://www.cgdigital.com.np/api/images/products/t55Idp_1605675378-OR013FD-B5.webp"
                            className="p-img-front"
                            alt="Front"
                          />
                          <img
                            src="https://www.cgdigital.com.np/api/images/products/t55Idp_1605675378-OR013FD-B5.webp"
                            className="p-img-back"
                            alt="Back"
                          />
                        </a>
                      </div>
                    </div>

                    <div className="p-box-text">
                      <div className="product-category">
                        <span>ABCDEDFASDFA</span>
                      </div>
                      <a href="/" className="product-title">
                        8kg, AI Direct Drive Front Load Washing Machine
                      </a>

                      <div className="price-buy">
                        <span className="p-price striking-price">
                          NPR 27,460
                        </span>
                        <a href="/" className="p-buy-btn">
                          NPR 9,970
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-2 col-lg-2 product-grid-list">
                  <div className="product-box-list-page">
                    <div className="lg-img-container">
                      <div className="p-img">
                        <a href="/">
                          <img
                            src="https://www.cgdigital.com.np/api/images/products/t55Idp_1605675378-OR013FD-B5.webp"
                            className="p-img-front"
                            alt="Front"
                          />
                          <img
                            src="https://www.cgdigital.com.np/api/images/products/t55Idp_1605675378-OR013FD-B5.webp"
                            className="p-img-back"
                            alt="Back"
                          />
                        </a>
                      </div>
                    </div>

                    <div className="p-box-text">
                      <div className="product-category">
                        <span>ABCDEDFASDFA</span>
                      </div>
                      <a href="/" className="product-title">
                        8kg, AI Direct Drive Front Load Washing Machine
                      </a>

                      <div className="price-buy">
                        <span className="p-price striking-price">
                          NPR 27,460
                        </span>
                        <a href="/" className="p-buy-btn">
                          NPR 9,970
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponApplicationProducts;
