import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Data from "../../utils/data";
import Api from "../../utils/api";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useHistory, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfiniteScroll from "react-infinite-scroller";
import LoadingImage from "../../../assets/img/loading.gif";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import ListProduct from "./ListProduct/ListProduct";
import AddSlider from "./CategorySlider/AddSlider";
import CategorySkeleton from "../Skeleton/CategorySkeleton";
const Category = (props) => {
  let history = useHistory();
  const [search, setSearch] = useState("");
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [priceRangeChange, setPriceRangeChange] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [productPaginate, setProductPaginate] = useState({});
  const [nextHref, setNextHref] = useState("");
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  function fetchSearchData() {
    let params = fetchParams();
    setSearch(getSearchData());
    params.keywords = search;
    let url = new URL(Data.API_BASE_URL + "web-search");
    url.search = new URLSearchParams(params).toString();
    console.log(url);
    fetch(url, {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        setHasMoreItems(true);
        setNextHref("");
        setSearchData(response.data);

        const gtmProductArr = [];
        response.data.products.map(function (product) {
          gtmProductArr.push({
            id: product.product_code,
            google_business_vertical: "retail",
          });
        });

        // GTM(gtmProductArr);
      })
      .catch((error) => {
        console.log(error);
        // history.push("/page-not-found");
      });
  }
  function getSearchData() {
    return props.match.params["search_data"];
  }
  function fetchParams(page = null) {
    let params = {};
    let brand = new URLSearchParams(props.location.search).get("brand");
    let range = new URLSearchParams(props.location.search).get("range");
    if (brand) {
      params.brand = brand;
    }
    if (page == 1)
      if (nextHref) {
        params.page = new URLSearchParams(new URL(nextHref).search).get("page");
      }
    if (range) {
      params.range = range;
    }
    return params;
  }
  function checkBrand(brand) {
    let urlBrand = new URLSearchParams(props.location.search).get("brand");
    if (urlBrand) {
      let brands = urlBrand.split("---");
      var findBrand = brands.find(function (brandfind) {
        return brandfind == brand.name;
      });
      if (findBrand) {
        return true;
      } else {
        return false;
      }
    }
  }
  function setSearchData(data) {
    // setProducts(data["products"]);
    setBrands(data["brands"]);
    setProductPaginate(data["setProductPaginate"]);
    setPriceRange(data["price_range"]);
    let range = new URLSearchParams(props.location.search).get("range");
    if (range) {
      range = range.split("-");
      let newRange = {};
      newRange.min = range[0];
      newRange.max = range[1];
      setPriceRangeChange(newRange);
    } else {
      setPriceRangeChange(data["price_range"]);
    }
    setSliders(data["sliders"]);
    setIsloading(false);
  }
  function handleChange(e) {
    const item = e.target.name;
    const val = e.target.value;
    const isChecked = e.target.checked;
    let brand = new URLSearchParams(props.location.search).get("brand");

    if (brand) {
      let brands = brand.split("---");
      var findBrand = brands.find(function (brand) {
        return brand == val;
      });
      if (findBrand) {
        brands.splice(brands.indexOf(findBrand), 1);
        console.log(brands);
        history.push({
          pathname: props.match.url,
          search: "?brand=" + brands.join("---"),
        });
      } else {
        history.push({
          pathname: props.match.url,
          search: "?brand=" + brand + "---" + val,
        });
      }
    } else {
      history.push({
        pathname: props.match.url,
        search: "?brand=" + val,
      });
    }
  }

  function handleSortBy(e) {
    let params = fetchParams();
    params.sort = e.target.value;
    history.push({
      pathname: props.match.url,
      search: new URLSearchParams(params).toString(),
    });
  }

  function GTM(gtmProductArr) {
    var dataLayer = window.dataLayer || [];

    dataLayer.push({
      event: "view_search_results",
      value: 1003,
      items: gtmProductArr,
    });
  }

  useEffect(() => {
    setIsloading(true);

    setProducts([]);
    fetchSearchData();
  }, [props.match.params]);

  function loadMore() {
    let url;
    let params = fetchParams(1);
    if (nextHref) {
      url = new URL(nextHref);
    } else {
      url = new URL(Data.API_BASE_URL + "web-search");
    }
    setSearch(getSearchData());

    params.keywords = search;
    url.search = new URLSearchParams(params).toString();
    fetch(url, {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        // setProducts([]);

        response.data.products.map(function (product) {
          products.push(product);
        });

        if (response.data.productPaginate.next_page_url) {
          setNextHref(response.data.productPaginate.next_page_url);
          // console.log(nextHref);
        } else {
          setHasMoreItems(false);
        }

        const gtmProductArr = [];
        response.data.products.map(function (product) {
          gtmProductArr.push({
            id: product.product_code,
            google_business_vertical: "retail",
          });
        });

        GTM(gtmProductArr);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <React.Fragment>
      <div className="search-page" id="sr1">
        <div className="search-content">
          {isLoading ? (
            <div
              className="container"
              style={{ textAlign: "center", padding: "20px 0px" }}
            >
              <CategorySkeleton />
            </div>
          ) : (
            <div className="container">
              <Breadcrumb
                levelOne={{
                  name: search,
                  link: "/search/" + search,
                }}
              />
              <div className="row search-row">
                <div className="facet-results ">
                  <div className="filter-wrp">
                    <div className="left-wrp">
                      <div className="filter-container" id="facets">
                        <div className="filter-type">
                          <div className="filter-section">
                            <div className="filter-name filter-accordion">
                              <div className="filter-header">
                                <span className="cat-name"> Shop By </span>
                                <FontAwesomeIcon
                                  className="sh-icon arrow_down"
                                  icon="angle-down"
                                />
                                {/* <i className="sh-icon dg-chevron_down"> </i> */}
                              </div>
                            </div>
                            <div className="filter-collapse">
                              <div className="filter-accordion cat-layer active">
                                <div className="filter-section">
                                  <div className="filter-header">
                                    <a
                                      href="#"
                                      data-attribute="taxonomies.taxonomies_hierarchical"
                                      data-value="Home Appliances"
                                      className="top-cat decoration-none active space-15"
                                    >
                                      <span className="cat-name">
                                        <b>{search}</b>
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="filter-type checkbox-factet">
                          <div className="filter-section">
                            <div className="filter-name filter-accordion">
                              <div className="filter-header">
                                <span className="cat-name"> Brand </span>
                                <FontAwesomeIcon
                                  className="sh-icon arrow_down"
                                  icon="angle-down"
                                />
                              </div>
                            </div>
                            <div className="filter-collapse">
                              <div className="collapse-body collapse-list">
                                <div className="filter-list">
                                  <ul className="nav facets">
                                    {brands.map(function (brand, index) {
                                      return (
                                        <li
                                          className="facet active"
                                          key={index}
                                        >
                                          <div className="filter-item">
                                            <div className="custom-checkbox">
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  name="brand"
                                                  className="checkbox"
                                                  data-facet="taxonomies.attr.Brand"
                                                  value={brand.name}
                                                  checked={checkBrand(brand)}
                                                  onChange={handleChange}
                                                />
                                                <i className="sh sh-checkbox"></i>
                                                <span className="item-name">
                                                  {brand.name}
                                                </span>
                                                <span className="item-count">
                                                  {brand.product_count}
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <AddSlider sliders={sliders} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="search-results">
                  <div className="grid-view">
                    <div className="clearfix search-header">
                      <h3 style={{ marginLeft: "20px" }}>
                        Search Results: "{search}"
                      </h3>
                      <div className="search-text" id="stats">
                        Showing {products.length} Results
                        {/* for "<span className="bold">lg</span>" */}
                      </div>
                      <div className="sort-wrp">
                        <span className="sort-by">Sort by</span>
                        <section className="sort-container">
                          <div className="selectric-wrapper selectric-sort">
                            <select
                              name="sort"
                              title="sort"
                              class="sort-by-select"
                              value={fetchParams().sort}
                              onChange={handleSortBy}
                            >
                              <option value=""> - </option>
                              <option value="new">New In</option>
                              <option value="low-to-high">Lowest Price</option>
                              <option value="high-to-low">Highest Price</option>
                            </select>

                            <div className="selectric-items" tabIndex="-1">
                              <div className="selectric-scroll">
                                <ul>
                                  <li data-index="0" className="selected">
                                    Most Relevant
                                  </li>
                                  <li data-index="1" className="">
                                    Lowest Price
                                  </li>
                                  <li data-index="2" className="last">
                                    Highest Price
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <input className="selectric-input" tabIndex="0" />
                          </div>
                        </section>
                      </div>
                      <div id="refinement-tags" className="clearfix"></div>
                    </div>
                    <div className="product-scroller js-product-list">
                      <div className="product-container">
                        <div
                          className="product-items row reset-margin"
                          id="hits"
                        >
                          <InfiniteScroll
                            pageStart={0}
                            loadMore={() => loadMore()}
                            hasMore={hasMoreItems}
                            loader={
                              <div style={{ alignContent: "center" }}>
                                <img
                                  src={LoadingImage}
                                  style={{
                                    height: "150px",
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                />
                              </div>
                            }
                          >
                            {products.map(function (product, index) {
                              return (
                                <div
                                  className="slide col-md-3 reset-padding fadeInUp"
                                  key={index}
                                >
                                  <ListProduct product={product} />
                                </div>
                              );
                            })}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Category);
