import Storage from "./storage";
import Data from "./data";
import api from "./api";

export default {
  addToCart(productId, qty = 1) {
    return fetch(Data.API_BASE_URL + "add-to-cart", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        qty: qty,
        product_id: productId,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        this.cartOnline();
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  },
  async cartOnline(couponCode = null, redeemPoint = null) {
    if (couponCode != null) {
      let url = new URL(Data.API_BASE_URL + "get-cart");
      let data = { coupon: couponCode, platform: "W" };
      if (redeemPoint != null) {
        data.redeem = redeemPoint;
      }
      url.search = new URLSearchParams(data).toString();
      return await fetch(url, {
        method: "GET",
        headers: Data.API_HEADER,
      })
        .then(api.handleResponseAndError)
        .then((response) => {
          if (response.data !== null) {
            return response;
          } else {
            return null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let url = new URL(Data.API_BASE_URL + "get-cart");
      let data = { platform: "W" };
      if (redeemPoint != null) {
        data.redeem = redeemPoint;
      }
      url.search = new URLSearchParams(data).toString();
      return await fetch(url, {
        method: "GET",
        headers: Data.API_HEADER,
      })
        .then(api.handleResponseAndError)
        .then((response) => {
          if (response.data !== null) {
            return response;
          } else {
            return null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },

  async checkOutFlexiPay() {
    
      let url = new URL(Data.API_BASE_URL + "get-checkout");
      let data = {};
     
      data.platform = "W";
      data.paymentType = "FlexiPay";
      url.search = new URLSearchParams(data).toString();
      return await fetch(url, {
        method: "GET",
        headers: Data.API_HEADER,
      })
        .then(api.handleResponseAndError)
        .then((response) => {
          if (response.data !== null) {
            return response;
          } else {
            return null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    
  },

  async checkOutOnline(couponCode = null, redeemPoint = null) {
    if (couponCode != null) {
      let url = new URL(Data.API_BASE_URL + "get-checkout");
      let data = { coupon: couponCode };
      if (redeemPoint != null) {
        data.redeem = redeemPoint;
      }
      data.platform = "W";
      url.search = new URLSearchParams(data).toString();
      return await fetch(url, {
        method: "GET",
        headers: Data.API_HEADER,
      })
        .then(api.handleResponseAndError)
        .then((response) => {
          console.log(response);
          console.log("coupon");
          if (response.data !== null) {
            return response;
          } else {
            return null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let url = new URL(Data.API_BASE_URL + "get-checkout");
      let data = {};
      if (redeemPoint != null) {
        data.redeem = redeemPoint;
      }
      data.platform = "W";
      url.search = new URLSearchParams(data).toString();
      return await fetch(url, {
        method: "GET",
        headers: Data.API_HEADER,
      })
        .then(api.handleResponseAndError)
        .then((response) => {
          if (response.data !== null) {
            return response;
          } else {
            return null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  async fetchCartCoupons() {
    let url = new URL(Data.API_BASE_URL + "get-coupons-via-cart-products");
    let data = { platform: "W" };

    url.search = new URLSearchParams(data).toString();
    return await fetch(url, {
      method: "GET",
      headers: Data.API_HEADER,
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        if (response.data !== null) {
          return response;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
