import React from "react";
import NumberFormat from "react-number-format";

import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
const SliderProduct = (props) => {
  return (
    <React.Fragment>
      <div className="sdg-ratio">
        <Link
          to={
            "/product-" +
            props.product.name.replace(/[#+. _/]/gi, "-").toLowerCase() +
            "-" +
            props.product.id
          }
          title={props.product.name}
        >
          <LazyLoadImage
            src={props.product.featured_image}
            style={{
              objectFit: "cover",
              width: "100%",
              maxHeight: "500px",
            }}
            className="img-hover-effect"
            alt={props.product.name}
            onMouseOver={(e) =>
              (e.currentTarget.src = props.product.alt_featured_image)
            }
            onMouseOut={(e) =>
              (e.currentTarget.src = props.product.featured_image)
            }
          />
        </Link>
      </div>
      <div className="carousel-details">
        <Link
          to={
            "/product-" +
            props.product.name.replace(/[#+. _/]/gi, "-").toLowerCase() +
            "-" +
            props.product.id
          }
          title={props.product.name}
        >
          <h4>
            {props.product.brand} {props.product.name}
            <br />
            <small>{props.product.model_number}</small>
          </h4>
        </Link>
      </div>
      {props.product.discount_percent > 0 ? (
        <div className="discount-holder first">
          <div className="product-disc">
            <span className="onsale">
              SAVE {props.product.discount_percent}%
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {props.product.new == 1 ? (
        <div className="dg-privilege__holder left">
          <span className="product-disc">New</span>
        </div>
      ) : (
        ""
      )}
      {props.product.product_type === "Combo" ? (
        <div className="discount-holder combo-ribbon">
          <div className="product-disc">
            <span className="onsale">Bundle</span>
          </div>
        </div>
      ) : (
        ""
      )}
      {props.product.free != null ? (
        <div className="discount-holder badge-deals badge-item0">
          <span
            className="product-disc text-center "
            style={{
              border: "1px solid black",
              color: "#000000",
            }}
          >
            <LazyLoadImage
              src={props.product.free.image}
              height="50px"
              className="free-product"
              style={{ objectFit: "cover", margin: "0 auto" }}
            />
            Free
          </span>
        </div>
      ) : (
        ""
      )}
      <div className="slider-extra--wrp" style={{ height: "105px" }}>
        {props.product.discount_percent != null ? (
          <div className="product-price" style={{ width: "100%" }}>
            <div className="cross-price">
              <span className="currency">NPR</span>
              <span className="strike">
                <NumberFormat
                  displayType={"text"}
                  thousandSeparator={true}
                  thousandsGroupStyle="lakh"
                  value={props.product.price}
                />
              </span>
            </div>
            <div className="price">
              <span className="currency">NPR</span>
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                value={props.product.discount_price}
              />
            </div>
          </div>
        ) : (
          <div className="product-price" style={{ width: "100%" }}>
            <div className="price">
              <span className="currency">NPR</span>
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                value={props.product.price}
              />
            </div>
          </div>
        )}
        <br />
        <div className="slider-extra" style={{ width: "100%" }}>
          {props.product.ratings != null ? (
            <div className="rating-badge">
              <div
                className="rating title-review"
                itemProp="aggregateRating"
                itemScope
                itemType="http://schema.org/AggregateRating"
              >
                <div className="rating-details rating-details-block in-block prod-extra">
                  <div className="rating-stars">
                    <div className="grey-stars"></div>
                    <div
                      className="filled-stars"
                      style={{ width: (props.product.ratings / 5) * 100 + "%" }}
                    ></div>
                  </div>
                  <span className="product-rating-count" itemProp="ratingValue">
                    ({props.product.ratings})
                  </span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <span className="algolia-instock" style={{ fontSize: "10px" }}>
            Collect CG|Coin:{" "}
            <NumberFormat
              displayType={"text"}
              thousandSeparator={true}
              thousandsGroupStyle="lakh"
              value={props.product.loyalty_point}
            />
          </span>

          <br />
          {props.product.emi_price != null ? (
            <span className="algolia-instock " style={{ fontSize: "12px" }}>
              EMI: NPR{" "}
              <NumberFormat
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                value={props.product.emi_price}
              />{" "}
            </span>
          ) : (
            ""
          )}
          <br />
          {props.product.availability == 1 ? (
            <span className="algolia-instock ">
              <span className="bullet">&#9679;</span> In stock
            </span>
          ) : (
            <span className="algolia-outofstock">
              <span className="bullet">&#9679;</span> Out of stock
            </span>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SliderProduct;
