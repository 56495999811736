import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Data from "../../utils/data";
import Api from "../../utils/api";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useHistory, withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import LoadingImage from "../../../assets/img/loading.gif";
import "react-input-range/lib/css/index.css";
import ListProduct from "../Product/ListProduct/ListProduct";
import Countdown from "react-countdown";
const FlashSales = (props) => {
  let history = useHistory();
  const [flashSale, setFlashSale] = useState({});
  const [products, setProducts] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [priceRangeChange, setPriceRangeChange] = useState([]);
  const [productPaginate, setProductPaginate] = useState({});
  const [nextHref, setNextHref] = useState("");
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  function fetchCategory() {
    let url = new URL(Data.API_BASE_URL + "current-flash-sales");
    console.log(url);
    fetch(url, {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        console.log(response.data);
        setFlashSale(response.data.flash_sale);
        if (response.data.products != null) {
          setProducts(response.data.products);
          console.log(response.data);
          setProductPaginate(response.data["productPaginate"]);
          setNextHref(response.data.nextHref);
        } else {
          history.push("/page-not-found");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function setCategoryData(data) {
    setIsloading(false);
  }

  useEffect(() => {
    setIsloading(false);
    fetchCategory();
  }, [props.match.params]);

  function loadMore(page) {
    let url;
    if (nextHref) {
      url = nextHref;

      fetch(url, {
        method: "GET",
      })
        .then(Api.handleResponseAndError)
        .then((response) => {
          if (response.data.products != null) {
            response.data.products.map(function (product) {
              products.push(product);
            });
            if (response.data.productPaginate) {
              setProducts(products);
              setNextHref(response.data.productPaginate.next_page_url);
            } else {
              setHasMoreItems(false);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setHasMoreItems(false);
    }
  }
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    lazyLoad: true,
    swipeToSlide: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 5000,
  };
  return (
    <React.Fragment>
      <div className="search-page" id="sr1">
        <div className="search-content">
          {isLoading ? (
            <div
              className="container"
              style={{ textAlign: "center", padding: "100px 0px" }}
            >
              <ClipLoader
                css={{ margin: "0 auto" }}
                size={150}
                //size={"150px"} this also works
                color={"#123abc"}
                loading={isLoading}
              />
            </div>
          ) : (
            <React.Fragment>
              <Breadcrumb
                levelOne={{
                  name: "Flash Sales",
                  link: "/flash-sales/",
                }}
              />
              <div className="row search-row">
                <div className="search-results-full">
                  <div className="grid-view">
                    <div className="clearfix search-header">
                      <h3 style={{ marginLeft: "20px" }}>
                        {flashSale.title}
                        <div
                          className="flash-sale-product-view"
                          style={{ textAlign: "right", paddingRight: "15px" }}
                        >
                          <span className="title">Flash Sales ends at</span>{" "}
                          <span className="timer">
                            {flashSale.end_time ? (
                              <Countdown date={flashSale.end_time} />
                            ) : (
                              <Countdown date={Date.now()} />
                            )}
                          </span>
                        </div>
                      </h3>
                      <div className="search-text" id="stats">
                        Showing {products.length} Results
                        {/* for "<span className="bold">lg</span>" */}
                      </div>
                      <div className="sort-wrp">
                        <span className="sort-by">Sort by</span>

                        <section className="sort-container">
                          <div className="custom-select">
                            <div className="selectric-wrapper selectric-sort">
                              <div className="selectric-hide-select">
                                <select
                                  name="sort"
                                  title="sort"
                                  className="sort"
                                  tabIndex="-1"
                                >
                                  <option value="products_index">
                                    Most Relevant
                                  </option>
                                  <option value="products_index_price_asc">
                                    Lowest Price
                                  </option>
                                  <option value="products_index_price_desc">
                                    Highest Price
                                  </option>
                                </select>
                              </div>
                              <div className="selectric">
                                <span className="label">Most Relevant</span>
                                <b className="button">▾</b>
                              </div>
                              <div className="selectric-items" tabIndex="-1">
                                <div className="selectric-scroll">
                                  <ul>
                                    <li data-index="0" className="selected">
                                      Most Relevant
                                    </li>
                                    <li data-index="1" className="">
                                      Lowest Price
                                    </li>
                                    <li data-index="2" className="last">
                                      Highest Price
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <input className="selectric-input" tabIndex="0" />
                            </div>
                          </div>
                        </section>
                      </div>
                      <div id="refinement-tags" className="clearfix"></div>
                    </div>
                    <div className="product-scroller js-product-list">
                      <div className="product-container">
                        <div
                          className="product-items row reset-margin"
                          id="hits"
                        >
                          <InfiniteScroll
                            pageStart={0}
                            loadMore={() => loadMore()}
                            hasMore={hasMoreItems}
                            loader={
                              <div style={{ alignContent: "center" }}>
                                <img
                                  src={LoadingImage}
                                  style={{
                                    height: "150px",
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                />
                              </div>
                            }
                          >
                            {products.map(function (product, index) {
                              return (
                                <div
                                  className="slide col-md-4 reset-padding fadeInUp"
                                  key={index}
                                >
                                  <ListProduct product={product} />
                                </div>
                              );
                            })}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(FlashSales);
