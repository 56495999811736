import React, { createContext, useState, useEffect } from "react";
import api from "../../utils/api";
import Data from "../../utils/data";
import storage from "../../utils/storage";

export const LoyaltyContext = createContext();

const LoyaltyContextProvider = ({ children }) => {
  const [loyaltyPoint, setLoyaltyPoint] = useState(0);
  const loyaltyOnline = async () => {
    if (storage.isLoggedIn()) {
      let url = new URL(Data.API_BASE_URL + "get-loyalty-point");
      return await fetch(url, {
        method: "GET",
        headers: Data.API_HEADER,
      })
        .then(api.handleResponseAndError)
        .then((response) => {
          if (response.data !== null) {
            setLoyaltyPoint(response.data.loyality);
          } else {
            storage.logout();
            setLoyaltyPoint(0);
          }
        })
        .catch((error) => {
          storage.logout();
          console.log(error);
          setLoyaltyPoint(0);
        });
    }
  };

  function refreshApi() {
    loyaltyOnline();
  }
  function refreshLoyaltyApi() {
    loyaltyOnline();
  }
  useEffect(() => {
    loyaltyOnline();
  }, []);
  return (
    <LoyaltyContext.Provider
      value={{
        loyaltyPoint: loyaltyPoint,
        refreshApi: refreshApi,
        refreshLoyaltyApi: refreshLoyaltyApi,
      }}
    >
      {children}
    </LoyaltyContext.Provider>
  );
};

export default LoyaltyContextProvider;
