import React, { useState, useEffect } from "react";

import { useHistory, Link } from "react-router-dom";
import { useInput } from "../hooks/inputHook";
import Data from "../utils/data";
import Api from "../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClipLoader from "react-spinners/ClipLoader";
import { Country_Code } from "./country";
import WithContext from "../Layouts/MainMessage/WithContext";
const Register = (props) => {
  let history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [countryCode, setCountryCode] = useState("");

  const { value: name, bind: bindName } = useInput("");
  const { value: number, bind: bindNumber } = useInput("");
  const { value: email, bind: bindEmail } = useInput("");

  const {
    value: password,
    bind: bindPassword,
    setValue: setPassword,
  } = useInput("");

  const {
    value: c_password,
    bind: bindCPassword,
    setValue: setCPassword,
  } = useInput("");
  function handleChange(e) {
    setCountryCode(e.target.value);
  }
  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (password != c_password) {
      setErrorMessage("Your Password doesnot match!!");
    } else {
      console.log(email);

      fetch(Data.API_BASE_URL + "register", {
        method: "POST",
        headers: Data.API_HEADER,
        body: JSON.stringify({
          fullName: name,
          emailAddress: email,
          mobileNumber: number,
          password: password,
          platform: "Web"
        }),
      })
        .then(Api.handleResponseAndError)
        .then((response) => {
          if (response.statusCode == 200) {
            setErrorMessage("");
            history.push({
              pathname: "/user/login",
            });
            props.value.setMessage(response.message);
          } else {
            setErrorMessage(response.message);
            console.log(errorMessage);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  useEffect(() => {
    setIsLoading(false);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      ) : (
          <React.Fragment>
            <div className="container">
              <div id="primary" className="content-area">
                <main id="main" className="site-main" role="main">
                  <div id="post-7">
                    <div className="entry-content">
                      <div className="woocommerce">
                        <div className="sh-reg">
                          <div className="container">
                            <div className="reg-container loginpage-wrp">
                              <form
                                onSubmit={handleSubmit}
                                className="box"
                                id="loginform"
                              >
                                <div className="login-text--wrp">
                                  <h1 className="headline">Register</h1>
                                  <h5>Create your very own account</h5>
                                </div>
                                <div className="login-button-container"></div>

                                <div className="register-input-item">
                                  <input
                                    type="text"
                                    className="input-text register-user-input-name register-user-input"
                                    name="full_name"
                                    placeholder="Full Name"
                                    {...bindName}
                                    required
                                  />
                                </div>
                                <div className="register-input-item">
                                  <input
                                    type="email"
                                    className="input-text register-user-input-name register-user-input"
                                    name="email"
                                    placeholder="Enter Your Email"
                                    {...bindEmail}
                                    required
                                  />
                                </div>
                                <div className="register-input-item">
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <input
                                        type="text"
                                        value={countryCode}
                                        disabled
                                        className="input-text register-user-input-name register-user-input register-user-select"
                                      />
                                    </div>
                                    <div className="col-lg-9">
                                      <select
                                        name="country"
                                        onChange={handleChange}
                                        required
                                        className="input-text register-user-input-name register-user-input register-user-select select-country"
                                      >
                                        <option value="">Select Any One</option>
                                        {Country_Code.map(function (
                                          country,
                                          index
                                        ) {
                                          return (
                                            <option
                                              value={country.dial_code}
                                              key={index}
                                            >
                                              {country.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="register-input-item">
                                  <input
                                    type="number"
                                    className="input-text register-user-input-name register-user-input"
                                    name="number"
                                    placeholder="Enter Your Number"
                                    required
                                    {...bindNumber}
                                  />
                                </div>

                                <div className="register-input-item">
                                  <input
                                    className="input-text register-user-input-name register-user-input"
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    required
                                    {...bindPassword}
                                  />
                                </div>
                                <div className="register-input-item">
                                  <input
                                    className="input-text register-user-input-name register-user-input"
                                    type="password"
                                    name="c_password"
                                    placeholder="Confirm Password"
                                    required
                                    {...bindCPassword}
                                  />
                                </div>
                                <div className="register-register-button-container">
                                  <input
                                    type="hidden"
                                    id="_wpnonce"
                                    name="_wpnonce"
                                    value="8c77275735"
                                  />
                                  <input
                                    type="hidden"
                                    name="_wp_http_referer"
                                    value="/my-account/"
                                  />{" "}
                                  <div
                                    className="register-input-item"
                                    style={{
                                      color: "red",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {errorMessage ? (
                                      <React.Fragment>
                                        <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                                        {errorMessage}{" "}
                                      </React.Fragment>
                                    ) : (
                                        ""
                                      )}
                                  </div>
                                  <input
                                    type="submit"
                                    className="button register-register-button btn btn-secondary"
                                    name="login"
                                    value="Register"
                                  />
                                </div>
                                <div className="forgot-link">
                                  <Link to={"/user/register/"}>
                                    Forgot password?
                                </Link>{" "}
                                  <Link to={"/user/login/"}>Login</Link>
                                </div>

                                <input type="hidden" name="redirect" value="" />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>

            <div className="container search-loading hide">
              <div className="search-row ">
                <div className="row">
                  <div className="col-md-3">
                    <div className="col s6">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col s6">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col s6">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col s6">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="block-load img-block"
                          style={{
                            height: "130px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100%",
                          }}
                        >
                          {" "}
                        </div>
                        <div
                          className="block-load"
                          style={{
                            height: "7px",
                            marginBottom: "15px",
                            width: "100px",
                          }}
                        >
                          {" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

export default WithContext(Register);
