import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
const CategoryListLayout = (props) => {

    return (
        <div className="home-brand-list">
            <div className="container" align="center">
                <h3 className="title-box"><b>{props.view.title}<small>{props.view.sub_title}</small></b> </h3>
                <div className="grid-wrapper" style={{ marginBottom: "25px" }}>
                    <div className="row">
                        {props.view.category_list.map(function (category, index) {
                            return (
                                <Link to={"/" + category.category_url} className="category-box-link">
                                    <div className="col-lg-3 col-sm-6 col-xl-6 m-b-15" key={index}>
                                        <div className="category-box">
                                            <div className="category-logo">
                                                <LazyLoadImage
                                                    style={{ height: "40px", width: "40px", marginLeft: "20px", objectFit: "contain", borderRadius: "16px" }}
                                                    className="mycat lazy-hidden loaded"
                                                    src={category.icon}
                                                />
                                            </div>
                                            <h5>{category.name}</h5>
                                        </div>

                                    </div>
                                </Link>

                            );
                        })}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryListLayout;
