import React, { useEffect, useState } from "react";
import "./myReservations.css";
import OrderDetails from "../../components/orderDetails/orderDetails";
import HomeButton from "../../components/homeButton/homeButton";
import storage from "../../components/utils/storage";
import { useHistory } from "react-router-dom";
import Data from "../../components/utils/data";
import Api from "../../components/utils/api";
import LoadingOverlay from "react-loading-overlay";


const MyReservations = () => {
  let history = useHistory();

  const [allOrders, setAllOrders] = useState([]);
  const [isActiveOverlay, setIsActiveOverlay] = useState(false);

  function fetchBookings() {
    setIsActiveOverlay(true);
     fetch(Data.API_BASE_URL + "ola/get-bookings", {
      method: "GET",
      headers: Data.API_HEADER,
    })
    .then(Api.handleResponseAndError)
    .then((response) => {
      console.log(response);
      if(response.status == 'success'){
        setAllOrders(response.data);
        setIsActiveOverlay(false);
      }
    })
    .catch((error) => {
      console.log(error);
      // return false;
    });
  }

  function getProductItemId() {
    var url = new URL(window.location.href);
    return url.searchParams.get("product");
  }

  useEffect(() => {
    if (!storage.isLoggedIn()) {
      // history.goBack();
      if(getProductItemId()){
        history.replace("/user/login?source=ola&product="+getProductItemId());
      }else{
        history.replace("/user/login?source=ola");
      }
    }
    fetchBookings();
  }, []);

  return (
    <div className="myreservation-cont">
      <div className="left-panel">
        <div className="logo-container-np">
          <div>
            <img
              src="https://d34kmefuuy0be0.cloudfront.net/ev_assets/ola_logo_light_f6fa846589.svg"
              alt="OLA"
              className="logo-np"
            />
          </div>
        </div>
        <div className="banner-img" />
      </div>
      <div className="right-panel">
        <HomeButton />
        <LoadingOverlay active={isActiveOverlay} spinner text="Loading">
        <OrderDetails allOrders={allOrders} />
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default MyReservations;
