import React, { useEffect, useState, useContext, useMemo } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PeopleAlosBuy from "./PeopleAlsoBuy";
import Featured from "./Featured";
import Data from "../../utils/data";
import Api from "../../utils/api";
import Magnifier from "react-magnifier";
import { useHistory, withRouter, Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import storage from "../../utils/storage";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "slick-carousel/slick/slick.css";
import Countdown from "react-countdown";
import Slider from "react-slick";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Enquiry from "./Enquiry/Enquiry";
import Modal from "../../Layouts/Modal/Modal";
import NextSlider from "../../Layouts/Silder/NextSlider";
import PrevSlider from "../../Layouts/Silder/PrevSlider";
import Review from "./Review/Review";
import StoreConsultation from "./StoreConsultation/StoreConsultation";
import CartFunction from "../../utils/cartFunction";
import wishlistFunction from "../../utils/wishlistFunction";
import WithContext from "../../Layouts/MainMessage/WithContext";
import MetaTags from "react-meta-tags";
import { BrowserView, MobileView } from "react-device-detect";
import { CartContext } from "../../Layouts/CartContextProvider";
import {
  sendAddToCart,
  sendProductVisited,
  sendAddToCartPushGTM,
} from "../../utils/pixel";
import SupplierLogo from "./Logo/SupplierLogo";
const ViewProduct = (props) => {
  const { addToCartApi } = useContext(CartContext);
  let history = useHistory();
  const [product, setProduct] = useState([]);
  const [meta, setMeta] = useState({});
  const [related_products, setRelatedProducts] = useState([]);
  const [featured_products, setFeaturedProducts] = useState([]);
  const [title, setTitle] = useState("");
  const [isLoading, setIsloading] = useState(true);
  const [tooltipText, setTooltipText] = useState("Click to Copy");

  async function fetchProduct() {
    //
    var fetchProduct = await fetch(
      Data.API_BASE_URL + "view-product?product=" + getProductItemId(),
      {
        method: "GET",
      }
    )
      .then(Api.handleResponseAndError)
      .then((response) => {
        return setProductData(response.data);
        //  GTM()
      })
      .catch((error) => {
        console.log("test" + error);
        // return false;
      });
  }
  function addToCompare(evt, product) {
    evt.preventDefault();
    var products = storage.getCompareProducts();
    var subCategory = products.find(function (findProduct) {
      return (
        findProduct.product_sub_category_id == product.product_sub_category_id
      );
    });
    if (subCategory) {
      var productFind = products.find(function (findProduct) {
        return findProduct.id == product.id;
      });
      if (!productFind) {
        products.push(product);
      }
    } else {
      products = [product];
    }
    storage.addToCompare(products);
    window.scrollTo(0, 0);
    props.value.setMessage(product.name + " added to Compare");
  }

  function getProductItemId() {
    let id = props.match.params["product_name"].split("-");
    id = id[id.length - 1];
    return id;
  }
  async function addToCart(evt, product) {
    if (product.availability !== 1) {
      props.value.setErrorMessage(product.name + " is out of Stock.");
    } else {
      if (!storage.isLoggedIn()) {
        history.push({
          pathname: "/user/login",
          search:
            "?cart=" + product.name.replace(/\s/g, "-") + "-" + product.id,
        });
      } else {
        let data = await addToCartApi(product.id);
        if (data) {
          sendAddToCart(product);
          sendAddToCartPushGTM(product);
          return history.push({
            pathname: "/user/cart",
          });
        }
      }
    }
  }
  function setProductData(data) {
    setTitle(data["title"]);
    setProduct(data["product"]);
    setMeta(data["meta"]);
    setRelatedProducts(data["related_products"]);
    setFeaturedProducts(data["featured_products"]);
    sendProductVisited(data["product"]);
    setIsloading(false);
    GTM(data["product"]);
  }

  async function handleAddToWishlist(evt) {
    evt.preventDefault();
    if (!storage.isLoggedIn()) {
      return history.push({
        pathname: "/user/login",
        search:
          "?wishlist=" + product.name.replace(/\s/g, "-") + "-" + product.id,
      });
    } else {
      let data = await wishlistFunction.addToWishlist(product.id);
      if (data) {
        window.scrollTo(0, 0);
        props.value.setMessage(product.name + " added to Wishlist");
      }
    }
  }

  function GTM(product) {
    // console.log(product);
    // alert(product.code);
    var dataLayer = window.dataLayer || [];
    // dataLayer.push({
    //   event: "view_item",
    //   value: product.price,
    //   items: [
    //     {
    //       id: product.product_code,
    //       // name: product.name,
    //       google_business_vertical: "retail",
    //     },
    //   ],
    // });
    // alert("dd");
    dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: "NPR",
        value: product.price,
        items: [
          {
            item_id: product.product_code,
            item_name: product.name,
            item_brand: product.brand,
            item_category: product.product_category_name,
            price: product.price,
            quantity: 1,
          },
        ],
      },
    });
  }

  function copyCoupon(e) {
    let coupon =
      e.target.parentNode.parentNode.getElementsByTagName("h4")[0].innerHTML;

    navigator.clipboard.writeText(coupon);
    setTooltipText("Copied: " + coupon);
  }

  function outFunc() {
    setTooltipText("Click to Copy");
  }

  // ahila lai yesari nai garnu haii
  useEffect(() => {
    setIsloading(true);
    fetchProduct();
  }, [props.match.params]);

  var settings = {
    customPaging: function (i) {
      return (
        <a>
          {i == 0 ? (
            <img
              src={product.featured_image}
              style={{ objectFit: "cover", width: "100%", maxHeight: "150px" }}
            />
          ) : (
            <img
              src={product.images[i - 1]}
              style={{ objectFit: "cover", width: "100%", maxHeight: "150px" }}
            />
          )}
        </a>
      );
    },
    nextArrow: <NextSlider />,
    prevArrow: <PrevSlider />,
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  var mobileSettings = {
    nextArrow: <NextSlider />,
    prevArrow: <PrevSlider />,

    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      ) : (
        <React.Fragment>
          <Breadcrumb
            levelOne={{
              name: product.product_category_name,
              link: "/cat-" + product.product_category_url,
            }}
            levelTwo={{
              name: product.product_sub_category_name,
              link: "/sub-" + product.product_sub_category_url,
            }}
            levelThree={{
              name: product.name,
              link:
                "/product-" +
                product.name.replace(/[#+. _/]/gi, "-").toLowerCase() +
                "-" +
                product.id,
            }}
          />

          <MetaTags>
            <title>{meta.title ? meta.title : product.name}</title>
            <meta name="description" content={meta.description} />
            <meta property="og:title" content={meta.title} />
            <meta name="keywords" content={meta.keywords} />
          </MetaTags>
          <div className="container view-product-container">
            <section className="pdp-section shown">
              <BrowserView>
                <div className="post-2845288 product type-product status-publish has-post-thumbnail product_brand-samsung product_cat-mobiles product_cat-mobiles_tablets product_tag-pre-order product_tag-samsung-20 first instock shipping-taxable purchasable product-type-simple">
                  {product.prepayment == 0 ? (
                    <div className="product-detail clearfix">
                      <div className="quick-right col-lg-8 col-xs-12">
                        <div className="summary entry-summary info-block clearfix ">
                          <div className="mainproduct-info ">
                            <div className="primary-info clearfix">
                              <div className="row">
                                <div className="product-title  col-xs-12">
                                  <b style={{ color: "#0171b6" }}>Sold By: </b>{" "}
                                  {product.supplier_logo ? (
                                    <SupplierLogo
                                      logo={product.supplier_logo}
                                      clickEvent={
                                        "/supplier/" + product.supplier_name
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <h1
                                    itemProp="name"
                                    className="product_title entry-title"
                                  >
                                    {title}
                                  </h1>
                                  {product.is_service == 0 ? (
                                    <div className="product-badges--tag">
                                      {product.availability === 1 ? (
                                        <React.Fragment>
                                          Availability:{" "}
                                          <span className="algolia-instock ">
                                            <span className="bullet">●</span> In
                                            stock
                                          </span>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          Availability:{" "}
                                          <span className="algolia-outofstock">
                                            <span className="bullet">●</span>{" "}
                                            Out of stock
                                          </span>
                                        </React.Fragment>
                                      )}
                                    </div>
                                  ) : (
                                    " "
                                  )}
                                  <div className="prod-extra in-block">
                                    <p className="reset-margin">
                                      <strong>Model: </strong>
                                      <span>{product.model_number}</span>
                                    </p>
                                  </div>
                                  <div className="prod-extra in-block">
                                    <p className="reset-margin">
                                      <strong>Brand: </strong>
                                      <span>{product.brand}</span>
                                    </p>
                                  </div>
                                  {product.flash_sales != null ? (
                                    <div className="flash-sale-product-view ">
                                      <span className="title">
                                        Flash Sales ends at
                                      </span>
                                      <br />
                                      <span className="timer">
                                        {product.flash_sales.end_time ? (
                                          <Countdown
                                            date={product.flash_sales.end_time}
                                          />
                                        ) : (
                                          <Countdown date={Date.now()} />
                                        )}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {product.free != null ? (
                                    <React.Fragment>
                                      <div className="alternative clearfix">
                                        <h5 className="blue sh-blue">Free</h5>
                                        <div className="alternative-slider clearfix">
                                          <div className="slide">
                                            <div className="sdg-ratio active">
                                              <Link
                                                to={
                                                  "/product-" +
                                                  product.free.name
                                                    .replace(/[#+. _/]/gi, "-")
                                                    .toLowerCase() +
                                                  "-" +
                                                  product.free.id
                                                }
                                              >
                                                <img
                                                  src={product.free.image}
                                                  alt={product.free.name}
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  data-container="body"
                                                  title={product.free.name}
                                                />
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="tooltip-name">
                                        {product.free.name}
                                        <br />
                                        {product.free.price}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                  {product.product_type === "Combo" ? (
                                    <React.Fragment>
                                      <div className="alternative clearfix">
                                        <h5 className="blue sh-blue">Combo</h5>
                                        <div className="alternative-slider clearfix">
                                          {product.combo_products.map(function (
                                            product,
                                            index
                                          ) {
                                            return (
                                              <div
                                                className="slide"
                                                key={index}
                                              >
                                                <div className="sdg-ratio active">
                                                  <Link
                                                    to={
                                                      "/product-" +
                                                      product.name
                                                        .replace(
                                                          /[#+. _/]/gi,
                                                          "-"
                                                        )
                                                        .toLowerCase() +
                                                      "-" +
                                                      product.product_id
                                                    }
                                                  >
                                                    <img
                                                      src={
                                                        product.featured_image
                                                      }
                                                      alt={product.name}
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-container="body"
                                                      title={product.name}
                                                    />
                                                  </Link>
                                                </div>
                                                <Link
                                                  to={
                                                    "/product-" +
                                                    product.name
                                                      .replace(
                                                        /[#+. _/]/gi,
                                                        "-"
                                                      )
                                                      .toLowerCase() +
                                                    "-" +
                                                    product.product_id
                                                  }
                                                >
                                                  <div>
                                                    {product.name}
                                                    <br />
                                                    <span className="currency">
                                                      NPR
                                                    </span>{" "}
                                                    {product.price}
                                                  </div>
                                                </Link>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="product-addcart  col-xs-12  ">
                                  <div className="addcart-action">
                                    <form
                                      className="cart"
                                      method="post"
                                      encType="multipart/form-data"
                                    >
                                      <div
                                        id="yith_wapo_groups_container"
                                        className="yith_wapo_groups_container"
                                      >
                                        <div
                                          id="ywapo_value_1"
                                          className="ywapo_group_container ywapo_group_container_radio form-row form-row-wide"
                                        >
                                          <h3>Quick Overview</h3>
                                          <div className="ywapo_input_container ywapo_input_container_radio">
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  product.short_description,
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>

                                {product.is_service == 0 ? (
                                  <div className="col-xs-6">
                                    <button
                                      type="button"
                                      href="#storeConsultation"
                                      data-toggle="modal"
                                      data-target="#storeConsultation"
                                      className="add_to_cart_button button btn btn-secondary alt"
                                    >
                                      Apply For Store Consultation
                                    </button>
                                    <Modal
                                      id="storeConsultation"
                                      body={
                                        <StoreConsultation
                                          product={product}
                                          setMessage={props.value.setMessage}
                                        />
                                      }
                                      heading={"Apply For Store Consultation"}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="pdp--price-wrp">
                            <div className="pdp-price-cart">
                              {product.discount_percent > 0 ? (
                                <div className="discount-holder">
                                  <div className="product-disc">
                                    <span className="onsale">
                                      SAVE {product.discount_percent}%
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {product.product_type === "Combo" ? (
                                <div className="discount-holder">
                                  <div className="product-disc">
                                    <span className="onsale">Bundle</span>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {product.new === 1 ? (
                                <div className="">
                                  <span className="product-disc new-product-div">
                                    New
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                              <div
                                itemProp="offers"
                                itemScope
                                itemType="http://schema.org/Offer"
                                className="product-price"
                              >
                                {product.discount_percent > 0 ? (
                                  <div className="cross-price">
                                    <span className="currency">NPR</span>
                                    <span className="strike">
                                      <NumberFormat
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        thousandsGroupStyle="lakh"
                                        value={product.price}
                                      />
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <meta itemProp="priceCurrency" content="AED " />

                                {product.discount_percent > 0 ? (
                                  <div className="price no-marign">
                                    <span className="currency">NPR</span>
                                    <meta
                                      itemProp="price"
                                      content={product.discount_price}
                                    />
                                    <span
                                      className="total--sale-price"
                                      data-sale-price={product.discount_price}
                                    >
                                      <NumberFormat
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        thousandsGroupStyle="lakh"
                                        value={product.discount_price}
                                      />
                                    </span>
                                  </div>
                                ) : (
                                  <div className="price no-marign">
                                    <span className="currency">NPR</span>
                                    <meta
                                      itemProp="price"
                                      content={product.price}
                                    />
                                    <span
                                      className="total--sale-price"
                                      data-sale-price={product.price}
                                    >
                                      <NumberFormat
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        thousandsGroupStyle="lakh"
                                        value={product.price}
                                      />
                                    </span>
                                  </div>
                                )}

                                <span className="vat-text">
                                  Inclusive of VAT
                                </span>
                                <br />
                                {product.discount_percent != null ? (
                                  <span className="algolia-instock ">
                                    Collect CG|Coins:{" "}
                                    <NumberFormat
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      thousandsGroupStyle="lakh"
                                      value={product.discount_price / 200}
                                    />{" "}
                                  </span>
                                ) : (
                                  <span className="algolia-instock ">
                                    Collect CG|Coins:{" "}
                                    <NumberFormat
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      thousandsGroupStyle="lakh"
                                      value={product.price / 200}
                                    />
                                  </span>
                                )}
                                <link
                                  itemProp="availability"
                                  href="https://schema.org/InStock"
                                />
                                <meta
                                  itemProp="itemCondition"
                                  content="http://schema.org/NewCondition"
                                />
                              </div>

                              {product.is_service == 0 ? (
                                <div className="add-to-cart-wrp clearfix">
                                  <button
                                    onClick={(evt) => addToCart(evt, product)}
                                    type="button"
                                    className="add_to_cart_button ajax_add_to_cart button btn btn-secondary alt"
                                    data-quantity="1"
                                    data-product_id="2845288"
                                    data-promo_id="846994"
                                  >
                                    <FontAwesomeIcon icon="shopping-cart" />{" "}
                                    {"  "}
                                    Add to Cart
                                  </button>
                                </div>
                              ) : (
                                <div className="add-to-cart-wrp clearfix">
                                  <button
                                    href="#productEnquiry"
                                    data-toggle="modal"
                                    data-target="#productEnquiry"
                                    className="add_to_cart_button ajax_add_to_cart button btn btn-secondary alt"
                                    data-quantity="1"
                                    data-product_id="2845288"
                                    data-promo_id="846994"
                                  >
                                    <FontAwesomeIcon icon="paper-plane" />{" "}
                                    {"  "}
                                    Enquiry
                                  </button>
                                  <Modal
                                    id="productEnquiry"
                                    body={
                                      <Enquiry
                                        product={product}
                                        setMessage={props.value.setMessage}
                                      />
                                    }
                                    heading={"Product Enquiry"}
                                  />
                                </div>
                              )}

                              <div className="pdp-icons clearfix">
                                <div className="pdp-icon">
                                  <div className="pdp-icon-wrp">
                                    <i className="sh-shield"></i>
                                  </div>
                                  <span>100% Genuine Product</span>
                                </div>
                                <div className="pdp-icon">
                                  <div className="pdp-icon-wrp">
                                    <i className="sh-secure-pay"></i>
                                  </div>
                                  <span>Secure Payment</span>
                                </div>

                                {product.coupons.length > 0 ? (
                                  <>
                                    {product.coupons.map(function (
                                      coupon,
                                      index
                                    ) {
                                      return (
                                        <div
                                          className="pdp-icon"
                                          style={{
                                            background: "rgb(37 170 225)",
                                            borderBottom: "2px solid #ffffff",
                                            padding: "5px",
                                            marginTop: "15px",
                                          }}
                                        >
                                          <div
                                            className="col col-sm-6"
                                            style={{ color: "white" }}
                                          >
                                            <h4>{coupon.code}</h4>
                                            <p>{coupon.description}</p>
                                          </div>
                                          <div className="col col-sm-6 tooltipcoupon">
                                            <button
                                              className="btn btn-copy-coupon"
                                              onClick={copyCoupon}
                                              onMouseOut={outFunc}
                                            >
                                              <span className="tooltiptext">
                                                {tooltipText}
                                              </span>{" "}
                                              Collect
                                            </button>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="product-summary clearfix product-cart-option">
                          <hr style={{ borderTop: "3px solid #eee" }} />
                          <ul
                            className="clearfix nav features "
                            itemProp="description"
                          >
                            <li>
                              <a href="#none" onClick={handleAddToWishlist}>
                                <FontAwesomeIcon icon="heart" />{" "}
                                <span>Add to Wishlist</span>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#addToCompare"
                                onClick={(evt) => addToCompare(evt, product)}
                              >
                                <FontAwesomeIcon icon="retweet" />{" "}
                                <span>Add to Compare</span>
                              </a>
                            </li>
                            {product.is_service == 0 ? (
                              <li>
                                <a
                                  href="#productEnquiry"
                                  data-toggle="modal"
                                  data-target="#productEnquiry"
                                  className="add_to_cart_button"
                                >
                                  <FontAwesomeIcon icon="paper-plane" />{" "}
                                  <span>Product Enquiry</span>
                                </a>
                                <Modal
                                  id="productEnquiry"
                                  body={
                                    <Enquiry
                                      product={product}
                                      setMessage={props.value.setMessage}
                                    />
                                  }
                                  heading={"Product Enquiry"}
                                />
                              </li>
                            ) : (
                              ""
                            )}
                            <li>
                              <a
                                href="#emi"
                                data-toggle="modal"
                                data-target="#myEMIModal"
                              >
                                <FontAwesomeIcon icon="calculator" />{" "}
                                <span>Calculate EMI</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="pdp-footer clearfix"></div>
                      </div>
                      <div className="quick-left col-lg-4 col-xs-12">
                        <div className="quick-img clearfix">
                          {product.images.length > 0 ? (
                            <Slider {...settings}>
                              <div>
                                <Magnifier
                                  src={product.featured_image}
                                  style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    maxHeight: "500px",
                                  }}
                                  zoomFactor="2"
                                />
                              </div>
                              {product.images.map(function (image, index) {
                                return (
                                  <div key={index}>
                                    <Magnifier
                                      src={image}
                                      style={{
                                        objectFit: "cover",
                                        width: "100%",
                                        maxHeight: "500px",
                                      }}
                                      zoomFactor="2"
                                    />
                                  </div>
                                );
                              })}
                            </Slider>
                          ) : (
                            <Magnifier
                              src={product.featured_image}
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                maxHeight: "500px",
                              }}
                              zoomFactor="2"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="product-detail clearfix">
                      <div className="quick-left col-lg-12 col-xs-12">
                        <div className="quick-img clearfix">
                          {product.images.length > 0 ? (
                            <Slider {...settings}>
                              <div>
                                <img
                                  src={product.featured_image}
                                  style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    maxHeight: "500px",
                                  }}
                                  zoomFactor="2"
                                />
                              </div>
                              {product.images.map(function (image, index) {
                                return (
                                  <div key={index}>
                                    <img
                                      src={image}
                                      style={{
                                        objectFit: "cover",
                                        width: "100%",
                                        maxHeight: "500px",
                                      }}
                                      zoomFactor="2"
                                    />
                                  </div>
                                );
                              })}
                            </Slider>
                          ) : (
                            <img
                              src={product.featured_image}
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                maxHeight: "500px",
                              }}
                              zoomFactor="2"
                            />
                          )}
                        </div>
                      </div>
                      <div className="quick-right col-lg-12 col-xs-12">
                        <div className="summary entry-summary info-block clearfix ">
                          <div className="mainproduct-info ">
                            <div className="primary-info clearfix">
                              <div className="row">
                                <div className="product-title  col-xs-12">
                                  <b style={{ color: "#0171b6" }}>Sold By: </b>{" "}
                                  {product.supplier_logo ? (
                                    <SupplierLogo
                                      logo={product.supplier_logo}
                                      clickEvent={
                                        "/supplier/" + product.supplier_name
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <h1
                                    itemProp="name"
                                    className="product_title entry-title"
                                  >
                                    {title}
                                  </h1>
                                  {product.is_service == 0 ? (
                                    <div className="product-badges--tag">
                                      {product.availability === 1 ? (
                                        <React.Fragment>
                                          Availability:{" "}
                                          <span className="algolia-instock ">
                                            <span className="bullet">●</span> In
                                            stock
                                          </span>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          Availability:{" "}
                                          <span className="algolia-outofstock">
                                            <span className="bullet">●</span>{" "}
                                            Out of stock
                                          </span>
                                        </React.Fragment>
                                      )}
                                    </div>
                                  ) : (
                                    " "
                                  )}
                                  <div className="prod-extra in-block">
                                    <p className="reset-margin">
                                      <strong>Model: </strong>
                                      <span>{product.model_number}</span>
                                    </p>
                                  </div>
                                  <div className="prod-extra in-block">
                                    <p className="reset-margin">
                                      <strong>Brand: </strong>
                                      <span>{product.brand}</span>
                                    </p>
                                  </div>
                                  {product.flash_sales != null ? (
                                    <div className="flash-sale-product-view ">
                                      <span className="title">
                                        Flash Sales ends at
                                      </span>
                                      <br />
                                      <span className="timer">
                                        {product.flash_sales.end_time ? (
                                          <Countdown
                                            date={product.flash_sales.end_time}
                                          />
                                        ) : (
                                          <Countdown date={Date.now()} />
                                        )}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {product.free != null ? (
                                    <React.Fragment>
                                      <div className="alternative clearfix">
                                        <h5 className="blue sh-blue">Free</h5>
                                        <div className="alternative-slider clearfix">
                                          <div className="slide">
                                            <div className="sdg-ratio active">
                                              <Link
                                                to={
                                                  "/product-" +
                                                  product.free.name
                                                    .replace(/[#+. _/]/gi, "-")
                                                    .toLowerCase() +
                                                  "-" +
                                                  product.free.id
                                                }
                                              >
                                                <img
                                                  src={product.free.image}
                                                  alt={product.free.name}
                                                  data-toggle="tooltip"
                                                  data-placement="bottom"
                                                  data-container="body"
                                                  title={product.free.name}
                                                />
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="tooltip-name">
                                        {product.free.name}
                                        <br />
                                        {product.free.price}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                  {product.product_type === "Combo" ? (
                                    <React.Fragment>
                                      <div className="alternative clearfix">
                                        <h5 className="blue sh-blue">Combo</h5>
                                        <div className="alternative-slider clearfix">
                                          {product.combo_products.map(function (
                                            product,
                                            index
                                          ) {
                                            return (
                                              <div
                                                className="slide"
                                                key={index}
                                              >
                                                <div className="sdg-ratio active">
                                                  <Link
                                                    to={
                                                      "/product-" +
                                                      product.name
                                                        .replace(
                                                          /[#+. _/]/gi,
                                                          "-"
                                                        )
                                                        .toLowerCase() +
                                                      "-" +
                                                      product.product_id
                                                    }
                                                  >
                                                    <img
                                                      src={
                                                        product.featured_image
                                                      }
                                                      alt={product.name}
                                                      data-toggle="tooltip"
                                                      data-placement="bottom"
                                                      data-container="body"
                                                      title={product.name}
                                                    />
                                                  </Link>
                                                </div>
                                                <Link
                                                  to={
                                                    "/product-" +
                                                    product.name
                                                      .replace(
                                                        /[#+. _/]/gi,
                                                        "-"
                                                      )
                                                      .toLowerCase() +
                                                    "-" +
                                                    product.product_id
                                                  }
                                                >
                                                  <div>
                                                    {product.name}
                                                    <br />
                                                    <span className="currency">
                                                      NPR
                                                    </span>{" "}
                                                    {product.price}
                                                  </div>
                                                </Link>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="product-addcart  col-xs-12  ">
                                  <div className="addcart-action">
                                    <form
                                      className="cart"
                                      method="post"
                                      encType="multipart/form-data"
                                    >
                                      <div
                                        id="yith_wapo_groups_container"
                                        className="yith_wapo_groups_container"
                                      >
                                        <div
                                          id="ywapo_value_1"
                                          className="ywapo_group_container ywapo_group_container_radio form-row form-row-wide"
                                        >
                                          <h3>Quick Overview</h3>
                                          <div className="ywapo_input_container ywapo_input_container_radio">
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  product.short_description,
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pdp--price-wrp">
                            <div className="pdp-price-cart">
                              {product.discount_percent > 0 ? (
                                <div className="discount-holder">
                                  <div className="product-disc">
                                    <span className="onsale">
                                      SAVE {product.discount_percent}%
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {product.product_type === "Combo" ? (
                                <div className="discount-holder">
                                  <div className="product-disc">
                                    <span className="onsale">Bundle</span>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {product.new === 1 ? (
                                <div className="">
                                  <span className="product-disc new-product-div">
                                    New
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                              <div
                                itemProp="offers"
                                itemScope
                                itemType="http://schema.org/Offer"
                                className="product-price"
                              >
                                <meta itemProp="priceCurrency" content="AED " />

                                {product.discount_price ? (
                                  <>
                                    <div className="price no-marign">
                                      <span className="currency">MRP NPR</span>
                                      <meta
                                        itemProp="price"
                                        content={product.price}
                                      />
                                      <span
                                        className="total--sale-price"
                                        data-sale-price={product.price}
                                      >
                                        <NumberFormat
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          thousandsGroupStyle="lakh"
                                          value={product.price}
                                        />
                                      </span>
                                    </div>
                                    <div className="price no-marign">
                                      <span className="currency">
                                        BOOKING PRICE NPR
                                      </span>
                                      <meta
                                        itemProp="price"
                                        content={product.discount_price}
                                      />
                                      <span
                                        className="total--sale-price"
                                        data-sale-price={product.discount_price}
                                      >
                                        <NumberFormat
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          thousandsGroupStyle="lakh"
                                          value={product.discount_price}
                                        />
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="price no-marign">
                                      <span className="currency">
                                        BOOKING PRICE NPR
                                      </span>
                                      <meta
                                        itemProp="price"
                                        content={product.price}
                                      />
                                      <span
                                        className="total--sale-price"
                                        data-sale-price={product.price}
                                      >
                                        <NumberFormat
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          thousandsGroupStyle="lakh"
                                          value={product.price}
                                        />
                                      </span>
                                    </div>
                                  </>
                                )}

                                <div className="price no-marign">
                                  <span className="currency">
                                    BOOKING AMOUNT NPR
                                  </span>
                                  <meta
                                    itemProp="price"
                                    content={product.prepayment_value}
                                  />
                                  <span
                                    className="total--sale-price"
                                    data-sale-price={product.prepayment_value}
                                  >
                                    <NumberFormat
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      thousandsGroupStyle="lakh"
                                      value={product.prepayment_value}
                                    />
                                  </span>
                                </div>

                                <span className="vat-text">
                                  Inclusive of VAT
                                </span>
                                <br />
                                {product.discount_percent != null ? (
                                  <span className="algolia-instock ">
                                    Collect CG|Coins:{" "}
                                    <NumberFormat
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      thousandsGroupStyle="lakh"
                                      value={product.discount_price / 200}
                                    />{" "}
                                  </span>
                                ) : (
                                  <span className="algolia-instock ">
                                    Collect CG|Coins:{" "}
                                    <NumberFormat
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      thousandsGroupStyle="lakh"
                                      value={product.price / 200}
                                    />
                                  </span>
                                )}
                                <link
                                  itemProp="availability"
                                  href="https://schema.org/InStock"
                                />
                                <meta
                                  itemProp="itemCondition"
                                  content="http://schema.org/NewCondition"
                                />
                              </div>

                              <div className="add-to-cart-wrp clearfix">
                                <Link
                                  to={
                                    "/prebook-" +
                                    product.name
                                      .replace(/[#+. _/]/gi, "-")
                                      .toLowerCase() +
                                    "-" +
                                    product.id
                                  }
                                  title={product.name}
                                  className="add_to_cart_button ajax_add_to_cart button btn btn-secondary alt"
                                >
                                  <FontAwesomeIcon icon="paper-plane" /> {"  "}
                                  Book Now
                                </Link>
                              </div>

                              <div className="pdp-icons clearfix">
                                <div className="pdp-icon">
                                  <div className="pdp-icon-wrp">
                                    <i className="sh-shield"></i>
                                  </div>
                                  <span>100% Genuine Product</span>
                                </div>
                                <div className="pdp-icon">
                                  <div className="pdp-icon-wrp">
                                    <i className="sh-secure-pay"></i>
                                  </div>
                                  <span>Secure Payment</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pdp-footer clearfix"></div>
                      </div>
                    </div>
                  )}
                </div>
              </BrowserView>
              <MobileView>
                <div className="post-2845288 product type-product status-publish has-post-thumbnail product_brand-samsung product_cat-mobiles product_cat-mobiles_tablets product_tag-pre-order product_tag-samsung-20 first instock shipping-taxable purchasable product-type-simple">
                  <div className="product-detail clearfix">
                    <div className="quick-right col-lg-8 col-xs-12">
                      <div className="summary entry-summary info-block clearfix ">
                        <div className="mainproduct-info ">
                          <div className="primary-info clearfix">
                            <div className="row">
                              <div className="quick-left col-lg-4 col-xs-12">
                                <div className="quick-img clearfix">
                                  {product.images.length > 0 ? (
                                    <Slider {...mobileSettings}>
                                      <div>
                                        <img
                                          src={product.featured_image}
                                          style={{
                                            objectFit: "cover",
                                            width: "100%",
                                            maxHeight: "500px",
                                          }}
                                        />
                                      </div>
                                      {product.images.map(function (
                                        image,
                                        index
                                      ) {
                                        return (
                                          <div key={index}>
                                            <img
                                              src={image}
                                              style={{
                                                objectFit: "cover",
                                                width: "100%",
                                                maxHeight: "500px",
                                              }}
                                            />
                                          </div>
                                        );
                                      })}
                                    </Slider>
                                  ) : (
                                    <img
                                      src={product.featured_image}
                                      style={{
                                        objectFit: "cover",
                                        width: "100%",
                                        maxHeight: "500px",
                                      }}
                                    />
                                  )}
                                  {product.discount_percent > 0 ? (
                                    <div className="discount-holder">
                                      <div className="product-disc">
                                        <span className="onsale">
                                          SAVE {product.discount_percent}%
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div className="product-title  col-xs-12">
                                <b style={{ color: "#0171b6" }}>Sold By: </b>{" "}
                                {product.supplier_logo ? (
                                  <SupplierLogo
                                    logo={product.supplier_logo}
                                    clickEvent={
                                      "/supplier/" + product.supplier_name
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                                <h1
                                  itemProp="name"
                                  className="product_title entry-title"
                                >
                                  {title}
                                </h1>
                                {product.is_service == 0 ? (
                                  <div className="product-badges--tag">
                                    {product.availability === 1 ? (
                                      <React.Fragment>
                                        Availability:{" "}
                                        <span className="algolia-instock ">
                                          <span className="bullet">●</span> In
                                          stock
                                        </span>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        Availability:{" "}
                                        <span className="algolia-outofstock">
                                          <span className="bullet">●</span> Out
                                          of stock
                                        </span>
                                      </React.Fragment>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="prod-extra in-block">
                                  <p className="reset-margin">
                                    <strong>Model: </strong>
                                    <span>{product.model_number}</span>
                                  </p>
                                </div>
                                <div className="prod-extra in-block">
                                  <p className="reset-margin">
                                    <strong>Brand: </strong>
                                    <span>{product.brand}</span>
                                  </p>
                                </div>
                                {product.flash_sales != null ? (
                                  <div className="flash-sale-product-view ">
                                    <span className="title">
                                      Flash Sales ends at
                                    </span>
                                    <br />
                                    <span className="timer">
                                      {product.flash_sales.end_time ? (
                                        <Countdown
                                          date={product.flash_sales.end_time}
                                        />
                                      ) : (
                                        <Countdown date={Date.now()} />
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pdp--price-wrp">
                          <div className="pdp-price-cart">
                            {product.new === 1 ? (
                              <div className="">
                                <span className="product-disc new-product-div">
                                  New
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            <div
                              itemProp="offers"
                              itemScope
                              itemType="http://schema.org/Offer"
                              className="product-price"
                            >
                              {product.discount_percent > 0 ? (
                                <div className="cross-price">
                                  <span className="currency">NPR</span>
                                  <span className="strike">
                                    <NumberFormat
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      thousandsGroupStyle="lakh"
                                      value={product.price}
                                    />
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                              <meta itemProp="priceCurrency" content="AED " />
                              <div className="price no-marign">
                                <span className="currency">NPR</span>
                                <meta
                                  itemProp="price"
                                  content={
                                    product.discount_percent > 0
                                      ? product.discount_price
                                      : product.price
                                  }
                                />
                                <span
                                  className="total--sale-price"
                                  data-sale-price={
                                    product.discount_percent > 0
                                      ? product.discount_price
                                      : product.price
                                  }
                                >
                                  <NumberFormat
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    thousandsGroupStyle="lakh"
                                    value={
                                      product.discount_percent > 0
                                        ? product.discount_price
                                        : product.price
                                    }
                                  />
                                </span>
                              </div>
                              <span className="vat-text">Inclusive of VAT</span>
                              <br />
                              {product.discount_percent != null ? (
                                <span className="algolia-instock ">
                                  Collect CG|Coins:{" "}
                                  <NumberFormat
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    thousandsGroupStyle="lakh"
                                    value={product.discount_price / 200}
                                  />{" "}
                                </span>
                              ) : (
                                <span className="algolia-instock ">
                                  Collect CG|Coins:{" "}
                                  <NumberFormat
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    thousandsGroupStyle="lakh"
                                    value={product.price / 200}
                                  />
                                </span>
                              )}
                              <link
                                itemProp="availability"
                                href="https://schema.org/InStock"
                              />
                              <meta
                                itemProp="itemCondition"
                                content="http://schema.org/NewCondition"
                              />
                            </div>
                            {product.free != null ? (
                              <React.Fragment>
                                <div className="alternative clearfix">
                                  <div className="free-product-page col-xs-4">
                                    <Link
                                      to={
                                        "/product-" +
                                        product.free.name.replace(/\s/g, "-") +
                                        "-" +
                                        product.free.id
                                      }
                                    >
                                      <img
                                        src={product.free.image}
                                        alt={product.free.name}
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        data-container="body"
                                        title={product.free.name}
                                      />
                                    </Link>
                                  </div>
                                  <div className="col-xs-8">
                                    <div className="tooltip-name">
                                      Get free {product.free.name}
                                      <br />
                                      worth NPR{" "}
                                      <NumberFormat
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        thousandsGroupStyle="lakh"
                                        value={product.free.price}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                            <div className="add-to-cart-wrp clearfix">
                              {product.is_service == 0 ? (
                                <>
                                  <div
                                    className="col-xs-7"
                                    style={{ padding: "0px" }}
                                  >
                                    <button
                                      type="button"
                                      href="#storeConsultation"
                                      data-toggle="modal"
                                      data-target="#storeConsultation"
                                      className="add_to_cart_button button btn btn-secondary alt"
                                    >
                                      Apply For Store Consultation
                                    </button>
                                    <Modal
                                      id="storeConsultation"
                                      body={
                                        <StoreConsultation
                                          product={product}
                                          setMessage={props.value.setMessage}
                                        />
                                      }
                                      heading={"Apply For Store Consultation"}
                                    />
                                  </div>
                                  <div
                                    className="col-xs-5"
                                    style={{ padding: "0px 0px 0px 10px" }}
                                  >
                                    <button
                                      onClick={(evt) => addToCart(evt, product)}
                                      type="button"
                                      className="add_to_cart_button ajax_add_to_cart button btn btn-secondary alt"
                                      data-quantity="1"
                                      data-product_id="2845288"
                                      data-promo_id="846994"
                                    >
                                      <FontAwesomeIcon icon="shopping-cart" />{" "}
                                      {"  "}
                                      Add to Cart
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <div
                                  className="col-xs-5"
                                  style={{ padding: "0px 0px 0px 10px" }}
                                >
                                  <a
                                    href="#productEnquiry"
                                    data-toggle="modal"
                                    data-target="#productEnquiry"
                                    className="add_to_cart_button ajax_add_to_cart button btn btn-secondary alt"
                                    data-quantity="1"
                                    data-product_id="2845288"
                                    data-promo_id="846994"
                                  >
                                    <FontAwesomeIcon icon="paper-plane" />
                                    Enquiry
                                  </a>
                                  <Modal
                                    id="productEnquiry"
                                    body={
                                      <Enquiry
                                        product={product}
                                        setMessage={props.value.setMessage}
                                      />
                                    }
                                    heading={"Enquiry"}
                                  />
                                </div>
                              )}
                            </div>

                            <div className="pdp-icons clearfix">
                              <div className="pdp-icon">
                                <div className="pdp-icon-wrp">
                                  <i className="sh-shield"></i>
                                </div>
                                <span>100% Genuine Product</span>
                              </div>
                              <div className="pdp-icon">
                                <div className="pdp-icon-wrp">
                                  <i className="sh-secure-pay"></i>
                                </div>
                                <span>Secure Payment</span>
                              </div>

                              {product.coupons.length > 0 ? (
                                <>
                                  {product.coupons.map(function (
                                    coupon,
                                    index
                                  ) {
                                    return (
                                      <div
                                        className="pdp-icon"
                                        style={{
                                          background: "rgb(37 170 225)",
                                          borderBottom: "2px solid #ffffff",
                                          padding: "5px",
                                          marginTop: "15px",
                                        }}
                                      >
                                        <div
                                          className="col col-sm-6"
                                          style={{ color: "white" }}
                                        >
                                          <h4>{coupon.code}</h4>
                                          <p>{coupon.coupon_desc}</p>
                                        </div>
                                        <div className="col col-sm-6 tooltipcoupon">
                                          <button
                                            className="btn btn-copy-coupon"
                                            onClick={copyCoupon}
                                            onMouseOut={outFunc}
                                          >
                                            <span className="tooltiptext">
                                              {tooltipText}
                                            </span>{" "}
                                            Collect
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="product-summary clearfix product-cart-option">
                          <div className="row">
                            <div className="col-xs-3 product-option">
                              <a href="#none" onClick={handleAddToWishlist}>
                                <FontAwesomeIcon icon="heart" />{" "}
                                <span> Wishlist</span>
                              </a>
                            </div>
                            <div className="col-xs-3 product-option">
                              <a
                                href="#addToCompare"
                                onClick={(evt) => addToCompare(evt, product)}
                              >
                                <FontAwesomeIcon icon="retweet" />{" "}
                                <span> Compare</span>
                              </a>
                            </div>
                            {product.is_service == 0 ? (
                              <div className="col-xs-3 product-option">
                                <a
                                  href="#productEnquiry"
                                  data-toggle="modal"
                                  data-target="#productEnquiry"
                                  className="add_to_cart_button"
                                >
                                  <FontAwesomeIcon icon="paper-plane" />{" "}
                                  <span> Enquiry</span>
                                </a>
                                <Modal
                                  id="productEnquiry"
                                  body={
                                    <Enquiry
                                      product={product}
                                      setMessage={props.value.setMessage}
                                    />
                                  }
                                  heading={"Product Enquiry"}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="col-xs-3 product-option">
                              <a
                                href="#emi"
                                data-toggle="modal"
                                data-target="#myEMIModal"
                              >
                                <FontAwesomeIcon icon="calculator" />{" "}
                                <span> EMI</span>
                              </a>
                            </div>
                          </div>
                          <hr
                            style={{
                              borderTop: "3px solid #eee",
                              marginBottom: "0px",
                            }}
                          />
                        </div>
                        <div className="pdp-footer clearfix"></div>
                        <div className="row">
                          <div className="product-addcart  col-xs-12  ">
                            <div className="addcart-action">
                              <form
                                className="cart"
                                method="post"
                                encType="multipart/form-data"
                              >
                                <div
                                  id="yith_wapo_groups_container"
                                  className="yith_wapo_groups_container"
                                >
                                  <div
                                    id="ywapo_value_1"
                                    className="ywapo_group_container ywapo_group_container_radio form-row form-row-wide"
                                  >
                                    <h3>Quick Overview</h3>
                                    <div className="ywapo_input_container ywapo_input_container_radio">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: product.short_description,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MobileView>

              {product.prepayment == 0 ? (
                <PeopleAlosBuy products={related_products} />
              ) : (
                ""
              )}

              <div className="product-scroller">
                <div className="product-container">
                  <ul className="nav tabs">
                    <li className="tab prd-desc">
                      <a href="#product-tab1" className="active">
                        Description
                      </a>
                    </li>
                    <li className="tab prd-Specs">
                      <a href="#product-tab2">Product Specs</a>
                    </li>
                    <li className="tab review-tab">
                      <a href="#product-tab4">Reviews</a>
                    </li>
                  </ul>
                  <div
                    className="product-tab"
                    style={{ display: "block" }}
                    id="product-tab1"
                  >
                    <div className="specs-wrp d ">
                      {product.youtube_video_id ? (
                        <>
                          <br />
                          <iframe
                            width="100%"
                            height="500"
                            src={
                              "https://www.youtube.com/embed/" +
                              product.youtube_video_id
                            }
                          ></iframe>

                          <br />
                          <br />
                        </>
                      ) : (
                        ""
                      )}

                      <div id="flix-minisite">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product.long_description,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="product-tab"
                    style={{ display: "none" }}
                    id="product-tab2"
                  >
                    {product.product_specifications ? (
                      <section className="specs-wrp">
                        <div className="detailspecbox">
                          <table
                            width="100%"
                            border="0"
                            cellSpacing="2"
                            cellPadding="0"
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <table
                                    width="100%"
                                    border="0"
                                    cellSpacing="0"
                                    cellPadding="0"
                                    className="product-spec"
                                    itemProp="description"
                                  >
                                    <tbody>
                                      {product.product_specifications.map(
                                        function (
                                          product_specifications,
                                          index
                                        ) {
                                          return (
                                            <tr key={index}>
                                              <td className="specs-heading">
                                                {
                                                  product_specifications
                                                    .product_feature.name
                                                }
                                              </td>
                                              <td itemProp="brand">
                                                {
                                                  product_specifications.feature_value
                                                }
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </section>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="product-tab review-container"
                    id="product-tab4"
                    style={{ display: "none" }}
                  >
                    <Review
                      product={product}
                      setMessage={props.value.setMessage}
                    />
                  </div>
                </div>
              </div>

              {product.prepayment == 0 ? (
                <Featured products={featured_products} />
              ) : (
                ""
              )}
            </section>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default withRouter(WithContext(ViewProduct));
