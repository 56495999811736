import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import storage from "../../utils/storage";
const HomePopUp = (props) => {
  const node = useRef();
  const popUpImage = props.imageUrl;
  const popUpLink = props.imageLink;
  const [active, setActive] = useState(true);
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        closeModal();
      }
    };
    window.addEventListener("keydown", handleEsc);
    document.addEventListener("mousedown", handleClick);

    return () => {
      window.removeEventListener("keydown", handleEsc);
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    closeModal();
  };
  function closeModal() {
    storage.save("home_pop_up", new Date().toLocaleString());
    setActive(false);
  }
  return (
    <React.Fragment>
      {/* {props.active == "active" ? setActive(true) : ""} */}
      <div
        className={active ? "modal fade in show" : "modal fade"}
        id={props.id}
        tabIndex="-1"
        style={
          props.active
            ? { transform: "translate(0px, 0px)", display: "block" }
            : {}
        }
      >
        <div
          ref={node}
          className={
            props.dataSection === "large"
              ? "modal-dialog modal-dialog-large"
              : "modal-dialog"
          }
          style={{ overflowY: "initial !important" }}
        >
          <div
            className="modal-content"
            style={{
              // maxHeight: "calc(100vh - 150px)",
              // objectFit: "contain",
              // overflowY: "auto",
            }}
          >
            <div className="row">
              <div
                className="col-lg-12"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
              >
                {/* <h4 className="text-center">Download CG Digital App</h4> */}
                <a href={popUpLink}>
                  <img
                    src={popUpImage}
                    style={{
                      height: "auto",
                      width: "100%",

                      objectFit: "cover",
                    }}
                  />
                </a>
              </div>
            </div>
            {/* <div
              className="modal-header"
              style={{ paddingBottom: 0, borderBottom: 0 }}
            >
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div> */}
            <div className="row">
              <div className="col-lg-12">
                <h4 id="product-name">{props.title}</h4>
                <h5 style={{ marginBottom: "15px", padding: "0" }}>
                  {props.desc}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomePopUp;
