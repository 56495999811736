import React, { Component } from "react";

class NextSlider extends Component {
  state = {};
  render() {
    const { className, style, onClick } = this.props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          right: "30px"
        }}
        onClick={onClick}
      ></div>
    );
  }
}

export default NextSlider;
