import React, { useState, useEffect, useContext } from "react";

import { useHistory, Link } from "react-router-dom";
import { useInput } from "../hooks/inputHook";
import Data from "../utils/data";
import Api from "../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClipLoader from "react-spinners/ClipLoader";
import { Country_Code } from "./country";
import WithContext from "../Layouts/MainMessage/WithContext";
import storage from "../utils/storage";
import cartFunction from "../utils/cartFunction";
import wishlistFunction from "../utils/wishlistFunction";
import { LoyaltyContext } from "../Layouts/LoyaltyProvider/LoyaltyContextProvider";
import { CartContext } from "../Layouts/CartContextProvider";

const RegisterByMobile = (props) => {
  let history = useHistory();
  const { refreshLoyaltyApi } = useContext(LoyaltyContext);
  const { refreshApi } = useContext(CartContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageMobile, setErrorMessageMobile] = useState("");
  const [errorMessageSMS, setErrorMessageSMS] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [countryCode, setCountryCode] = useState("");

  const [sendSmsBtnText, setSendSmsBtnText] = useState("Send SMS Code");
  const { value: name, bind: bindName } = useInput(null);
  const { value: number, bind: bindNumber } = useInput(null);
  const { value: smsCode, bind: bindSmsCode } = useInput(null);
  const [returnedSMSCode, setReturnedSMSCode] = useState(null);

  const {
    value: password,
    bind: bindPassword,
    setValue: setPassword,
  } = useInput(null);

  function handleChange(e) {
    setCountryCode(e.target.value);
  }

  function getSMSCode() {
    setErrorMessage("");
    setErrorMessageMobile("");
    if (!number) {
      setErrorMessageMobile("Please enter mobile number.");
      return;
    }

    fetch(Data.API_BASE_URL + "get-sms-code", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        mobile: number,
      }),
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        console.log(response);
        setErrorMessage("");
        if (response.statusCode == 200) {
          setReturnedSMSCode(response.code);
          setSendSmsBtnText("Resend SMS Code");
        } else {
          setErrorMessage(response.message);
        }
      })
      .catch((error) => {
        // alert("error");
        // console.log(error.message);
      });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();

    setErrorMessage("");
    setErrorMessageMobile("");
    setErrorMessageSMS("");
    if (!name || !number || !password || !smsCode) {
      setErrorMessage("Please enter all the required fields.");
      return;
    }

    if (smsCode != returnedSMSCode) {
      setErrorMessageSMS("Please enter valid SMS code.");
      return;
    }

    fetch(Data.API_BASE_URL + "register/mobile", {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        fullName: name,
        mobileNumber: number,
        password: password,
        smsCode: smsCode,
        platform: "Web",
      }),
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        // console.log(response);
        console.log(response.data);
        // return;
        if (response.statusCode == 200) {
          storage.login(response.data);
          if (new URLSearchParams(props.location.search).get("cart")) {
            var productId = new URLSearchParams(props.location.search).get(
              "cart"
            );
            if (productId) {
              productId = productId.split("-");
              productId = productId[productId.length - 1];
              return addProductToCart(productId);
            }
          } else if (
            new URLSearchParams(props.location.search).get("wishlist")
          ) {
            var productId = new URLSearchParams(props.location.search).get(
              "cart"
            );
            if (productId) {
              productId = productId.split("-");
              productId = productId[productId.length - 1];
              return addProductToWishlist(productId);
            }
          }
          refreshApi();
          refreshLoyaltyApi();
          history.replace("/user/account/");
        } else {
          setErrorMessage(response.message);
          console.log(errorMessage);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  async function addProductToWishlist(productId) {
    let data = await wishlistFunction.addToWishlist(productId);
    if (data) {
      return history.push({
        pathname: "/user/wishlist",
      });
    }
  }
  async function addProductToCart(productId) {
    let data = await cartFunction.addToCart(productId);
    if (data) {
      return history.push({
        pathname: "/user/cart",
      });
    }
  }

  useEffect(() => {
    setIsLoading(false);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      ) : (
        <React.Fragment>
          <div className="container">
            <div id="primary" className="content-area">
              <main id="main" className="site-main" role="main">
                <div id="post-7">
                  <div className="entry-content">
                    <div className="woocommerce">
                      <div className="sh-reg">
                        <div className="container">
                          <div
                            className="reg-container loginpage-wrp"
                            style={{ width: "800px", textAlign: "left" }}
                          >
                            <form
                              onSubmit={handleSubmit}
                              className="box"
                              id="loginform"
                            >
                              <div className="login-text--wrp">
                                <h1
                                  className="headline"
                                  style={{ fontSize: "28px" }}
                                >
                                  Register
                                </h1>
                                {/* <h5>Create your very own account</h5> */}
                              </div>
                              <div className="login-button-container"></div>

                              <div className="row">
                                <div className="col col-lg-7">
                                  {/* <div className="register-input-item">
                                    <div className="row">
                                      <div className="col-lg-9">
                                        <label htmlFor="">Country *</label>
                                        <input
                                          type="text"
                                          value="Nepal"
                                          disabled
                                          className="input-text register-user-input-name register-user-input register-user-select"
                                        />
                                      </div>
                                      <div className="col-lg-3">
                                        <label htmlFor="">Code *</label>
                                        <input
                                          type="text"
                                          value="+977"
                                          disabled
                                          className="input-text register-user-input-name register-user-input register-user-select"
                                        />
                                      </div>
                                    </div>
                                  </div> */}

                                  <div className="register-input-item">
                                    <label htmlFor="">Full Name *</label>
                                    <input
                                      type="text"
                                      className="input-text register-user-input-name register-user-input"
                                      name="full_name"
                                      placeholder="Full Name"
                                      {...bindName}
                                    />
                                  </div>

                                  <div className="register-input-item">
                                    <label htmlFor="">Mobile Number *</label>
                                    <input
                                      type="number"
                                      className="input-text register-user-input-name register-user-input"
                                      name="number"
                                      placeholder="Enter Your Number"
                                      {...bindNumber}
                                    />
                                    {errorMessageMobile ? (
                                      <span style={{ color: "red" }}>
                                        <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                                        {errorMessageMobile}{" "}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div className="register-input-item">
                                    <div className="row">
                                      <div className="col col-lg-7">
                                        <label htmlFor="">SMS Code *</label>
                                        <input
                                          type="number"
                                          className="input-text register-user-input-name register-user-input"
                                          name="number"
                                          placeholder="Enter SMS Verification Code"
                                          {...bindSmsCode}
                                        />
                                        {errorMessageSMS ? (
                                          <span style={{ color: "red" }}>
                                            <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                                            {errorMessageSMS}{" "}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col col-lg-5">
                                        <br />
                                        <button
                                          type="button"
                                          className="button register-register-button btn btn-secondary"
                                          onClick={getSMSCode}
                                        >
                                          {sendSmsBtnText}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col col-lg-5">
                                  <div className="register-input-item">
                                    <label htmlFor="">Password *</label>
                                    <input
                                      className="input-text register-user-input-name register-user-input"
                                      type="password"
                                      name="password"
                                      placeholder="Password"
                                      autocomplete="new-password"
                                      {...bindPassword}
                                    />
                                  </div>

                                  <div className="register-register-button-container">
                                    <input
                                      type="hidden"
                                      id="_wpnonce"
                                      name="_wpnonce"
                                      value="8c77275735"
                                    />
                                    <input
                                      type="hidden"
                                      name="_wp_http_referer"
                                      value="/my-account/"
                                    />{" "}
                                    <div
                                      className="register-input-item"
                                      style={{
                                        color: "red",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {errorMessage ? (
                                        <React.Fragment>
                                          <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                                          {errorMessage}{" "}
                                        </React.Fragment>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <input
                                      type="submit"
                                      className="button register-register-button btn btn-secondary"
                                      name="login"
                                      value="REGISTER"
                                    />
                                  </div>
                                  <p
                                    style={{ color: "#bbb", fontSize: "11px" }}
                                  >
                                    By clicking “REGISTER”, I agree to CG
                                    Digital's
                                    <Link
                                      style={{
                                        color: "#bbb",
                                        fontSize: "11px",
                                      }}
                                      to={"/page-terms-and-conditions-3"}
                                    >
                                      <br /> Terms And Conditions
                                    </Link>
                                  </p>
                                  <p
                                    style={{ color: "#bbb", fontSize: "11px" }}
                                  >
                                    Or, register with
                                  </p>
                                  <Link
                                    style={{
                                      background: "transparent",
                                      color: "#0171b6",
                                      border: "1px solid #0171b6",
                                    }}
                                    className="button btn btn-secondary"
                                    to={"/user/register/email"}
                                  >
                                    Register with Email
                                  </Link>
                                  <div className="forgot-link">
                                    <Link to={"/customer/login/"}>
                                      Already Member?
                                    </Link>{" "}
                                    <Link to={"/customer/login/"}>Login</Link>
                                  </div>

                                  <input
                                    type="hidden"
                                    name="redirect"
                                    value=""
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>

          <div className="container search-loading hide">
            <div className="search-row ">
              <div className="row">
                <div className="col-md-3">
                  <div className="col s6">
                    <div
                      className="block-load img-block"
                      style={{
                        height: "130px",
                        marginBottom: "15px",
                        width: "100%",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                  <div className="col s6">
                    <div
                      className="block-load img-block"
                      style={{
                        height: "130px",
                        marginBottom: "15px",
                        width: "100%",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                  <div className="col s6">
                    <div
                      className="block-load img-block"
                      style={{
                        height: "130px",
                        marginBottom: "15px",
                        width: "100%",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                  <div className="col s6">
                    <div
                      className="block-load img-block"
                      style={{
                        height: "130px",
                        marginBottom: "15px",
                        width: "100%",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-4">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100px",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100px",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100px",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100px",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100px",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100px",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100px",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100px",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="block-load img-block"
                        style={{
                          height: "130px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100%",
                        }}
                      >
                        {" "}
                      </div>
                      <div
                        className="block-load"
                        style={{
                          height: "7px",
                          marginBottom: "15px",
                          width: "100px",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default WithContext(RegisterByMobile);
