import React, { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import storage from "../utils/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from "react-number-format";
import ClipLoader from "react-spinners/ClipLoader";
import { HOME, CHECKOUT, CHECKOUT_FLEXI_PAY } from "../route/routeURL";
import LoadingOverlay from "react-loading-overlay";
import cartFunction from "../utils/cartFunction";
import { CartContext } from "../Layouts/CartContextProvider";
import { useInput } from "../hooks/inputHook";

import { BrowserView, MobileView } from "react-device-detect";
import { LoyaltyContext } from "../Layouts/LoyaltyProvider/LoyaltyContextProvider";
import { MetaTags } from "react-meta-tags";
import SupplierLogo from "../page/Product/Logo/SupplierLogo";
const Cart = (props) => {
  let history = useHistory();
  const {
    cartItem,
    productDeleteApi,
    addProductCountApi,
    lessProductCountApi,
    refreshApi,
    cartData,
    setCartData,
  } = useContext(CartContext);
  const [carts, setCarts] = useState(null);
  const { loyaltyPoint } = useContext(LoyaltyContext);
  const [couponMessageClass, setCouponMessageClass] = useState("");
  const [loyaltyMessageClass, setLoyaltyMessageClass] = useState("");
  const [couponCode, setCouponCode] = useState(null);
  const [cartCoupons, setCartCoupons] = useState([]);
  const [couponMessage, setCouponMessage] = useState(null);
  const [loyaltyMessage, setLoyaltyMessage] = useState(null);
  const [redeemPoint, setRedeemPointCode] = useState(null);
  const [tds, setTds] = useState(null);
  const { value: coupon, bind: bindCoupon, setValue: setCoupon } = useInput("");
  const { value: redeem, bind: bindRedeem, setValue: setRedeem } = useInput("");
  const isChanging = React.useRef(false);
  const [isLoading, setIsloading] = useState(true);
  const [isActiveOverlay, setIsActiveOverlay] = useState(false);
  function fetchCarts(couponCode = null, redeemPoint = null) {
    getCartOnline(couponCode, redeemPoint);
    refreshApi(couponCode, redeemPoint);
  }

  async function fetchCouponCodes() {
    var coupons = await cartFunction.fetchCartCoupons();

    if (coupons) {
      setCartCoupons(coupons);
    }
  }

  async function productDelete(product) {
    setIsActiveOverlay(true);
    let data = await productDeleteApi(product, coupon, redeem);
    if (data) {
      getCartOnline(couponCode, redeemPoint);
      setIsActiveOverlay(false);
    }
  }
  async function getCartOnline(couponCode = null, redeemPoint = null) {
    var items = await cartFunction.cartOnline(couponCode, redeemPoint);

    if (items) {
      console.log(items);
      setCarts(items.data.cart_items);
      setCartData(items.data);
      if (redeemPoint != null && redeemPoint != "") {
        if (items.statusCode == 200) {
          setRedeemPointCode(redeemPoint);
          setLoyaltyMessageClass("success");
          setLoyaltyMessage(items.message);
          setTds(items.data.tds);
        } else {
          setLoyaltyMessageClass("danger");
          setLoyaltyMessage(items.message);
          setRedeemPointCode(null);
          setTds(null);
        }
      }

      if (couponCode != null && couponCode != "") {
        if (items.statusCode == 200) {
          setCouponMessageClass("success");
          setCouponMessage(items.message);
          setCouponCode(coupon);
        } else {
          setCouponMessageClass("danger");
          setCouponMessage(items.message);
        }
      }
      setIsActiveOverlay(false);
    } else {
      setCarts([]);
    }

    setIsloading(false);
  }
  async function addProductCount(product) {
    setIsActiveOverlay(true);
    let data = await addProductCountApi(product, coupon, redeem);
    if (data) {
      getCartOnline(couponCode, redeemPoint);
      setIsActiveOverlay(false);
    }
  }
  async function lessProductCount(product) {
    setIsActiveOverlay(true);
    let data = await lessProductCountApi(product, coupon, redeem);
    if (data) {
      getCartOnline(couponCode, redeemPoint);
      setIsActiveOverlay(false);
    }
  }
  function getTotal() {
    let sum = 0;
    if (cartItem != null) {
      cartItem.forEach((product) => {
        sum += product.product_discount_price * product.qty;
      });
      return sum;
    }
  }
  function applyCouponCode(evt) {
    setIsActiveOverlay(true);
    evt.preventDefault();
    fetchCarts(coupon, redeem);
  }
  function deleteCouponCode(evt) {
    setIsActiveOverlay(true);
    setCouponMessage(null);
    setCouponCode(null);
    evt.preventDefault();
    fetchCarts(null, redeem);
    setCoupon("");
  }
  function deleteLoyaltyPoint(evt) {
    setIsActiveOverlay(true);
    setLoyaltyMessage(null);
    setRedeemPointCode(null);
    evt.preventDefault();
    fetchCarts(coupon, null);
    setRedeem("");
  }
  function applyLoyaltyPoint(evt) {
    setIsActiveOverlay(true);
    evt.preventDefault();
    fetchCarts(coupon, redeem);
  }
  useEffect(() => {
    console.log(cartItem);
    console.log("asdsdfsdf");
    if (!storage.isLoggedIn()) {
      history.replace("/");
    } else {
      fetchCarts();
      fetchCouponCodes();
    }
    return () => {
      isChanging.current = true;
    };
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Cart</title>
      </MetaTags>
      {cartItem != null ? (
        cartItem.length > 0 ? (
          <div className="col-main">
            <div id="cart-items">
              <div className="cart">
                <div className="page-content page-order">
                  <div className="page-title">
                    <h2>Shopping Cart</h2>
                  </div>

                  <div className="order-detail-content">
                    <LoadingOverlay
                      active={isActiveOverlay}
                      spinner
                      text="Loading"
                    >
                      <BrowserView>
                        <div className="table-responsive">
                          <table className="table table-bordered cart_summary">
                            <thead>
                              <tr>
                                <th className="cart_product">Product</th>
                                <th>Description</th>
                                <th>Unit price</th>
                                <th>Qty</th>
                                <th>Total</th>
                                <th className="action">
                                  <i className="fa fa-trash-o"></i>
                                </th>
                              </tr>
                            </thead>
                            {cartItem.map(function (product, index) {
                              return (
                                <tbody key={index}>
                                  <tr>
                                    <td className="cart_product">
                                      <Link
                                        to={
                                          "/product-" +
                                          product.product_name
                                            .replace(/[#+. _/]/gi, "-")
                                            .toLowerCase() +
                                          "-" +
                                          product.product_id
                                        }
                                      >
                                        <img
                                          src={product.product_img}
                                          alt={product.product_name}
                                          style={{ height: "100px" }}
                                          className="center-img"
                                        />
                                      </Link>
                                    </td>
                                    <td className="cart_description">
                                      <div className="col-md-8">
                                        <p className="product-name">
                                          <Link
                                            to={
                                              "/product-" +
                                              product.product_name
                                                .replace(/[#+. _/]/gi, "-")
                                                .toLowerCase() +
                                              "-" +
                                              product.product_id
                                            }
                                          >
                                            {product.product_name}
                                          </Link>
                                        </p>
                                        <span
                                          className="brand"
                                          style={{ borderBottom: "none" }}
                                        >
                                          {product.brand}
                                          <p>{product.model_number} </p>
                                          <b>Sold By : </b>
                                          <SupplierLogo
                                            logo={product.supplier_logo}
                                            clickEvent={
                                              "/supplier/" +
                                              product.supplier_name
                                            }
                                          />{" "}
                                          <br />
                                          Collect CGCoin:{" "}
                                          <NumberFormat
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            thousandsGroupStyle="lakh"
                                            value={product.loyalty_point}
                                          />
                                          {product.coupon_discount != null ? (
                                            <React.Fragment>
                                              <span className="cart-app-rights">
                                                &nbsp;
                                                <FontAwesomeIcon icon="check" />
                                                {/* <i className="fa fa-check"></i> */}
                                                &nbsp;Coupon Code Applied
                                              </span>
                                              <br />
                                              {product.coupon_discount.option ==
                                              "P" ? (
                                                <span className="cart-app-rights">
                                                  {
                                                    product.coupon_discount
                                                      .coupon
                                                  }
                                                </span>
                                              ) : (
                                                <span className="cart-app-rights">
                                                  Discount NPR{" "}
                                                  {
                                                    product.coupon_discount
                                                      .coupon
                                                  }
                                                  <React.Fragment>
                                                    {/* {product.coupon_discount
                                                      .mrp_or_mop == "MRP"
                                                      ? "on MRP:" + product.price
                                                      : product.price ==
                                                        product.price
                                                      ? "on MRP: " + product.price
                                                      : "on MOP: " +
                                                        product.mop.value} */}
                                                  </React.Fragment>
                                                </span>
                                              )}
                                            </React.Fragment>
                                          ) : (
                                            <>
                                              {" "}
                                              {/* {couponCode ? <> <span style={{color:'red'}}>
                                                <FontAwesomeIcon icon="times" color="red" />
                            
                                                &nbsp;Coupon Code not valid for this product
                                              </span></> : ""} */}
                                            </>
                                          )}
                                        </span>
                                      </div>
                                      <div className="col-md-4">
                                        {product.free != null ? (
                                          <div
                                            style={{ height: "100%" }}
                                            className="free-wid pr-img-area large-image banner-inner"
                                          >
                                            <div
                                              className="icon-new-label new-right"
                                              style={{
                                                background: "rgb(249, 207, 79)",
                                                color: "#403b3b",
                                                width: "65px",
                                                height: "37px",
                                                fontSize: "10px",
                                              }}
                                            >
                                              Free
                                            </div>
                                            <a
                                              style={{
                                                pointerEvents: "none",
                                              }}
                                              className="cloud-zoom"
                                              id="zoom1"
                                              rel="useWrapper: false, adjustY:0, adjustX:20"
                                            >
                                              <img
                                                style={{
                                                  height: "48px",
                                                  width: "auto",
                                                  objectFit:
                                                    "contain !important",
                                                }}
                                                className="freewidimg loading"
                                                src={product.free.image}
                                                alt={product.free.name}
                                              />{" "}
                                            </a>
                                            <p
                                              style={{
                                                fontSize: "12px",
                                                margin: "0px",
                                              }}
                                            >
                                              {product.free.name}
                                            </p>
                                            <div
                                              className="price-box"
                                              style={{
                                                borderBottom: "none",
                                                marginTop: "0px",
                                              }}
                                            >
                                              <p
                                                className="special-price"
                                                style={{
                                                  marginRight: "18px",
                                                }}
                                              >
                                                {" "}
                                                <span className="price-label">
                                                  Regular Price:
                                                </span>{" "}
                                                <span
                                                  className="price"
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {" "}
                                                  {product.free.price}{" "}
                                                </span>{" "}
                                              </p>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </td>
                                    <td className="price">
                                      <p className="special-price">
                                        {" "}
                                        <span className="price-label">
                                          Special Price
                                        </span>{" "}
                                        <span className="price">
                                          {" "}
                                          <NumberFormat
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            thousandsGroupStyle="lakh"
                                            prefix={"NPR "}
                                            value={
                                              product.product_discount_price
                                            }
                                          />
                                        </span>{" "}
                                      </p>
                                      <p
                                        className={
                                          product.product_discount_price &&
                                          product.product_discount_price > 0
                                            ? "old-price"
                                            : "special-price"
                                        }
                                      >
                                        {" "}
                                        <span className="price-label">
                                          Regular Price:
                                        </span>{" "}
                                        <span className="price">
                                          {" "}
                                          <NumberFormat
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            thousandsGroupStyle="lakh"
                                            prefix={"NPR "}
                                            value={product.product_price}
                                          />
                                        </span>{" "}
                                      </p>
                                      <p>
                                        Shipping Charge:{" "}
                                        {product.delivery_charge}
                                      </p>
                                    </td>
                                    <td
                                      className="qty"
                                      style={{ textAlign: "center" }}
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          cursor: "pointer",
                                          padding: "12px 14px 9px 16px",
                                        }}
                                        className="btn btn-xs btn-success"
                                        onClick={() => addProductCount(product)}
                                      >
                                        <FontAwesomeIcon icon="plus" />
                                      </button>
                                      <input
                                        className="form-control input-sm"
                                        type="text"
                                        value={product.qty}
                                        id="qty"
                                        readOnly
                                      />
                                      <button
                                        type="button"
                                        style={{
                                          cursor: "pointer",
                                          padding: "12px 14px 9px 16px",
                                        }}
                                        className="btn btn-sm btn-danger"
                                        onClick={() =>
                                          lessProductCount(product)
                                        }
                                      >
                                        <FontAwesomeIcon icon="minus" />
                                      </button>
                                    </td>
                                    <td className="price">
                                      <p className="special-price">
                                        {" "}
                                        <span className="price-label">
                                          Special Price
                                        </span>{" "}
                                        <span className="price">
                                          {" "}
                                          <NumberFormat
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            thousandsGroupStyle="lakh"
                                            prefix={"NPR "}
                                            value={product.total}
                                          />
                                        </span>{" "}
                                      </p>
                                    </td>
                                    <td className="action">
                                      <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => productDelete(product)}
                                      >
                                        <FontAwesomeIcon icon="times" />
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            })}
                            <tfoot>
                              <tr>
                                <td colSpan="2" rowSpan="2"></td>
                                <td colSpan="3">Total products (tax incl.)</td>
                                <td colSpan="2">
                                  <NumberFormat
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    thousandsGroupStyle="lakh"
                                    prefix={"NPR "}
                                    value={cartData.cart_total}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td colSpan="3">Shipping Charge</td>
                                <td colSpan="2">
                                  {cartData.delivery_charge != null
                                    ? "NPR " + cartData.delivery_charge
                                    : "Free"}
                                </td>
                              </tr>

                              <tr>
                                <td colSpan="2" rowSpan="4">
                                  Total Loyalty point :{" "}
                                  {cartData.loyalty_point_total}
                                </td>
                                <td colSpan="3">Redeem Amount</td>
                                <td colSpan="2">
                                  NPR{" "}
                                  {redeemPoint != null ? "- " + redeemPoint : 0}
                                </td>
                              </tr>
                              {redeemPoint != null ? (
                                <tr>
                                  <td colSpan="3">TDS on Redeem Amount</td>
                                  <td colSpan="2">
                                    NPR {redeemPoint != null ? "+ " + tds : 0}
                                  </td>
                                </tr>
                              ) : (
                                " "
                              )}

                              <tr>
                                <td colSpan="3">
                                  <strong>Total</strong>
                                </td>
                                <td colSpan="2">
                                  <strong>
                                    <NumberFormat
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      thousandsGroupStyle="lakh"
                                      prefix={"NPR "}
                                      value={cartData.grand_total}
                                    />
                                  </strong>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </BrowserView>
                      <MobileView>
                        {cartItem.map(function (product, index) {
                          return (
                            <div key={index}>
                              <div
                                className="row"
                                style={{ margin: "10px 0px" }}
                              >
                                <div className="delete-cart-hover">
                                  <button
                                    className="btn-danger btn-mobile"
                                    onClick={() => productDelete(product)}
                                  >
                                    <FontAwesomeIcon icon="trash" />
                                  </button>
                                </div>

                                <div className="col-xs-3">
                                  <Link
                                    to={
                                      "/product-" +
                                      product.product_name
                                        .replace(/[#+. _/]/gi, "-")
                                        .toLowerCase() +
                                      "-" +
                                      product.product_id
                                    }
                                  >
                                    <img
                                      src={product.product_img}
                                      alt={product.product_name}
                                      style={{ height: "50px" }}
                                      className="center-img"
                                    />
                                  </Link>
                                  <div className="col-md-4">
                                    {product.free != null ? (
                                      <div
                                        style={{ height: "100%" }}
                                        className="free-wid pr-img-area large-image banner-inner"
                                      >
                                        <div
                                          className="icon-new-label new-right"
                                          style={{
                                            background: "rgb(249, 207, 79)",
                                            color: "#403b3b",
                                            width: "65px",
                                            height: "37px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          Free
                                        </div>
                                        <a
                                          style={{
                                            pointerEvents: "none",
                                          }}
                                          className="cloud-zoom"
                                          id="zoom1"
                                          rel="useWrapper: false, adjustY:0, adjustX:20"
                                        >
                                          <img
                                            style={{
                                              height: "48px",
                                              width: "auto",
                                              objectFit: "contain !important",
                                            }}
                                            className="freewidimg loading"
                                            src={product.free.image}
                                            alt={product.free.name}
                                          />{" "}
                                        </a>
                                        <p
                                          style={{
                                            fontSize: "12px",
                                            margin: "0px",
                                          }}
                                        >
                                          {product.free.name}
                                        </p>
                                        <div
                                          className="price-box"
                                          style={{
                                            borderBottom: "none",
                                            marginTop: "0px",
                                          }}
                                        >
                                          <p
                                            className="special-price"
                                            style={{
                                              marginRight: "18px",
                                            }}
                                          >
                                            {" "}
                                            <span className="price-label">
                                              Regular Price:
                                            </span>{" "}
                                            <span
                                              className="price"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {" "}
                                              {product.free.price}{" "}
                                            </span>{" "}
                                          </p>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-xs-9">
                                  <p className="product-name">
                                    <Link
                                      to={
                                        "/product-" +
                                        product.product_name
                                          .replace(/[#+. _/]/gi, "-")
                                          .toLowerCase() +
                                        "-" +
                                        product.product_id
                                      }
                                    >
                                      {product.product_name}
                                    </Link>
                                  </p>
                                  <span
                                    className="brand"
                                    style={{ borderBottom: "none" }}
                                  >
                                    {product.brand} <br />
                                    {product.model_number}
                                    <br />
                                    <b>Sold By : </b>
                                    <SupplierLogo
                                      logo={product.supplier_logo}
                                      clickEvent={
                                        "/supplier/" + product.supplier_name
                                      }
                                    />{" "}
                                    <br />
                                    Collect CG|Coins:{" "}
                                    <NumberFormat
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      thousandsGroupStyle="lakh"
                                      value={product.loyalty_point}
                                    />
                                    {product.coupon_discount != null ? (
                                      <React.Fragment>
                                        <span className="cart-app-rights">
                                          <FontAwesomeIcon icon="check" />
                                          {/* <i className="fa fa-check"></i> */}
                                          Coupon Code Applied
                                        </span>
                                        <br />
                                        {product.coupon_discount.option ==
                                        "P" ? (
                                          <span className="cart-app-rights">
                                            {product.coupon_discount.coupon}
                                          </span>
                                        ) : (
                                          <span className="cart-app-rights">
                                            Discount NPR{" "}
                                            {product.coupon_discount.value}
                                            <React.Fragment>
                                              {product.coupon_discount
                                                .mrp_or_mop == "MRP"
                                                ? "on MRP:" + product.mrp
                                                : product.mrp == product.price
                                                ? "on MRP: " + product.mrp
                                                : "on MOP: " +
                                                  product.mop.value}
                                            </React.Fragment>
                                          </span>
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                  <div className="price full-price">
                                    <p className="special-price">
                                      {" "}
                                      <span className="price-label">
                                        Special Price
                                      </span>{" "}
                                      <span className="price">
                                        {" "}
                                        <NumberFormat
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          thousandsGroupStyle="lakh"
                                          prefix={"NPR "}
                                          value={product.product_discount_price}
                                        />
                                      </span>{" "}
                                    </p>
                                    <p
                                      className={
                                        product.product_discount_price &&
                                        product.product_discount_price > 0
                                          ? "old-price"
                                          : "special-price"
                                      }
                                    >
                                      {" "}
                                      <span className="price-label">
                                        Regular Price:
                                      </span>{" "}
                                      <span className="price">
                                        {" "}
                                        <NumberFormat
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          thousandsGroupStyle="lakh"
                                          prefix={"NPR "}
                                          value={product.product_price}
                                        />
                                      </span>{" "}
                                    </p>
                                    <p>
                                      Shipping Charge: {product.delivery_charge}
                                    </p>
                                  </div>
                                  <div
                                    className="qty"
                                    style={{
                                      textAlign: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <span
                                      style={{
                                        padding: "5px",
                                      }}
                                    >
                                      Qty:
                                    </span>
                                    <button
                                      type="button"
                                      style={{
                                        cursor: "pointer",
                                        padding: "2px",
                                        height: "30px",
                                      }}
                                      // className="btn btn-xs"
                                      onClick={() => addProductCount(product)}
                                    >
                                      <FontAwesomeIcon icon="plus" />
                                    </button>
                                    <input
                                      className="form-control input-sm"
                                      style={{ width: "50px" }}
                                      type="text"
                                      value={product.qty}
                                      id="qty"
                                      readOnly
                                    />
                                    <button
                                      type="button"
                                      style={{
                                        cursor: "pointer",
                                        padding: "2px",
                                        height: "30px",
                                      }}
                                      onClick={() => lessProductCount(product)}
                                    >
                                      <FontAwesomeIcon icon="minus" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          );
                        })}
                        <div className="row" style={{ margin: "15px 0px" }}>
                          <div className="col-xs-8">
                            Total products (tax incl.)
                          </div>
                          <div className="col-xs-4">
                            <NumberFormat
                              displayType={"text"}
                              thousandSeparator={true}
                              thousandsGroupStyle="lakh"
                              prefix={"NPR "}
                              value={cartData.cart_total}
                            />
                          </div>
                        </div>

                        <div className="row " style={{ margin: "15px 0px" }}>
                          <div className="col-xs-8">Shipping Charge</div>
                          <div className="col-xs-4">
                            {cartData.delivery_charge != null
                              ? "NPR " + cartData.delivery_charge
                              : "Free"}
                          </div>
                        </div>
                        <div className="row" style={{ margin: "15px 0px" }}>
                          <div className="col-xs-8">Total Loyalty point </div>
                          <div className="col-xs-4">
                            {cartData.loyalty_point_total}
                          </div>
                        </div>
                        <div className="row" style={{ margin: "15px 0px" }}>
                          <div className="col-xs-8">Redeem Amount</div>
                          <div className="col-xs-4">
                            NPR {redeemPoint != null ? redeemPoint : 0}
                          </div>
                        </div>

                        <div className="row" style={{ margin: "15px 0px" }}>
                          <div className="col-xs-8">
                            <strong>Total</strong>
                          </div>
                          <div className="col-xs-4">
                            <strong>
                              <NumberFormat
                                displayType={"text"}
                                thousandSeparator={true}
                                thousandsGroupStyle="lakh"
                                prefix={"NPR "}
                                value={cartData.grand_total}
                              />
                            </strong>
                          </div>
                        </div>
                      </MobileView>
                    </LoadingOverlay>
                  </div>
                </div>
              </div>
            </div>

            <div id="cart-sub-totals">
              <div
                className="col-md-offset-9 col-md-3 cart-apply col-sm-12"
                style={{ paddingLeft: "0" }}
              >
                {couponMessage != null ? (
                  <p id="coupon-error-msg" className={couponMessageClass}>
                    {couponMessage}
                  </p>
                ) : (
                  ""
                )}
                {loyaltyMessage != null ? (
                  <p id="coupon-error-msg" className={loyaltyMessageClass}>
                    {loyaltyMessage}
                  </p>
                ) : (
                  ""
                )}
                <div
                  className="col-md-12"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <form
                    onSubmit={applyCouponCode}
                    id="coupon-form"
                    className="ng-pristine ng-valid"
                  >
                    <div
                      className="col-md-8 col-lg-6"
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      <input
                        type="text"
                        list="coupons"
                        className="form-control coup-code"
                        name="coupon"
                        placeholder="Coupon Code"
                        {...bindCoupon}
                        required
                      />
                      <datalist id="coupons">
                        {cartCoupons.map(function (coupon, index) {
                          return (
                            <option value={coupon.code} key={coupon.code}>
                              {coupon.code}
                            </option>
                          );
                        })}
                      </datalist>
                    </div>
                    <div className="col-md-4 col-lg-6 cart-apply-btn">
                      <button
                        className="btn btn-xs btn-secondary"
                        type="submit"
                      >
                        Apply
                      </button>
                    </div>
                  </form>
                  {couponMessage != null ? (
                    <p class="delete-coupon-code" onClick={deleteCouponCode}>
                      <FontAwesomeIcon icon="times" /> Remove Coupon Code
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* <div
                className="col-md-offset-9 col-md-3 cart-apply col-sm-12"
                style={{
                  paddingLeft: "0",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div
                  className="col-md-12"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <form
                    onSubmit={applyLoyaltyPoint}
                    id="coupon-form"
                    className="ng-pristine ng-valid"
                  >
                    <div
                      className="col-md-8 col-lg-6"
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      <input
                        className="form-control coup-code"
                        type="number"
                        placeholder="Redeem Point"
                        name="redeem"
                        step="0.5"
                        min="0"
                        {...bindRedeem}
                        required
                      />
                    </div>
                    <div className="col-md-4 col-lg-6 cart-apply-btn">
                      <button
                        className="btn btn-xs btn-secondary"
                        type="submit"
                      >
                        Redeem
                      </button>
                    </div>
                  </form>
                  {loyaltyMessage != null ? (
                    <p class="delete-coupon-code" onClick={deleteLoyaltyPoint}>
                      <FontAwesomeIcon icon="times" /> Remove Loyalty
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <b>
                  Available Redeem Value:{" "}
                  <NumberFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    thousandsGroupStyle="lakh"
                    value={loyaltyPoint}
                  />
                </b>
              </div> */}

              <br />
              <br />
              <br />

              <div className="cart_navigation">
                <Link className="continue-btn" to={HOME}>
                  <FontAwesomeIcon icon="angle-left" />
                  &nbsp; Continue shopping
                </Link>
                <Link
                  className="checkout-btn"
                  id="checkout-url"
                  to={{
                    pathname: CHECKOUT,
                    search:
                      couponCode != null || redeemPoint != null
                        ? new URLSearchParams({
                            coupon: couponCode,
                            redeem: redeemPoint,
                          }).toString()
                        : "",
                  }}
                >
                  <FontAwesomeIcon icon="check" /> Proceed to checkout
                </Link>
              </div>
              <div className="cart_navigation">
                <Link
                  className="checkout-btn"
                  id="checkout-url"
                  to={{
                    pathname: CHECKOUT_FLEXI_PAY,
                  }}
                >
                  <FontAwesomeIcon icon="check" /> Checkout VIA Flexi Pay
                </Link>
                <br />
                <br />
              </div>
              <div className="row">
                <div className="col-lg-12" style={{ marginBottom: "10px" }}>
                  <div style={{ float: "right", color: "red" }}>
                    {" "}
                    * Discount coupon & redeem point are not applicable on Flexi
                    Pay{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h3
            className="text-center"
            style={{ height: "200px", marginTop: "10%" }}
          >
            Your Cart is empty
            <br />
            <small>
              <Link to={HOME}>Shop Now</Link>
            </small>
          </h3>
        )
      ) : (
        <div
          className="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Cart;
