import React, { Component } from "react";
import { HOME } from "../route/routeURL";
import { Link } from "react-router-dom";
class Breadcrumb extends Component {
  state = {};
  render() {
    let { levelOne, levelTwo, levelThree } = this.props;
    return (
      <div className="breadcrumb-wrapper">
        <div className="breadcrumb-wrp">
          <div className="breadcrumb">
            <ul
              className="nav clearfix woocommerce-breadcrumb"
              itemProp="breadcrumb"
            >
              <li
                className="first"
                itemScope=""
                itemType="https://data-vocabulary.org/Breadcrumb"
              >
                <Link to={HOME} itemProp="url">
                  <span itemProp="title">Home</span>
                </Link>
                <i className="sh-icon sh-icon-next"></i>
              </li>
              {levelOne ? (
                <li
                  className="other"
                  itemScope=""
                  itemType="https://data-vocabulary.org/Breadcrumb"
                >
                  <Link to={levelOne.link} itemProp="url">
                    <span itemProp="title">{levelOne.name}</span>
                  </Link>
                  {levelTwo ? <i className="sh-icon sh-icon-next"></i> : ""}
                </li>
              ) : (
                ""
              )}
              {levelTwo ? (
                <li
                  className="other"
                  itemScope=""
                  itemType="https://data-vocabulary.org/Breadcrumb"
                >
                  <Link to={levelTwo.link} itemProp="url">
                    <span itemProp="title">{levelTwo.name}</span>
                  </Link>
                  {levelThree ? <i className="sh-icon sh-icon-next"></i> : ""}
                </li>
              ) : (
                ""
              )}
              {levelThree ? (
                <li
                  className="other"
                  itemScope=""
                  itemType="https://data-vocabulary.org/Breadcrumb"
                >
                  <Link to={levelThree.link} itemProp="url">
                    <span itemProp="title">{levelThree.name}</span>
                  </Link>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>{" "}
      </div>
    );
  }
}

export default Breadcrumb;
