import React from "react";

const Modal = (props) => {
  return (
    <div className="modal fade" id={props.id} tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {props.heading}
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </h4>
          </div>
          {props.body}
        </div>
      </div>
    </div>
  );
};

export default Modal;
