import React, { useEffect, useState } from "react";
import Data from "../../utils/data";
import api from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Cart from "../Cart";

import swal from "sweetalert";
import NumberFormat from "react-number-format";
const OrderHistory = (props) => {
  const [orders, setOrders] = useState([]);

  async function fetchOrders() {
    let url = new URL(Data.API_BASE_URL + "get-orders");
    await fetch(url, {
      method: "GET",
      headers: Data.API_HEADER,
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setOrders(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function cancelOrders(order, reason) {
    let url = new URL(Data.API_BASE_URL + "cancel-order/" + order.id);
    await fetch(url, {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        cancelled_reason: reason,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const CancelOrder = (evt, order) => {
    var cancelOrder = order;
    swal({
      title: "Are you sure?",
      text:
        "You Want to Cancel Order ID : " +
        order.id +
        ", Reason for cancellation",
      content: "input",
      //   icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((name) => {
      if (!name) throw null;
      var cancelled = cancelOrders(order, name);
      if (cancelled) {
        window.scrollTo(0, 0);
        props.setMessage(
          "Your order with Order ID " +
            order.id +
            " request for cancellation is forward to us."
        );
        fetchOrders();
      }
    });
  };
  function subTotal(cart_data) {
    let total = 0;
    cart_data.forEach(function (cart) {
      total = total + cart.subtotal;
    });
    return total;
  }
  useEffect(() => {
    fetchOrders();
  }, []);
  return (
    <React.Fragment>
      <h3 className="text-center">Order History</h3>
      <form className="ng-pristine ng-valid">
        <div className="table-responsive">
          <table className="table order-table table-striped">
            <tbody className="panel-group accordion-faq" id="faq-accordion">
              <tr>
                <th>
                  <center>
                    <b>Date</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Order ID</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Payment Method</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Order Status</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Payment Status</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Delivery Status</b>
                  </center>
                </th>
                <th>
                  <center>
                    <b>Action</b>
                  </center>
                </th>
              </tr>
              {orders.map(function (order, index) {
                return (
                  <React.Fragment key={index}>
                    <tr className="panel">
                      <td>
                        <center>{order.created_at}</center>
                      </td>
                      <td>
                        <center>{order.id}</center>
                      </td>
                      <td>
                        <center>{order.payment_method}</center>
                      </td>
                      <td>
                        <center>{order.order_status}</center>
                      </td>
                      <td>
                        <center>{order.payment_status}</center>
                      </td>
                      <td>
                        <center>{order.delivery_status}</center>
                      </td>
                      <td className="panel-heading" style={{ width: "1px" }}>
                        <center>
                          <button
                            //   href={"#question" + order.id}
                            type="button"
                            className="btn collapse-click"
                            data-toggle="collapse"
                            data-target={"#order" + order.id}
                          >
                            <span className="arrow-down">
                              <FontAwesomeIcon icon="plus" />
                            </span>{" "}
                            {/* <span className="arrow-up">
                            <FontAwesomeIcon icon="minus" />
                          </span> */}
                          </button>
                        </center>
                      </td>
                    </tr>
                    <tr
                      id={"order" + order.id}
                      className="panel-collapse collapse"
                    >
                      <td colSpan="7">
                        <div
                          className="table-responsive"
                          style={{ marginBottom: "30px" }}
                        >
                          {order.order_status == "Pending" &&
                          order.cancelled_order != "C" ? (
                            <p
                              className="cancel-order text-center"
                              style={{ color: "red" }}
                            >
                              If you want to cancel your order click
                              <a
                                onClick={(evt) => CancelOrder(evt, order)}
                                className="cancel-order"
                              >
                                here.
                              </a>
                            </p>
                          ) : (
                            ""
                          )}
                          {order.order_status == "Pending" &&
                          order.cancelled_order == "C" ? (
                            <p
                              className="cancel-order text-center"
                              style={{ color: "red" }}
                            >
                              You have made order cancellation request for this
                              order.
                            </p>
                          ) : (
                            ""
                          )}

                          {order.order_status == "Rejected" &&
                          order.cancelled_order == "C" ? (
                            <p
                              className="cancel-order text-center"
                              style={{ color: "red" }}
                            >
                              Order rejected on your order cancellation request.
                            </p>
                          ) : (
                            ""
                          )}

                          <table className="table table-condensed">
                            <thead>
                              <tr className="active">
                                <th className="order-popup-item">
                                  Description
                                </th>

                                {order.order_products.length > 0 ? (
                                  <>
                                    <th className="order-popup-qty">Status</th>
                                    <th className="order-popup-qty">Payment</th>
                                    <th className="order-popup-qty">
                                      Delivery
                                    </th>
                                  </>
                                ) : (
                                  ""
                                )}

                                <th className="order-popup-qty">Qty.</th>
                                <th className="order-popup-price">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order.order_products.length > 0 ? (
                                <>
                                  {order.order_products.map(function (
                                    cart,
                                    indexTwo
                                  ) {
                                    return (
                                      <tr key={indexTwo}>
                                        <td className="cart_description">
                                          <p className="product-name">
                                            <Link
                                              to={
                                                "/product-" +
                                                cart.name.replace(/\s/g, "-") +
                                                "-" +
                                                cart.product_id
                                              }
                                              target="_blank"
                                              title={cart.name}
                                            >
                                              {cart.name} {cart.model_number}{" "}
                                            </Link>
                                          </p>
                                          {cart.options ? (
                                            <React.Fragment>
                                              <p>
                                                <strong>
                                                  Free:
                                                  {
                                                    cart.options.free_product
                                                      .name
                                                  }
                                                  (
                                                  {
                                                    cart.options.free_product
                                                      .price
                                                  }
                                                  )
                                                </strong>
                                              </p>
                                            </React.Fragment>
                                          ) : (
                                            ""
                                          )}
                                          {cart.coupon_discount ? (
                                            <p>
                                              Coupon:
                                              <strong>
                                                {cart.coupon_discount.coupon}
                                              </strong>
                                              {"mrp_or_mop" in
                                              cart.coupon_discount
                                                ? cart.coupon_discount
                                                    .mrp_or_mop == "MRP"
                                                  ? "on MRP: " + cart.mrp
                                                  : cart.mrp == cart.price
                                                  ? "on MRP: " + cart.mrp
                                                  : "on MOP: " + cart.mop.value
                                                : ""}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </td>

                                        <td>
                                          <span>{cart.order_status}</span>
                                        </td>

                                        <td>
                                          <span>{cart.payment_status}</span>
                                        </td>

                                        <td>
                                          <span>{cart.delivery_status}</span>
                                        </td>

                                        <td className="">
                                          <span>{cart.qty}</span>
                                        </td>
                                        <td className="">
                                          <span>
                                            {order.currency + " "}
                                            <NumberFormat
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              thousandsGroupStyle="lakh"
                                              value={cart.subtotal}
                                            />
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  {order.cart_data.map(function (
                                    cart,
                                    indexTwo
                                  ) {
                                    return (
                                      <tr key={indexTwo}>
                                        <td className="cart_description">
                                          <p className="product-name">
                                            <Link
                                              to={
                                                "/product-" +
                                                cart.name.replace(/\s/g, "-") +
                                                "-" +
                                                cart.id
                                              }
                                              target="_blank"
                                              title={cart.name}
                                            >
                                              {cart.name} {cart.model_number}{" "}
                                            </Link>
                                          </p>
                                          {cart.options ? (
                                            <React.Fragment>
                                              {cart.options.count > 0 ? (
                                                <p>
                                                  <strong>
                                                    Free :
                                                    {
                                                      cart.options.free_product
                                                        .name
                                                    }
                                                    (NPR
                                                    {
                                                      cart.options.free_product
                                                        .price
                                                    }
                                                    )
                                                  </strong>
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </React.Fragment>
                                          ) : (
                                            ""
                                          )}
                                          {cart.coupon_discount != null ? (
                                            <p>
                                              Coupon:
                                              <strong>
                                                {cart.coupon_discount.coupon}
                                              </strong>
                                              {"mrp_or_mop" in
                                              cart.coupon_discount
                                                ? cart.coupon_discount
                                                    .mrp_or_mop == "MRP"
                                                  ? "on MRP: " + cart.mrp
                                                  : cart.mrp == cart.price
                                                  ? "on MRP: " + cart.mrp
                                                  : "on MOP: " + cart.mop.value
                                                : ""}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </td>

                                        <td className="">
                                          <span>{cart.qty}</span>
                                        </td>
                                        <td className="">
                                          <span>
                                            {order.currency + " "}
                                            <NumberFormat
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              thousandsGroupStyle="lakh"
                                              value={cart.subtotal}
                                            />
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              )}

                              <tr>
                                {order.order_products.length > 0 ? (
                                  <>
                                    <td></td> <td></td> <td></td>
                                  </>
                                ) : (
                                  ""
                                )}
                                <td></td>
                                <td>Total Products (tax incl.)</td>
                                <td>
                                  {order.currency + " "}
                                  <NumberFormat
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    thousandsGroupStyle="lakh"
                                    value={subTotal(order.cart_data)}
                                  />
                                </td>
                              </tr>
                              <tr>
                                {order.order_products.length > 0 ? (
                                  <>
                                    <td></td> <td></td> <td></td>
                                  </>
                                ) : (
                                  ""
                                )}
                                <td></td>
                                <td>Shipping Charge</td>
                                <td>
                                  {order.delivery_charge != null ? (
                                    <React.Fragment>
                                      {order.currency + " "}
                                      <NumberFormat
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        thousandsGroupStyle="lakh"
                                        value={order.delivery_charge}
                                      />
                                    </React.Fragment>
                                  ) : (
                                    "Free"
                                  )}{" "}
                                </td>
                              </tr>

                              {order.redeem === "Yes" ? (
                                <>
                                  <tr>
                                    {order.order_products.length > 0 ? (
                                      <>
                                        <td></td> <td></td> <td></td>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <td></td>
                                    <td>Redeem</td>
                                    <td>
                                      {order.currency + " "}
                                      <NumberFormat
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        thousandsGroupStyle="lakh"
                                        value={order.redeem_point}
                                      />
                                    </td>
                                  </tr>
                                  {order.tds !== null ? (
                                    <tr>
                                      {order.order_products.length > 0 ? (
                                        <>
                                          <td></td> <td></td> <td></td>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <td></td>
                                      <td>TDS on Redeem Point</td>
                                      <td>
                                        {order.currency + " "}
                                        <NumberFormat
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          thousandsGroupStyle="lakh"
                                          value={order.tds}
                                        />
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : null}

                              {order.cash_back !== null ? (
                                <tr>
                                  {order.order_products.length > 0 ? (
                                    <>
                                      <td></td> <td></td> <td></td>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <td></td>
                                  <td>Cash Back</td>
                                  <td>
                                    {order.currency + " "}
                                    <NumberFormat
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      thousandsGroupStyle="lakh"
                                      value={order.cash_back}
                                    />
                                  </td>
                                </tr>
                              ) : null}
                              <tr>
                                {order.order_products.length > 0 ? (
                                  <>
                                    <td></td> <td></td> <td></td>
                                  </>
                                ) : (
                                  ""
                                )}
                                <td></td>
                                <td>Total</td>
                                <td>
                                  {order.currency + " "}
                                  <NumberFormat
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    thousandsGroupStyle="lakh"
                                    value={order.total_amount}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </form>
    </React.Fragment>
  );
};
export default OrderHistory;
