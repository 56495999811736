import React, { useEffect, useState, useRef } from "react";
import Breadcrumb from "../../Layouts/Breadcrumb";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Data from "../../utils/data";
import api from "../../utils/api";
import { MetaTags } from "react-meta-tags";
import "./NewCustom.css";
import Slider from "react-slick";
import Countdown from "react-countdown";
import ReactDOM from "react-dom";
import OneBannerLayout from "../../Home/Layout/OneBannerLayout";
import TwoBannerLayout from "../../Home/Layout/TwoBannerLayout";
import ProductListLayout from "../../Home/Layout/ProductListLayout";
import SupplierListLayout from "../../Home/Layout/SupplierListLayout";
import BrandLayout from "../../Home/Layout/BrandLayout";
import CategoryListLayout from "../../Home/Layout/CategoryLayout";
import ProductGridLayout from "../../Home/Layout/ProductGridLayout";
import ThreeBannerLayout from "../../Home/Layout/ThreeBannerLayout";
import ScrollTextLayout from "../../Home/Layout/ScrollTextLayout";
import FlashSalelayout from "../../Home/Layout/FlashSaleLayout";
import CouponLayout from "../../Home/Layout/CouponLayout";
import CustomPageSkeleton from "../../page/Skeleton/CustomPageSkeleton";

const NewCustomPage = (props) => {
  const [dyanmicViews, setDynamicViews] = useState([]);

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const brandsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const [isLoading, setIsloading] = useState(true);
  const [page, setPage] = useState({});
  let history = useHistory();
  function fetchDynamicData() {
    setIsloading(true);
    fetch(Data.API_BASE_URL + "page/" + getPageData(), {
      method: "GET",
      headers: Data.API_HEADER,
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setPage(response.page);
        setDynamicViews(response.layouts);
        setIsloading(false);
      })
      .catch((error) => {
        history.push("/not-found");
        console.log(error);
      });
  }
  function getPageData() {
    let id = props.match.params["slug"];
    return id;
  }
  useEffect(() => {
    fetchDynamicData();
  }, [getPageData()]);
  return (
    <React.Fragment>
      <div className="" id="sr1">
        <div className="search-content">
          {isLoading ? (
            <div
              className="container"
              style={{ textAlign: "center", padding: "30px 0px" }}
            >
              <CustomPageSkeleton />
            </div>
          ) : (
            <div className="container">
              <Breadcrumb
                levelOne={{
                  name: page.title,
                  link: "/page-",
                }}
              />
              <MetaTags>
                <title>{page.meta_title}</title>
                <meta name="description" content={page.meta_description} />
                <meta name="keywords" content={page.meta_keyword} />
              </MetaTags>

              {dyanmicViews.map(function (view, index) {
                return (
                  <div key={index}>
                    {view.type == "One Banner" ? (
                      <OneBannerLayout banners={view.banner_list} />
                    ) : view.type == "Two Banner" ? (
                      <TwoBannerLayout banners={view.banner_list} />
                    ) : view.type == "Three Banner" ? (
                      <ThreeBannerLayout banners={view.banner_list} />
                    ) : view.type == "Product List" ? (
                      view.product_view == "Grid" ? (
                        <ProductGridLayout view={view} />
                      ) : (
                        <ProductListLayout view={view} />
                      )
                    ) : view.type == "Brand List" ? (
                      <BrandLayout view={view} />
                    ) : view.type == "Supplier List" ? (
                      <SupplierListLayout view={view} />
                    ) : view.type == "Category List" ? (
                      <CategoryListLayout view={view} />
                    ) : view.type == "Sub Category List" ? (
                      <CategoryListLayout view={view} />
                    ) : view.type == "Sub Category Type List" ? (
                      <CategoryListLayout view={view} />
                    ) : view.type == "Scroll Text" ? (
                      <ScrollTextLayout view={view} />
                    ) : view.type == "Flash Sales" ? (
                      <FlashSalelayout view={view} />
                    ) : (
                      <CouponLayout view={view} />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewCustomPage;
