import React, { useEffect, useState } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import {
  HOME,
  VIEW_PRODUCT,
  CATEGORY,
  SUB_CATEGORY,
  USER_LOGIN,
  // OLA_LOGIN,
  CUSTOMER_LOGIN,
  USER_REGISTER,
  USER_REGISTER_BY_MOBILE,
  USER_REGISTER_BY_EMAIL,
  CART,
  WISH_LIST,
  CHECKOUT,
  SUB_CATEGORY_TYPE,
  FLASH_SALES,
  CUSTOM_PAGE,
  NEW_CUSTOM_PAGE,
  FAQ,
  CATALOGUE,
  EXCHANGE,
  PREBOOKING,
  BUYNOW,
  CONTACT_US,
  SHOPEE,
  SEARCH_PRODUCT,
  COMPARE,
  BRAND,
  USER_ACCOUNT,
  USER_FORGET,
  ORDER_COMPLETE,
  BOOKING_COMPLETE,
  EVENT,
  NABIL_PAYMENT,
  NABIL_BOOKING_PAYMENT,
  ESEWA_PAYMENT,
  ESEWA_BOOKING_PAYMENT,
  CONNECT_IPS_PAYMENT,
  CONNECT_IPS_BOOKING_PAYMENT,
  IME_PAY_PAYMENT,
  EXCLUSIVE,
  NEW_ARRIVAL,
  USER_FORGET_RESET,
  CG_PAY_PAYMENT,
  KHALTI_PAYMENT,
  nBANK_PAYMENT,
  CHECKOUT_FLEXI_PAY,
  SERVICE_CENTER,
  SUPPLIER_PAGE,
  LAYOUT_VIEW_ALL,
} from "./route/routeURL";
import Home from "./Home/HomeV2";
import Header from "./Layouts/Header";
import Footer from "./Layouts/Footer";
import ViewProduct from "./page/Product/ViewProduct";
import Category from "./page/Product/Category";
import SubCategory from "./page/Product/SubCategory";
import Login from "./Account/Login";
// import OlaLogin from "../pages/login/login";
import CustomerLogin from "./Account/CustomerLogin";
import Register from "./Account/Register";
import RegisterByMobile from "./Account/RegisterByMobile";
import RegisterByEmail from "./Account/RegisterByEmail";
import NotFoundPage from "./other/NotFoundPage";
import Cart from "./Account/Cart";
import Checkout from "./Account/Checkout";
import storage from "./utils/storage";
import WishList from "./Account/WishList";
import SubCategoryType from "./page/Product/SubCategoryType";
import { askForPermissioToReceiveNotifications } from "./utils/push-notification";
import FlashSales from "./page/FlashSales/FlashSales";
import CustomPage from "./page/Other/CustomPage";
import NewCustomPage from "./page/Other/NewCustomPage";
import Faqs from "./page/Faq/Faq";
import Catalogues from "./page/Catalogue/Catalogue";
import Exchange from "./Exchange/Exchange";
import Prebook from "./Account/Prebook";
import Buynow from "./Account/Buynow";
import ContactUs from "./page/ContactUs/ContactUs";
import NabilCallBackUrl from "./Account/Payment/NabilCallBackUrl";
import NabilBookingCallBackUrl from "./Account/Payment/NabilBookingCallBackUrl";
import Shop from "./page/Shop/Shop";
import ServiceCenter from "./page/ServiceCenter/ServiceCenter";
import SearchProduct from "./page/Product/SearchProduct";
import Compare from "./page/Product/Compare";
import Brand from "./page/Product/Brand";
import CartContextProvider from "./Layouts/CartContextProvider";
import UserAccount from "./Account/UserAcount/UserAccount";
import ForgetPassword from "./Account/ForgetPassword";
import MainBody from "./Layouts/MainBody";
import LoyaltyContextProvider from "./Layouts/LoyaltyProvider/LoyaltyContextProvider";
import OrderComplete from "./other/OrderComplete";
import BookingComplete from "./other/BookingComplete";
import Event from "./page/Product/Event";
import LayoutViewAll from "./Home/Layout/LayoutViewAll";
import EsewaCallBackUrl from "./Account/Payment/EsewaCallBackUrl";
import EsewaBookingCallBackUrl from "./Account/Payment/EsewaBookingCallBackUrl";
import ConnectIpsCallBackUrl from "./Account/Payment/ConnectIpsCallBackUrl";
import ConnectIpsBookingCallBackUrl from "./Account/Payment/ConnectIpsBookingCallBackUrl";
import ImepayCallBackUrl from "./Account/Payment/ImepayCallBackUrl";
import ExclusivePage from "./page/Product/ExclusivePage";
import ArrivalPage from "./page/Product/ArrivalPage";
import ForgetPasswordReset from "./Account/ForgetPasswordReset";
import CgpayCallBackUrl from "./Account/Payment/CgpayCallBackUrl";
import KhaltiCallBackUrl from "./Account/Payment/KhaltiCallBackUrl";
import NBankCallBackUrl from "./Account/Payment/NBankCallBackUrl";
import useGoogleAnalytics from "./utils/googleA";
import { MobileView } from "react-device-detect";
import PopUp from "./Home/PopUp/PopUp";
import CheckoutFlexiPay from "./page/Product/Emi/CheckoutFlexiPay";
import { sendPageView } from "./utils/pixel";
import Supplier from "./page/Supplier/Supplier";

const Routers = () => {
  const [firstSegment, setFirstSegment] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utm_source = urlParams.get("utm_source");
    if (utm_source == "nBank") {
      localStorage.setItem("utm_source", "nBank");
    } else {
      // localStorage.setItem("utm_source", null);
    }

    sendPageView();
    // askForPermissioToReceiveNotifications();
    const url = window.location.pathname;
    const segment = url.split("/");
    setFirstSegment(segment[1]);
  }, []);
  useGoogleAnalytics();
  return (
    <React.Fragment>
      {firstSegment == "catalogues" ? (
        <Catalogues />
      ) : (
        <CartContextProvider>
          <LoyaltyContextProvider>
            <Header />
            <MainBody>
              <ScrollToTop>
                <Switch>
                  <Route exact path={HOME} component={Home} />
                  <Route
                    exact
                    path={LAYOUT_VIEW_ALL}
                    component={LayoutViewAll}
                  />
                  <Route exact path={SUPPLIER_PAGE} component={Supplier} />
                  <Route
                    exact
                    path={VIEW_PRODUCT}
                    component={() => <ViewProduct />}
                  />
                  <Route exact path={CATEGORY} component={Category} />
                  <Route exact path={BRAND} component={Brand} />
                  <Route
                    exact
                    path={SEARCH_PRODUCT}
                    component={SearchProduct}
                  />
                  <Route exact path={SUB_CATEGORY} component={SubCategory} />
                  <Route
                    exact
                    path={SUB_CATEGORY_TYPE}
                    component={SubCategoryType}
                  />
                  <Route exact path={EVENT} component={Event} />
                  <Route exact path={USER_LOGIN} component={Login} />
                  {/* <Route exact path={OLA_LOGIN} component={OlaLogin} /> */}
                  <Route
                    exact
                    path={CUSTOMER_LOGIN}
                    component={CustomerLogin}
                  />
                  <Route
                    exact
                    path={ORDER_COMPLETE}
                    component={OrderComplete}
                  />
                  <Route
                    exact
                    path={BOOKING_COMPLETE}
                    component={BookingComplete}
                  />
                  <Route exact path={USER_REGISTER} component={Register} />
                  <Route
                    exact
                    path={USER_REGISTER_BY_MOBILE}
                    component={RegisterByMobile}
                  />
                  <Route
                    exact
                    path={USER_REGISTER_BY_EMAIL}
                    component={RegisterByEmail}
                  />
                  <Route exact path={USER_ACCOUNT} component={UserAccount} />
                  <Route exact path={USER_FORGET} component={ForgetPassword} />
                  <Route
                    exact
                    path={USER_FORGET_RESET}
                    component={ForgetPasswordReset}
                  />
                  <Route
                    exact
                    path={NABIL_PAYMENT}
                    component={NabilCallBackUrl}
                  />
                  <Route
                    exact
                    path={NABIL_BOOKING_PAYMENT}
                    component={NabilBookingCallBackUrl}
                  />
                  <Route
                    exact
                    path={ESEWA_PAYMENT}
                    component={EsewaCallBackUrl}
                  />
                  <Route
                    exact
                    path={ESEWA_BOOKING_PAYMENT}
                    component={EsewaBookingCallBackUrl}
                  />
                  <Route
                    exact
                    path={CONNECT_IPS_PAYMENT}
                    component={ConnectIpsCallBackUrl}
                  />
                  <Route
                    exact
                    path={CONNECT_IPS_BOOKING_PAYMENT}
                    component={ConnectIpsBookingCallBackUrl}
                  />
                  <Route
                    exact
                    path={IME_PAY_PAYMENT}
                    component={ImepayCallBackUrl}
                  />
                  <Route
                    exact
                    path={CG_PAY_PAYMENT}
                    component={CgpayCallBackUrl}
                  />
                  <Route
                    exact
                    path={KHALTI_PAYMENT}
                    component={KhaltiCallBackUrl}
                  />
                  <Route
                    exact
                    path={nBANK_PAYMENT}
                    component={NBankCallBackUrl}
                  />
                  <Route exact path={CART} component={() => <Cart />} />
                  <Route exact path={FLASH_SALES} component={FlashSales} />
                  <Route exact path={EXCLUSIVE} component={ExclusivePage} />
                  <Route exact path={NEW_ARRIVAL} component={ArrivalPage} />
                  <Route exact path={WISH_LIST} component={WishList} />
                  <Route exact path={CHECKOUT} component={Checkout} />
                  <Route exact path={PREBOOKING} component={Prebook} />
                  <Route exact path={CATALOGUE} component={Catalogues} />
                  <Route exact path={EXCHANGE} component={Exchange} />
                  <Route exact path={BUYNOW} component={Buynow} />
                  <Route
                    exact
                    path={CHECKOUT_FLEXI_PAY}
                    component={CheckoutFlexiPay}
                  />
                  <Route exact path={CUSTOM_PAGE} component={CustomPage} />
                  <Route
                    exact
                    path={NEW_CUSTOM_PAGE}
                    component={NewCustomPage}
                  />
                  <Route exact path={FAQ} component={Faqs} />

                  <Route exact path={CONTACT_US} component={ContactUs} />
                  <Route exact path={SHOPEE} component={Shop} />
                  <Route exact path={COMPARE} component={Compare} />
                  <Route
                    exact
                    path={SERVICE_CENTER}
                    component={ServiceCenter}
                  />
                  <Route exact path="*" component={NotFoundPage} />
                </Switch>
              </ScrollToTop>
            </MainBody>
            {/* <MobileView>
                {storage.checkPopUp() ? (
                  <PopUp id="mobile-pop-up" heading={"Download App"} />
                ) : (
                  ""
                )}
              </MobileView> */}
            <Footer />
          </LoyaltyContextProvider>
        </CartContextProvider>
      )}
    </React.Fragment>
  );
};

export default withRouter(Routers);
