import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
//import App from './App';
import * as serviceWorker from "./serviceWorker";
import Main from "./components/Main";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { BrowserRouter } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { initializeFirebase } from "./components/utils/push-notification";



import {
  faArrowAltCircleLeft,
  faAngleRight,
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faExclamationTriangle,
  faTimes,
  faPlus,
  faMinus,
  faShoppingCart,
  faTrash,
  faHeart,
  faRetweet,
  faCalculator,
  faPaperPlane,
  faCheck,
  faHome,
  faInbox,
  faPhone,
  faEnvelope,
  faStar,
  faCaretDown,
  faBars,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowAltCircleLeft,
  faAngleRight,
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faExclamationTriangle,
  faTimes,
  faPlus,
  faMinus,
  faShoppingCart,
  faTrash,
  faHeart,
  faRetweet,
  faCalculator,
  faPaperPlane,
  faCheck,
  faHome,
  faInbox,
  faPhone,
  faEnvelope,
  faStar,
  faCaretDown,
  faBars,
  faSearch
);
initializeFirebase();



ReactDOM.render(
  <BrowserRouter>
    <Main/>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

