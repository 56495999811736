import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Slider from "react-slick";
import NextSlider from "../../Layouts/Silder/NextSlider";
import PrevSlider from "../../Layouts/Silder/PrevSlider";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
const BrandLayout = (props) => {
  var settings = {
    infinite: true,
    speed: 1500,
    slidesToShow: 5,
    slidesToScroll: 3,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    nextArrow: <NextSlider />,
    prevArrow: <PrevSlider />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  return (
    <div className="home-brand-list">
      <div className="container" align="center">
        <h3 className="title-box">
          <b>
            {props.view.title} <small>{props.view.sub_title}</small>
          </b>
        </h3>
        <div className="grid-wrapper" style={{ marginBottom: "25px" }}>
          <div className="row">
            <Slider {...settings}>
              {props.view.brand_list.map(function (brand, index) {
                return (
                  <div className="col-md-3 m-b-15" key={index}>
                    <Link to={"/" + brand.brand_url}>
                      <LazyLoadImage
                        style={{
                          height: "200px",
                          width: "300px",
                          objectFit: "contain",
                          backgroundColor: "white",
                        }}
                        className="img-responsive mycat lazy-hidden loaded"
                        src={brand.image}
                      />
                    </Link>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandLayout;
