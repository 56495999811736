import React, { useEffect, useContext } from "react";
import {
  USER_LOGIN,
  USER_REGISTER,
  WISH_LIST,
  USER_ACCOUNT,
} from "../route/routeURL";

import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import storage from "../utils/storage";
import { LoyaltyContext } from "./LoyaltyProvider/LoyaltyContextProvider";
import { CartContext } from "./CartContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from '@fortawesome/free-solid-svg-icons';

const UserLoginHeader = (props) => {
  let history = useHistory();
  const { loyaltyPoint, refreshLoyaltyApi } = useContext(LoyaltyContext);

  const { refreshApi } = useContext(CartContext);
  function logout(e) {
    e.preventDefault();
    storage.logout();
    console.log("logout");
    history.push("/");
    // history.push(props.location);
    refreshApi();
    refreshLoyaltyApi();
  }
  useEffect(() => {}, []);

  return (
    <div className="navbar-login">
      <div className="login-wrp">
        <div className="dropdown">
          <div
            className="dropdown-toggle"
            // data-toggle="dropdown"
            // aria-haspopup="true"
            // aria-expanded="false"
          >
            <Link to={storage.isLoggedIn() ? USER_ACCOUNT : USER_LOGIN}>
              <FontAwesomeIcon icon={faUser} style={{ transform:"scale(1.5)" }} />
            </Link>
          </div>
          <div className="dropdown-menu">
            <div className="sign-dropdown">
              <h5>Welcome to CG Digital</h5>
              {storage.isLoggedIn() ? (
                <React.Fragment>
                  <h5>
                    Hello {storage.getUserName()} <br />
                    Loyality Point :{" "}
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandsGroupStyle="lakh"
                      value={loyaltyPoint}
                    />
                  </h5>
                  <div className="login--dropdown-link">
                    <div className="drop-sign">
                      <Link className="btn-transparent" to={USER_ACCOUNT}>
                        Account
                      </Link>
                    </div>
                    <div className="drop-sign">
                      <Link className="btn-transparent" to={WISH_LIST}>
                        Wishlist
                      </Link>
                    </div>
                    <div className="drop-sign">
                      <a
                        className="btn-transparent"
                        href="logout"
                        onClick={logout}
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <p>Login or Create new account on CG Digital</p>
                  <div className="login--dropdown-link">
                    <div className="drop-sign">
                      <Link className="btn-transparent" to={USER_LOGIN}>
                        Login
                      </Link>
                    </div>
                    <div className="drop-sign">
                      <Link className="btn-transparent" to={USER_REGISTER}>
                        Register
                      </Link>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLoginHeader;
