import React, { useEffect, useState } from "react";
import Data from "../../utils/data";
import api from "../../utils/api";
import ClipLoader from "react-spinners/ClipLoader";
import "./Catalogue.css";

const Catalogues = (props) => {
  const [dyanmicBrand, setDynamicBrand] = useState([]);
  const [dyanmicCatalogues, setDynamicCatalogues] = useState([]);
  const [type, setType] = useState('');
  const [isLoading, setIsloading] = useState(true);

  function fetchDynamicData() {
    fetch(Data.API_BASE_URL + "catalogues/" + getBrandName(), {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setDynamicBrand(response.data.brand);
        setDynamicCatalogues(response.data.catalogues);
        setType(response.data.type);
        // console.log(response.data);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getBrandName() {
    const url = window.location.pathname;
    const segment = url.split("/");
    return segment[2];
  }

  useEffect(() => {
    fetchDynamicData();
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      ) : (
        <div id="wrapper">

        {type == 'single' ? (
            <>
            <div className="header">
           
           <h1 className="logo">
           <img
           src={dyanmicBrand.image}
           style={{ height: "auto", width: "200px" }}
           width="190"
           alt={dyanmicBrand.name}
         />
         </h1>
        
         </div>
         <br />
         <div className="header_kv">
           <h2>Product Catalogues</h2>
         </div>

         {dyanmicCatalogues.length > 0 ? (
           <div className="tab_content" style={{ paddingBottom: "100px" }}>
             <div className="section">
               <ul className="booklist">
                 {dyanmicCatalogues.map(function (catalogue, index) {
                   return (
                     <li key={index}>
                       <a href={catalogue.file_path} target="_blank">
                         <img
                           src={catalogue.featured_image}
                           alt={catalogue.title}
                         />
                       </a>
                       <p>{catalogue.title}</p>
                     </li>
                   );
                 })}
               </ul>
             </div>
           </div>
         ) : (
           ""
         )}
            </>
          ) : (
            <>
             {/* <br /><br />
             <div className="header_kv">
           <h2>Product Catalogues</h2>
         </div> */}
               {dyanmicCatalogues.map(function (brand, index) {
                   return (
                     <>
                      {brand.catalogues.length > 0 ? (
                    <>
                     <div className="header">
           
           <h1 className="logo">
           <img
           src={brand.image}
           style={{ height: "auto", width: "200px" }}
           width="190"
           alt={brand.name}
         />
         </h1>
        
         </div>
         <br />
         <div className="header_kv">
           <h2>Product Catalogues</h2>
         </div>

        
           <div className="tab_content" style={{ paddingBottom: "100px" }}>
             <div className="section">
               <ul className="booklist">
                 {brand.catalogues.map(function (catalogue, index) {
                   return (
                     <li key={index}>
                       <a href={catalogue.file_path} target="_blank">
                         <img
                           src={catalogue.featured_image}
                           alt={catalogue.title}
                         />
                       </a>
                       <p>{catalogue.title}</p>
                     </li>
                   );
                 })}
               </ul>
             </div>
           </div>
                    </>
         ) : (
           ""
         )}
                     </>
                   );
                 })}
            </>
           
          )}


          

          

          <div className="footer">
            <p>
              Features, specifications, and price(s) shown in these e-catalogues
              may change from time to time.
              <br />
              CG ELECTRONICS reserves the right to update these e-catalogues any
              time at its sole discretion without any prior notice.
            </p>
          </div>
          <div className="footer">
            <p>
              COPYRIGHT ⓒ 2022 RIGHTS RESERVED BY CG ELECTRONICS.
              <br />
              <br />
              This website is best viewed using Microsoft Internet Explorer 11
              or higher, and/or latest version of Google Chrome and Mozilla
              Firefox browsers.
              <br />
              <br />
              Registered Office Address: Chandragiri Municipality-10/11,
              Kathmandu, Phone:{" "}
              <span style={{ color: "#fafafa" }}>01-5108122</span>
            </p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Catalogues;
