import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import SliderProduct from "../../page/Product/SliderProduct/SliderProduct";
const ProductGridLayout = (props) => {
  return (
    <div className="container other-product product-list-grid">
      <div className="product-scroller">
        <div className="product-container">
          <h3 className="text-left">
            <Link to={"/"}>{props.view.title}</Link>{" "}
            <small>{props.view.sub_title}</small>
            <Link
              to={
                "/view-all/" +
                props.view.title.replace(/[#+. _/]/gi, "-").toLowerCase() +
                "-" +
                props.view.id
              }
              style={{ float: "right", marginRight: "20px" }}
            >
              View all
            </Link>
          </h3>
          <div
            className="product-tab row"
            id="attachment-1"
            style={{
              display: "block",
              marginLeft: "0px",
              marginRight: "0px",
            }}
          >
            {props.view.product_list.map(function (product, index) {
              return (
                <div
                  className="col-lg-2 col-sm-6 col-xs-6 col-md-3 grid-product-layout"
                  key={index}
                >
                  <SliderProduct product={product} key={index} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductGridLayout;
