import React from "react";
import ReactDOM from "react-dom";
import  { useState, useEffect } from "react";

const Test = () => {
  const [value, setValue] = useState('Hello World');
  const handleChange = (e) => setValue(e.target.value);
  return (
    <div>
      <input type="text" value={value} onChange={handleChange} />
      <p>{value}</p>
    </div>
  );
};

export default Test;