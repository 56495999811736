import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Layouts/Breadcrumb";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Data from "../../utils/data";
import api from "../../utils/api";
const ServiceCenter = (props) => {
  const [isLoading, setIsloading] = useState(true);
  const [stores, setStores] = useState([]);
  let history = useHistory();
  function getShopPage() {
    setIsloading(false);
  }
  function getPageData() {
    let id = props.match.params["type"];
    return id;
  }
  useEffect(() => {
    getShopPage();
  }, [getPageData()]);
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      ) : (
        <React.Fragment>
          <Breadcrumb
            levelOne={{
              name: "Service Center",
              link: "/service-centers/",
            }}
          />

          <div className="static-page white-well">
            <div class="product-overview-tab">
              <div class="container">
                <div class="row" style={{ paddingBottom: "15px" }}>
                  <div class="col-md-8">
                    <h3>Toll Free: 16600100211</h3>
                    <h3>LandLine: +977 01 5970211</h3>
                    <br />

                    <h4 style={{ color: "#777777" }}>
                      Your satisfaction is our prime concern. Keeping this in
                      mind, we render after sales support services all over the
                      country. If our are facing any problem with our products,
                      please feel free to contact your nearest service center.
                    </h4>
                  </div>
                  <div class="col-md-4">
                    <img src="http://demo.cgdigital.com.np/images/slider//66VMBm_1524806650-CGNYHEsidebanner.gif" />
                  </div>
                </div>

                <div
                  class="form-servicecenter"
                  style={{ paddingLeft: "8px", paddingBottom: "47px" }}
                >
                  <form
                    enctype="multipart/formData"
                    method="get"
                    action=""
                    class="ng-pristine ng-valid"
                  >
                    <div class="col-lg-4 col-md-4">
                      <select
                        class="form-control selectpicker"
                        id="shippingAddrStateInput"
                        required=""
                        name="state"
                      >
                        <option selected="selected" value="">
                          Select State
                        </option>
                        <option value="1">Province No. 1</option>
                        <option value="2">Province No. 2</option>
                        <option value="3">Province No. 3</option>
                        <option value="4">Province No. 4</option>
                        <option value="5">Province No. 5</option>
                        <option value="6">Province No. 6</option>
                        <option value="7">Province No. 7</option>
                      </select>
                    </div>
                    <div class="col-lg-4 col-md-4">
                      <select
                        id="shippingAddrDistrictInput"
                        class="form-control selectpicker"
                        required=""
                        name="district"
                      >
                        <option selected="selected" value="">
                          Select District
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-4 col-md-4">
                      <span class="input-group-btn">
                        <button
                          class="btn btn-search btn-primary"
                          type="submit"
                          id="searchBtn"
                        >
                          Search
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
                <div class="row">
                  <div class="col-xs-12">
                    <div class="product-tab-inner">
                      <div
                        id="productTabContent"
                        class="tab-content"
                        style={{ marginTop: "0" }}
                      >
                        <div
                          class="tab-pane fade active in"
                          id="description"
                          style={{ marginTop: "0" }}
                        >
                          <div class="row">
                            <div class="col-main col-sm-12 col-xs-12">
                              <div
                                class="shop-inner servicecen"
                                style={{ border: "none", padding: "0" }}
                              >
                                <div class="clearfix"></div>

                                <div class="page-title">
                                  <h2 style={{ marginBottom: "10px" }}>
                                    Home Appliances Service Center
                                  </h2>
                                </div>

                                <div class="row" id="cgcarecontainer">
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>Service Center</h3>
                                        </li>
                                        <li>bidur 1- Dhunge </li>
                                        <li>Nuwakot, Province No. 3, Nepal</li>
                                        <li>Contact Person: Rabi Adhikari</li>
                                        <li>Contact No.: 9841219242 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:rabi.adhikari4497@gmail.com">
                                            rabi.adhikari4497@gmail.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>Lg Service Center</h3>
                                        </li>
                                        <li>Lahan ward no 8 </li>
                                        <li>Siraha, Province No. 2, Nepal</li>
                                        <li>Contact Person: Manoj Shah</li>
                                        <li>Contact No.: 9842826209 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:shahmanoj698@gmail.com">
                                            shahmanoj698@gmail.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>Sai Radio And Electronics</h3>
                                        </li>
                                        <li>Phidim </li>
                                        <li>
                                          Panchthar, Province No. 1, Nepal
                                        </li>
                                        <li>Contact Person: Hari Bhandari</li>
                                        <li>
                                          Contact No.: 024-520241/9742601842{" "}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>CG Service Center</h3>
                                        </li>
                                        <li>Lahan ward no 7 </li>
                                        <li>Siraha, Province No. 2, Nepal</li>
                                        <li>Contact Person: Asharam Yadav</li>
                                        <li>Contact No.: 9804787104 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:asharamyadav61@gmail.com">
                                            asharamyadav61@gmail.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>Dantakali Refrigerator</h3>
                                        </li>
                                        <li>Itahari chowk ward no 6 </li>
                                        <li>Sunsari, Province No. 1, Nepal</li>
                                        <li>Contact Person: Meghraj</li>
                                        <li>Contact No.: 9842037300 </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>LG SERVICE CENTER</h3>
                                        </li>
                                        <li>GULMI WARD NO 1-TAMGHAS </li>
                                        <li>Gulmi, Province No. 5, Nepal</li>
                                        <li>Contact Person: SANOJ SHARMA</li>
                                        <li>Contact No.: 9857022522 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:sanojsharma24@gmail.com">
                                            sanojsharma24@gmail.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>CG Service Center</h3>
                                        </li>
                                        <li>Bagbazar </li>
                                        <li>
                                          Dadeldhura, Province No. 7, Nepal
                                        </li>
                                        <li>Contact Person: Babu Ram Tiwari</li>
                                        <li>Contact No.: 9857038209 </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>LG CG Service Center</h3>
                                        </li>
                                        <li>Gorahi, Dang </li>
                                        <li>Dang, Province No. 5, Nepal</li>
                                        <li>Contact Person: Pradeep Kc</li>
                                        <li>Contact No.: 9847881286 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:madip7564@gmail.com">
                                            madip7564@gmail.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>LG CG Service Center</h3>
                                        </li>
                                        <li>Gorahi, Dang </li>
                                        <li>Dang, Province No. 5, Nepal</li>
                                        <li>
                                          Contact Person: Ramendra Shrestha
                                        </li>
                                        <li>Contact No.: 9847871131 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:ramendrastha@gmail.com">
                                            ramendrastha@gmail.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>
                                            Jai MA Purnagiri Service Center
                                          </h3>
                                        </li>
                                        <li>Mahendra Nagar , Kanchanpur </li>
                                        <li>
                                          Kanchanpur, Province No. 7, Nepal
                                        </li>
                                        <li>
                                          Contact Person: Amar Singh Khatri
                                        </li>
                                        <li>Contact No.: 9848819332 </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>LG Service Center</h3>
                                        </li>
                                        <li>beach bazar ,Hile,Dhankuta </li>
                                        <li>Dhankuta, Province No. 1, Nepal</li>
                                        <li>Contact Person: MD Naseem</li>
                                        <li>Contact No.: 9842435191 </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>Sidhartha Electronics</h3>
                                        </li>
                                        <li>Bhutandevi, Hetauda </li>
                                        <li>
                                          Makwanpur, Province No. 3, Nepal
                                        </li>
                                        <li>Contact Person: Ram Kumar Lama</li>
                                        <li>Contact No.: 9845027220 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:ram_kumar9188@yahoo.com">
                                            ram_kumar9188@yahoo.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>LG CG Service Center</h3>
                                        </li>
                                        <li>Hariyon, Sarlahi </li>
                                        <li>Sarlahi, Province No. 2, Nepal</li>
                                        <li>Contact Person: Manturam</li>
                                        <li>Contact No.: 9844062087 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:manturam518@gmail.com">
                                            manturam518@gmail.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>CG Service Center</h3>
                                        </li>
                                        <li>Sarlahi, Lalbandi </li>
                                        <li>Sarlahi, Province No. 2, Nepal</li>
                                        <li>Contact Person: Siddhimoni</li>
                                        <li>Contact No.: 9844144185 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:daiuncle@gmail.com">
                                            daiuncle@gmail.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>LG Service Center</h3>
                                        </li>
                                        <li>Kabir Tole, Dharan 08 </li>
                                        <li>Sunsari, Province No. 1, Nepal</li>
                                        <li>Contact Person: Ram Chandra</li>
                                        <li>Contact No.: 9852026629 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:ramchandra26629@gmail.com">
                                            ramchandra26629@gmail.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>CG Service Center</h3>
                                        </li>
                                        <li>
                                          Birendranagar ward no 6, surkhet{" "}
                                        </li>
                                        <li>Surkhet, Province No. 6, Nepal</li>
                                        <li>Contact Person: Jagat</li>
                                        <li>
                                          Contact No.: 083-524399 / 9858050153{" "}
                                        </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:tdhakal88@gmail.com">
                                            tdhakal88@gmail.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>LG Service Center</h3>
                                        </li>
                                        <li>
                                          Urbashi Chowk ward no 4, Birtamod{" "}
                                        </li>
                                        <li>Jhapa, Province No. 1, Nepal</li>
                                        <li>Contact Person: Niraj Thakur</li>
                                        <li>Contact No.: 023 541178 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:birtamodsvc@chaudharygroup.com">
                                            birtamodsvc@chaudharygroup.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>LG Service Center</h3>
                                        </li>
                                        <li>
                                          Bhanu Chowk ward no 1, Janakpur{" "}
                                        </li>
                                        <li>Dhanusa, Province No. 2, Nepal</li>
                                        <li>Contact Person: Chanda Shah</li>
                                        <li>Contact No.: 041 530692 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:janakpursvc@chaudharygroup.com">
                                            janakpursvc@chaudharygroup.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>LG Service Center</h3>
                                        </li>
                                        <li>Golpark ward no 5, Butwal </li>
                                        <li>
                                          Rupandehi, Province No. 5, Nepal
                                        </li>
                                        <li>Contact Person: Rita Pokhrel</li>
                                        <li>Contact No.: 071 540528 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:kamal.paneru@chaudharygroup.com">
                                            kamal.paneru@chaudharygroup.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>LG Service Center</h3>
                                        </li>
                                        <li>
                                          Atithi Sadan Road ward no. 7,
                                          Biratnagar{" "}
                                        </li>
                                        <li>Morang, Province No. 1, Nepal</li>
                                        <li>Contact Person: Anisha Shrestha</li>
                                        <li>Contact No.: 021 526733 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:madan.shrestha@chaudharygroup.com">
                                            madan.shrestha@chaudharygroup.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>LG Service Center</h3>
                                        </li>
                                        <li>
                                          Bhirkutipath ward no.2, Narayanghat{" "}
                                        </li>
                                        <li>Chitawan, Province No. 3, Nepal</li>
                                        <li>Contact Person: Asha Bhattarai</li>
                                        <li>Contact No.: 056 531744 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:rajan.magar@chaudharygroup.com">
                                            rajan.magar@chaudharygroup.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>LG Service Center</h3>
                                        </li>
                                        <li>Newroad ward no.2, Nepalgunj </li>
                                        <li>Banke, Province No. 5, Nepal</li>
                                        <li>Contact Person: Tej Kumari</li>
                                        <li>Contact No.: 081 526515 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:nepalgunjsvc@chaudharygroup.com">
                                            nepalgunjsvc@chaudharygroup.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>LG Service Center</h3>
                                        </li>
                                        <li>Bayabihadi ward no.2 Dhangadi </li>
                                        <li>Kailali, Province No. 7, Nepal</li>
                                        <li>Contact Person: Lok Raj Bhatta</li>
                                        <li>Contact No.: 091 526735 </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:lok.bhatta@chaudharygroup.com">
                                            lok.bhatta@chaudharygroup.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="cg-careWrap">
                                      <ul>
                                        <li>
                                          <h3>K.C.AUDIO VIDEO BAGLUNG</h3>
                                        </li>
                                        <li>Galkot </li>
                                        <li>Baglung, Province No. 4, Nepal</li>
                                        <li>
                                          Contact Person: Mr.Pream Raj Paudel
                                        </li>
                                        <li>
                                          Contact No.: 068-412060, 9848362933{" "}
                                        </li>

                                        <li>
                                          Email id:
                                          <a href="mailto:prem_pdl05@yahoo.com">
                                            prem_pdl05@yahoo.com
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="tab-pane fade"
                          id="specifications"
                          style={{ marginTop: "0" }}
                        >
                          <div class="row">
                            <div class="col-main col-sm-12 col-xs-12">
                              <div
                                class="shop-inner servicecen"
                                style={{ border: "none", padding: "0" }}
                              >
                                <div class="clearfix"></div>

                                <div class="page-title">
                                  <h2 style={{ marginBottom: "10px" }}>
                                    Mobile Service Center
                                  </h2>
                                </div>

                                <div class="row" id="cgcarecontainer"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ServiceCenter;
