import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { HOME } from "../route/routeURL";
class NotFoundPage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <h3 className="text-center">
            <FontAwesomeIcon
              icon="exclamation-triangle"
              style={{ fontSize: "70px", color: "orange" }}
            />
            <br />
            <br />
            PAGE NOT FOUND
          </h3>
          <div className="text-center">
            <Link to={HOME}>Home</Link>
          </div>
          <br />
          <br />
        </div>
      </React.Fragment>
    );
  }
}

export default NotFoundPage;
