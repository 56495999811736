import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import "./priceDetails.css";
import LoadingOverlay from "react-loading-overlay";
import Data from '../utils/data';
import api from "../utils/api";

const PriceDetails = (props) => {
  const history = useHistory();

  const [isActiveOverlay, setIsActiveOverlay] = useState(false);

  const colorObject = JSON.parse(localStorage.getItem("currentColorObject"));
  const modalData = JSON.parse(localStorage.getItem("selectedModalData")).data
    .catalog_pricing_details[0];

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [province, setProvince] = useState("");
    const [area, setArea] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [note, setNote] = useState("");
    const [isLoading, setIsloading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [product, setProduct] = useState("");



  function handleClick(){
    if(paymentMethod == 'Card Payment'){
      window.location.href = props.nabilCartPaymentUrl;
    }
  }


    useEffect(() => {
      if(!props.data){
        history.goBack();
      }else{
        setProduct(props.data.product);
        setFullName(props.data.fullName);
        setEmail(props.data.email);
        setPhone(props.data.phone);
        setAddress(props.data.address);
        setNote(props.data.note);
        setProvince(props.data.province);
        setCity(props.data.city);
        setArea(props.data.area);
        setPaymentMethod(props.data.payment_method);
        setIsloading(false);
      }      
    });


  return (

    <>
    {isLoading == false ? (
<>
    <div className="payment-page">
      <div className="payment-title">
        <div>Total payable</div>
        <div>{modalData.price_breakup_response.currency+' '+modalData.price_breakup_response.reserve_amount}</div>
      </div>
      <div className="product-list">
        <div className="product-item" onClick={() => (window.location.href = "https://ola-ev-ui.olaelectric.com/select-variant?c=np&v=s1")}>
          <img
            src="https://d34kmefuuy0be0.cloudfront.net/ev_assets/Scooter_logo_6b25b0644b.svg"
            alt="scooter logo"
            className="logo"
          />
          <div className="body">
            <div>
              <span>{modalData.price_breakup_response.product_name}</span>
              <img
                src="https://d34kmefuuy0be0.cloudfront.net/ev_assets/info_11d4f0e329.svg"
                alt="info icon"
              />
            </div>
            <div className="color">{colorObject.option_value}</div>
          </div>
          <div className="right-arrow">
            <img
              src="https://d34kmefuuy0be0.cloudfront.net/ev_assets/right_arrow_df19910e4d.svg"
              alt="right arrow"
            />
          </div>
        </div>
      </div>
      <div className="customer-details" onClick={() => history.push("/shippingAddress?source=ola&product="+product)}>
        <div className="product-item">
          <img
            src="https://d34kmefuuy0be0.cloudfront.net/ev_assets/gray_box_ba935bb3f2.svg"
            alt="scooter logo"
            className="logo"
          />
          <div className="body">
            <div>
              <span>Customer details</span>
            </div>
            <div className="color">{fullName}</div>
            {/* <div className="color">{email}</div>
            <div className="color">{phone}</div> */}
            <div className="address color">
              {address} <br />
              {area}, {city}
              <br />{province}, Nepal
            </div>
          </div>
          <div className="right-arrow">
            <img
              src="https://d34kmefuuy0be0.cloudfront.net/ev_assets/right_arrow_df19910e4d.svg"
              alt="right arrow"
            />
          </div>
        </div>
      </div>

     

      

      
    </div>

    {paymentMethod == 'Card Payment' ? (
        <>
         <button className="pay-button" onClick={() => handleClick()}>Pay with {paymentMethod}</button>
        </>
      ) : ''}

      {paymentMethod == 'eSewa' && props.esewaPaymentData ? (
        <>

          <form className="" action={props.esewaPaymentData.url} method={props.esewaPaymentData.method}>
              <input type="hidden" name="tAmt" value={props.esewaPaymentData.data.tAmt} />
              <input type="hidden" name="amt" value={props.esewaPaymentData.data.amt} />
              <input type="hidden" name="txAmt" value={props.esewaPaymentData.data.txAmt} />
              <input type="hidden" name="psc" value={props.esewaPaymentData.data.psc} />
              <input type="hidden" name="pdc" value={props.esewaPaymentData.data.pdc} />
              <input type="hidden" name="scd" value={props.esewaPaymentData.data.scd} />
              <input type="hidden" name="pid" value={props.esewaPaymentData.data.pid} />
              <input type="hidden" name="su" value={props.esewaPaymentData.data.su} />
              <input type="hidden" name="fu" value={props.esewaPaymentData.data.fu} />
              <input value="Pay With eSewa" type="submit" className="pay-button" />
            </form>

        </>
      ) : ''}

{paymentMethod == 'Connect IPS' && props.connectIpsPaymentData ? (
  <>

          <form action={props.connectIpsPaymentData.URL} method={props.connectIpsPaymentData.METHOD}>
                <input type="hidden" name="MERCHANTID" id="MERCHANTID" value={props.connectIpsPaymentData.MERCHANTID} />
                <input type="hidden" name="APPID" id="APPID" value={props.connectIpsPaymentData.APPID} />
                <input type="hidden" name="APPNAME" id="APPNAME" value={props.connectIpsPaymentData.APPNAME} />
                <input type="hidden" name="TXNID" id="TXNID" value={props.connectIpsPaymentData.TXNID} />
                <input type="hidden" name="TXNDATE" id="TXNDATE" value={props.connectIpsPaymentData.TXNDATE} />
                <input type="hidden" name="TXNCRNCY" id="TXNCRNCY" value={props.connectIpsPaymentData.TXNCRNCY} />
                <input type="hidden" name="TXNAMT" id="TXNAMT" value={props.connectIpsPaymentData.TXNAMT} />
                <input type="hidden" name="REFERENCEID" id="REFERENCEID" value={props.connectIpsPaymentData.REFERENCEID} />
                <input type="hidden" name="REMARKS" id="REMARKS" value={props.connectIpsPaymentData.REMARKS} />
                <input type="hidden" name="PARTICULARS" id="PARTICULARS" value={props.connectIpsPaymentData.PARTICULARS} />
                <input type="hidden" name="TOKEN" id="TOKEN" value={props.connectIpsPaymentData.TOKEN} />
                <input value="Pay With Connect IPS" type="submit" className="pay-button" />
            </form>

  </>
) : ''}
</>

    ) : ''}
    </>
  );
};

export default PriceDetails;
