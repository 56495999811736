import React, { useState, useEffect } from "react";
import { useInput } from "../hooks/inputHook";
import { useHistory, Link } from "react-router-dom";
import Data from "../utils/data";
import Api from "../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClipLoader from "react-spinners/ClipLoader";
import { USER_REGISTER, USER_LOGIN } from "../route/routeURL";
import WithContext from "../Layouts/MainMessage/WithContext";
const ForgetPasswordReset = (props) => {
  let history = useHistory();

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { value: password, bind: bindPassword } = useInput("");
  const { value: confirmPassword, bind: bindConfirmPassword } = useInput("");
  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Confirm password nad password doesnot match.");
    } else {
      let token = new URLSearchParams(props.location.search).get("token");
      let url = new URL(Data.API_BASE_URL + "forgot-password-reset");
      url.search = new URLSearchParams({
        token: token,
      }).toString();
      fetch(url, {
        method: "POST",
        headers: Data.API_HEADER,
        body: JSON.stringify({
          password: password,
        }),
      })
        .then(Api.handleResponseAndError)
        .then((response) => {
          console.log(response);
          if (response.statusCode == 200) {
            props.value.setMessage(response.message);
            history.replace(USER_LOGIN);
          } else {
            setErrorMessage(response.message);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  function getToken() {
    let token = new URLSearchParams(props.location.search).get("token");
    if (token === null) {
      history.replace("/not-found-page");
    }
    let url = new URL(Data.API_BASE_URL + "forgot-password-reset");
    url.search = new URLSearchParams({
      token: token,
    }).toString();
    fetch(url, {
      method: "GET",
      headers: Data.API_HEADER,
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        if (response.statusCode == 200) {
        } else {
          history.replace("/not-found-page");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
  useEffect(() => {
    getToken();
    setIsLoading(false);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      ) : (
        <React.Fragment>
          <div class="reg-container loginpage-wrp">
            <form class="lost_reset_password" onSubmit={handleSubmit}>
              <h1 class="headline">Reset Password</h1>
              <p>Please Enter a New Password</p>

              <p class=" form-row register-input-item">
                <input
                  class="input-text register-user-input-name register-user-input"
                  type="password"
                  name="password"
                  placeholder="Enter New Password"
                  {...bindPassword}
                />
              </p>

              <p class=" form-row register-input-item">
                <input
                  class="input-text register-user-input-name register-user-input"
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  {...bindConfirmPassword}
                />
              </p>

              <div class="clear"></div>

              <p class="form-row">
                <input
                  type="submit"
                  class=" button btn btn-secondary"
                  value="Reset Password"
                />
              </p>
              <div
                className="register-input-item"
                style={{
                  color: "red",
                  fontSize: "13px",
                }}
              >
                {errorMessage ? (
                  <React.Fragment>
                    <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                    {errorMessage}{" "}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default WithContext(ForgetPasswordReset);
