import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Data from "../../utils/data";
import Api from "../../utils/api";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useHistory, withRouter } from "react-router-dom";
import "react-input-range/lib/css/index.css";
import ListProduct from "./ListProduct/ListProduct";
import { EXCLUSIVE } from "../../route/routeURL";
import MetaTags from "react-meta-tags";
const ArrivalPage = (props) => {
  let history = useHistory();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  function fetchExclusiveData() {
    let params = fetchParams();
    let url = new URL(Data.API_BASE_URL + "new-arrival");
    url.search = new URLSearchParams(params).toString();
    fetch(url, {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        setExclusiveData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchParams(page = null) {
    let params = {};
    let sort = new URLSearchParams(props.location.search).get("sort");
    if (sort) {
      params.sort = sort;
    }

    return params;
  }
  function setExclusiveData(data) {
    console.log(data);
    setProducts(data["products"]);
    setIsloading(false);
  }
  function handleSortBy(e) {
    let params = fetchParams();
    params.sort = e.target.value;
    history.push({
      pathname: props.match.url,
      search: new URLSearchParams(params).toString(),
    });
  }

  useEffect(() => {
    setIsloading(true);
    setProducts([]);
    fetchExclusiveData();
  }, [props.match.params]);

  return (
    <React.Fragment>
      <div className="search-page" id="sr1">
        <div className="search-content">
          {isLoading ? (
            <div
              className="container"
              style={{ textAlign: "center", padding: "100px 0px" }}
            >
              <ClipLoader
                css={{ margin: "0 auto" }}
                size={150}
                //size={"150px"} this also works
                color={"#123abc"}
                loading={isLoading}
              />
            </div>
          ) : (
            <div className="container">
              <Breadcrumb
                levelOne={{
                  name: "CG Exclusive",
                  link: EXCLUSIVE,
                }}
              />
              <MetaTags>
                <title>New Arrival</title>
              </MetaTags>
              <div className="row search-row">
                <div className="search-results-full">
                  <div className="grid-view">
                    <div className="clearfix search-header">
                      <h3 style={{ marginLeft: "20px" }}>New Arrivals</h3>
                      <div className="search-text" id="stats">
                        Showing {products.length} Results
                      </div>
                      <div className="sort-wrp">
                        <span className="sort-by">Sort by</span>
                        <section className="sort-container">
                          <div className="selectric-wrapper selectric-sort">
                            <select
                              name="sort"
                              title="sort"
                              className="sort-by-select"
                              value={fetchParams().sort}
                              onChange={handleSortBy}
                            >
                              <option value=""> - </option>
                              <option value="new">New In</option>
                              <option value="low-to-high">Lowest Price</option>
                              <option value="high-to-low">Highest Price</option>
                            </select>

                            <div className="selectric-items" tabIndex="-1">
                              <div className="selectric-scroll">
                                <ul>
                                  <li data-index="0" className="selected">
                                    Most Relevant
                                  </li>
                                  <li data-index="1" className="">
                                    Lowest Price
                                  </li>
                                  <li data-index="2" className="last">
                                    Highest Price
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <input className="selectric-input" tabIndex="0" />
                          </div>
                        </section>
                      </div>
                      <div id="refinement-tags" className="clearfix"></div>
                    </div>
                    <div className="product-scroller js-product-list">
                      <div className="product-container">
                        <div
                          className="product-items row reset-margin"
                          id="hits"
                        >
                          {products.map(function (product, index) {
                            return (
                              <div
                                className="slide col-md-4 reset-padding fadeInUp"
                                key={index}
                              >
                                <ListProduct product={product} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ArrivalPage);
