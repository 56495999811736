import React, { useEffect, useState } from "react";
import BackButton from "../../components/backButton/backButton";
import LeftPanel from "../../components/left-panel/left-panel";
import NewAccount from "../../components/newAccount/newAccount";
import "../../globalStyles/globalStyles.css";
// import "../../ola.css";

const SignUp = () => {
  const [isMweb, setIsMweb] = useState(false);
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth <= 439) {
      setIsMweb(true);
    } else {
      setIsMweb(false);
    }
  };
  return (
      <div className="main-container">
          {isMweb ? (
              <div className="common-rightside">
                  <BackButton />
                  <NewAccount />
              </div>
          ) : (
              <>
                  <LeftPanel
                      selectedModalData={JSON.parse(
                          localStorage.getItem("selectedModalData")
                      )}
                      currentColor={JSON.parse(
                          localStorage.getItem("currentColorObject")
                      )}
                  />
                  <div className="common-rightside">
                      <BackButton />
                      <NewAccount />
                  </div>
              </>
          )}
      </div>
  );
};

export default SignUp;
