export const HOME = "/";
export const HOMEV2 = "/v2";
export const LAYOUT_VIEW_ALL = "/view-all/:layout_name";
export const SUPPLIER_PAGE = "/supplier/:supplier_name";
export const VIEW_PRODUCT = "/product-:product_name/";
export const BRAND = "/brand-:brand_name/";
export const CATEGORY = "/cat-:category_name/";
export const SEARCH_PRODUCT = "/search/:search_data/";
export const SUB_CATEGORY = "/sub-:sub_category_name/";
export const SUB_CATEGORY_TYPE = "/type-:sub_category_type_name/";
export const EVENT = "/event-:event_name/";
//old
export const USER_LOGIN = "/user/login/";
//ola
// export const OLA_LOGIN = "/ola/login/";
//new
export const CUSTOMER_LOGIN = "/customer/login/";
export const ORDER_COMPLETE = "/user/order-status";
export const BOOKING_COMPLETE = "/user/booking-status";
export const NABIL_PAYMENT = "/payment/nabil-card/";
export const NABIL_BOOKING_PAYMENT = "/payment/booking/nabil-card/";
export const ESEWA_PAYMENT = "/payment/esewa/";
export const ESEWA_BOOKING_PAYMENT = "/payment/booking/esewa/";
export const CONNECT_IPS_PAYMENT = "/payment/connect-ips/";
export const CONNECT_IPS_BOOKING_PAYMENT = "/payment/booking/connect-ips/";
export const IME_PAY_PAYMENT = "/payment/imepay/";
export const CG_PAY_PAYMENT = "/payment/cg-pay/";
export const KHALTI_PAYMENT = "/payment/khalti/";
export const nBANK_PAYMENT = "/payment/nBank/";
export const USER_REGISTER = "/user/register/";
export const USER_REGISTER_BY_MOBILE = "/user/register/mobile";
export const USER_REGISTER_BY_EMAIL = "/user/register/email";
export const USER_ACCOUNT = "/user/account/";
export const USER_FORGET = "/user/forget-password/";
export const USER_FORGET_RESET = "/user/forget-password/reset";
export const CART = "/user/cart/";
export const WISH_LIST = "/user/wishlist/";
export const FLASH_SALES = "/flash-sales/";
export const EXCLUSIVE = "/products/cg-exclusive/view-all";
export const NEW_ARRIVAL = "/products/new-arrivals/view-all";
export const CHECKOUT = "/user/checkout";
export const CUSTOM_PAGE = "/page-:data/";
export const CONTACT_US = "/page/contact-us/";
export const SHOPEE = "/stores/:type/";
export const COMPARE = "/products/compare/";
export const SERVICE_CENTER = "/service-centers/";
export const CHECKOUT_FLEXI_PAY = "/checkout-flexi-pay/";
export const FAQ = "/faqs/";
export const CATALOGUE = "/catalogues/:brand_name";
export const PREBOOKING = "/prebook-:product_name";
export const BUYNOW = "/buynow-:product_name";
export const EXCHANGE = "/exchange";
export const NEW_CUSTOM_PAGE = "/page/:slug/";
