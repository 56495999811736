import { Event } from "./event";

export default {
  handleResponseAndError(response) {
    Event.progress(false);
    if ((response.statusCode = "400")) {
      return response.json();
    }
    if (!response.ok) {
      return response.text().then((text) => {
        console.log(text);
        throw new Error(JSON.parse(text).message);
      });
    } else {
      return response.json();
    }
  },
  handleResponseAndErrorCGdigital(response) {
    Event.progress(false);
    if (!(response.statusCode == "200")) {
      return response.message;
    } else {
      return response.json();
    }
  },
};
