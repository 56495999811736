import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MetaTags from "react-meta-tags";
import Data from "../../utils/data";
import api from "../../utils/api";
import HomeSkeleton from "../Skeleton/HomeSkeleton";
import OneBannerLayout from "../../Home/Layout/OneBannerLayout";
import TwoBannerLayout from "../../Home/Layout/TwoBannerLayout";
import ThreeBannerLayout from "../../Home/Layout/ThreeBannerLayout";
import ProductGridLayout from "../../Home/Layout/ProductGridLayout";
import ProductListLayout from "../../Home/Layout/ProductListLayout";
import BrandLayout from "../../Home/Layout/BrandLayout";
import SupplierListLayout from "../../Home/Layout/SupplierListLayout";
import CategoryListLayout from "../../Home/Layout/CategoryLayout";
const Supplier = (props) => {
  const [dyanmicViews, setDynamicViews] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  function fetchDynamicData() {
    fetch(Data.API_BASE_URL + "layouts?supplier=" + getSupplierName(), {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setDynamicViews(response.data.layouts);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getSupplierName() {
    let id = props.match.params["supplier_name"];
    return id;
  }
  useEffect(() => {
    fetchDynamicData();
  }, []);
  return (
    <React.Fragment>
      <MetaTags>
        <title>CG Digital</title>
        <meta name="description" content={"cg-digital cg digital"} />
        <meta name="keywords" content={"'cg-digital"} />
      </MetaTags>
      {isLoading ? (
        <div
          className="container"
          style={{ textAlign: "center", padding: "30px 0px" }}
        >
          <HomeSkeleton />
        </div>
      ) : (
        <div className="entry-content">
          {dyanmicViews.map(function (view, index) {
            return (
              <div key={index}>
                {view.type == "One Banner" ? (
                  <OneBannerLayout banners={view.banner_list} />
                ) : view.type == "Two Banner" ? (
                  <TwoBannerLayout banners={view.banner_list} />
                ) : view.type == "Three Banner" ? (
                  <ThreeBannerLayout banners={view.banner_list} />
                ) : view.type == "Product List" ? (
                  view.product_view == "Grid" ? (
                    <ProductGridLayout view={view} />
                  ) : (
                    <ProductListLayout view={view} />
                  )
                ) : view.type == "Brand List" ? (
                  <BrandLayout view={view} />
                ) : view.type == "Supplier List" ? (
                  <SupplierListLayout view={view} />
                ) : view.type == "Category List" ? (
                  <CategoryListLayout view={view} />
                ) : (
                  " "
                )}
              </div>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

export default Supplier;
