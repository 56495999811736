import React from "react";
import { Link } from "react-router-dom";
const ClickBanner = (props) => {
  function checkLinkType() {
    let banner = props.banner;
    if (banner.link_type == "C") {
      return "/cat-" + banner.category_url;
    } else if (banner.link_type == "S") {
      return "/sub-" + banner.sub_category_url;
    } else if (banner.link_type == "T") {
      return "/type-" + banner.sub_category_type_url;
    } else if (banner.link_type == "B") {
      return "/brand-" + banner.brand_url;
    } else if (banner.link_type == "PR") {
      return banner.product ? "/product-" +
      banner.product.name.replace(/\s/g, "-") +
      "-" +
      banner.product.id : '/';
    } else if (banner.link_type == "P") {
      return (
        "/page-" + banner.page.title.replace(/\s/g, "-") + "-" + banner.page.id
      );
    } else if (banner.link_type == "E") {
      return "/event-" + banner.event.url_slug + "-" + banner.event.id;
    } else if (banner.link_type == "SP") {
      return "/supplier/" + banner.supplier.name;
    } else {
      return "";
    }
  }
  return props.banner.link_type == "L" ? (
    <a href={props.banner.link}>{props.children}</a>
  ) : (
    <Link to={checkLinkType()}>{props.children}</Link>
  );
};
export default ClickBanner;
