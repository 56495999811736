import React, { useEffect, useState } from "react";
import "./orderDetails.css";
// import axios from "axios";



const OrderDetails = ({allOrders}) => {

  useEffect(() => {
    // console.log(data.data);
    
  }, []);

  const handleClick = () => {
    // click logic
  };

  return (

    <div className="order-wrapper">
      <div className="order-title">Your orders</div>
      <div className="order-desc">Choose an order to view its details</div>
      <hr className="break-line" />
      {/* main container */}
      <div className="all-orders">
        {/* each order */}
        {allOrders.map((ele, i) => (
          <div className="each-order" onClick={() => handleClick()} key={i}>
            {/* details */}
            <div className="order-details">
              {/* image */}
              <div className="order-image">
                <img src={ele.variantImg} alt="orderImage" />
              </div>
              <div className="order-summary">
                <div className="order-variant">{ele.variant}</div>
                <div className="order-id">{`Booking ID : ${ele.orderId}`}</div>
                {/* <div className="order-id">Reserved on 15th Aug 2021</div> */}
                <div
                  className={
                    ele.orderStatus === "Accepted"
                      ? "order-id"
                      : "order-fail"
                  }
                >
                  {ele.orderStatus === "Accepted"
                    ? `Reserved on ${ele.orderDate}`
                    : `Payment ${ele.reasonForFail}`
                }
                </div>
              </div>
            </div>
            {/* arrows */}
            <div className="order-cta">
              <div>
                <img
                  src="https://d34kmefuuy0be0.cloudfront.net/ev_assets/right_arrow_c3b9836fcb.svg"
                  alt="click"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className="another-order"
        onClick={() => (window.location.href = "https://ola-ev-ui.olaelectric.com/np")}
      >
        <div className="another-title">Purchase another scooter</div>
        <div className="another-arrow">
          <img
            src="https://d34kmefuuy0be0.cloudfront.net/ev_assets/right_arrow_c3b9836fcb.svg"
            alt="click"
          />
        </div>
      </div>
    </div>

  );
};

export default OrderDetails;
