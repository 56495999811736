import React, { useState } from "react";
import MessageContext from "./MainMessage/MessageContext";
const MainBody = (props) => {
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function viewMessage() {
    setTimeout(() => setMessage(""), 5000);
  }

  function viewErrorMessage() {
    setTimeout(() => setErrorMessage(""), 5000);
  }
  return (
    <main id="content" className="site-content main-content" role="main">
      <div className="searcharea-overlay hidden"></div>
      <div className="loaderProgress toploader hidden"></div>
      <div className="sharaf-wrp" id="spjax">
        <div className="container">
          <div className="woocommerce"></div>
        </div>
        <div className="home-content">
          <div className="container">
            {message != "" ? (
              <div role="alert" class="fade alert alert-success show mt-2">
                {message}
                {viewMessage()}
              </div>
            ) : (
              ""
            )}
            {errorMessage != "" ? (
              <div role="alert" class="fade alert alert-danger show mt-2">
                {errorMessage}
                {viewErrorMessage()}
              </div>
            ) : (
              ""
            )}
            <MessageContext.Provider
              value={{
                message: message,
                setMessage: setMessage,
                setErrorMessage: setErrorMessage,
              }}
            >
              {React.Children.map(props.children, (child) => {
                return React.cloneElement(child, { ...props });
              })}
            </MessageContext.Provider>
          </div>
        </div>
      </div>
    </main>
  );
};
export default MainBody;
