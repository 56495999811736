import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Layouts/Breadcrumb";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Data from "../../utils/data";
import api from "../../utils/api";
import "./Shop.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from '@fortawesome/free-solid-svg-icons';
const Shop = (props) => {
  const [isLoading, setIsloading] = useState(true);
  const [stores, setStores] = useState([]);
  let history = useHistory();
  function getShopPage() {
    setIsloading(true);
    fetch(Data.API_BASE_URL + "get-stores", {
      method: "GET",
      headers: Data.API_HEADER,
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        if (getPageData() === "cg-digital") {
          setStores(response.data.cg_digital);
        } else if (getPageData() === "lg-shoppe") {
          setStores(response.data.lg_shoppe);
        } else {
          history.push("/not-found");
        }
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getPageData() {
    let id = props.match.params["type"];
    return id;
  }
  useEffect(() => {
    getShopPage();
  }, [getPageData()]);
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      ) : (
          <React.Fragment>
            <Breadcrumb
              levelOne={{
                name: getPageData(),
                link: "/stores/" + getPageData(),
              }}
            />
            
                <div className="shops-container">
                {stores.map(function (store, index) {
                  return (
                    <div
                      className="shop-wrapping"
                      style={{
                        background: "#fff",
                      }}
                      key={index}
                    >
                        
                      <div className="store-ko-naam">
                          <span className="store-ko-agadi-ko-title">Store Name:</span>
                          <span className="store-ko-agadi-ko-descriptions-haru">{store.name}</span>
                      </div>
                      <div className="store-ko-photo">
                        <img src={store.profile_image} />
                      </div>
                      <div className="store-ko-info">
                            <div className="store-ko-info-container">
                                <span className="store-ko-agadi-ko-title">Branch:</span>
                                <span className="store-ko-agadi-ko-desc">{store.branch}</span>
                            </div>
                            <div className="store-ko-info-container">
                                <span className="store-ko-agadi-ko-title">Location:</span>
                                <span className="store-ko-agadi-ko-desc">{store.location}</span>
                            </div>
                            <div className="store-ko-info-container">
                                <span className="store-ko-agadi-ko-title">Contact Number:</span>
                                <span className="store-ko-agadi-ko-desc">{store.phone}</span>
                            </div>
                        </div>
                          {/* <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div
                              id="iframe"
                              style={{ width: "100%", height: "374px" }}
                            >
                              <iframe
                                className=""
                                style={{
                                  height: "366px",
                                  width: "104%",
                                }}
                                frameBorder="0"
                                src={
                                  "https://www.google.com/maps/embed/v1/place?key=AIzaSyCr1jQKKXnElHr_7zd9PksyVimxKAKiTvw&q=" +
                                  store.google_coordinates
                                }
                                allowFullScreen=""
                              ></iframe>
                            </div>
                          </div> */}
                        
                      
                    </div>
                  
                  );
                })}
                </div>
              
            
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

export default Shop;
