import React, {useState, useEffect} from 'react';
import "./paymentSelection.css";
import Data from '../utils/data';
import api from "../utils/api";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";


const paymentOptions = [
  {
    payIcon:
      "https://d34kmefuuy0be0.cloudfront.net/ev_assets/credit_card_1120c23128.svg",
    payTitle: "Card Payment",
    payCta:
      "https://d34kmefuuy0be0.cloudfront.net/ev_assets/right_arrow_c3b9836fcb.svg",
    ctaLink: "#",
  },
  {
    payIcon:
      "https://d34kmefuuy0be0.cloudfront.net/ev_assets/house_4aa14bfe5e.svg",
    payTitle: "eSewa",
    payCta:
      "https://d34kmefuuy0be0.cloudfront.net/ev_assets/right_arrow_c3b9836fcb.svg",
    ctaLink: "#",
  },
  {
    payIcon:
      "https://d34kmefuuy0be0.cloudfront.net/ev_assets/house_4aa14bfe5e.svg",
    payTitle: "Connect IPS",
    payCta:
      "https://d34kmefuuy0be0.cloudfront.net/ev_assets/right_arrow_c3b9836fcb.svg",
    ctaLink: "#",
  },
 
];



const PaymentSelection = (props) => {

  let history = useHistory();

  const [isActiveOverlay, setIsActiveOverlay] = useState(false);

  const modalData = JSON.parse(localStorage.getItem("selectedModalData")).data
  .catalog_pricing_details[0];

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [province, setProvince] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [product, setProduct] = useState("");

  function handleClick(p_method){
    history.push({
      pathname: "/priceSummary",
      search:
      "?source=ola&product="+getProductItemId(),
      state: {'product' : product,'payment_method' : p_method,'fullName' : fullName, 'email' : email, 'phone' : phone, 'province' : province, 'city' : city, 'area' : area, 'address': address, 'note' : note}
    })

  };

  function getProductItemId() {
    var url = new URL(window.location.href);
    return url.searchParams.get("product");
  }

 

  useEffect(() => {
    console.log(props);
    if(!props.data){
      history.goBack();
    }else{
      setProduct(getProductItemId());
      setFullName(props.data.fullName);
      setEmail(props.data.email);
      setPhone(props.data.phone);
      setProvince(props.data.province);
      setCity(props.data.city);
      setArea(props.data.area);
      setAddress(props.data.address);
      setNote(props.data.note);
    }
    // console.log('props');
    
  });

  return (
    <LoadingOverlay active={isActiveOverlay} spinner text="Loading">
      {console.log(paymentMethod+' fullNamefullName')}
    <div className="payment-page">
      <div className="payment-title">
        <div>Total payable</div>
        <div>{modalData.price_breakup_response.currency+' '+modalData.price_breakup_response.reserve_amount}</div>
      </div>
      <div className="pay-desc">Pay in full</div>
      <span className="break-line"></span>
      <div className="pay-options-wrapper">
        {paymentOptions.map((ele, i) => (
          <div key={i} className="each-pay-option" onClick={() => handleClick(ele.payTitle)} style={{'cursor' : 'pointer'}}>
            <div className="right-sideicon">
              <div>
                <img src={ele.payIcon} alt="opt" />
              </div>
              <div className="pay-title">{ele.payTitle}</div>
            </div>
            <div>
              <img src={ele.payCta} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
    </LoadingOverlay>
  );
};

export default PaymentSelection;
