import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import NumberFormat from "react-number-format";
import Countdown from "react-countdown";
import SliderProduct from "../../page/Product/SliderProduct/SliderProduct";
import NextSlider from "../../Layouts/Silder/NextSlider";
import PrevSlider from "../../Layouts/Silder/PrevSlider";
import { FLASH_SALES } from "../../route/routeURL";
const FlashSalelayout = (props) => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        nextArrow: <NextSlider />,
        prevArrow: <PrevSlider />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div className="container other-product product-list-grid">
            <div className="product-scroller">
                <div className="product-container">
                    <h3>
                        <Link to={FLASH_SALES}>
                            {props.view.flash_sale.title}
                        </Link>{" "}
                        <span className="countDown">
                            <small>End at</small> {" "}
                            {props.view.flash_sale ? (
                                <Countdown date={props.view.flash_sale.end_time} />
                            ) : (
                                <Countdown date={Date.now()} />
                            )}
                        </span>
                        <Link to={FLASH_SALES} style={{ float: "right", marginRight: "20px" }}>View all</Link>
                    </h3>
                    <div
                        className="product-tab"
                        id="attachment-1"
                        style={{ display: "block" }}
                    >
                        {props.view.flash_sale ? (
                            <Slider {...settings}>
                                {props.view.flash_sale.products.map(function (product, index) {
                                    return (
                                        <div className="slide" key={index}>
                                            <SliderProduct product={product} />
                                        </div>
                                    );
                                })}
                            </Slider>
                        ) : (
                            <div
                                className="container"
                                style={{ textAlign: "center", padding: "100px 0px" }}
                            >
                                <ClipLoader
                                    css={{ margin: "0 auto" }}
                                    size={120}
                                    //size={"150px"} this also works
                                    color={"#123abc"}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlashSalelayout;
