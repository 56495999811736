import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import ClickBanner from "../Banner/ClickBanner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import NextSlider from "../../Layouts/Silder/NextSlider";
import PrevSlider from "../../Layouts/Silder/PrevSlider";
const ThreeBannerLayout = (props) => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3000,
    autoplaySpeed: 4000,
    nextArrow: <NextSlider />,
    prevArrow: <PrevSlider />,
  };

  var settingsTwo = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3000,
    // arrows: false,
    nextArrow: <NextSlider />,
    prevArrow: <PrevSlider />,
  };
  return (
    <React.Fragment>
      <div style={{ marginTop: "15px" }}>
        <div className="vc-container">
          <div className="row">
            <div className="col-md-8 col-xs-12 top-main-banner">
              <div className="banner-slider">
                {props.banners[0].length == 1 ? (
                  <ClickBanner banner={props.banners[0][0]}>
                    <LazyLoadImage
                      style={{
                        width: "100%",
                        objectFit: "cover",
                      }}
                      src={props.banners[0][0].image}
                    />
                  </ClickBanner>
                ) : (
                  <Slider {...settings}>
                    {props.banners[0].map(function (banner, index) {
                      return (
                        <div key={index}>
                          <ClickBanner banner={banner}>
                            <LazyLoadImage
                              style={{
                                width: "100%",
                                objectFit: "cover",
                              }}
                              className="main-slider-banner-img"
                              alt={banner.name}
                              src={banner.image}
                            />
                          </ClickBanner>
                        </div>
                      );
                    })}
                  </Slider>
                )}
              </div>
            </div>

            <div
              className="col-md-4 col-xs-12 main-side-banner"
              // style={{ marginTop: "15px" }}
            >
              <div className="row">
                <div className="col-md-12 col-xs-6 col s6 top-banner top-banner-right">
                  {props.banners[1].length == 1 ? (
                    <ClickBanner banner={props.banners[1][0]}>
                      <LazyLoadImage
                        style={{
                          width: "100%",
                          objectFit: "cover",
                        }}
                        src={props.banners[1][0].image}
                      />
                    </ClickBanner>
                  ) : (
                    <Slider {...settingsTwo}>
                      {props.banners[1].map(function (banner, index) {
                        return (
                          <div key={index}>
                            <ClickBanner banner={banner}>
                              <LazyLoadImage
                                style={{
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                                alt={banner.name}
                                src={banner.image}
                              />
                            </ClickBanner>
                          </div>
                        );
                      })}
                    </Slider>
                  )}
                </div>
                <div className="col-md-12 col-xs-6 col s6 top-banner top-banner-left">
                  {props.banners[2].length == 1 ? (
                    <ClickBanner banner={props.banners[2][0]}>
                      <LazyLoadImage
                        style={{
                          width: "100%",
                          objectFit: "cover",
                        }}
                        src={props.banners[2][0].image}
                      />
                    </ClickBanner>
                  ) : (
                    <Slider {...settingsTwo}>
                      {props.banners[2].map(function (banner, index) {
                        return (
                          <div key={index}>
                            <ClickBanner banner={banner}>
                              <LazyLoadImage
                                style={{
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                                alt={banner.name}
                                src={banner.image}
                              />
                            </ClickBanner>
                          </div>
                        );
                      })}
                    </Slider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ThreeBannerLayout;
