import React from "react";
import Skeleton from "react-loading-skeleton";
const CheckoutSkeleton = () => {
    return (
        <React.Fragment>
            <div className="row" style={{ padding: "20px 0px" }}>
                <div className="col-lg-12">
                    <Skeleton height={30} />
                    <Skeleton height={400} />
                </div>

            </div>

        </React.Fragment>
    )
};

export default CheckoutSkeleton;