import React, { useState, useEffect } from "react";

import ClipLoader from "react-spinners/ClipLoader";
import storage from "../../utils/storage";
import api from "../../utils/api";
import Data from "../../utils/data";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cartFunction from "../../utils/cartFunction";
import LoadingOverlay from "react-loading-overlay";
import wishlistFunction from "../../utils/wishlistFunction";
import WithContext from "../../Layouts/MainMessage/WithContext";
import { MetaTags } from "react-meta-tags";
const Compare = (props) => {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [compareProducts, setCompareProducts] = useState({});
  const [isActiveOverlay, setIsActiveOverlay] = useState(false);
  function getCompareProducts() {
    var products = storage.getCompareProducts();
    let url = new URL(Data.API_BASE_URL + "compare-products");
    var product_id = products.map(function (obj) {
      return obj.id;
    });
    url.search = new URLSearchParams({
      product_id: product_id,
    });
    fetch(url, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setCompareProducts(response.data.products);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // history.push("/page-not-found");
      });
  }

  async function addToCart(evt, productId) {
    evt.preventDefault();
    let data = await cartFunction.addToCart(productId);
    if (data) {
      return history.push({
        pathname: "/user/cart",
      });
    }
  }
  async function addToWishlist(evt, productId) {
    evt.preventDefault();
    let data = await wishlistFunction.addToWishlist(productId);
    if (data) {
      var products = storage.getCompareProducts();
      var findProduct = products.find(function (obj) {
        return productId === obj.id;
      });
      window.scrollTo(0, 0);
      props.value.setMessage(findProduct.name + " added to Wishlist");
    }
  }
  function deleteProduct(evt, productId) {
    evt.preventDefault();
    setIsActiveOverlay(true);

    // return isActiveOverlay;
    var products = storage.getCompareProducts();
    var findProduct = products.find(function (obj) {
      return productId === obj.id;
    });
    products.splice(
      products.findIndex(function (obj) {
        return productId === obj.id;
      }),
      1
    );
    storage.addToCompare(products);
    getCompareProducts();
    props.value.setMessage(findProduct.name + " removed from compare list");
    window.scrollTo(0, 0);
    setIsActiveOverlay(false);
  }
  useEffect(() => {
    setIsLoading(true);
    getCompareProducts();
  }, []);
  return (
    <React.Fragment>
      <MetaTags>
        <title>Compare</title>
      </MetaTags>
      {isLoading ? (
        <div
          className="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      ) : (
        <div className="main container">
          <section className="pdp-section shown">
            <div className="col-main">
              <LoadingOverlay active={isActiveOverlay} spinner text="Loading">
                <div className="compare-list">
                  <div className="page-title">
                    <h2>Compare Products</h2>
                  </div>

                  <div className="table-responsive">
                    {compareProducts.product_image ? (
                      <table className="table table-bordered table-compare">
                        <tbody>
                          {compareProducts.product_image ? (
                            <tr>
                              <td className="compare-label">Product Image</td>
                              {compareProducts.product_image.map(function (
                                image,
                                index
                              ) {
                                return (
                                  <td key={index}>
                                    {compareProducts.product_id &&
                                    compareProducts.product_name ? (
                                      <Link
                                        to={
                                          "/product-" +
                                          compareProducts.product_name[index]
                                            .replace(/[#+. _]/gi, "-")
                                            .toLowerCase() +
                                          "-" +
                                          compareProducts.product_id[index]
                                        }
                                      >
                                        <img
                                          className="fit-image"
                                          src={image}
                                          alt="Product"
                                          width="230"
                                        />
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          ) : (
                            ""
                          )}
                          {compareProducts.product_name ? (
                            <tr>
                              <td className="compare-label">Product Name</td>
                              {compareProducts.product_name.map(function (
                                name,
                                index
                              ) {
                                return (
                                  <td key={index}>
                                    <Link
                                      to={
                                        "/product-" +
                                        compareProducts.product_name[index]
                                          .replace(/[#+. _]/gi, "-")
                                          .toLowerCase() +
                                        "-" +
                                        compareProducts.product_id[index]
                                      }
                                    >
                                      {name}
                                    </Link>
                                  </td>
                                );
                              })}
                            </tr>
                          ) : (
                            ""
                          )}
                          {compareProducts.model_number ? (
                            <tr>
                              <td className="compare-label">Model Number</td>
                              {compareProducts.model_number.map(function (
                                model,
                                index
                              ) {
                                return <td key={index}>{model}</td>;
                              })}
                            </tr>
                          ) : (
                            ""
                          )}
                          {compareProducts.rating_count ? (
                            <tr>
                              <td className="compare-label">Ratings</td>
                              {compareProducts.rating_count.map(function (
                                rating,
                                index
                              ) {
                                return (
                                  <td key={index}>
                                    {" "}
                                    <div className="rating">
                                      &nbsp; <span>({rating} reviews)</span>
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>
                          ) : (
                            ""
                          )}
                          {compareProducts.price ? (
                            <tr>
                              <td className="compare-label">Price</td>
                              {compareProducts.price.map(function (
                                price,
                                index
                              ) {
                                return (
                                  <td key={index}>
                                    {" "}
                                    <span className="price">
                                      {" "}
                                      NPR{" "}
                                      {price.discount_price
                                        ? price.discount_price
                                        : price.price}{" "}
                                    </span>
                                    {price.discount_price ? (
                                      <p className="old-price">
                                        <span className="price">
                                          {" "}
                                          NPR {price.price}{" "}
                                        </span>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          ) : (
                            ""
                          )}
                          {compareProducts.description ? (
                            <tr>
                              <td className="compare-label">Description</td>
                              {compareProducts.description.map(function (
                                description,
                                index
                              ) {
                                return (
                                  <td key={index}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: description,
                                      }}
                                    ></div>
                                  </td>
                                );
                              })}
                            </tr>
                          ) : (
                            ""
                          )}
                          {compareProducts.manufacturer ? (
                            <tr>
                              <td className="compare-label">Manufacturer</td>
                              {compareProducts.manufacturer.map(function (
                                manufacturer,
                                index
                              ) {
                                return <td key={index}>{manufacturer}</td>;
                              })}
                            </tr>
                          ) : (
                            ""
                          )}
                          {compareProducts.availbility ? (
                            <tr>
                              <td className="compare-label">Availbility</td>
                              {compareProducts.availbility.map(function (
                                availbility,
                                index
                              ) {
                                return (
                                  <td key={index}>
                                    {availbility == 1 ? (
                                      <span className="instock">Instock</span>
                                    ) : (
                                      <span className="outofstock">
                                        Out of stock
                                      </span>
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          ) : (
                            ""
                          )}
                          {compareProducts.other
                            ? Object.keys(compareProducts.other).map(function (
                                name,
                                index
                              ) {
                                return (
                                  <tr key={index}>
                                    <td key={index}>{name}</td>
                                    {compareProducts.other[name].map(function (
                                      data,
                                      indexTwo
                                    ) {
                                      return <td key={indexTwo}>{data}</td>;
                                    })}
                                  </tr>
                                );
                              })
                            : ""}

                          {compareProducts.product_id ? (
                            <tr>
                              <td className="compare-label">Availbility</td>
                              {compareProducts.product_id.map(function (
                                product_id,
                                index
                              ) {
                                return (
                                  <td key={index}>
                                    <button
                                      className="add-cart button-times button-sm "
                                      onClick={(evt) =>
                                        addToCart(evt, product_id)
                                      }
                                    >
                                      <FontAwesomeIcon icon="shopping-cart" />
                                    </button>
                                    <button
                                      className="button-times button-sm"
                                      onClick={(evt) =>
                                        addToWishlist(evt, product_id)
                                      }
                                    >
                                      <FontAwesomeIcon icon="heart" />
                                    </button>
                                    <button
                                      className="button-times button-sm"
                                      onClick={(evt) =>
                                        deleteProduct(evt, product_id)
                                      }
                                    >
                                      <FontAwesomeIcon icon="trash" />
                                    </button>
                                  </td>
                                );
                              })}
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <p className="no-data-p">
                        No Products in comparison list.
                      </p>
                    )}
                  </div>
                </div>
              </LoadingOverlay>
            </div>
          </section>
        </div>
      )}
    </React.Fragment>
  );
};

export default WithContext(Compare);
