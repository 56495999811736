import React from "react";
import "./homeButton.css";
import { useHistory } from "react-router-dom";

const BackButton = ({url}) => {
  const { goBack } = useHistory();
  return (
    <div
      className="backButton-cont"
      onClick={()=>(window.location.href = "https://ola-ev-ui.olaelectric.com/np")}
    >
      {console.log(goBack)}
      Home
      {/* <img src="https://d34kmefuuy0be0.cloudfront.net/ev_assets/long_back_arrow_38efed2689.svg" /> */}
    </div>
  );
};

export default BackButton;
