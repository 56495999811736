import React from "react";
import Skeleton from "react-loading-skeleton";
const CustomPageSkeleton = () => {
  return (
    <React.Fragment>
      <div className="row" style={{ padding: "0px 0px" }}>
        <div className="col-lg-8">
          <Skeleton height={400} />
        </div>
        <div className="col-lg-4">
          <Skeleton height={200} />
          <Skeleton height={200} />
        </div>
      </div>
      <div className="row" style={{ padding: "20px 0px" }}>
        <div className="col-lg-12">
          <Skeleton height={20} />
        </div>
      </div>
      <div className="row" style={{ padding: "20px 0px" }}>
        <div className="col-lg-6">
          <Skeleton height={300} />
        </div>
        <div className="col-lg-6">
          <Skeleton height={300} />
        </div>
      </div>
      <div className="row" style={{ padding: "20px 0px" }}>
        <div className="col-lg-12">
          <Skeleton height={300} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomPageSkeleton;
