import React, { useEffect } from "react";
import {
  USER_LOGIN,
  USER_REGISTER,
  WISH_LIST,
  COMPARE,
} from "../route/routeURL";

import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import storage from "../utils/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CompareHeader = (props) => {
  let history = useHistory();
  function logout(e) {
    e.preventDefault();
    storage.logout();
    console.log("logout");
    history.push(props.location);
  }
  useEffect(() => {}, []);

  return (
    <div className="navbar-login">
      <div className="login-wrp">
        <Link to={COMPARE} className="cartview decoration-none" title="Compare">
          <FontAwesomeIcon icon="retweet"  style={{ transform:"scale(1.5)" }} />
        </Link>
      </div>
    </div>
  );
};

export default CompareHeader;
