import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ClipLoader from "react-spinners/ClipLoader";
import storage from "../../utils/storage";
import { BOOKING_COMPLETE, CART } from "../../route/routeURL";
import WithContext from "../../Layouts/MainMessage/WithContext";
const ConnectIpsBookingCallBackUrl = (props) => {
  let history = useHistory();
  const [isLoading] = useState(true);

  useEffect(() => {
    if (!storage.isLoggedIn()) {
      history.replace("/");
    }
    let booking = "";
    if (new URLSearchParams(props.location.search).get("booking")) {
      var orderData = new URLSearchParams(props.location.search).get("booking");
      booking = orderData;
    }
    if (booking == "completed") {
      setTimeout(
        () => (
          history.replace(BOOKING_COMPLETE + "?order=completed"),
          props.value.setMessage("Your booking is completed successfully!")
        ),
        2000
      );
    } else if (booking == "failure") {
      setTimeout(
        () => (
          history.replace(CART),
          props.value.setErrorMessage(
            "Your booking has been cancelled or failed!"
          )
        ),
        2000
      );
    } else {
      setTimeout(() => history.replace(CART), 2000);
    }
  }, []);
  return (
    <React.Fragment>
      <div className="container" style={{ height: "400px" }}>
        <div
          className="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            color={"#123abc"}
            loading={isLoading}
          />
          <br />
          <br />
          <b>Will redirect you in a sec</b>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WithContext(ConnectIpsBookingCallBackUrl);
