import React, { useEffect, useState } from "react";
import { useInput } from "../../hooks/inputHook";
import Data from "../../utils/data";
import api from "../../utils/api";

const UserChangePassword = (props) => {
  const [errorMessage, setErrorMessage] = useState("");

  const { value: oldPassword, bind: bindOldPassword } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");
  const { value: confirmPassword, bind: bindConfirmPassword } = useInput("");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    console.log(password);
    console.log(confirmPassword);

    if (password === confirmPassword) {
      fetch(Data.API_BASE_URL + "user/change-password", {
        method: "POST",
        headers: Data.API_HEADER,
        body: JSON.stringify({
          old_password: oldPassword,
          password: password,
          new_password: confirmPassword,
        }),
      })
        .then(api.handleResponseAndError)
        .then((response) => {
          if (response.statusCode === 200) {
            console.log(response);
            window.scrollTo(0, 0);
            setErrorMessage("");
            props.setMessage(response.message);
          } else {
            setErrorMessage(response.message);
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      setErrorMessage("Confirm Password Doesnot Match");
    }
  };
  useEffect(() => {}, []);
  return (
    <form
      onSubmit={handleSubmit}
      acceptCharset="UTF-8"
      className="ng-pristine ng-valid"
    >
      <br />
      <br />
      <h3 style={{ textAlign: "center" }}>Change Password</h3>

      <div className="col-lg-12 row">
        <div className="col-lg-6">
          <label htmlFor="current_password">
            <b>Old Password</b>
          </label>
          <input
            name="current_password"
            type="password"
            {...bindOldPassword}
            required
          />
          <span className="error-message"></span>
          <br />
        </div>
      </div>

      <div className="col-lg-12 row">
        <div className="col-lg-6">
          <label htmlFor="password">
            <b>New Password</b>
          </label>
          <input name="password" type="password" {...bindPassword} required />
          <span className="error-message"></span>
          <br />
        </div>
        <div className="col-lg-6">
          <label htmlFor="password_confirmation">
            <b>Confirm Password</b>
          </label>
          <input
            name="password_confirmation"
            type="password"
            {...bindConfirmPassword}
            required
          />
          {errorMessage ? (
            <span
              className="error-message"
              style={{ color: "red", marginBottom: "10px" }}
            >
              {errorMessage}
            </span>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="clearfix" style={{ textAlign: "center" }}>
        <br />
        <input
          type="submit"
          className="btn btn-success"
          value="Change Password"
        />
      </div>
    </form>
  );
};
export default UserChangePassword;
