import storage from "./storage";
import Data from "./data";
import api from "./api";

export default {
  addToWishlist(productId) {
    let url = new URL(Data.API_BASE_URL + "add-to-wishlist");
    url.search = new URLSearchParams({
      token: storage.getAccessToken(),
    }).toString();
    return fetch(url, {
      method: "POST",
      headers: Data.API_HEADER,
      body: JSON.stringify({
        product_id: productId,
      }),
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  },
};
