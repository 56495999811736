import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Exclusive from "../page/Product/Exclusive";
import Featured from "../page/Product/Featured";
import Arrival from "../page/Product/Arrival";
import MainBanner from "./MainBanner";
import Data from "../utils/data";
import Api from "../utils/api";
import TopBanner from "./Banner/TopBanner";
import MiddleBanner from "./Banner/MiddleBanner";
import BrandBanner from "./Banner/BrandBanner";

import FlashSales from "./FlashSales/FlashSales";
import MetaTags from "react-meta-tags";
import ClipLoader from "react-spinners/ClipLoader";
import HomeSkeleton from "../page/Skeleton/HomeSkeleton";
import { MobileView } from "react-device-detect";
import Modal from "../Layouts/Modal/Modal";
import OneBannerLayout from "./Layout/OneBannerLayout";
import TwoBannerLayout from "./Layout/TwoBannerLayout";
import ProductListLayout from "./Layout/ProductListLayout";
import SupplierListLayout from "./Layout/SupplierListLayout";
import BrandLayout from "./Layout/BrandLayout";
import CategoryListLayout from "./Layout/CategoryLayout";
import ProductGridLayout from "./Layout/ProductGridLayout";
import ThreeBannerLayout from "./Layout/ThreeBannerLayout";
import ScrollTextLayout from "./Layout/ScrollTextLayout";
import FlashSalelayout from "./Layout/FlashSaleLayout";
import CouponLayout from "./Layout/CouponLayout";
import storage from "../utils/storage";
import HomePopUp from "./PopUp/HomePopUp";
const HomeV2 = () => {
  const [dyanmicViews, setDynamicViews] = useState([]);
  const [popUpContent, setPopUpContent] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [bestDeals, setBestDeals] = useState([]);
  const [flashSales, setFlashSales] = useState(null);
  const [marque, setMarque] = useState(null);
  const [newArrivals, setNewArrivals] = useState([]);
  const [topBanners, setTopBanners] = useState([]);
  const [topSliders, setTopSliders] = useState([]);
  const [bottomSliders, setBottomSliders] = useState([]);
  const [middleBanners, setMiddleBanners] = useState([]);
  const [featureProducts, setFeatureProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  function fetchHomeData() {
    fetch(Data.API_BASE_URL + "get-home-data", {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        setSliders(response.data.sliders);
        setBestDeals(response.data.bestDeals);
        setNewArrivals(response.data.newArrivals);
        setTopBanners(response.data.topBanners);
        setTopSliders(response.data.topSliders);
        setBottomSliders(response.data.bottomSliders);
        setFlashSales(response.data.flashSales);
        setMiddleBanners(response.data.middleBanners);
        setFeatureProducts(response.data.featureProducts);
        setBrands(response.data.brandProducts);
        setMarque(response.data.marque);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function fetchDynamicData() {
    fetch(Data.API_BASE_URL + "layouts", {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        setDynamicViews(response.data.layouts);
        setPopUpContent(response.data.pop_up);
        // console.log(response.data.layouts);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchDynamicData();
    // fetchHomeData();
  }, []);
  return (
    <React.Fragment>
      <MetaTags>
        <title>CG Digital</title>
        <meta name="description" content={"cg-digital cg digital"} />
        <meta name="keywords" content={"'cg-digital"} />
      </MetaTags>
      {isLoading ? (
        <div
          className="container"
          style={{ textAlign: "center", padding: "30px 0px" }}
        >
          <HomeSkeleton />
        </div>
      ) : (
        <div className="entry-content">
          {popUpContent &&
          popUpContent.status == 1 &&
          storage.checkHomePopUp() ? (
            <HomePopUp
              id="home-pop-up"
              heading={"heading"}
              imageUrl={popUpContent.image_url}
              imageLink={popUpContent.slider_link}
              title={popUpContent.title}
              desc={popUpContent.short_description}
            />
          ) : (
            ""
          )}

          {/* <div style={{ marginTop: "15px" }}>
            <div className="vc-container">
              <div className="row">
                <MainBanner sliders={sliders} />

                <TopBanner topBanners={topBanners} topSliders={topSliders} bottomSliders={bottomSliders} marque={marque} />
              </div>
            </div>
          </div>
          <Exclusive bestDeals={bestDeals} />
          {flashSales !== null ? <FlashSales flashSales={flashSales} /> : ""}
          <MiddleBanner
            middleBanners={middleBanners}
            newArrivals={newArrivals}
          />
          <Featured products={featureProducts} />
          <BrandBanner brands={brands} /> */}
          {/* {console.log(dyanmicViews)} */}
          {/* <h5>V2</h5> */}
          {dyanmicViews.map(function (view, index) {
            return (
              <div key={index}>
                {view.type == "One Banner" ? (
                  <OneBannerLayout banners={view.banner_list} />
                ) : view.type == "Two Banner" ? (
                  <TwoBannerLayout banners={view.banner_list} />
                ) : view.type == "Three Banner" ? (
                  <ThreeBannerLayout banners={view.banner_list} />
                ) : view.type == "Product List" ? (
                  view.product_view == "Grid" ? (
                    <ProductGridLayout view={view} />
                  ) : (
                    <ProductListLayout view={view} />
                  )
                ) : view.type == "Brand List" ? (
                  <BrandLayout view={view} />
                ) : view.type == "Supplier List" ? (
                  <SupplierListLayout view={view} />
                ) : view.type == "Category List" ? (
                  <CategoryListLayout view={view} />
                ) : view.type == "Sub Category List" ? (
                  <CategoryListLayout view={view} />
                ) : view.type == "Sub Category Type List" ? (
                  <CategoryListLayout view={view} />
                ) : view.type == "Scroll Text" ? (
                  <ScrollTextLayout view={view} />
                ) : view.type == "Flash Sales" ? (
                  <FlashSalelayout view={view} />
                ) : view.type == "Coupon List" ? (
                  <CouponLayout view={view} />
                ) : (
                  " "
                )}
              </div>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

export default HomeV2;
