import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import SliderProduct from "../../page/Product/SliderProduct/SliderProduct";
import NextSlider from "../../Layouts/Silder/NextSlider";
import PrevSlider from "../../Layouts/Silder/PrevSlider";
const ProductListLayout = (props) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextSlider />,
    prevArrow: <PrevSlider />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container other-product product-list-grid">
      <div className="product-scroller">
        <div className="product-container">
          <h3>
            <Link to={"/"}>{props.view.title}</Link>{" "}
            <small>{props.view.sub_title}</small>
            <Link
              to={
                "/view-all/" +
                props.view.title.replace(/[#+. _/]/gi, "-").toLowerCase() +
                "-" +
                props.view.id
              }
              style={{ float: "right", marginRight: "20px" }}
            >
              View all
            </Link>
          </h3>
          <div
            className="product-tab"
            id="attachment-1"
            style={{ display: "block" }}
          >
            <Slider {...settings}>
              {props.view.product_list.map(function (product, index) {
                return (
                  <div className="slide" key={index}>
                    <SliderProduct product={product} key={index} />
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListLayout;
