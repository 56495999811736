import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Layouts/Breadcrumb";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Data from "../../utils/data";
import api from "../../utils/api";
import { MetaTags } from "react-meta-tags";
const CustomPage = (props) => {
  const [isLoading, setIsloading] = useState(true);
  const [page, setPage] = useState({});
  let history = useHistory();
  function getCustomPage() {
    setIsloading(true);
    fetch(Data.API_BASE_URL + "page-" + getPageData(), {
      method: "GET",
      headers: Data.API_HEADER,
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setPage(response.data);
        setIsloading(false);
      })
      .catch((error) => {
        history.push("/not-found");
        console.log(error);
      });
  }
  function getPageData() {
    let id = props.match.params["data"];
    return id;
  }
  useEffect(() => {
    getCustomPage();
  }, [getPageData()]);
  return (
    <React.Fragment>
      <div className="search-page" id="sr1">
        <div className="search-content">
          {isLoading ? (
            <div
              className="container"
              style={{ textAlign: "center", padding: "100px 0px" }}
            >
              <ClipLoader
                css={{ margin: "0 auto" }}
                size={150}
                //size={"150px"} this also works
                color={"#123abc"}
                loading={isLoading}
              />
            </div>
          ) : (
            <div className="container">
              <Breadcrumb
                levelOne={{
                  name: page.title,
                  link: "/page-",
                }}
              />
              <MetaTags>
                <title>{page.title}</title>
              </MetaTags>
              <div className="static-page white-well">
                <div id="post-92427">
                  <div className="entry-content">
                    <h1>{page.title}</h1>
                    <hr />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page.content,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomPage;
