import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import "./Exchange.css";
import Data from "../utils/data";
import api from "../utils/api";
import { useInput } from "../hooks/inputHook";


const Exchange = () => {
  const [isLoading, setIsloading] = useState(true);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [productType, setProductType] = useState(null);
  const [workingCondition, setWorkingCondition] = useState(null);
  const [next, setNext] = useState(false);
  const [productTypes, setProductTypes] = useState([]);

  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategory, setSubCategory] = useState(null);
  const [subCategoryTypes, setSubCategoryTypes] = useState([]);
  const [subCategoryType, setSubCategoryType] = useState(null);
  const [brandProducts, setBrandProducts] = useState([]);
  const [brandProduct, setBrandProduct] = useState(null);
  const [exchangePrice, setExchangePrice] = useState(null);

  const { value: fullName, bind: bindFullName, setValue: setFullName } = useInput("");
  const { value: address, bind: bindAddress, setValue: setAddress } = useInput("");
  const { value: phone, bind: bindPhone, setValue: setPhone } = useInput("");
  const { value: email, bind: bindEmail, setValue: setEmail } = useInput("");

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessageFullname, setErrorMessageFullname] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePhone, setErrorMessagePhone] = useState("");
  const [errorMessageAddress, setErrorMessageAddress] = useState("");



  function fetchProducts() {
    fetch(Data.API_BASE_URL + "exchange/products", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setProducts(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchProductTypes(product) {
    // console.log(product);
    setProductType(null);
    setProductTypes([]);
    setIsloading(true);
    fetch(Data.API_BASE_URL + "exchange/productTypes/" + product.id, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setProduct(product);
        setProductTypes(response);
        setWorkingCondition(null);
        
        // const element = document.getElementById('newsletter-validate-detail');
        // if (element) {
        //   element.scrollIntoView();
        // }
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchBrands() {
    setIsloading(true);
    fetch(Data.API_BASE_URL + "exchange/brands", {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setBrands(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchSubCategories(brand) {
    setSubCategory(null);
    setSubCategories([]);
    setSubCategoryType(null);
    setSubCategoryTypes([]);
    setBrandProduct(null);
    setBrandProducts([]);
    setIsloading(true);
    fetch(Data.API_BASE_URL + "exchange/subCategories/" + brand.id, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setSubCategories(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchSubCategoryTypes(subCategory) {
    setSubCategoryType(null);
    setSubCategoryTypes([]);
    setBrandProduct(null);
    setBrandProducts([]);
    setIsloading(true);
    fetch(Data.API_BASE_URL + "exchange/subCategoryTypes/" + subCategory.id, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setSubCategoryTypes(response);
        if(response.length == 0){
          fetchBrandProductsViaSubCategory(subCategory);
        }
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchBrandProductsViaCategoryType(subCategoryType) {
    setBrandProduct(null);
    setBrandProducts([]);
    setIsloading(true);
    fetch(Data.API_BASE_URL + "exchange/products-via-category-type-n-brand/" + subCategoryType.id + "/" + brand.id, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setBrandProducts(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fetchBrandProductsViaSubCategory(subCategory) {
    setBrandProduct(null);
    setBrandProducts([]);
    setIsloading(true);
    fetch(Data.API_BASE_URL + "exchange/products-via-sub-category-n-brand/" + subCategory.id + "/" + brand.id, {
      method: "GET",
    })
      .then(api.handleResponseAndError)
      .then((response) => {
        setBrandProducts(response);
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
  
    setErrorMessageFullname('');
    setErrorMessageEmail('');
    setErrorMessagePhone('');
    setErrorMessageAddress('');
    setSuccessMessage('');

    let formError = false;

    const pattern = /[^a-zA-Z\s.]/g;
    if(fullName == ''){
      setErrorMessageFullname('Please enter fullname');
      formError = true;
    }else if(pattern.test(fullName)){
      setErrorMessageFullname("Name should not contain special characters.");
      formError = true;
    }
    
    const emailRegExp   = /\S+@\S+\.\S+/;
    if(email == ''){
      setErrorMessageEmail('Please enter email');
      formError = true;
    }else if(!emailRegExp.test(email)){
      setErrorMessageEmail("Please enter valid email address.");
      formError = true;
    }
    
    if(phone == ''){
      setErrorMessagePhone('Please enter mobile number');
      formError = true;
    }else if(phone.length != 10){
      setErrorMessagePhone("Please enter valid mobile number.");
      formError = true;
    }
    
    if(address == ''){
      setErrorMessageAddress('Please enter address');
      formError = true;
    }
 

    if(formError == false){
      let url = new URL(Data.API_BASE_URL + "exchange/post-lead");

      // setIsloading(true);
      fetch(url, {
        method: "POST",
        headers: Data.API_HEADER,
        body: JSON.stringify({
          fullName: fullName,
            email: email,
            address: address,
            phone: phone,
            condition: workingCondition,
          productTypeId: productType.id,
          brandProductId: brandProduct.id,
        }),
      })
        .then(api.handleResponseAndError)
        .then((response) => {
          console.log(response);
          if (response.statusCode == 200) {
            // window.location = response.url;
            setFullName("");
            setAddress("");
            setEmail("");
            setPhone("");
            setSuccessMessage(response.message);
            // setIsloading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    
  };

  

  useEffect(() => {
    fetchProducts();
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className="container"
          style={{ textAlign: "center", padding: "100px 0px" }}
        >
          <ClipLoader
            css={{ margin: "0 auto" }}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={isLoading}
          />
        </div>
      ) : (
        <div id="wrapper">
          <div className="container-fluid pe-0 ps-0">
            <div className="main-layout">
              <div className="content-wrapper">
                {next ? (
                  <div id="back-button" className="content-header">
                    <a
                      href="#"
                      className="back-btn"
                      onClick={() => {
                        setNext(false);
                        setWorkingCondition(null);
                        setBrand(null);
                        setSubCategories([]);
                        setSubCategory(null);
                        setSubCategoryTypes([]);
                        setSubCategoryType(null);
                        setBrandProducts([]);
                        setBrandProduct(null);
                      }}
                    >
                      <i className="fa-solid fa-left-long"></i> Back
                    </a>
                  </div>
                ) : (
                  ""
                )}
                <div className="content-main">
                  {/* <form action=""> */}
                    {!next ? (
                      <div className="old-products">
                        {products.length > 0 ? (
                          <section
                            id="old-product-container"
                            className="radio-section"
                          >
                            <h3>Old Product Details</h3>
                            <h4>Choose your old product</h4>
                            <div
                              id="available-exchange-product-categories"
                              className="radio-selections inline-radios"
                            >
                              {products.map(function (item, index) {
                                return (
                                  <div className="radio" key={index}>
                                    <input
                                      type="radio"
                                      value={item.id}
                                      checked={
                                        product && product.id === item.id
                                      }
                                      onClick={() => fetchProductTypes(item)}
                                    />
                                    <label>
                                      <span className="cr image-radio"></span>
                                      <span className="radio-captions text-center">
                                        <img
                                          src={item.icon}
                                          alt={item.name}
                                          width="100"
                                        />
                                        {item.name}
                                      </span>
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </section>
                        ) : (
                          ""
                        )}

                        {productTypes.length > 0 ? (
                          <section
                            id="old-product-types-container"
                            className="radio-section"
                          >
                            <h4>Choose your old product type</h4>
                            <div
                              id="available-conditions"
                              className="radio-selections inline-radios"
                            >
                              {productTypes.map(function (item, index) {
                                return (
                                  <div
                                    className="radio old-product-type"
                                    key={index}
                                  >
                                    <input
                                      type="radio"
                                      value={item.id}
                                      checked={
                                        productType &&
                                        productType.id === item.id
                                      }
                                      onClick={() => {
                                        setProductType(item);
                                        setWorkingCondition(null);
                                      }}
                                    />
                                    <label>
                                      <span className="cr"></span>
                                      <span className="radio-captions">
                                        {item.name}
                                      </span>
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </section>
                        ) : (
                          ""
                        )}

                        {productType ? (
                          <section
                            id="old-product-working-condition-container"
                            className="radio-section"
                          >
                            <h4>Is your product in working condition?</h4>
                            <div className="radio-selections inline-radios">
                              <div className="radio yes-selection">
                                <input
                                  type="radio"
                                  value="Yes"
                                  checked={
                                    workingCondition &&
                                    workingCondition === "Functional"
                                  }
                                  onClick={() => {
                                    setWorkingCondition("Functional");
                                    setExchangePrice(productType.functional_price);
                                  }}
                                />
                                <label>
                                  <span className="cr"></span>
                                  <span className="radio-captions"> Yes </span>
                                </label>
                              </div>
                              <div className="radio no-selection">
                                <input
                                  type="radio"
                                  value="No"
                                  checked={
                                    workingCondition &&
                                    workingCondition === "Non Functioanl"
                                  }
                                  onClick={() => {
                                    setWorkingCondition("Non Functioanl");
                                    setExchangePrice(productType.non_functional_price);
                                  }}
                                />
                                <label>
                                  <span className="cr"></span>
                                  <span className="radio-captions"> No </span>
                                </label>
                              </div>
                              <div className="radio partial-selection">
                                <input
                                  type="radio"
                                  value="Partial Working"
                                  checked={
                                    workingCondition &&
                                    workingCondition === "Partial Functional"
                                  }
                                  onClick={() =>{
                                    setWorkingCondition("Partial Functional");
                                    setExchangePrice(productType.partial_functional_price);
                                  }
                                  }
                                />
                                <label>
                                  <span className="cr"></span>
                                  <span className="radio-captions">
                                    {" "}
                                    Partial Working{" "}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </section>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {next ? (
                      <div className="new-products">
                        {brands.length > 0 ? (
                          <section
                            id="new-brands-container"
                            className="radio-section"
                          >
                            <h3>Interested New Product Details</h3>
                            <h4>
                              Choose the brand you would like to exchange with
                            </h4>
                            <div
                              id="available-brands"
                              className="radio-selections inline-radios"
                            >
                              {brands.map(function (item, index) {
                                return (
                                  <div className="radio" key={index}>
                                    <input
                                      type="radio"
                                      value={item.id}
                                      checked={brand && brand.id === item.id}
                                      onClick={() => {
                                        setBrand(item);
                                        fetchSubCategories(item);
                                      }}
                                    />
                                    <label className="h-auto">
                                      <span className="cr image-radio"></span>
                                      <span className="radio-captions text-center">
                                        <img
                                          src={item.image}
                                          alt={item.name}
                                          width="100"
                                        />
                                      </span>
                                    </label>
                                    {/* <a
                                      href="#"
                                      className="url-link"
                                      target="_blank"
                                    >
                                      Explore LG Website
                                    </a> */}
                                  </div>
                                );
                              })}
                            </div>
                          </section>
                        ) : (
                          ""
                        )}

                        {subCategories.length > 0 ? (
                          <section
                            id="new-product-container"
                            className="radio-section"
                          >
                            <h4>Choose the new product</h4>
                            <div className="radio-selections inline-radios">
                              {subCategories.map(function (item, index) {
                                return (
                                  <div className="radio" key={index}>
                                    <input
                                      type="radio"
                                      value={item.id}
                                      checked={
                                        subCategory &&
                                        subCategory.id === item.id
                                      }
                                      onClick={() => {
                                        setSubCategory(item);
                                        fetchSubCategoryTypes(item);
                                      }}
                                    />
                                    <label>
                                      <span className="cr image-radio"></span>
                                      <span className="radio-captions text-center">
                                        <img src={item.icon} alt={item.name} />
                                        {item.name}
                                      </span>
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </section>
                        ) : (
                          ""
                        )}

                        {subCategoryTypes.length > 0 ? (
                          <section
                            id="new-product-types-container"
                            className="radio-section"
                          >
                            <h4>Choose new product type</h4>
                            <div
                              id="availabe-category-types"
                              className="radio-selections inline-radios"
                            >
                              {subCategoryTypes.map(function (item, index) {
                                return (
                                  <div
                                    className="radio old-product-type"
                                    key={index}
                                  >
                                    <input
                                      type="radio"
                                      value={item.id}
                                      checked={
                                        subCategoryType &&
                                        subCategoryType.id === item.id
                                      }
                                      onClick={() => {
                                        setSubCategoryType(item);
                                        fetchBrandProductsViaCategoryType(item);
                                      }}
                                    />
                                    <label>
                                      <span className="cr"></span>
                                      <span className="radio-captions">
                                        {item.name}
                                      </span>
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </section>
                        ) : (
                          ""
                        )}

                        {brandProducts.length > 0 ? (
                          <section
                            id="new-product-model-container"
                            className="radio-section">
                            <h4>Choose Model</h4>
                            <div
                              id="available-products"
                              className="radio-selections inline-radios"
                            >
                              {brandProducts.map(function (item, index) {
                                return (
                                  <div
                                    className="radio new-product-type-model"
                                    key={index}
                                  >
                                    <input
                                      type="radio"
                                      checked={
                                        brandProduct &&
                                        brandProduct.id === item.id
                                      }
                                      onClick={() => setBrandProduct(item)}
                                    />
                                    <label>
                                      <span className="cr image-radio"></span>
                                      <span className="radio-captions text-center">
                                        <img
                                          src={item.featured_image}
                                          alt={item.name}
                                        />
                                        {item.model_number}
                                      </span>
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </section>
                        ) : (
                          ""
                        )}

                       
                        {brandProduct ? (
                        <div
                          className="modal fade show customer-information"
                          id="customer-information"
                          tabIndex="-1"
                          aria-labelledby="customer-information-label"
                          aria-hidden="true"
                          style={{display: "block"}}
                          >
                          <div className="modal-dialog"  style={{height: "70%"}}>
                            <div className="modal-content">
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={()=>setBrandProduct(null)}
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </button>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="modal-left">
                                      <a
                                        target="_blank"
                                        className="product_url"
                                        href={
                                          "/product-" +
                                          brandProduct.name.replace(/[#+. _/]/gi, "-").toLowerCase() +
                                          "-" +
                                          brandProduct.id
                                        }
                                      >
                                        <img
                                          id="choosen_product_image"
                                          src={brandProduct.featured_image}
                                          style={{ width: "50%" }}
                                          alt=""
                                        />
                                      </a>
                                      <div className="exchange-wrapper">
                                        <a
                                          target="_blank"
                                          className="product_url"
                                          href={
                                            "/product-" +
                                            brandProduct.name.replace(/[#+. _/]/gi, "-").toLowerCase() +
                                            "-" +
                                            brandProduct.id
                                          }
                                        >
                                          <h2 id="choosen_product_name">
                                            {brandProduct.model_number}
                                          </h2>
                                        </a>
                                        <div className="exchange-information">
                                          <div className="item">
                                            <h3>MRP</h3>
                                            <p id="choosen_product_mrp">
                                              Rs. {brandProduct.price}
                                            </p>
                                          </div>
                                          <div
                                            className="item highlighted offer"
                                            id="additional-discounts"
                                          >
                                            {/* <h3>Additional Discounts</h3>
                                            <ul id="discount-offers">
                                              <li>
                                                <span className="offer-title">
                                                  Offer Title
                                                  <span className="offer-valid-till">
                                                    (Valid till: 2022-10-22
                                                    05:45)
                                                  </span>
                                                </span>
                                                <span className="discount-amount">
                                                  Rs. 2000
                                                </span>
                                              </li>
                                            </ul> */}
                                          </div>
                                          <div className="item highlighted">
                                            <h3>Amount You Pay</h3>
                                            <p id="net_payable_price">
                                              Rs. {brandProduct.price - exchangePrice}
                                            </p>
                                          </div>
                                          <div className="item">
                                            <h3>Amount You Save</h3>
                                            <p id="amount_save">Rs. {exchangePrice}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">

                                    <form onSubmit={handleSubmit}>
                                    <div className="modal-right">
                                      <h2>Confirm Your Evaluation</h2>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label htmlFor="full_name">
                                              Full Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customer_full_name"
                                              name="customer_full_name"
                                              placeholder="Full Name"
                                             {...bindFullName}
                                            />
                                             {errorMessageFullname ? (<><p style={{'color':'red'}}>{errorMessageFullname}</p></>) : ""}  
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label htmlFor="address">
                                              Address
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customer_address"
                                              name="customer_address"
                                              placeholder="Address"
                                              {...bindAddress}
                                            />
                                             {errorMessageAddress ? (<><p style={{'color':'red'}}>{errorMessageAddress}</p></>) : ""}  
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label htmlFor="phone_number">
                                              Phone Number
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customer_phone_number"
                                              name="customer_phone_number"
                                              placeholder="Phone Number"
                                              {...bindPhone}
                                            />
                                             {errorMessagePhone ? (<><p style={{'color':'red'}}>{errorMessagePhone}</p></>) : ""}  
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="customer_email"
                                              name="customer_email"
                                              placeholder="Email"
                                              {...bindEmail}
                                            />
                                             {errorMessageEmail ? (<><p style={{'color':'red'}}>{errorMessageEmail}</p></>) : ""}  
                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="form-check">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="i_agree"
                                              name="i_agree"
                                            />
                                            <label
                                              id="i-agree-label"
                                              className="form-check-label"
                                              htmlFor="IAgree"
                                            >
                                              I agree
                                              <a
                                                href="terms-and-conditions"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                Terms and Conditions
                                              </a>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <button
                                        type="submit"
                                        id="confirm-exchange-btn"
                                        className="btn hvr-sweep-to-right"
                                      >
                                        Confirm Exchange
                                      </button>
                                      {successMessage ? (<><p style={{'color':'green'}}>{successMessage}</p></>) : ""}
                                    </div>
                                    </form>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                          ) : (
                        ""
                      )}

                      </div>
                    ) : (
                      ""
                    )}
                  {/* </form> */}
                </div>

                {!next && product && productType && workingCondition ? (
                  <div className="content-footer" style={{ bottom: "0px" }}>
                    <div className="footer-wrapper">
                      <div className="evaluated-price">
                        <h5>Evaluated Price</h5>
                        <h4 id="evaluated-price">
                          Rs. {exchangePrice}
                          </h4>
                      </div>
                      <div className="next-page-info">
                        <h6>Next Page: Interested New Product Details</h6>
                      </div>
                      <a
                        type="button"
                        className="continue-btn hvr-sweep-to-right"
                        onClick={() => {
                          if (workingCondition && productType && product) {
                            setNext(true);
                            fetchBrands();
                          }
                        }}
                      >
                        Continue
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Exchange;
