import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Layouts/Breadcrumb";
import Data from "../../utils/data";
import Api from "../../utils/api";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfiniteScroll from "react-infinite-scroller";
import LoadingImage from "../../../assets/img/loading.gif";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import ListProduct from "./ListProduct/ListProduct";
import MetaTags from "react-meta-tags";
import AddSlider from "./CategorySlider/AddSlider";
import CategorySkeleton from "../Skeleton/CategorySkeleton";
const Brand = (props) => {
  let history = useHistory();
  const [brand, setBrand] = useState({});
  const [categories, setCategories] = useState([]);
  const [meta, setMeta] = useState({});
  const [products, setProducts] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [priceRangeChange, setPriceRangeChange] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [productPaginate, setProductPaginate] = useState({});
  const [nextHref, setNextHref] = useState("");
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  function fetchBrand() {
    let params = fetchParams();
    let url = new URL(Data.API_BASE_URL + "get/brand-" + getBrandId());
    url.search = new URLSearchParams(params).toString();

    fetch(url, {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        setHasMoreItems(true);
        setNextHref("");
        setBrandData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getBrandId() {
    return props.match.params["brand_name"];
  }
  function fetchParams(page = null) {
    let params = {};
    let range = new URLSearchParams(props.location.search).get("range");
    let sort = new URLSearchParams(props.location.search).get("sort");
    let cat = new URLSearchParams(props.location.search).get("cat");
    let sub = new URLSearchParams(props.location.search).get("sub");
    let type = new URLSearchParams(props.location.search).get("type");
    if (page == 1)
      if (nextHref) {
        params.page = new URLSearchParams(new URL(nextHref).search).get("page");
      }
    if (range) {
      params.range = range;
    }
    if (sort) {
      params.sort = sort;
    }
    if (cat) {
      params.filter = "cat=" + cat;
      params.cat = cat;
    } else if (sub) {
      params.filter = "sub=" + sub;
      params.sub = sub;
    } else if (type) {
      params.filter = "type=" + type;
      params.type = type;
    }
    return params;
  }
  function setBrandData(data) {
    setBrand(data["brand"]);
    // setProducts([]);
    setProducts(data["products"]);
    setMeta(data["meta"]);
    setProductPaginate(data["productPaginate"]);
    setPriceRange(data["price_range"]);
    setCategories(data["categories"]);
    let range = new URLSearchParams(props.location.search).get("range");
    if (range) {
      range = range.split("-");
      let newRange = {};
      newRange.min = range[0];
      newRange.max = range[1];
      setPriceRangeChange(newRange);
    } else {
      setPriceRangeChange(data["price_range"]);
    }
    setSliders(data["sliders"]);
    setIsloading(false);
  }
  function handleChange(e) {
    const item = e.target.name;
    const val = e.target.value;
    const isChecked = e.target.checked;
  }
  function handleFliterBy(e) {
    let params = fetchParams();
    history.push({
      pathname: props.match.url,
      search: e.target.value,
    });
  }
  function handlePriceChange(value) {
    let params = fetchParams();
    params.range = value.min + "-" + value.max;
    history.push({
      pathname: props.match.url,
      search: new URLSearchParams(params).toString(),
    });
  }
  function handleSortBy(e) {
    let params = fetchParams();
    params.sort = e.target.value;
    history.push({
      pathname: props.match.url,
      search: new URLSearchParams(params).toString(),
    });
  }
  useEffect(() => {
    setIsloading(true);
    fetchBrand();
  }, [props.match.params]);

  function loadMore() {
    let url;
    let params = fetchParams(1);
    if (nextHref) {
      url = nextHref;
      url = new URL(nextHref);
    } else {
      url = Data.API_BASE_URL + "get/brand-" + getBrandId();
      url = new URL(Data.API_BASE_URL + "get/brand-" + getBrandId());
    }
    url.search = new URLSearchParams(params).toString();
    fetch(url, {
      method: "GET",
    })
      .then(Api.handleResponseAndError)
      .then((response) => {
        response.data.products.map(function (product) {
          products.push(product);
        });

        if (response.data.productPaginate.next_page_url) {
          setNextHref(response.data.productPaginate.next_page_url);
        } else {
          setHasMoreItems(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <React.Fragment>
      <div className="search-page" id="sr1">
        <div className="search-content">
          {isLoading ? (
            <div
              className="container"
              style={{ textAlign: "center", padding: "20px 0px" }}
            >
              <CategorySkeleton />
            </div>
          ) : (
              <div className="container">
                <Breadcrumb
                  levelOne={{
                    name: brand.name,
                    link: "/brand/" + brand.brand_url,
                  }}
                />
                <MetaTags>
                  <title>{meta.title ? meta.title : brand.name}</title>
                  <meta name="description" content={meta.description} />
                  <meta property="og:title" content={meta.title} />
                  <meta name="keywords" content={meta.keywords} />
                </MetaTags>
                <div className="row search-row">
                  <div className="facet-results ">
                    <div className="filter-wrp">
                      <div className="left-wrp">
                        <div className="filter-container" id="facets">
                          <div className="filter-type">
                            <div className="filter-section">
                              <div className="filter-name filter-accordion">
                                <div className="filter-header">
                                  <span className="cat-name"> Shop By </span>
                                  <FontAwesomeIcon
                                    className="sh-icon arrow_down"
                                    icon="angle-down"
                                  />
                                  {/* <i className="sh-icon dg-chevron_down"> </i> */}
                                </div>
                              </div>
                              <div className="filter-collapse">
                                <div className="filter-accordion cat-layer active">
                                  <div className="filter-section">
                                    <div className="filter-header">
                                      <a
                                        href="#"
                                        data-attribute="taxonomies.taxonomies_hierarchical"
                                        data-value="Home Appliances"
                                        className="top-cat decoration-none active space-15"
                                      >
                                        <span className="cat-name">
                                          <b>{brand.name}</b>
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="filter-type">
                            <div className="filter-section">
                              <div className="filter-name filter-accordion">
                                <div className="filter-header">
                                  <span className="cat-name"> Price </span>
                                  <FontAwesomeIcon
                                    className="sh-icon arrow_down"
                                    icon="angle-down"
                                  />
                                </div>
                              </div>
                              <div className="filter-collapse">
                                <div className="collapse-body">
                                  <div
                                    className="range-slider"
                                    style={{ margin: "20px" }}
                                  >
                                    <InputRange
                                      maxValue={priceRange.max}
                                      minValue={priceRange.min}
                                      formatLabel={(value) => `NPR ${value}`}
                                      onChange={(value) =>
                                        setPriceRangeChange(value)
                                      }
                                      onChangeComplete={(value) =>
                                        handlePriceChange(value)
                                      }
                                      value={priceRangeChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AddSlider sliders={sliders} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="search-results">
                    {brand.banner ? (
                      <div
                        className="mobile-cat-banner"
                        style={{ width: "100%" }}
                      >
                        <img
                          src={brand.banner}
                          style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "100%",
                          }}
                        />
                      </div>
                    ) : (
                        ""
                      )}
                    <div className="grid-view">
                      <div className="clearfix search-header">
                        <h3 style={{ marginLeft: "20px" }}>{brand.name}</h3>
                        <div className="search-text" id="stats">
                          Showing {productPaginate.total} Results
                      </div>
                        <div className="sort-wrp">
                          <span className="sort-by">Sort by</span>
                          <section className="sort-container">
                            <div className="selectric-wrapper selectric-sort">
                              <select
                                name="sort"
                                title="sort"
                                className="sort-by-select"
                                value={fetchParams().sort}
                                onChange={handleSortBy}
                              >
                                <option value=""> - </option>
                                <option value="new">New In</option>
                                <option value="low-to-high">Lowest Price</option>
                                <option value="high-to-low">Highest Price</option>
                              </select>

                              <div className="selectric-items" tabIndex="-1">
                                <div className="selectric-scroll">
                                  <ul>
                                    <li data-index="0" className="selected">
                                      Most Relevant
                                  </li>
                                    <li data-index="1" className="">
                                      Lowest Price
                                  </li>
                                    <li data-index="2" className="last">
                                      Highest Price
                                  </li>
                                  </ul>
                                </div>
                              </div>

                              <input className="selectric-input" tabIndex="0" />
                            </div>
                          </section>
                        </div>
                        <div className="sort-wrp">
                          <span className="sort-by">Filter By</span>
                          <section className="sort-container">
                            <div className="selectric-wrapper selectric-sort">
                              <select
                                name="sort"
                                title="sort"
                                className="sort-by-select"
                                value={fetchParams().filter}
                                onChange={handleFliterBy}
                              >
                                <option value=""> All Categories </option>
                                {categories.map(function (category, index) {
                                  return (
                                    <React.Fragment>
                                      <option
                                        value={"cat=" + category.category_url}
                                        key={index}
                                      >
                                        {category.name}
                                      </option>
                                      {category.subCategories.map(function (
                                        subCategory,
                                        indexTwo
                                      ) {
                                        return (
                                          <React.Fragment>
                                            <option
                                              value={
                                                "sub=" +
                                                subCategory.sub_category_url
                                              }
                                              key={indexTwo}
                                            >
                                              &nbsp;&nbsp;&nbsp;{" "}
                                              {subCategory.name}
                                            </option>
                                            {subCategory.subCategoryTypes.map(
                                              function (
                                                subCategoryType,
                                                indexThree
                                              ) {
                                                return (
                                                  <option
                                                    value={
                                                      "type=" +
                                                      subCategoryType.sub_category_type_url
                                                    }
                                                    key={indexThree}
                                                  >
                                                    &nbsp;&nbsp;&nbsp;
                                                  &nbsp;&nbsp;&nbsp;{" "}
                                                    {subCategoryType.name}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </React.Fragment>
                                        );
                                      })}
                                    </React.Fragment>
                                  );
                                })}
                              </select>

                              <div className="selectric-items" tabIndex="-1">
                                <div className="selectric-scroll">
                                  <ul>
                                    <li data-index="0" className="selected">
                                      Most Relevant
                                  </li>
                                    <li data-index="1" className="">
                                      Lowest Price
                                  </li>
                                    <li data-index="2" className="last">
                                      Highest Price
                                  </li>
                                  </ul>
                                </div>
                              </div>

                              <input className="selectric-input" tabIndex="0" />
                            </div>
                          </section>
                        </div>

                        <div id="refinement-tags" className="clearfix"></div>
                      </div>
                      <div className="product-scroller js-product-list">
                        <div className="product-container">
                          <div
                            className="product-items row reset-margin"
                            id="hits"
                          >
                            <InfiniteScroll
                              pageStart={0}
                              loadMore={() => loadMore()}
                              hasMore={hasMoreItems}
                              loader={
                                <div style={{ alignContent: "center" }} key={0}>
                                  <img
                                    src={LoadingImage}
                                    style={{
                                      height: "150px",
                                      display: "block",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }}
                                  />
                                </div>
                              }
                            >
                              {products.map(function (product, index) {
                                return (
                                  <div
                                    className="slide col-md-3 reset-padding fadeInUp"
                                    key={index}
                                  >
                                    <ListProduct product={product} />
                                  </div>
                                );
                              })}
                            </InfiniteScroll>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Brand;
