import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Slider from "react-slick";
import NextSlider from "../../Layouts/Silder/NextSlider";
import PrevSlider from "../../Layouts/Silder/PrevSlider";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
const SupplierListLayout = (props) => {

  return (
    <div className="home-brand-list">
      <div className="container" align="center">
        <h3 className="title-box"><b>{props.view.title} <small>{props.view.sub_title}</small></b></h3>
        <div className="grid-wrapper" style={{ marginBottom: "25px" }}>
          <div className="row">
            {props.view.supplier_list.map(function (supplier, index) {
              return (
                <div className="col-lg-3 col-md-6 m-b-15" key={index}>
                  <Link to={"/supplier/" + supplier.slug}>
                    <LazyLoadImage
                      style={{ height: "50px", width: "180px", objectFit: "contain" }}
                      className="img-responsive mycat lazy-hidden loaded"
                      src={supplier.logo}
                    />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

      </div>
    </div>
  );
};

export default SupplierListLayout;
